import { createSlice } from '@reduxjs/toolkit';
import { coreItem } from '0_variables/coreItem';

const initialState = [];

const filesSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    fetchFilesStore: (state, action) => {
      const { items } = action.payload;
      const newFileList = [
        ...items.map((v) => {
          const foundItem = state.find((v2) => v2.fileID === v.fileID);
          if (foundItem === undefined) {
            return { ...v, Select: false, Opened: false };
          } else {
            return {
              ...foundItem,
              ...v,
              Select: foundItem.Select,
              Opened: foundItem.Opened,
            };
          }
        }),
      ];
      return newFileList;
    },
    selectSingleFile: (state, action) => {
      const { fileID } = action.payload;

      const selectedFile = state.find((el) => el.fileID === fileID);
      if (selectedFile) {
        selectedFile.Select = !selectedFile.Select;
      }
    },
    shiftSelectFiles: (state, action) => {
      const { items } = action.payload;
      const updated = state.map((el) => {
        const foundItem = items.find((v) => v.fileID === el.fileID);
        if (foundItem === undefined) {
          return { ...el, Select: false };
        } else {
          return { ...el, Select: foundItem.Select };
        }
      });
      return updated;
    },
    selectPageAllFiles: (state, action) => {
      const { productName, fileIDArray, status, groupSelector } =
        action.payload;
      if (productName === 'all') {
        return state.map((el) => ({ ...el, Select: status }));
      } else {
        const tracerList = coreItem[productName].tracers.map(
          (el) => el.shortname,
        );
        const updated = state.map((el) =>
          tracerList.includes(el.Tracer) &&
          el.Group >= groupSelector &&
          fileIDArray.includes(el.id)
            ? { ...el, Select: status }
            : el,
        );
        return updated;
      }
    },
    selectAllFiles: (state, action) => {
      const { productName, status, groupSelector } = action.payload;
      if (productName === 'all') {
        return state.map((el) => ({ ...el, Select: status }));
      } else {
        const tracerList = coreItem[productName].tracers.map(
          (el) => el.shortname,
        );
        const updated = state.map((el) =>
          tracerList.includes(el.Tracer) && el.Group >= groupSelector
            ? { ...el, Select: status }
            : el,
        );
        return updated;
      }
    },
    openSelectedFile: (state, action) => {
      const { productName, openState } = action.payload;
      const tracerList = coreItem[productName].tracers.map(
        (obj) => obj.shortname,
      );
      const updatedSelectedFilesOpen = state.map((el) =>
        tracerList.includes(el.Tracer) &&
        el.Select === true &&
        el.Complete === true
          ? { ...el, Opened: openState }
          : el,
      );
      return updatedSelectedFilesOpen;
    },
    openSingleFile: (state, action) => {
      const { fileID } = action.payload;

      const selectedFile = state.find((el) => el.fileID === fileID);
      if (selectedFile) {
        selectedFile.Opened = true;
      }
    },
    closeSingleFile: (state, action) => {
      const { fileID } = action.payload;

      const selectedFile = state.find((el) => el.fileID === fileID);
      if (selectedFile) {
        selectedFile.Opened = false;
      }
    },
    closeAllFiles: (state) => {
      const updatedFilesAsClosed = state.map((el) => {
        return { ...el, Opened: false };
      });
      return updatedFilesAsClosed;
    },
  },
});

export const {
  fetchFilesStore,
  selectSingleFile,
  shiftSelectFiles,
  selectPageAllFiles,
  selectAllFiles,
  openSelectedFile,
  openSingleFile,
  closeSingleFile,
  closeAllFiles,
} = filesSlice.actions;

export default filesSlice.reducer;

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { debounce } from 'lodash';
import { coreItem } from '0_variables/coreItem';
import { RefFactor } from '0_variables/utils';
import { updateViewOption } from '1_reduxs/reducers/viewOptionReducer';
import { ViewerGroup } from '5_components';
import { ViewTopMenu } from './ViewTopMenu,';
import styles from './Container.module.scss';

export default function Container(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const fileList = useSelector((state) => state.fileList);
  const [selectedFile, setSelectedFile] = useState(
    fileList.find(({ fileID }) => fileID === params.fileID),
  );
  const {
    clipConfig,
    opacity,
    colorMap,
    showCrosshair,
    showNormalized,
    showInverted,
    window_max_out,
    window_min_out,
    window_max_in,
    window_min_in,
  } = useSelector((state) => state.viewOption);
  const productName = params.product.toLowerCase();
  const productCoreItem = coreItem[productName];
  const CTN_Mode = productCoreItem.CTN_Mode;
  const username = sessionStorage.getItem('username');

  const [colorbarPressed, setColorbarPressed] = useState({
    top: false,
    center: false,
    bottom: false,
  });

  const settingOfProduct = useSelector((state) => state.setting[productName]);
  const tracerName = selectedFile['Tracer'];
  const refName = settingOfProduct.defaultRef[tracerName];
  const RF = RefFactor({
    productName,
    refName,
    ponsRF: selectedFile['ratio_gry2pons'],
    crblRF: selectedFile['ratio_gry2crbl'],
    wmRF: selectedFile['ratio_gry2wm'],
    cgRF: selectedFile['ratio_gry2cg'],
    global: 1 / selectedFile['Global'],
  });

  const out_suvr_min = selectedFile.out_suvr_min * RF;
  const out_suvr_max = selectedFile.out_suvr_max * RF;
  const outputPixelInfo = {
    regularizedMax: 32767,
    min: out_suvr_min,
    max: out_suvr_max,
    width: CTN_Mode ? 32767 : out_suvr_max - out_suvr_min,
    center: (out_suvr_max + out_suvr_min) / 2,
  };
  const in_suvr_min = selectedFile.in_suvr_min * RF;
  const in_suvr_max = selectedFile.in_suvr_max * RF;
  const inputPixelInfo = {
    regularizedMax: 32767,
    min: in_suvr_min,
    max: in_suvr_max,
    width: CTN_Mode ? 32767 : in_suvr_max - in_suvr_min,
    center: (in_suvr_max + in_suvr_min) / 2,
    // value: 0,
  };

  const changeViewOption = useCallback(
    (optionName, updateValue) => {
      dispatch(
        updateViewOption({
          optionName,
          updateValue,
        }),
      );
    },
    [dispatch],
  ); // Add an empty array as the second argument to useCallback

  useEffect(() => {
    const selectedFile = fileList.find(
      ({ fileID }) => fileID === params.fileID,
    );
    setSelectedFile(selectedFile);
    changeViewOption('colorMap', productName !== 'dat' ? 'jet' : 'invertGray');
  }, [params.fileID, fileList, productName, username, changeViewOption]);

  const [viewerWidth, setViewerWidth] = useState(null);

  useEffect(() => {
    const handleResize = debounce(() => {
      const size = {
        width: window.innerWidth || document.body.clientWidth,
        height: window.innerHeight || document.body.clientHeight,
      };
      if (size.width > size.height) setViewerWidth(25);
      else setViewerWidth(35);
    }, 100);

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const OutputViewerGroupProps = {
    pixelInfo: outputPixelInfo,
    inout: 'output',
    cmap: colorMap,
    opacity: opacity,
    clipConfig,
    crosshair: showCrosshair,
    inverted: showInverted,
    sNorm: showNormalized,

    coronalWMax: window_max_out,
    setCoronalWMax: (value) => changeViewOption('window_max_out', value),
    sagittalWMax: window_max_out,
    setSagittalWMax: (value) => changeViewOption('window_max_out', value),
    axialWMax: window_max_out,
    setAxialWMax: (value) => changeViewOption('window_max_out', value),
    mipWMax: window_max_out,
    setMipWMax: (value) => changeViewOption('window_max_out', value),

    coronalWMin: window_min_out,
    setCoronalWMin: (value) => changeViewOption('window_min_out', value),
    sagittalWMin: window_min_out,
    setSagittalWMin: (value) => changeViewOption('window_min_out', value),
    axialWMin: window_min_out,
    setAxialWMin: (value) => changeViewOption('window_min_out', value),
    mipWMin: window_min_out,
    setMipWMin: (value) => changeViewOption('window_min_out', value),

    colorbarPressed,
    setColorbarPressed,
  };

  const InputViewerGroupProps = {
    pixelInfo: inputPixelInfo,
    inout: 'input',
    cmap: colorMap,
    opacity: opacity,
    clipConfig,
    crosshair: showCrosshair,
    inverted: showInverted,
    sNorm: !showNormalized,

    coronalWMax: window_max_in,
    setCoronalWMax: (value) => changeViewOption('window_max_in', value),
    sagittalWMax: window_max_in,
    setSagittalWMax: (value) => changeViewOption('window_max_in', value),
    axialWMax: window_max_in,
    setAxialWMax: (value) => changeViewOption('window_max_in', value),
    mipWMax: window_max_in,
    setMipWMax: (value) => changeViewOption('window_max_in', value),

    coronalWMin: window_min_in,
    setCoronalWMin: (value) => changeViewOption('window_min_in', value),
    sagittalWMin: window_min_in,
    setSagittalWMin: (value) => changeViewOption('window_min_in', value),
    axialWMin: window_min_in,
    setAxialWMin: (value) => changeViewOption('window_min_in', value),
    mipWMin: window_min_in,
    setMipWMin: (value) => changeViewOption('window_min_in', value),

    colorbarPressed,
    setColorbarPressed,
  };

  return (
    <div
      className={`${styles['container']}`}
      style={{
        opacity: props.enabled ? 1 : 0.3,
        zIndex: props.enabled ? 0 : -1,
      }}
    >
      <div style={{ border: 'px red solid' }}>{<ViewTopMenu />}</div>

      {viewerWidth && (
        <div className={styles.viewGroup}>
          <ViewerGroup
            {...props}
            viewerWidth={viewerWidth}
            {...OutputViewerGroupProps}
          />
          <ViewerGroup
            {...props}
            viewerWidth={viewerWidth}
            {...InputViewerGroupProps}
          />
        </div>
      )}
    </div>
  );
}

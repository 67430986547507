import React, { useEffect, useState, useCallback } from 'react';
import { HiOutlineSwitchVertical } from 'react-icons/hi';
import { coreItem } from '0_variables/coreItem';
import {
  arrow_down as MEDIA_arrow_down,
  arrow_up as MEDIA_arrow_up,
  Check as MEDIA_Check,
  unCheck as MEDIA_unCheck,
} from '6_media';
import styles from './PacsTable.module.scss';

export default function PacsTable({
  productName,
  tableHead,
  sortedList,
  sortClick,
  setSortClick,
  clickHandler,
  deleteHandler,
  selectHandler,
  doubleClickHandler,
  foldable,
  selectedItem,
  updateSelectPatient,
  updateTracerHandeler,
  tracerInfoList,
  identifier,
  flipStatus,
  setFlipStatus,
}) {
  const [trClick, setTrClick] = useState([]);
  const [slidingTarget, setSlidingTarget] = useState({});
  const [slidingTargetID, setSlidingTargetID] = useState({});
  const [slidingTarget_sub, setSlidingTarget_sub] = useState({});
  const [slidingTargetID_sub, setSlidingTargetID_sub] = useState({});
  const [tracerInfoByFile, setUpdateTracer] = useState([]);
  const tracers = coreItem[productName].tracers.map((v) => v.shortname);

  const _openHandler = useCallback(
    (_id) => {
      if (trClick.includes(_id)) {
        const _trClick = trClick.filter((el) => el !== _id);
        setTrClick([..._trClick]);
      } else {
        setTrClick([...trClick, _id]);
      }
    },
    [trClick],
  );

  // sort order ascend -> descend -> none -> ascend
  const onClickTableHead = (headTitle) => {
    if (
      headTitle === 'Flip' ||
      headTitle === 'Delete' ||
      headTitle === 'Marker'
    )
      return;

    let sortOrder = {};
    if (sortClick[headTitle] === undefined) {
      sortOrder = { [headTitle]: 'ascend' };
    } else {
      if (sortClick[headTitle] === 'ascend') {
        sortOrder = { [headTitle]: 'descend' };
      }
    }

    setSortClick(sortOrder);
  };

  useEffect(() => {
    setUpdateTracer(tracerInfoList);
  }, [tracerInfoList]);

  return (
    <>
      <table className={styles.table}>
        <thead>
          <tr>
            {tableHead.map((head, idx) => (
              <th
                key={`pacs_table_head_${idx}`}
                className={`${styles[head.title.replaceAll(' ', '_')]}`}
                onClick={() => onClickTableHead(head.title)}
              >
                <div>
                  <span>{head.title === 'Marker' ? '' : head.title}</span>
                  <div>
                    {head.title === 'Flip' && (
                      <HiOutlineSwitchVertical size={'1vw'} />
                    )}
                  </div>
                  <div
                    style={{
                      visibility: `${
                        sortClick[head.title] === undefined ? 'hidden' : ''
                      }`,
                    }}
                  >
                    <img
                      src={
                        sortClick[head.title] === 'ascend'
                          ? MEDIA_arrow_down
                          : MEDIA_arrow_up
                      }
                      alt=""
                    />
                  </div>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedList.map((fileData, fileIdx) => {
            return (
              <React.Fragment key={`file_idx_${fileIdx}`}>
                <tr
                  className={styles.trMain}
                  style={{
                    color: `${
                      selectedItem === fileData ? styles['active-color'] : ''
                    }`,
                  }}
                  onClick={() => {
                    _openHandler(fileData.id);
                    clickHandler(fileData);
                  }}
                  onDoubleClick={() => {
                    doubleClickHandler(fileData);
                  }}
                  onMouseLeave={() => {
                    setSlidingTarget({});
                    setSlidingTargetID({});
                  }}
                >
                  {tableHead.map((tableHeadColumn, th_idx) => (
                    <td
                      key={`pacs_table_td_${th_idx}`}
                      className={`${
                        styles[tableHeadColumn.title.replaceAll(' ', '_')]
                      } ${
                        trClick.includes(fileData.id)
                          ? styles.active
                          : styles.inactive
                      }`}
                    >
                      {(() => {
                        const tracerInfo = coreItem[productName].tracers.find(
                          (el) =>
                            el.shortname ===
                            fileData[tableHeadColumn.fieldName],
                        );
                        if (tableHeadColumn.title === 'Tracer') {
                          if (
                            identifier !== 'upload' &&
                            productName === 'amyloid'
                          ) {
                            return (
                              <div>
                                <div
                                  className={styles.selectTracerContainer}
                                  tabIndex={0}
                                  onBlur={() => {
                                    setUpdateTracer(
                                      tracerInfoByFile.map((obj) => {
                                        return { ...obj, clickState: false };
                                      }),
                                    );
                                  }}
                                >
                                  <div
                                    className={styles.selectTracer}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      setUpdateTracer(
                                        tracerInfoByFile?.map((obj) =>
                                          obj.idx === fileData.id
                                            ? {
                                                ...obj,
                                                clickState: !obj.clickState,
                                              }
                                            : obj,
                                        ),
                                      );
                                    }}
                                  >
                                    <div>
                                      {tracerInfoByFile[fileIdx]?.tracer}
                                    </div>
                                    <img src={MEDIA_arrow_down} alt="" />
                                  </div>
                                  <div
                                    className={styles.selectTracer_divs}
                                    style={{
                                      display: `${
                                        tracerInfoByFile[fileIdx]?.clickState
                                          ? ''
                                          : 'none'
                                      }`,
                                    }}
                                  >
                                    {tracers.map((tracer, tracerIdx) => {
                                      return (
                                        <div
                                          key={`tracer_${tracerIdx}`}
                                          className={styles.selectTracer_div}
                                          onClick={(event) => {
                                            event.stopPropagation();

                                            const newTracer =
                                              tracerInfoByFile?.map((obj) =>
                                                obj.idx === fileData.id
                                                  ? {
                                                      ...obj,
                                                      clickState: false,
                                                      tracer: tracer,
                                                    }
                                                  : obj,
                                              );
                                            // setUpdateTracer(newTracer);
                                            updateTracerHandeler(
                                              sortedList,
                                              newTracer,
                                            );
                                          }}
                                        >
                                          {tracer}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            );
                          } else {
                            const fieldName = fileData[
                              tableHeadColumn.fieldName
                            ]
                              .replace('18', '\u00B9\u2078')
                              .replace('99mtc', '\u2079\u2079\u1d50Tc');
                            return (
                              <div
                                className={styles.TracerMarker}
                                style={{ borderColor: tracerInfo?.color }}
                              >
                                {fieldName}
                              </div>
                            );
                          }
                        } else if (tableHeadColumn.title === 'Marker') {
                          return (
                            <div>{fileData[tableHeadColumn.fieldName]}</div>
                          );
                        } else if (tableHeadColumn.title === 'Delete') {
                          return (
                            <span
                              onClick={(e) => {
                                e.stopPropagation();
                                deleteHandler(fileData, fileData.id, -1);
                              }}
                            >
                              {fileData[tableHeadColumn.fieldName]}
                            </span>
                          );
                        } else if (tableHeadColumn.title === 'Select') {
                          return (
                            <span
                              onClick={(e) => {
                                e.stopPropagation();
                                updateSelectPatient(
                                  !fileData.Select,
                                  fileData.id,
                                );
                              }}
                            >
                              <img
                                className={styles.checkbox}
                                src={
                                  fileData.Select ? MEDIA_Check : MEDIA_unCheck
                                }
                                alt="checkbox"
                              />
                            </span>
                          );
                        } else if (tableHeadColumn.title === 'Patient name') {
                          return (
                            <div
                              className={
                                slidingTarget?.id === fileData.id &&
                                slidingTarget?.enable
                                  ? styles.slideContainer
                                  : ''
                              }
                            >
                              <span
                                onMouseEnter={(e) => {
                                  const currentDom = e.target.offsetWidth;
                                  const parentDom =
                                    e.target.parentElement.offsetWidth;
                                  const slide = currentDom > parentDom;
                                  setSlidingTarget({
                                    id: fileData.id,
                                    enable: slide,
                                  });
                                }}
                              >
                                {fileData[
                                  tableHeadColumn.fieldName
                                ]?.toString()}
                              </span>
                            </div>
                          );
                        } else if (tableHeadColumn.title === 'Patient ID') {
                          return (
                            <div
                              className={
                                slidingTargetID?.id === fileData.id &&
                                slidingTargetID?.enable
                                  ? styles.slideContainer
                                  : ''
                              }
                            >
                              <span
                                onMouseEnter={(e) => {
                                  const currentDom = e.target.offsetWidth;
                                  const parentDom =
                                    e.target.parentElement.offsetWidth;
                                  const slide = currentDom > parentDom;
                                  setSlidingTargetID({
                                    id: fileData.id,
                                    enable: slide,
                                  });
                                }}
                              >
                                {fileData[
                                  tableHeadColumn.fieldName
                                ]?.toString()}
                              </span>
                            </div>
                          );
                        } else if (tableHeadColumn.title === 'Flip') {
                          return (
                            <div
                              className={styles.flipContainer}
                              onClick={() => {
                                const isInclude = flipStatus.includes(
                                  fileData.id,
                                );
                                if (isInclude) {
                                  setFlipStatus([
                                    ...flipStatus.filter(
                                      (el) => el !== fileData.id,
                                    ),
                                  ]);
                                } else {
                                  setFlipStatus([...flipStatus, fileData.id]);
                                }
                              }}
                            >
                              <img
                                src={
                                  flipStatus.includes(fileData.id)
                                    ? MEDIA_Check
                                    : MEDIA_unCheck
                                }
                                className={styles.checkbox}
                                alt="flip check"
                              />
                            </div>
                          );
                        } else {
                          return fileData[
                            tableHeadColumn.fieldName
                          ]?.toString();
                        }
                      })()}
                    </td>
                  ))}
                </tr>

                {fileData.seriesItem.map((seriesData, seriesIdx) => {
                  return (
                    <tr
                      key={`pacs_table_tr_${seriesIdx}`}
                      className={`${styles.trSub} ${
                        !foldable || trClick.includes(fileData.id)
                          ? styles.active
                          : styles.inactive
                      }`}
                      onClick={() => clickHandler(seriesData)}
                      onMouseLeave={() => {
                        setSlidingTarget_sub({});
                        setSlidingTargetID_sub({});
                      }}
                    >
                      {tableHead.map((tableHeadColumn, seriesThIdx) => {
                        return (
                          <td
                            key={`pacs_table_series_td_${seriesThIdx}`}
                            className={`${
                              styles[tableHeadColumn.title.replaceAll(' ', '_')]
                            }`}
                          >
                            {(() => {
                              if (tableHeadColumn.title === 'Delete')
                                return (
                                  <span
                                    style={{ fontSize: '1.0vw' }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      deleteHandler(
                                        seriesData,
                                        fileData.id,
                                        seriesData.id,
                                      );
                                    }}
                                  >
                                    {seriesData[tableHeadColumn.fieldName]}
                                  </span>
                                );
                              else if (tableHeadColumn.title === 'Patient name')
                                return (
                                  <div
                                    className={
                                      slidingTarget_sub?.id === fileData.id &&
                                      slidingTarget_sub?.subId ===
                                        seriesData.id &&
                                      slidingTarget_sub?.enable
                                        ? styles.slideContainer
                                        : ''
                                    }
                                  >
                                    <span
                                      onMouseEnter={(e) => {
                                        const currentDom = e.target.offsetWidth;
                                        const parentDom =
                                          e.target.parentElement.offsetWidth;
                                        const slide = currentDom > parentDom;
                                        setSlidingTarget_sub({
                                          id: fileData.id,
                                          subId: seriesData.id,
                                          enable: slide,
                                        });
                                      }}
                                    >
                                      {seriesData[tableHeadColumn.fieldName]}
                                    </span>
                                  </div>
                                );
                              else if (tableHeadColumn.title === 'Patient ID')
                                return (
                                  <div
                                    className={
                                      slidingTargetID_sub?.id === fileData.id &&
                                      slidingTargetID_sub?.subId ===
                                        seriesData.id &&
                                      slidingTargetID_sub?.enable
                                        ? styles.slideContainer
                                        : ''
                                    }
                                  >
                                    <span
                                      onMouseEnter={(e) => {
                                        const currentDom = e.target.offsetWidth;
                                        const parentDom =
                                          e.target.parentElement.offsetWidth;
                                        const slide = currentDom > parentDom;
                                        setSlidingTargetID_sub({
                                          id: fileData.id,
                                          subId: seriesData.id,
                                          enable: slide,
                                        });
                                      }}
                                    >
                                      {seriesData[tableHeadColumn.fieldName]}
                                    </span>
                                  </div>
                                );
                              else if (tableHeadColumn.title === 'Select') {
                                return (
                                  <span
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      selectHandler(
                                        seriesData,
                                        fileData.id,
                                        seriesData.id,
                                      );
                                    }}
                                  >
                                    <img
                                      className={styles.checkbox}
                                      src={
                                        seriesData[tableHeadColumn.fieldName]
                                          ? MEDIA_Check
                                          : MEDIA_unCheck
                                      }
                                      alt="checkbox"
                                    />
                                  </span>
                                );
                              } else
                                return seriesData[tableHeadColumn.fieldName];
                            })()}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

import React, { useEffect, useState, useRef } from 'react';

export default function TextDisplay(props) {
  // debugger;
  return (
    <div
      style={{
        ...props.style,
        pointerEvent: 'none',
        userSelect: 'none',
      }}
    >
      {(() => {
        if (props.name === 'direction') {
          const title = props.title;
          return title;
        } else {
          const title = `${props.title}: ${props.value}`;
          return title;
        }
      })()}
    </div>
  );
}

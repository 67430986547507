import React, { createRef, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { useInterval } from 'react-use';
import { coreItem } from '0_variables/coreItem';
import {
  limitNum as UTILS_LIMITNUM,
  metaDataLoader,
  getLicenseInfo as getLicenseInfoAPI,
} from '0_variables/utils';
import { subRegions } from '0_variables/subRegions';
import { getFilesThunk } from '1_reduxs/actions/filesAction';
import { initControl } from '1_reduxs/reducers/controllerReducer';
import { initSettingThunk } from '1_reduxs/actions/settingAction';
import { updateLicenseInfo } from '1_reduxs/reducers/licenseReducer';
import { getCounts as getCounts_API } from '2_services/uploadApi';
import { AuthCheck } from '2_services/loginApi';
import {
  Header,
  Menu,
  Worklist,
  SpinnerModal,
  ReportPages,
  ScrollToTop,
  VersionModal,
} from '5_components';
import styles from './GlobalLayout.module.scss';

const GlobalLayout = React.forwardRef((props, ref) => {
  const history = useHistory();
  const reloadChecker = props.reloadChecker
  const params = useParams();
  const location = useLocation();
  const [elRefs, setElRefs] = useState([]);
  const dispatch = useDispatch();
  const modalState = useSelector((state) => state.modal);
  const productName = params.product.toLowerCase();
  const productCoreItem = coreItem[productName];
  const CTN_Mode = productCoreItem.CTN_Mode;
  const Cloud_Mode = productCoreItem.Cloud_Mode; // 구 클라우드 버전(AWS, 데모용)
  const tracerList = productCoreItem.tracers.map((obj) => obj.shortname);
  const fileList = useSelector((state) => state.fileList);
  const licenseState = useSelector((state) => state.license);
  // const productList = fileList.filter((el) => tracerList.includes(el.Tracer));
  // const notCompleteList = productList
  //   .filter((el) => !el.Complete)
  //   .map((el) => el.Complete);
  const selectedFileList = fileList.filter(
    (item) =>
      item.Select && // 선택된
      tracerList.includes(item.Tracer) && // product tracer와 일치하는
      item.Group !== 0 && // worklist에 포함된
      item.Complete === true, // analysis 완료된
  );
  const selectedFileListOnProduct = selectedFileList.map(
    ({ fileID }) => fileID,
  );

  const [prevPage, setPrevPage] = useState('analysis');
  const setting = useSelector((state) => state.setting);
  const settingOfProduct = setting[productName];
  const defaultRegionLength = subRegions[productName]
    .map((v) => v.level)
    .filter((lev) => lev === 0).length;
  const settingRegionLength = settingOfProduct.list
    .map((v) => v.level)
    .filter((lev) => lev === 0).length;
  const limitNum = UTILS_LIMITNUM;
  const [downloadState, setDownloadState] = useState(false);

  // init
  useEffect(() => {
    metaDataLoader();
    // get files
    dispatch(initSettingThunk());
    dispatch(initControl());
    dispatch(getFilesThunk());
  }, [dispatch, productName]);

  useEffect(() => {
    const currentPage = location.pathname.split('/')[2];
    if (currentPage === 'view' || currentPage === 'analysis') {
      setPrevPage(location.pathname.split('/')[2]);
    }
  }, [location]);

  // token validation, license check
  useInterval(() => {
    const refreshLicenseInfo = async () => {
      const licenseResult = await getLicenseInfoAPI([productName]);
      dispatch(
        updateLicenseInfo({
          productName,
          ...licenseResult[productName],
        }),
      );
    };

    //token이 있을때만 check
    const token = sessionStorage.getItem('token');
    if (token) {
      AuthCheck();

      // update license Info
      refreshLicenseInfo();
    }
  }, 1000 * 30);

  const pageLength =
    settingRegionLength - defaultRegionLength > limitNum[productName] ? 4 : 3;

  useEffect(() => {
    async function updateLicense() {
      const { setRemainCounts, setEndDate } = props;

      const licenseInfoByProduct = licenseState[productName];
      setEndDate(licenseInfoByProduct.until);

      const token = sessionStorage.getItem('token');

      if (process.env.REACT_APP_IS_CLOUD === 'true') { // 정식 클라우드 버전(OCI)
        const res = await getCounts_API({ token, product: productName });
        setRemainCounts(res.data);
      } else if (Cloud_Mode) { // 구 클라우드 버전(AWS, 데모용)
        const res = await getCounts_API({ token });
        setRemainCounts(res.data);
      } else {
        setRemainCounts(String(licenseInfoByProduct.count)); // 'inf' or Number
      }

      if (
        licenseInfoByProduct.isInitailized &&
        licenseInfoByProduct.valid === false
      ) {
        // url을 직접 치고 들어왔을때, 라이센스가 없는 경우
        alert('A product license is required to use this service.');
        history.goBack(); // move back
      }
    }

    updateLicense();
  }, [Cloud_Mode, history, licenseState, productName, reloadChecker, props]);

  useEffect(() => {
    // TODO: 리포트를 4장으로 변경 가능하도록 수정 필요.
    const productCoreItem = coreItem[productName];
    const tracerList = productCoreItem.tracers.map((obj) => obj.shortname);

    const selectedItem = fileList.filter(
      (item) =>
        tracerList.includes(item.Tracer) && item.Select && item.Group !== 0,
    );
    const newElRefs = selectedItem.map((item) => {
      const count =
        settingRegionLength - defaultRegionLength > limitNum[productName]
          ? item.Tracer === '[18F]FPN'
            ? 3
            : 4
          : 3;
      return Array(count).fill(createRef());
    });

    setElRefs(newElRefs);
  }, [
    defaultRegionLength,
    fileList,
    limitNum,
    productName,
    selectedFileListOnProduct.length,
    settingRegionLength,
  ]);

  return (
    <>
      <ScrollToTop />
      <div className={`${styles['container']}`}>{props.children}</div>
      <div className={styles.backBtn}>
        <span
          onClick={() =>
            history.push(`/${productName}/${prevPage}/${params.fileID}`)
          }
          style={{ paddingBottom: '0.4vw' }}
        >
          <IoMdArrowRoundBack size={'1.5vw'} />
          <div style={{ marginLeft: '0.5vw' }}>{' back to ' + prevPage}</div>
        </span>
      </div>
      <Header />
      <Menu remainCounts={props.remainCounts} endDate={props.endDate} />
      {!CTN_Mode && (
        <Worklist
          {...props}
          elRefs={elRefs}
          selectedFileList={selectedFileList}
          downloadState={downloadState}
          setDownloadState={setDownloadState}
        />
      )}{' '}
      {/* TODO: worklist에서 선택목록 묶음 다운받기 기능 검토중 2022.04.11 (월) */}
      {/* TODO: 선택했을때 리포트를 띄우지 말고, 다운로드 버튼 눌렀을때에만 리포트를 띄우고 다운로드 종료후에 리포트 닫기 */}
      {/* <div className={styles.reportContainer}> */}
      <div
        className={styles.reportContainer}
        style={{
          visibility: props.reportOn ? 'visible' : 'hidden',
          overflow: 'scroll',
        }}
      >
        <div
          className={styles.buttons}
          onClick={() => props.setReportOn(false)}
        >
          <span>X</span>
        </div>
        {/* <div className={styles.buttons} style={{top:"4rem"}} 
            onClick={()=>{
              const selectedFileList = fileList.filter(item=>tracerList.includes(item.Tracer) && item.Select && item.Group !== 0)
              print(elRefs, productName, selectedFileList)}
              // print(elRefs, productName, selectedFileListOnProduct, table1Data, table2Data)}
            }><span>Print</span>
          </div> */}
        {elRefs.map((elRef, i) => {
          const fileID = selectedFileListOnProduct[i];
          // debugger;
          return (
            // <ReportPages fileID={fileID} innerRef={elRef} key={i} reportItembyProduct={utils.reportItembyProduct(productName, fileID)}/>
            props.reportOn && (
              <ReportPages
                key={fileID}
                order={i}
                fileID={fileID}
                innerRef={elRef}
                pageLength={pageLength}
              />
            )
          );
        })}
      </div>
      {modalState.spinner.status && <SpinnerModal />}
      {modalState.versionModal.status && <VersionModal />}
      {/* {modalState.ReportPacsModal.status && <ReportPacsModal />} */}
      {/* {modalState.customROI.status && <CustomROIModal/>} */}
    </>
  );
});
export default GlobalLayout;

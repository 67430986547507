import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './StackViewer.scss';
import { connect } from 'react-redux';
import TextDisplay from '../TextDisplay/TextDisplay';
import { coreItem } from '0_variables/coreItem';
import { clamp } from '0_variables/utils';
import { debounce } from 'lodash';

import * as cornerstone from 'cornerstone-core';
import * as cornerstoneMath from 'cornerstone-math';
import * as cornerstoneTools from 'cornerstone-tools';
import Hammer from 'hammerjs';
import * as cornerstoneWebImageLoader from 'cornerstone-web-image-loader';
cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneWebImageLoader.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;

class StackViewer extends Component {
  state = {
    productName: this.props.productName,
    layerIds: [],
    value: 0,
    valueRatio: 0.5,
    sliceID: 0,
    Backward: false,
  };

  loadImages = (Stacks) => {
    const element = this.props.myForwardedRef.current;
    const currentImageIdIndex = Stacks.at(-1).currentImageIdIndex;
    const promises = [];

    Stacks.forEach((stack) => {
      cornerstoneTools.addStackStateManager(element, ['stack', 'playClip']);
      cornerstoneTools.addStackStateManager(element, [
        'stack',
        'referenceLines',
        'crosshairs',
      ]);
      cornerstoneTools.addToolState(element, 'stack', stack);

      const loadPromise = cornerstone.loadAndCacheImage(
        stack.imageIds[currentImageIdIndex],
      );
      promises.push(loadPromise);
    });

    return Promise.all(promises);
  };

  componentDidMount() {
    const element = this.props.myForwardedRef.current;
    const { Stacks, x, y, z, type, depthInfo } = this.props;
    const {
      pixelInfo,
      referenceLinesync,
      clipConfig,
      wwwcsynchronizer,
      crosshair,
    } = this.props.cornerstoneProps;

    cornerstone.enable(element);
    cornerstoneTools.mouseInput.enable(element);
    cornerstoneTools.mouseWheelInput.enable(element);

    this.loadImages(Stacks).then((images) => {
      const layerIds = images.map((image, index) => {
        const stack = Stacks[index];
        const layerId = cornerstone.addLayer(element, image, stack.options);
        cornerstone.updateImage(element);
        return layerId;
      });

      cornerstone.setActiveLayer(element, layerIds.at(-1));
      cornerstoneTools.wwwc.activate(element, 4);
      cornerstoneTools.stackScrollWheel.activate(element);
      cornerstoneTools.wwwcRegion.activate(element, 2);
      cornerstoneTools.keyboardInput.enable(element);
      wwwcsynchronizer.add(element);

      if (type === 'slice')
        element.addEventListener(
          'cornerstoneimagerendered',
          this.onImageFusionRenderer,
        );
      if (type === 'mip')
        element.addEventListener(
          'cornerstoneimagerendered',
          this.onMipRenderer,
        );
      element.addEventListener('cornerstoneimagerendered', this.onWWWCRenderer);
      if (clipConfig.status) {
        if (type === 'mip')
          cornerstoneTools.playClip(element, clipConfig.speed);
      } else {
        if (type === 'mip') cornerstoneTools.stopClip(element);
      }

      if (type === 'slice') {
        cornerstoneTools.crosshairs.enable(element, 1, referenceLinesync);
        cornerstoneTools.referenceLines.tool.enable(element, referenceLinesync);
        referenceLinesync.add(element);

        if (!crosshair) {
          cornerstoneTools.referenceLines.tool.disable(element);
        }
      }
      cornerstoneTools.dragProbe.disable(element);

      if (type === 'mip') cornerstoneTools.stackScroll.activate(element, 1);
      window.addEventListener('resize', this.onWindowResize);
      window.addEventListener(
        'contextmenu',
        (e) => {
          const isView = window.location.href.split('/')[4] === 'view';
          if (isView) e.preventDefault();
        },
        false,
      );
      if (type === 'slice') {
        const pixel = cornerstone.getPixels(element, x, y, 1, 1);
        const value = (
          (pixel / pixelInfo.regularizedMax) *
          pixelInfo.width
        ).toFixed(2);
        const valueRatio = value / pixelInfo.width;
        this.setState({
          value,
          valueRatio,
          layerIds,
        });
      } else {
        const pixel = cornerstone.getPixels(element, x, y, 1, 1);
        const value = (
          (pixel / pixelInfo.regularizedMax) *
          pixelInfo.width
        ).toFixed(2);
        const valueRatio = value / pixelInfo.width;
        this.setState({
          value,
          valueRatio,
          layerIds,
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const element = this.props.myForwardedRef.current;
    const { layerIds } = this.state;
    const { x, y, z, Stacks, type, direction, depthInfo } = this.props;
    const { pixelInfo, crosshair, clipConfig, referenceLinesync, inverted } =
      this.props.cornerstoneProps;

    if (prevProps.cornerstoneProps !== this.props.cornerstoneProps) {
      if (type === 'slice') {
        const pixel = cornerstone.getPixels(element, x, y, 1, 1);
        const value = (
          (pixel / pixelInfo.regularizedMax) *
          pixelInfo.width
        ).toFixed(2);
        const valueRatio = value / pixelInfo.width;
        this.setState({
          value,
          valueRatio,
        });
      } else {
        const angleUnit = 360 / depthInfo['mip'];
        const angle = angleUnit * this.props.a;
        const cos = Math.cos((Math.PI * angle) / 180);
        const sin = Math.sin((Math.PI * angle) / 180);
        const cartesianX = (x / depthInfo['coronal'] - 0.5) * 76;
        const cartesianY = (y / depthInfo['axial'] - 0.5) * 76;
        const cartesianZ = (z / depthInfo['sagittal'] - 0.5) * 76;
        const scaledX = Number(x);
        const scaledY = Number(y);
        const percentX = cartesianX * cos + cartesianZ * sin + 50;
        const percentY = cartesianY + 50;
        const posX = Number(
          ((percentX * depthInfo['sagittal']) / 100).toFixed(0),
        );
        const posY = Number(((percentY * depthInfo['axial']) / 100).toFixed(0));
        const pixel = cornerstone.getPixels(element, posX, posY, 1, 1);
        const value = (
          (pixel / pixelInfo.regularizedMax) *
          pixelInfo.width
        ).toFixed(2);
        const valueRatio = value / pixelInfo.width;

        this.setState({
          value,
          valueRatio,
        });
      }
    }

    if (type === 'slice') {
      layerIds.forEach((layerId, index) => {
        const layer = cornerstone.getLayer(element, layerId);
        layer.options = {
          ...layer.options,
          ...Stacks[index].options,
          viewport: {
            ...layer.options.viewport,
            ...Stacks[index].options.viewport,
            invert: index === 1 ? inverted : false,
          },
        };
        layer.viewport = {
          ...layer.viewport,
          ...Stacks[index].options.viewport,
          invert: index === 1 ? inverted : false,
        };
      });
    } else {
      layerIds.forEach((layerId, index) => {
        const layer = cornerstone.getLayer(element, layerId);
        layer.options = {
          ...layer.options,
          ...Stacks[index].options,
          viewport: {
            ...layer.options.viewport,
            ...Stacks[index].options.viewport,
            invert: inverted,
          },
        };
        layer.viewport = {
          ...layer.viewport,
          ...Stacks[index].options.viewport,
          invert: inverted,
        };
      });
    }

    if (clipConfig.status) {
      if (type === 'mip') cornerstoneTools.playClip(element, clipConfig.speed);
    } else {
      if (type === 'mip') cornerstoneTools.stopClip(element);
    }

    if (crosshair) {
      if (type === 'slice')
        cornerstoneTools.referenceLines.tool.enable(element, referenceLinesync);
    } else {
      if (type === 'slice')
        cornerstoneTools.referenceLines.tool.disable(element);
    }

    const petLayerId = layerIds.at(-1);
    const petLayer = cornerstone.getLayer(element, petLayerId);
    petLayer.options.viewport.voi = {
      windowWidth: clamp(this.props.WMax - this.props.WMin, 0, 32767),
      windowCenter: clamp((this.props.WMax + this.props.WMin) / 2, 0, 32767),
    };
    petLayer.viewport.voi = {
      windowWidth: clamp(this.props.WMax - this.props.WMin, 0, 32767),
      windowCenter: clamp((this.props.WMax + this.props.WMin) / 2, 0, 32767),
    };
    const enableElement = cornerstone.getEnabledElement(element);
    enableElement.viewport.voi = {
      windowWidth: clamp(this.props.WMax - this.props.WMin, 0, 32767),
      windowCenter: clamp((this.props.WMax + this.props.WMin) / 2, 0, 32767),
    };

    // if (this.state.Backward===false) {
    //   const petLayerId = layerIds.at(-1);
    //   const petLayer = cornerstone.getLayer(element, petLayerId);
    //   petLayer.options.viewport.voi={windowWidth:this.props.WMin, windowCenter:this.props.WMax};
    //   petLayer.viewport.voi={windowWidth:this.props.WMin, windowCenter:this.props.WMax};

    // }

    cornerstone.updateImage(element);
  }

  componentWillUnmount() {
    const element = this.props.myForwardedRef.current;
    // element.removeEventListener("contextmenu",this.onContextMenu);
    element.removeEventListener(
      'cornerstoneimagerendered',
      this.onImageFusionRenderer,
    );
    element.removeEventListener('cornerstoneimagerendered', this.onMipRenderer);
    element.removeEventListener(
      'cornerstoneimagerendered',
      this.onWWWCRenderer,
    );
    window.removeEventListener('resize', this.onWindowResize);
  }

  onWindowResize = () => {
    const element = this.props.myForwardedRef.current;
    cornerstone.resize(element);
  };

  onWWWCRenderer = debounce((e) => {
    // console.log('onWWWCRenderer Backward: '+this.state.Backward.toString())
    if (this.state.Backward === true) {
      // console.log('onWWWCRenderer', this.props.cornerstoneProps.inout, this.props.type, this.props.direction);
      try {
        const enabledElement = e.detail.enabledElement;
        const { windowWidth, windowCenter } = enabledElement.viewport.voi;
        // debugger;
        // const {windowWidth, windowCenter} = enabledElement.layers.at(-1).viewport.voi;
        const max = windowCenter + windowWidth / 2;
        const min = windowCenter - windowWidth / 2;
        this.props.setWMax(max);
        this.props.setWMin(min);
      } catch (e) {
        console.log('onWWWCRenderer error');
      }
    }
  }, 1);

  // onContextMenu = (e) => {
  //   e.preventDefault();
  // }

  onMipRenderer = debounce((e) => {
    // console.log('onMipRenderer', this.props.cornerstoneProps.inout, this.props.type, this.props.direction);
    const { Stacks, type } = this.props;
    try {
      const element = e.detail.element;
      const enabledElement = e.detail.enabledElement;
      const id = enabledElement.image.imageId.split('/').at(-1);
      this.props.getSliceID(id);
    } catch (e) {
      console.log('onImageRendered error');
    }
  }, 0);

  onImageFusionRenderer = debounce((e) => {
    // console.log('onImageFusionRenderer', this.props.cornerstoneProps.inout, this.props.type, this.props.direction);
    const { Stacks } = this.props;
    try {
      const element = e.detail.element;
      const enabledElement = e.detail.enabledElement;
      const id = enabledElement.image.imageId.split('/').at(-1);
      this.props.getSliceID(id);

      const layers = cornerstone.getLayers(element);
      if (layers.length === 2) {
        const getPETcurrentIndex = Number(
          layers[1].image.imageId.split('/').at(-1),
        );
        const getMNIcurrentIndex = Number(
          layers[0].image.imageId.split('/').at(-1),
        );
        if (getPETcurrentIndex !== getMNIcurrentIndex) {
          this.props.updateSliceId(getPETcurrentIndex);
          cornerstone
            .loadAndCacheImage(Stacks[0].imageIds[getPETcurrentIndex])
            .then((image) => {
              cornerstone.setLayerImage(element, image, layers[0].layerId);
              cornerstone.updateImage(element);
            });
        }
      }
    } catch (e) {
      console.log('onImageRendered error');
    }
  }, 1);

  render() {
    const { value } = this.state;
    const { direction, x, y, z, a, type, myForwardedRef, Stacks, depthInfo } =
      this.props;
    const { inverted } = this.props.cornerstoneProps;
    // console.log(this.props.params);
    const productName = this.props.params.product.toLowerCase();
    const CTN_Mode = coreItem[productName].CTN_Mode;
    return (
      <>
        <div
          className={`${direction}`}
          style={{
            boxSizing: 'border-box',
            // width:this.props.width, height:this.props.height,
            width: this.props.width,
            height: this.props.height,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'stretch',
            transform: 'translate(-50%,-50%)',
            position: 'relative',
            top: '50%',
            left: '50%',
          }}
          ref={this.props.myForwardedRef}
          onMouseEnter={() => this.setState({ Backward: true })}
          onMouseLeave={() => this.setState({ Backward: false })}
        >
          {(() => {
            const { productName } = this.state;
            const cmap = Stacks.at(-1).options.viewport.colormap;
            const textColor = (() => {
              if (inverted) return cmap === 'invertGray' ? 'red' : 'black';
              else return cmap === 'invertGray' ? 'red' : 'white';
            })();
            const valueTitle = {
              amyloid: CTN_Mode ? 'Raw intensity' : 'SUVR',
              dat: 'SBR',
              fdg: 'SUVR',
              tau: 'SUVR',
              perfusion: 'SUVR',
            };
            const rePos = {
              coronal: {
                title: CTN_Mode ? 'Voxel Position' : 'MNI y',
                a: CTN_Mode ? 1 : 2,
                b: CTN_Mode ? 0 : -150,
              },
              sagittal: {
                title: CTN_Mode ? 'Voxel Position' : 'MNI x',
                a: CTN_Mode ? 1 : -2,
                b: CTN_Mode ? 0 : +130,
              },
              axial: {
                title: CTN_Mode ? 'Voxel Position' : 'MNI z',
                a: CTN_Mode ? 1 : 2,
                b: CTN_Mode ? 0 : -74,
              },
            };
            const direction_indicator = {
              coronal: { top: 'S', bottom: 'I', left: 'R', right: 'L' },
              sagittal: { top: 'S', bottom: 'I', left: 'P', right: 'A' },
              axial: { top: 'A', bottom: 'P', left: 'R', right: 'L' },
            };
            const mniPos = rePos[direction]?.a * z + rePos[direction]?.b;
            if (type === 'slice')
              return (
                <>
                  <TextDisplay
                    title={valueTitle[productName]}
                    value={CTN_Mode ? Math.floor(value) : value}
                    style={{
                      fontWeight: '500',
                      position: 'absolute',
                      fontSize: '0.8vw',
                      bottom: '28px',
                      left: '20px',
                      color: 'red',
                    }}
                    name={'SUVR'}
                  />
                  <TextDisplay
                    title={rePos[direction]?.title}
                    value={mniPos}
                    style={{
                      fontWeight: '500',
                      position: 'absolute',
                      fontSize: '0.8vw',
                      bottom: '10px',
                      left: '20px',
                      color: 'red',
                    }}
                    name={'MNI'}
                  />
                  <TextDisplay
                    title={direction_indicator[direction]?.top}
                    value={mniPos}
                    style={{
                      fontWeight: '500',
                      position: 'absolute',
                      fontSize: '0.8vw',
                      top: '10px',
                      left: '48.5%',
                      color: 'red',
                    }}
                    name={'direction'}
                  />
                  <TextDisplay
                    title={direction_indicator[direction]?.bottom}
                    value={mniPos}
                    style={{
                      fontWeight: '500',
                      position: 'absolute',
                      fontSize: '0.8vw',
                      bottom: '10px',
                      left: '48.5%',
                      color: 'red',
                    }}
                    name={'direction'}
                  />
                  <TextDisplay
                    title={direction_indicator[direction]?.left}
                    value={mniPos}
                    style={{
                      fontWeight: '500',
                      position: 'absolute',
                      fontSize: '0.8vw',
                      top: '50%',
                      left: '10px',
                      color: 'red',
                    }}
                    name={'direction'}
                  />
                  <TextDisplay
                    title={direction_indicator[direction]?.right}
                    value={mniPos}
                    style={{
                      fontWeight: '500',
                      position: 'absolute',
                      fontSize: '0.8vw',
                      top: '50%',
                      right: '10px',
                      color: 'red',
                    }}
                    name={'direction'}
                  />
                </>
              );
          })()}

          {(() => {
            const cmap = Stacks.at(-1).options.viewport.colormap;
            const markerColor = (inverted, value) => {
              // console.log(inverted)
              if (inverted)
                return {
                  invertGray: value > 0.2 ? 'red' : 'red',
                  hot: value > 0.2 ? 'white' : 'blue',
                  jet: value > 0.2 ? 'white' : 'black',
                  pet: value > 0.2 ? 'white' : 'black',
                };
              else
                return {
                  invertGray: value < 0.2 ? 'red' : 'red',
                  hot: value < 0.2 ? 'white' : 'blue',
                  jet: value < 0.2 ? 'white' : 'black',
                  pet: value < 0.2 ? 'white' : 'black',
                };
            };

            const topPos = ({ depthInfo, x, y, z }) => ({
              coronal: (y / depthInfo?.['axial']) * 100,
              sagittal: (y / depthInfo?.['axial']) * 100,
              axial: (y / depthInfo?.['coronal']) * 100,
              mip: (y / depthInfo?.['axial'] - 0.5) * 76 + 50,
            });

            const leftPos = ({ depthInfo, x, y, z, a }) => ({
              coronal: (x / depthInfo?.['sagittal']) * 100,
              sagittal: (x / depthInfo?.['coronal']) * 100,
              axial: (x / depthInfo?.['sagittal']) * 100,
              mip: (() => {
                const angleUnit = 360 / depthInfo?.['mip'];
                const angle = angleUnit * a;
                const cos = Math.cos((Math.PI * angle) / 180);
                const sin = Math.sin((Math.PI * angle) / 180);
                const cartesianX = (x / depthInfo?.['coronal'] - 0.5) * 76;
                const cartesianY = (y / depthInfo?.['axial'] - 0.5) * 76;
                const cartesianZ = (z / depthInfo?.['sagittal'] - 0.5) * 76;
                return cartesianX * cos + cartesianZ * sin + 50;
              })(),
            });
          })()}
        </div>
      </>
    );
  }
}
StackViewer.defaultProps = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'stretch',
  flexShrink: 0,
  transform: 'translate(-50%,-50%)',
  position: 'relative',
  top: '50%',
  left: '50%',
};
StackViewer.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};
const mapStateToProps = (state) => ({
  fileList: state.fileList,
  control: state.control,
});

const mapDispatchToProps = (dispatch) => ({
  // fetchSlices: (obj)=>dispatch(actionsSlices.fetchSlices(obj)),
  // update_currentIdx: (obj)=>dispatch(actionsControl.update_currentIdx(obj)),
});
const ConnectedStackViewer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(StackViewer);

export default React.forwardRef((props, ref) => (
  <ConnectedStackViewer {...props} myForwardedRef={ref} />
));

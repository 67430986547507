import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { coreItem } from '0_variables/coreItem';
import { PageLayout, CardLayout } from '5_components';
import { BarGraph, PieGraph } from './components';
import styles from './Dashboard.module.scss';

export default function Dashboard(props) {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const pageName = location.pathname.split('/')[2];
  const productName = params.product.toLowerCase();
  const productCoreItem = coreItem[productName];
  const username = sessionStorage.getItem('username');
  const CTN_Mode = productCoreItem.CTN_Mode;

  useEffect(() => {
    const sessionStorageState = sessionStorage.getItem('username');
    if (CTN_Mode && sessionStorageState === null) history.push(`/signin`);
  }, [CTN_Mode, history]);

  const dashboardPageTop = () => {
    return (
      <div className={styles['top']} id="dashboard">
        {`Hello, ${username}`}
        <br />
        {`Have a great day with BTXBrain-${productCoreItem.productName} !`}
      </div>
    );
  };

  const dashboardPageBottom = () => {
    const cardComponents = {
      BarGraph: <BarGraph />,
      PieGraph: <PieGraph {...props} />,
      // PieGraph: <BarGraph/>,
    };
    const cardItem = productCoreItem[pageName].bottom.map((v, i1) => ({
      ...v,
      cards: v.cards.map((card, i2) => {
        return {
          ...card,
          content: cardComponents[card.componentName],
        };
      }),
    }));

    return (
      <>
        {cardItem.map((v, i) => (
          <CardLayout key={i} {...v} />
        ))}
      </>
    );
  };

  return (
    <>
      <PageLayout
        topContent={dashboardPageTop}
        bottomContent={dashboardPageBottom}
      />
    </>
  );
}

import React, { useState } from 'react';
import styles from './DropDownDiv.module.scss';
import {
  arrow_up as MEDIA_arrow_up,
  arrow_down as MEDIA_arrow_down,
} from '6_media';

function DropDownDiv(props) {
  const { style, item, styleName } = props;
  const { options: dropDownOptions, selectItem, setItem } = item;
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <div className={styles[styleName]}>
      <div
        tabIndex={0}
        className={styles['container']}
        style={style}
        onBlur={() => setShowDropdown(false)}
      >
        <div
          //className props로 받아 바꾸기
          style={{ background: style?.background }}
          className={styles['title_container']}
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <div>
            {selectItem === 'invertGray'
              ? 'Gray scale'
              : selectItem === 'pet'
              ? 'GE'
              : selectItem === 'jet'
              ? 'Jet'
              : selectItem === 'hot'
              ? 'Hot'
              : selectItem}
          </div>
          <div className={styles['arrow_img']}>
            <img
              src={showDropdown ? MEDIA_arrow_up : MEDIA_arrow_down}
              alt=""
            />
          </div>
        </div>
        <div
          className={styles['drop_container']}
          style={{
            display: `${showDropdown ? '' : 'none'}`,
            background: style?.background,
          }}
        >
          {dropDownOptions.map((itemLabel, i) => (
            <div
              key={i}
              onClick={() => {
                setShowDropdown(false);
                setItem(itemLabel);
              }}
            >
              {itemLabel === 'invertGray'
                ? 'Gray scale'
                : itemLabel === 'pet'
                ? 'GE'
                : itemLabel === 'jet'
                ? 'Jet'
                : itemLabel === 'hot'
                ? 'Hot'
                : itemLabel}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DropDownDiv;

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { resetPasswdThunk } from '1_reduxs/actions/loginAction';
import { HomePassButton, InputText } from '5_components';
import styles from './Forgot.module.scss';

export default function Forgot(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [forgotValues, setForgotValues] = useState({
    username: '',
    email: '',
    password1: '',
    password2: '',
  });
  const validPassword = forgotValues.password1 === forgotValues.password2;

  const processFunc = async () => {
    if (validPassword) {
      const resObj = await dispatch(
        resetPasswdThunk({
          username: forgotValues.username,
          email: forgotValues.email,
          password1: forgotValues.password1,
          password2: forgotValues.password2,
        }),
      );
      if (resObj.response) {
        alert(resObj.message);
        history.push(`/signin`);
        setForgotValues({
          username: '',
          email: '',
          password1: '',
          password2: '',
        });
      } else {
        if (resObj.code === undefined)
          alert(
            'Failed to reset the password due to connection error. Please check your connection to the server, and try again.',
          );
        else {
          alert(resObj.message);
        }
      }
      console.log(`${resObj.message} (${resObj.code}) `);
    } else {
      alert('The password confirmation doses not match.');
    }
  };

  const propsForgot = {
    processFunc,
    btnClassName: 'forgot-btn',
    title: 'SUBMIT',
  };

  return (
    <div className={styles['container']}>
      {/* <div>{location.pathname}</div> */}
      <div>
        <h1 className={`${styles['title']}`}>{'PASSWORD'}</h1>
        <InputText
          title="USERNAME"
          name="username"
          placeholder="Enter username"
          value={forgotValues}
          setValue={setForgotValues}
          inputClassName="signup-input"
        />
        <InputText
          title="EMAIL"
          name="email"
          placeholder="Enter email"
          value={forgotValues}
          setValue={setForgotValues}
          inputClassName="signup-input"
        />
        <InputText
          title="NEW PASSWORD"
          type="password"
          name="password1"
          placeholder="Enter new password"
          value={forgotValues}
          setValue={setForgotValues}
          inputClassName="signup-input"
        />
        <InputText
          title="CONFIRM PASSWORD"
          type="password"
          name="password2"
          placeholder="Enter confirm password"
          value={forgotValues}
          setValue={setForgotValues}
          inputClassName="signup-input"
        />
        <div
          className={`${styles['CheckPassword']}`}
          style={{ display: `${validPassword ? 'none' : ''}` }}
        >
          The password confirmation doses not match.
        </div>
        <HomePassButton item={propsForgot} />
        {/* <div><button onClick={()=>history.push('/signin')}>Submit</button></div> */}
      </div>
    </div>
  );
}

import React from 'react';
import styles from './Setting.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { coreItem } from '0_variables/coreItem';
import * as actionSetting from '1_reduxs/actions/settingAction';
import { MainConfig, ReportFormats, CSVFormat } from './components';
import { PageLayout, CardLayout, CustomROIModal, Buttons } from '5_components';

export default function Setting(props) {
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const settingChanged = useSelector((state) => state.setting.changed);
  const modalState = useSelector((state) => state.modal);
  const productName = params.product.toLowerCase();
  const productCoreItem = coreItem[productName];
  const pageName = location.pathname.split('/')[2];

  const funcSelector = {
    reset: async () => {
      if (window.confirm('Are you sure you want to reset the settings?')) {
        // 리셋버튼을 누를때 해당 product의 세팅을 초기상태로 변환
        await dispatch(actionSetting.resetSettingThunk(productName));
        alert('The settings have been reset successfully.');
      }
    },
    save: async () => {
      // if (utils.isChangedObjects(settingGlobal, settingLocal)) {
      if (settingChanged) {
        await dispatch(actionSetting.saveSettingServerThunk());
        alert('The settings have been saved successfully.');
      } else {
        alert('No changes have been made.');
      }
    },
  };

  const settingPageTop = () => {
    const { top } = productCoreItem[pageName];
    return (
      <div className={styles.btnContainer}>
        <div className={styles.btnGroup1}>
          {top.btnGroup1.map((v, i) => (
            <Buttons
              key={i}
              {...{
                Enable: true,
                styleName: 'rightBtn',
                imgSrc: v.icon,
                title: v.title,
                func: { onClickHandler: funcSelector[v.funcName] },
              }}
            />
          ))}
        </div>
      </div>
    );
  };

  const settingPageBottom = () => {
    const { bottom } = productCoreItem[pageName];
    const cardComponents = {
      MainConfig: <MainConfig />,
      CSVFormat: <CSVFormat />,
      ReportFormats: <ReportFormats />,
    };
    const cardItem = bottom.map((v) => ({
      ...v,
      cards: v.cards.map((card) => {
        return {
          ...card,
          content: cardComponents[card.componentName],
        };
      }),
    }));
    return (
      <>
        {cardItem.map((v, i) => (
          <CardLayout
            key={i}
            {...v}
            width={v.styles.width}
            height={v.styles.height}
          />
        ))}
      </>
    );
  };

  return (
    <>
      <PageLayout
        topContent={settingPageTop}
        bottomContent={settingPageBottom}
      />
      {modalState.customROI.status && <CustomROIModal />}
    </>
  );
}

import jsPDF from 'jspdf';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';
import { subRegions } from '0_variables/subRegions';
import {
  noImage_dat_top as MEDIA_noImage_dat_top,
  noImage_dat_bottom as MEDIA_noImage_dat_bottom,
  noImage_amyloid_top as MEDIA_noImage_amyloid_top,
  noImage_amyloid_bottom as MEDIA_noImage_amyloid_bottom,
  logo as MEDIA_logo,
  reportIcon1 as MEDIA_reportIcon1,
  reportIcon2 as MEDIA_reportIcon2,
  reportIcon3 as MEDIA_reportIcon3,
} from '6_media';
import { montserrat_medium } from '7_font/montserrat/Montserrat-Medium-normal';
import { montserrat_medium_italic } from '7_font/montserrat/Montserrat-LightItalic-normal';
import { montserrat_bold } from '7_font/montserrat/Montserrat-Bold-normal';
import { montserrat_bold_semi } from '7_font/montserrat/Montserrat-SemiBold-normal';
import { montserrat_light } from '7_font/montserrat/Montserrat-Light-normal';
import { notosans_medium_normal } from '7_font/montserrat/NotoSans-Medium-normal';

export const print = async (
  refs,
  productName,
  selectedFileList,
  table1DataList,
  table2DataList,
  settingOfProduct,
  transferFunc,
  spinnerController,
  setReportOn,
  setDownloadState,
) => {
  // transferFunc({message:"Downloading...", percent:currentPercent, target:"PDF"})
  const defaultRegionLength = subRegions[productName]
    .map((v) => v.level)
    .filter((lev) => lev === 0).length;
  const settingRegionLength = settingOfProduct.list
    .map((v) => v.level)
    .filter((lev) => lev === 0).length;
  // const limitNum = LIMIT_NUM[productName];
  // const pageLength = settingRegionLength-defaultRegionLength>limitNum[productName] ? 4 : 3
  // debugger;

  const product_full_name = () => {
    if (productName === 'amyloid') return 'Amyloid';
    else if (productName === 'dat') return 'Dopamine';
    else if (productName === 'tau') return 'Tau';
    else if (productName === 'fdg') return 'FDG';
    else if (productName === 'perfusion') return 'Perfusion';
    else return 'Amyloid';
  };

  const suvrOrSbr = productName === 'dat' ? ' SBR ' : ' SUVR ';

  const imgCollection = [];
  let temp;
  for (const [i] of refs.entries()) {
    if (productName === 'dat') {
      const initialPercent = (i / refs.length) * 100;
      const plusPercent = 100 / (refs.length * 4);
      try {
        //*여기서 부터 작업하면됨 !!( 그룹 다운로드 이미지 예외 처리 부분 !)
        const brainTable = await domtoimage.toPng(
          document.getElementById(`brain-table-${i}`),
          { quality: 2 },
        );
        temp = initialPercent + plusPercent;
        transferFunc({
          message: 'Downloading...',
          percent: temp.toFixed(0),
          target: 'PDF',
        });

        const report2Graph = await html2canvas(
          document.getElementById(`report2-graph-${i}`),
          { scale: 1.5 },
        ).then((canvas) => canvas.toDataURL('image/jpeg'));
        temp = temp + plusPercent;
        transferFunc({
          message: 'Downloading...',
          percent: temp.toFixed(0),
          target: 'PDF',
        });

        const report3Axial = await domtoimage.toPng(
          document.getElementById(`report3-axial-${i}`),
          { quality: 1 },
        );
        temp = temp + plusPercent;
        transferFunc({
          message: 'Downloading...',
          percent: temp.toFixed(0),
          target: 'PDF',
        });

        const report3Mip = await domtoimage.toPng(
          document.getElementById(`report3-mip-${i}`),
          { quality: 1 },
        );
        temp = temp + plusPercent;
        transferFunc({
          message: 'Downloading...',
          percent: temp.toFixed(0),
          target: 'PDF',
        });

        const imgResult = {
          brainTable: brainTable,
          report2Graph: report2Graph,
          report3Axial: report3Axial,
          report3Mip: report3Mip,
        };
        imgCollection.push(imgResult);
      } catch {
        const brainTable = await domtoimage.toPng(
          document.getElementById(`brain-table-${i}`),
          { quality: 2 },
        );
        temp = initialPercent + plusPercent;
        transferFunc({
          message: 'Downloading...',
          percent: temp.toFixed(0),
          target: 'PDF',
        });

        const report2Graph = await html2canvas(
          document.getElementById(`report2-graph-${i}`),
          { scale: 1.5 },
        ).then((canvas) => canvas.toDataURL('image/jpeg'));
        temp = temp + plusPercent;
        transferFunc({
          message: 'Downloading...',
          percent: temp.toFixed(0),
          target: 'PDF',
        });

        const report3Axial = MEDIA_noImage_dat_top;
        temp = temp + plusPercent;
        transferFunc({
          message: 'Downloading...',
          percent: temp.toFixed(0),
          target: 'PDF',
        });

        const report3Mip = MEDIA_noImage_dat_bottom;
        temp = temp + plusPercent;
        transferFunc({
          message: 'Downloading...',
          percent: temp.toFixed(0),
          target: 'PDF',
        });

        const imgResult = {
          brainTable: brainTable,
          report2Graph: report2Graph,
          report3Axial: report3Axial,
          report3Mip: report3Mip,
        };
        imgCollection.push(imgResult);
      }
    } else {
      // const tracer = selectedFileList[i]['Tracer'];
      // if(tracer ==='[18F]FPN'){
      //   debugger;
      // }
      const initialPercent = (i / refs.length) * 100;
      const plusPercent = 100 / (refs.length * 4);
      // const sumPercent = initialPercent + plusPercent

      try {
        const brainTable = await domtoimage.toPng(
          document.getElementById(`brain-table-${i}`),
          { quality: 3 },
        );
        temp = initialPercent + plusPercent;
        transferFunc({
          message: 'Downloading...',
          percent: temp.toFixed(0),
          target: 'PDF',
        });

        const report2Graph = await domtoimage.toPng(
          document.getElementById(`report2-graph-${i}`),
          { quality: 1 },
        );
        temp = temp + plusPercent;
        transferFunc({
          message: 'Downloading...',
          percent: temp.toFixed(0),
          target: 'PDF',
        });

        const report3Axial = await domtoimage.toPng(
          document.getElementById(`report3-axial-${i}`),
          { quality: 1 },
        );
        temp = temp + plusPercent;
        transferFunc({
          message: 'Downloading...',
          percent: temp.toFixed(0),
          target: 'PDF',
        });

        const report3Surface = await domtoimage.toPng(
          document.getElementById(`report3-surface-${i}`),
          { quality: 1 },
        );
        temp = temp + plusPercent;
        transferFunc({
          message: 'Downloading...',
          percent: temp.toFixed(0),
          target: 'PDF',
        });

        const imgResult = {
          brainTable: brainTable,
          report2Graph: report2Graph,
          report3Axial: report3Axial,
          report3Surface: report3Surface,
        };

        imgCollection.push(imgResult);
      } catch {
        const brainTable = await domtoimage.toPng(
          document.getElementById(`brain-table-${i}`),
          { quality: 3 },
        );
        temp = initialPercent + plusPercent;
        transferFunc({
          message: 'Downloading...',
          percent: temp.toFixed(0),
          target: 'PDF',
        });

        const report2Graph = await domtoimage.toPng(
          document.getElementById(`report2-graph-${i}`),
          { quality: 1 },
        );
        temp = temp + plusPercent;
        transferFunc({
          message: 'Downloading...',
          percent: temp.toFixed(0),
          target: 'PDF',
        });

        const report3Axial = MEDIA_noImage_amyloid_top;
        temp = temp + plusPercent;
        transferFunc({
          message: 'Downloading...',
          percent: temp.toFixed(0),
          target: 'PDF',
        });

        const report3Surface = MEDIA_noImage_amyloid_bottom;
        temp = temp + plusPercent;
        transferFunc({
          message: 'Downloading...',
          percent: temp.toFixed(0),
          target: 'PDF',
        });

        const imgResult = {
          brainTable: brainTable,
          report2Graph: report2Graph,
          report3Axial: report3Axial,
          report3Surface: report3Surface,
        };

        imgCollection.push(imgResult);
      }
    }
  }

  // const imgCollection = await Promise.all(
  //   refs.map( async (ref,i)=>{

  //     if (productName === 'dat') {
  //       // const brainTable = await html2canvas(document.getElementById(`brain-table-${i}`),opt).then(canvas=>canvas.toDataURL('image/jpeg'))
  //       const brainTable = await domtoimage.toPng(document.getElementById(`brain-table-${i}`), {quality: 2})
  //       const report2Graph = await html2canvas(document.getElementById(`report2-graph-${i}`),{scale:1.5}).then(canvas=>canvas.toDataURL('image/jpeg'));
  //       const report3Axial = await domtoimage.toPng(document.getElementById(`report3-axial-${i}`), {quality: 1})
  //       const report3Mip = await domtoimage.toPng(document.getElementById(`report3-mip-${i}`), {quality: 1})

  //       // debugger;
  //       return {
  //         'brainTable': brainTable,
  //         'report2Graph': report2Graph,
  //         'report3Axial': report3Axial,
  //         'report3Mip': report3Mip,
  //         // 'report3AxialSlices': await html2canvas(document.getElementById(`report3-axial-slices-${i}`),{scale:1.5}).then(canvas=>canvas.toDataURL('image/jpeg')),
  //         // 'report3AxialColorbar': await html2canvas(document.getElementById(`report3-axial-colorbar-${i}`),{scale:1.5}).then(canvas=>canvas.toDataURL('image/jpeg')),
  //         // 'report3Surface': await html2canvas(document.getElementById(`report3-surface-${i}`),{scale:1.5}).then(canvas=>canvas.toDataURL('image/jpeg')),
  //       };
  //     } else {
  //       // debugger;
  //       const brainTable = await domtoimage.toPng(document.getElementById(`brain-table-${i}`), {quality: 3})
  //       const report2Graph = await domtoimage.toPng(document.getElementById(`report2-graph-${i}`), {quality: 1})
  //       const report3Axial = await domtoimage.toPng(document.getElementById(`report3-axial-${i}`), {quality: 1})
  //       // const report3AxialSlices = await domtoimage.toPng(document.getElementById(`report3-axial-slices-${i}`), {quality: 1})
  //       // const report3AxialColorbar = await domtoimage.toPng(document.getElementById(`report3-axial-colorbar-${i}`), {quality: 1})
  //       const report3Surface = await domtoimage.toPng(document.getElementById(`report3-surface-${i}`), {quality: 1})

  //       // const brainTable = await html2canvas(document.getElementById(`brain-table-${i}`),{scale:1.2}).then(canvas=>canvas.toDataURL('image/jpeg'));
  //       // const report2Graph = await html2canvas(document.getElementById(`report2-graph-${i}`),opt).then(canvas=>canvas.toDataURL('image/jpeg'));
  //       // const report3AxialSlices = await html2canvas(document.getElementById(`report3-axial-slices-${i}`),opt).then(canvas=>canvas.toDataURL('image/jpeg'));
  //       // const report3AxialColorbar = await html2canvas(document.getElementById(`report3-axial-colorbar-${i}`),opt).then(canvas=>canvas.toDataURL('image/jpeg'));
  //       // const report3Surface = await html2canvas(document.getElementById(`report3-surface-${i}`),opt).then(canvas=>canvas.toDataURL('image/jpeg'));
  //       // console.log(Math.round((new Date() - startTime)))
  //       // console.log(Math.round((new Date() - startTime)))
  //       // console.log(Math.round((new Date() - startTime)))
  //       // console.log(Math.round((new Date() - startTime)))
  //       // console.log(Math.round((new Date() - startTime)))

  //       return {
  //         'brainTable': brainTable,
  //         'report2Graph': report2Graph,
  //         'report3Axial': report3Axial,
  //         // 'report3AxialSlices': report3AxialSlices,
  //         // 'report3AxialColorbar': report3AxialColorbar,
  //         'report3Surface': report3Surface,
  //       };
  //     }
  //   })
  // )

  const pdf = refs.map((_) => new jsPDF('p', 'mm', 'a4'));
  // debugger;
  const setFont = (pdf) => {
    pdf.addFileToVFS('montserrat_italic.ttf', montserrat_medium_italic);
    pdf.addFont('montserrat_italic.ttf', 'montserrat', 'italic');
    pdf.addFileToVFS('montserrat_medium.ttf', montserrat_medium);
    pdf.addFont('montserrat_medium.ttf', 'montserrat', 'normal');
    pdf.addFileToVFS('montserrat_bold.ttf', montserrat_bold);
    pdf.addFont('montserrat_bold.ttf', 'montserrat', 'bold');
    pdf.addFileToVFS('montserrat_bold_semi.ttf', montserrat_bold_semi);
    pdf.addFont('montserrat_bold_semi.ttf', 'montserrat', 'bold_semi');
    pdf.addFileToVFS('montserrat_light.ttf', montserrat_light);
    pdf.addFont('montserrat_light.ttf', 'montserrat', 'light');
    pdf.addFileToVFS('notosans_medium_normal.ttf', notosans_medium_normal);
    pdf.addFont('notosans_medium_normal.ttf', 'noto', 'medium');
  };

  const header = (pdf) => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const date = today.getDate();

    pdf.setFont('montserrat', 'italic');
    pdf.setFontSize(8);
    pdf.setTextColor(179, 179, 179);
    // if (productName==="perfusion"){
    //   pdf.text(20,10,'This product has not been approved by the MFDS and FDA')
    //   pdf.text(20,13,'and is not intended to diagnose, treat, cure, or prevent any disease.')
    // }
    pdf.text(180, 10, 'Last Printed');
    pdf.text(184, 13, date + '/' + month + '/' + year);
  };

  const mainTitle = (pdf) => {
    pdf.setFillColor(90, 122, 181);
    pdf.rect(20, 15, 1, 20, 'F');

    pdf.setFont('montserrat', 'bold');
    pdf.setFontSize(20);
    pdf.setTextColor(0, 0, 0);
    pdf.text(25, 23, 'BTXBrain-' + product_full_name());

    pdf.setFont('montserrat', 'light');
    pdf.setFontSize(13);
    productName === 'perfusion'
      ? pdf.text(
          25,
          31,
          'AI-based Automatic ' +
            product_full_name() +
            ' SPECT Quantification Report',
        )
      : pdf.text(
          25,
          31,
          'AI-based Automatic ' +
            product_full_name() +
            ' PET Quantification Report',
        );
  };

  const subTitle = (pdf, iconSrc, a, b, msg, x, y) => {
    pdf.addImage(iconSrc, 'PNG', a, b, 5.5, 5);
    pdf.setFont('montserrat', 'bold');
    pdf.setTextColor(90, 122, 181);
    pdf.text(x, y, msg);
  };

  const tableTitle = (pdf, msg, x, y) => {
    pdf.setFont('montserrat', 'normal');
    pdf.setFontSize(12);
    pdf.setTextColor(0, 0, 0);
    pdf.text(x, y, msg);
  };

  const footer = (pdf, pageNum, opacity, idx, pageLength) => {
    pdf.setFont('montserrat', 'normal');
    pdf.setFontSize(8);
    pdf.setTextColor(179, 179, 179);
    pdf.text(20, 270, 'Quantification analysis details');
    pdf.setFont('montserrat', 'light');
    pdf.text(
      20,
      273,
      'Atlas used: ' +
        settingOfProduct.defaultAtlas +
        ' / Reference region: ' +
        settingOfProduct.defaultRef[selectedFileList[idx].Tracer],
    );
    pdf.addImage(MEDIA_logo, 'PNG', 20, 276, 60, 4);
    pdf.text(180, 280, 'PatientID : ' + selectedFileList[idx].PatientID);
    pdf.text(185, 283, 'page ' + pageNum + '/' + pageLength);

    pdf.setFont('montserrat', 'bold');
    pdf.setFontSize(50);
    pdf.saveGraphicsState();
    pdf.setGState(new pdf.GState({ opacity: opacity }));
    // if(productName==="perfusion"){
    //   pdf.text(50,190, 'FOR RESEARCH', null, 30)
    //   pdf.text(50,210, 'PURPOSES ONLY', null, 30)
    // }
    pdf.saveGraphicsState();
    pdf.setGState(new pdf.GState({ opacity: 1 }));
  };

  // middle1 = new Date();
  // debugger;

  const pdfCollection = [];
  for (const [idx, item] of imgCollection.entries()) {
    const makePageLength = () => {
      // debugger;
      if (selectedFileList[idx].Tracer === '[18F]FPN') {
        if (settingRegionLength - defaultRegionLength > 30) {
          return 4;
        } else {
          return 3;
        }
      } else {
        if (settingRegionLength - defaultRegionLength > 0) {
          return 4;
        }
      }
    };
    const pageLength = makePageLength();
    // debugger;
    let temp_pdf = (idx / imgCollection.length) * 100;
    const plusPercent = 100 / (imgCollection.length * pageLength);

    const tracer_fullName = (tracer) => {
      if (tracer === '[18F]FBP') return '[18F]Florbetapir';
      else if (tracer === '[18F]FMM') return '[18F]Flutemetamol';
      else if (tracer === '[18F]FBB') return '[18F]Florbetaben';
      else if (tracer === '[18F]FPN') return '[18F]Florapronol';
      else if (tracer === '[18F]FPCIT') return '[18F]FP-CIT';
      else if (tracer === '[18F]FDG') return '[18F]FDG';
      else if (tracer === '[18F]TAU') return '[18F]TAU';
      else if (tracer === '[99mtc]ECD') return '[99mtc]ECD';
      else if (tracer === '[99mtc]HMPAO') return '[99mtc]HMPAO';
      else return '[18F]Florbetapir';
    };
    const patient_info = [
      ['Patient Name', selectedFileList[idx].PatientName],
      ['Patient ID', selectedFileList[idx].PatientID],
      ['DOB', selectedFileList[idx].Age],
      ['Sex', selectedFileList[idx].Sex],
      ['Study Date', selectedFileList[idx].AcquisitionDateTime],
      [
        'Study Description',
        tracer_fullName(selectedFileList[idx].Tracer)
          .replace('18', '\u00B9\u2078')
          .replace('99mtc', '\u2079\u2079\u1d50Tc'),
      ],
    ];

    const patient_info_state = [
      settingOfProduct.defaultPInfoState[0].state,
      settingOfProduct.defaultPInfoState[1].state,
      settingOfProduct.defaultPInfoState[2].state,
      settingOfProduct.defaultPInfoState[3].state,
      settingOfProduct.defaultPInfoState[4].state,
      true,
    ];

    const headerName_region = productName === 'dat' ? 'Measure' : 'Region';
    const data_columns_1 = [
      { title: 'Region', dataKey: 'Region' },
      { title: 'Total', dataKey: 'Total' },
      { title: 'Left', dataKey: 'Left' },
      { title: 'Right', dataKey: 'Right' },
    ];
    const data_columns_2 = [
      { title: headerName_region, dataKey: headerName_region },
      { title: 'Total', dataKey: 'Total' },
      { title: 'Left', dataKey: 'Left' },
      { title: 'Right', dataKey: 'Right' },
    ];

    const data_rows = table1DataList[idx][productName].items;

    for (let i = 0; i < data_rows.length; i++) {
      data_rows[i].Total = data_rows[i].Total.toFixed(2);
      data_rows[i].Left = data_rows[i].Left.toFixed(2);
      data_rows[i].Right = data_rows[i].Right.toFixed(2);
    }

    const data_rows_2 =
      productName === 'dat' || productName === 'amyloid'
        ? table2DataList[idx][productName].items
        : '';

    for (let i = 0; i < data_rows_2.length; i++) {
      const semiquantification_percent_mark = [
        'Striatal asymmetry index',
        'Caudate asymmetry index',
        'Putamen asymmetry index',
      ];
      if (semiquantification_percent_mark.includes(data_rows_2[i].Measure)) {
        data_rows_2[i].Measure = data_rows_2[i].Measure + ', %';
      }
    }

    if (productName === 'dat' || productName === 'amyloid') {
      for (let i = 0; i < data_rows_2.length; i++) {
        data_rows_2[i].Total = data_rows_2[i].Total.toFixed(2);
        data_rows_2[i].Left = data_rows_2[i].Left.toFixed(2);
        data_rows_2[i].Right = data_rows_2[i].Right.toFixed(2);
      }
    }

    setFont(pdf[idx]);
    header(pdf[idx]);
    mainTitle(pdf[idx]);

    subTitle(
      pdf[idx],
      MEDIA_reportIcon1,
      20,
      42,
      'Patient Information',
      30,
      46,
    );
    const new_paitent_info = [];

    for (var i = 0; i < patient_info.length; i++) {
      if (patient_info_state[i]) {
        new_paitent_info.push(patient_info[i]);
      }
    }
    pdf[idx].autoTable({
      body: new_paitent_info,
      // body: patient_info_state.map((item, idx) => item ? patient_info[idx]: ''),
      margin: { top: 53, left: 31 },
      theme: 'plain',

      didParseCell: (body) => {
        body.cell.styles.fontSize = 11;
        const header = [
          'Patient Name',
          'Patient ID',
          'DOB',
          'Sex',
          'Study Date',
          'Study Description',
        ];
        if (header.includes(body.cell.text[0])) {
          body.cell.styles.font = 'montserrat';
          body.cell.styles.fontStyle = 'bold_semi';
          body.cell.styles.cellWidth = 46;
          // debugger;
        } else {
          body.cell.styles.font = 'noto';
          body.cell.styles.fontStyle = 'medium';
        }
      },
    });

    //brain table
    subTitle(
      pdf[idx],
      MEDIA_reportIcon2,
      20,
      112,
      'Quantification Results',
      30,
      116,
    );
    if (productName === 'dat') {
      footer(pdf[idx], 1, 0.2, idx);
      pdf[idx].addImage(
        item.brainTable,
        'PNG',
        30,
        120,
        Math.ceil(140 * 1.07),
        120,
      );
      transferFunc({ message: 'Please wait...', target: '' });
    } else {
      footer(pdf[idx], 1, 0.2, idx);
      pdf[idx].addImage(
        item.brainTable,
        'PNG',
        30,
        120,
        Math.ceil(140 * 1.07),
        130,
      );
      transferFunc({ message: 'Please wait...', target: '' });
    }

    // page 2
    pdf[idx].addPage();
    header(pdf[idx]);
    mainTitle(pdf[idx]);
    subTitle(
      pdf[idx],
      MEDIA_reportIcon2,
      20,
      42,
      'Quantification Results',
      30,
      46,
    );

    // d3 graph
    if (productName === 'dat') {
      // tableTitle('Striatal SBR', 30, 58)
      pdf[idx].addImage(
        item.report2Graph,
        'PNG',
        20,
        50,
        Math.ceil(3.4 * 55),
        55,
      );
    } else {
      pdf[idx].addImage(
        item.report2Graph,
        'PNG',
        3,
        50,
        Math.ceil(3.4 * 60),
        60,
      );
      tableTitle(pdf[idx], 'Regional SUVR', 30, 112);
    }

    //*SUVR or SBR table
    const table1_startY = productName !== 'dat' ? 115 : 105;
    const table_fontSize = productName !== 'dat' ? 11 : 10;
    pdf[idx].autoTable(data_columns_1, data_rows, {
      startY: table1_startY,
      margin: { left: 30 },
      tableWidth: 155,
      theme: 'plain',
      styles: {
        halign: 'center',
      },
      headStyles: {
        fillColor: [202, 202, 202],
      },
      didParseCell: (body) => {
        body.cell.styles.fontSize = table_fontSize;
        // body.cell.contentHeight = 0.2
        // body.cell.height = 0.2
        body.cell.styles.minCellHeight = 0.2;
        if (body.section === 'head') {
          body.cell.styles.font = 'montserrat';
          body.cell.styles.fontStyle = 'bold_semi';
        } else {
          // body.table.styles.bodyStyles = {
          //   height : 5,

          // }
          // debugger;
          const Total = parseFloat(body.row.raw.Total.toString());
          const Left = parseFloat(body.row.raw.Left.toString());
          const Right = parseFloat(body.row.raw.Right.toString());
          body.cell.styles.font = 'montserrat';
          body.cell.styles.fontStyle = 'light';

          body.row.raw.Total = Total.toFixed(2);
          body.row.raw.Left = Left.toFixed(2);
          body.row.raw.Right = Right.toFixed(2);
        }
      },
      createCell: (cell, opts) => {
        cell.styles.cellPadding = 50;
      },
    });

    if (pageLength === 4) {
      footer(pdf[idx], 2, 0.202, idx);
      let temp_pdf = (idx / imgCollection.length) * 100;
      const plusPercent = 100 / (imgCollection.length * pageLength);
      temp_pdf = temp_pdf + plusPercent;

      transferFunc({ message: 'Please wait...', target: '' });
      pdf[idx].addPage();
      header(pdf[idx]);
      mainTitle(pdf[idx]);
      subTitle(
        pdf[idx],
        MEDIA_reportIcon2,
        20,
        42,
        'Quantification Results',
        30,
        46,
      );
      if (productName === 'amyloid' || productName === 'dat') {
        if (selectedFileList[idx].Tracer === '[18F]FPN') {
        } else {
          const titleContent =
            productName === 'amyloid'
              ? 'Regional Centiloid'
              : 'Semiquantification';
          const table2_startY = productName === 'amyloid' ? 60 : 60;
          tableTitle(pdf[idx], titleContent, 30, table2_startY - 3);
          //Centiloid table
          pdf[idx].autoTable(data_columns_2, data_rows_2, {
            startY: table2_startY,
            margin: { left: 30 },
            tableWidth: 155,
            theme: 'plain',
            styles: {
              halign: 'center',
            },
            headStyles: {
              fillColor: [202, 202, 202],
            },
            didParseCell: (body) => {
              body.cell.styles.fontSize = table_fontSize;
              if (body.section === 'head') {
                body.cell.styles.font = 'montserrat';
                body.cell.styles.fontStyle = 'bold_semi';
              } else {
                const Total = parseFloat(body.row.raw.Total.toString());
                const Left = parseFloat(body.row.raw.Left.toString());
                const Right = parseFloat(body.row.raw.Right.toString());
                body.cell.styles.font = 'montserrat';
                body.cell.styles.fontStyle = 'light';
                body.row.raw.Total = Total.toFixed(2);
                body.row.raw.Left = Left.toFixed(2);
                body.row.raw.Right = Right.toFixed(2);
              }
            },
          });
          footer(pdf[idx], 3, 0.201, idx);
          transferFunc({ message: 'Please wait...', target: '' });
        }
      }
    } else {
      if (productName === 'amyloid' || productName === 'dat') {
        if (selectedFileList[idx].Tracer === '[18F]FPN') {
        } else {
          const titleContent =
            productName === 'amyloid'
              ? 'Regional Centiloid'
              : 'Semiquantification';
          const table2_startY = productName === 'amyloid' ? 195 : 212;
          tableTitle(pdf[idx], titleContent, 30, table2_startY - 3);
          //Centiloid table
          pdf[idx].autoTable(data_columns_2, data_rows_2, {
            startY: table2_startY,
            margin: { left: 30 },
            tableWidth: 155,
            theme: 'plain',
            styles: {
              halign: 'center',
            },
            headStyles: {
              fillColor: [202, 202, 202],
            },
            didParseCell: (body) => {
              body.cell.styles.fontSize = table_fontSize;
              if (body.section === 'head') {
                body.cell.styles.font = 'montserrat';
                body.cell.styles.fontStyle = 'bold_semi';
              } else {
                const Total = parseFloat(body.row.raw.Total.toString());
                const Left = parseFloat(body.row.raw.Left.toString());
                const Right = parseFloat(body.row.raw.Right.toString());
                body.cell.styles.font = 'montserrat';
                body.cell.styles.fontStyle = 'light';
                body.row.raw.Total = Total.toFixed(2);
                body.row.raw.Left = Left.toFixed(2);
                body.row.raw.Right = Right.toFixed(2);
              }
            },
          });
        }
      }
      footer(pdf[idx], 2, 0.201, idx);
      transferFunc({ message: 'Please wait...', target: '' });
    }

    //*second table
    pdf[idx].addPage();
    header(pdf[idx]);
    mainTitle(pdf[idx]);
    subTitle(
      pdf[idx],
      MEDIA_reportIcon3,
      20,
      42,
      product_full_name() + ' PET Image',
      30,
      46,
    );

    tableTitle(pdf[idx], 'Spatially normalized' + suvrOrSbr + 'Image', 35, 58);

    const report3AxialSize =
      productName === 'dat'
        ? { width: Math.ceil(2.3 * 80), height: 30 }
        : { width: Math.ceil(2.3 * 80), height: 62 };
    pdf[idx].addImage(
      item.report3Axial,
      'PNG',
      20,
      65,
      report3AxialSize.width,
      report3AxialSize.height,
    );

    productName !== 'dat' &&
      tableTitle(pdf[idx], 'SUVR rendered on brain surface', 35, 148);
    productName === 'dat'
      ? pdf[idx].addImage(
          item.report3Mip,
          'PNG',
          25,
          100,
          Math.ceil(2.3 * 80),
          80,
        )
      : pdf[idx].addImage(
          item.report3Surface,
          'PNG',
          20,
          160,
          Math.ceil(2.3 * 80),
          80,
        ); //surface image
    footer(pdf[idx], pageLength, 0.206, idx);

    temp_pdf = temp_pdf + plusPercent;
    transferFunc({ message: 'Please wait...', target: '' });

    pdfCollection.push(pdf);
  }

  const zip = new JSZip();
  pdf.forEach((p, i) => {
    //*디버깅용
    // pdf.save('output.pdf')
    //*최종
    //* 파일명 : fileNum+"_"+PatientID +"_"+PatientName+"_"+tracerName+"_"+AcquisitionDateTime+"_"+refName+".pdf"
    const selectedFile = selectedFileList[i];
    zip.file(
      selectedFile.id +
        '_' +
        selectedFile.PatientID +
        '_' +
        selectedFile.PatientName +
        '_' +
        selectedFile.Tracer +
        '_' +
        selectedFile.AcquisitionDateTime +
        '_' +
        settingOfProduct.defaultRef[selectedFile.Tracer] +
        '.pdf',
      p.output('blob'),
    );
  });

  setTimeout(() => {
    // func3("Downloading...")
    // transferFunc({message:"Downloading...", percent:100, target:"PDF"})
  }, 0);

  zip.generateAsync({ type: 'blob' }).then((content) => {
    saveAs(content, 'BRTNX(PDF).zip');
    spinnerController(false);
    setReportOn(false);
  });

  setDownloadState(false);
};

//   pdfCollection.forEach((pdf,i)=>{
//     //*디버깅용
//     // pdf.save('output.pdf')
//     //*최종
//     zip.file('output_'+i+'.pdf', pdf.output('blob'))

//   })

//   zip.generateAsync({type:'blob'}).then((content) => {
//     saveAs(content, 'brtnx(pdf).zip');
//   });
// };

// pdfCollection.forEach((pdf,i)=>{
//   pdf.forEach((p,i)=>{
//     zip.file('output_'+i+'.pdf', p.output('blob'))
//   })
//   zip.generateAsync({type:'blob'}).then((content) => {
//     saveAs(content, 'brtnx(pdf).zip');
//   });
// })

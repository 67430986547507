import React, { useEffect, useState } from 'react';
import styles from './Buttons.module.scss';
import { io } from 'socket.io-client';

export default function Buttons(props) {
  const [isHover, setIsHover] = useState(false);
  const [pacsHover, setPacsHover] = useState(false);
  const [n_niis, set_n_niis] = useState('0');

  useEffect(() => {
    if (process.env.REACT_APP_IS_CLOUD !== 'true') { // Cloud 버전은 socket.io 사용 않음
      if (props.title === 'Connect to PACS') {
        const socket = io(process.env.REACT_APP_BASE_URL, { secure: false });
        socket.removeAllListeners();
        socket.on('ping', (output) => {
          set_n_niis(output.n_niis);
        });
        return () => {
          socket.off('ping');
        };
      }
    }
  }, [props.title]);

  //TODO : 20220609 Expired modal 조건 생성 (button component에서 조건분기)>>>
  const today = new Date();
  const expiredDate = new Date(props.endDate);
  const dateValid =
    props.endDate?.trim() === 'inf' ? true : today < expiredDate;
  const countValid =
    props.remainCounts?.trim() === 'inf' ? true : props.remainCounts > 0;
  //TODO : 20220609 Expired modal 조건 생성 (button component에서 조건분기)<<<

  return (
    <>
      {(() => {
        if (props.title === 'Connect to PACS') {
          return (
            <div
              onMouseEnter={() => setPacsHover(true)}
              onMouseLeave={() => setPacsHover(false)}
              className={styles[props.styleName]}
              style={{ opacity: props.Enable ? 1 : 0.3 }}
            >
              <>
                <div
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: `${pacsHover ? '-100%' : '0%'}`,
                    display: props.Cloud_Mode ? 'none' : '',
                  }}
                  onClick={() => {
                    if (dateValid && countValid) {
                      if (n_niis === '0') alert('No dicom received.');
                      else props.func.onClickHandler2();
                    } else props.openModal('expired');
                  }}
                >
                  <label>{'Received Dicom'}</label>
                  <div
                    style={{
                      borderRadius: '50%',
                      background: 'red',
                      width: '1vw',
                      height: '1vw',
                      position: 'absolute',
                      left: '-10px',
                      top: '-10px',
                      zIndex: '2',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      visibility: `${n_niis === '0' ? 'hidden' : ''}`,
                    }}
                  >
                    <span
                      style={{
                        fontSize: '0.5vw',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {n_niis}
                    </span>
                  </div>
                </div>

                <div
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: '0%',
                    display: props.Cloud_Mode ? 'none' : '',
                  }}
                  onClick={() => {
                    if (dateValid && countValid) props.func.onClickHandler();
                    else props.openModal('expired');
                  }}
                >
                  <label>{'Connect to PACS'}</label>
                </div>
              </>
            </div>
          );
        } else if (props.title === 'Upload') {
          return (
            <div
              onMouseEnter={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
              className={styles[props.styleName]}
              style={{ opacity: props.Enable ? 1 : 0.3 }}
            >
              <>
                <div
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: `${isHover ? '-100%' : '0%'}`,
                  }}
                >
                  <label
                    style={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {'Select a folder'}
                    {dateValid && countValid ? (
                      <input
                        style={{ display: 'none' }}
                        multiple
                        type="file"
                        webkitdirectory="true"
                        onChange={(e) => {
                          // debugger;
                          // setTimeout(() => {
                          //   // console.log("Delayed for 1 second.");
                          //   props.func.onChangeHandler2(e)
                          // }, 500)
                          props.func.onChangeHandler2(e);
                        }}
                        onClick={(e) => {
                          // dispatch(actionModal.open_spinner({ status: true, length: 0, message: "Running...", target: 'UPLOAD' }));
                          // setTimeout(()=>{
                          //   dispatch(actionModal.open_spinner({ status: false, length: 0, message: "", target: 'UPLOAD' }));
                          // },500)
                          e.target.value = null;
                          props.func.onClickHandler();
                          // setTimeout(()=>{
                          // },1000)
                        }}
                      />
                    ) : (
                      <input
                        style={{ display: 'none' }}
                        onClick={() => {
                          props.openModal('expired');
                        }}
                      />
                    )}
                  </label>
                </div>
                <div
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: '0%',
                  }}
                >
                  <label
                    style={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {'Select files'}
                    {dateValid && countValid ? (
                      <input
                        style={{ display: 'none' }}
                        multiple
                        type="file"
                        accept=".nii, .dicom, .dcm"
                        onChange={(e) => {
                          // debugger;
                          props.func.onChangeHandler(e);
                        }}
                        onClick={(e) => {
                          e.target.value = null;
                          props.func.onClickHandler();
                        }}
                      />
                    ) : (
                      <input
                        style={{ display: 'none' }}
                        onClick={() => {
                          props.openModal('expired');
                        }}
                      />
                    )}
                  </label>
                </div>
                <div
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: '0%',
                    display: `${isHover ? 'none' : ''}`,
                  }}
                >
                  <label>{props.title}</label>
                </div>
              </>
            </div>
          );
        } else if (props.title === 'Download samples') {
          return (
            <div
              className={styles[props.styleName]}
              style={{ display: props.Cloud_Mode ? '' : 'none' }}
              onClick={() => {
                props.func.onClickHandler();
              }}
            >
              <label>{props.title}</label>
            </div>
          );
        }
        // else if (props.title==='Folder') {
        //   return (
        //   <div className={styles[props.styleName]} style={{opacity:props.Enable ? 1:0.3}}>
        //     <label>
        //       {/* {props.title} */}
        //       {/* TODO: 폴더 단위로 업로드하기 추가 필요*/}

        //       {/* TODO : 20220609 Expired modal 조건 생성 (button component에서 조건분기)>>> */}
        //       {dateValid&&countValid ? <input style={{display:""}} multiple type="file" webkitdirectory="true"
        //         onChange={e=>{props.func.onChangeHandler(e)}}
        //         onClick={e=>{e.target.value=null; props.func.onClickHandler()}}
        //       /> : <input style={{display:"none"}}
        //         onClick={()=>{
        //         props.openModal('expired')}} />
        //       }
        //       {/* TODO : 20220609 Expired modal 조건 생성 (button component에서 조건분기)<<< */}

        //       {/* <input style={{display:"none"}} multiple type="file"
        //         onChange={e=>{props.func.onChangeHandler(e)}}
        //         onClick={e=>{e.target.value=null; props.func.onClickHandler()}}
        //       /> */}
        //     </label>
        //   </div>
        // )}
        else {
          return (
            <div
              className={styles[props.styleName]}
              style={{ opacity: props.Enable ? 1 : 0.3 }}
              onClick={() => {
                props.Enable
                  ? props.func.onClickHandler()
                  : alert('Please select file(s).');
              }}
            >
              <div>{props.imgSrc}</div>
              <div>{props.title}</div>
            </div>
          );
        }
      })()}
    </>
  );
}

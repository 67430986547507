import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { info as MEDIA_info } from '6_media';
import styles from './CardLayout.module.scss';

export default function CardLayout({
  cards,
  width = '50%',
  height = '90%',
  setExpanded,
}) {
  return (
    <div
      className={styles['container_wrapper']}
      style={{
        width: width,
        height: height,
        paddingRight: `${cards[0].componentName === 'MainConfig' ? '0' : ''}`,
      }}
    >
      {cards.map((card, idx) => {
        const {
          componentName,
          title: cardTitle,
          Date: cardDate,
          content,
          cardHeight = '100%',
          isExpandable = false,
          isExpanded,
        } = card;

        return (
          // <div key={idx} className={styles["container"]}
          <div
            key={idx}
            className={
              styles[
                `${
                  componentName === 'CSVFormat' ||
                  componentName === 'ReportFormats'
                    ? componentName === 'CSVFormat'
                      ? 'container_setting_card2_1'
                      : 'container_setting_card2_2'
                    : 'container'
                }`
              ]
            }
            style={{
              height: cardHeight,
            }}
          >
            <div className={styles['top']}>
              {
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  {(() => {
                    if (cardTitle === 'Striatal SBR') {
                      return (
                        <div className={styles.infoWrapper}>
                          <div data-tooltip-id="tooltip_sbr_info">
                            <span>{cardTitle}</span>
                            <img src={MEDIA_info} alt="info icon" />
                          </div>
                          <ReactTooltip
                            id="tooltip_sbr_info"
                            place="right"
                            className={styles.tooltip_sbr_info}
                            content="When using atlases other than Melbourne subcortical atlas, anterior and posterior portions of putamen illustrated below display SBR of the whole putamen. "
                          />
                        </div>
                      );
                    } else if (cardTitle === 'Regional Centiloid') {
                      return (
                        <div className={styles.infoWrapper}>
                          <div data-tooltip-id="tooltip_centiloid_info">
                            <span>{cardTitle}</span>
                            <img src={MEDIA_info} alt="info icon" />
                          </div>
                          <ReactTooltip
                            id="tooltip_centiloid_info"
                            place="right"
                            content="Centiloid is calculated using whole cerebellum as a reference region."
                          />
                        </div>
                      );
                    } else {
                      return cardTitle;
                    }
                  })()}
                  {isExpandable && (
                    <span
                      style={{
                        fontSize: '1.5vw',
                        cursor: 'pointer',
                        userSelect: 'none',
                      }}
                      className={styles.plusMinusIcon}
                      onClick={() => {
                        setExpanded(componentName);
                      }}
                    >
                      {isExpanded ? '\u2013' : '+'}
                    </span>
                  )}
                </div>
              }
              {cardDate && <div>{cardDate}</div>}
            </div>
            <div className={styles['bottom']}>{content}</div>
          </div>
        );
      })}
    </div>
  );
}

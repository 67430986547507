import React from 'react';
import { useSelector } from 'react-redux';
import styles from './PatientInfoTable.module.scss';
import { useParams } from 'react-router-dom';

export default function PatientInfoTable(props) {
  const params = useParams();
  const productName = params.product.toLowerCase();
  const setting = useSelector((state) => state.setting);

  return (
    <table className={styles.table}>
      <tbody>
        {setting[productName].defaultPInfoState.map((info, idx) => {
          return (
            <tr
              key={`info_head_${idx}`}
              style={{ display: `${info.state ? '' : 'none'}` }}
            >
              <th>
                {info.name === 'Type & Date of Examination'
                  ? 'Study Date'
                  : info.name}
              </th>
              <td>
                <span style={{ display: `${info.state ? '' : 'none'}` }}>
                  {
                    props.contents[
                      info.name === 'Patient Name'
                        ? 'Name'
                        : info.name === 'Type & Date of Examination'
                        ? 'Study Date'
                        : info.name
                    ]
                  }
                </span>
              </td>
            </tr>
          );
        })}
        <tr>
          <th>{'Study Description'}</th>
          <td>
            <span>
              {props.contents['Study Description']
                ?.replace('18', '\u00B9\u2078')
                ?.replace('99mtc', '\u2079\u2079\u1d50Tc')}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

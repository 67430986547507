import axios from 'axios';

export function getCounts(data) {
  const url = process.env.REACT_APP_IS_CLOUD === 'true' ? `testing/uploader/?product=${data.product}` : 'testing/uploader/';
  return axios.get(url);
}

export function postPacs(data) {
  return axios.post('testing/pacs/', {
    Select: data.Select,
    Method: data.Method,
    PatientID: data.PatientID,
    PatientName: data.PatientName,
    StudyInstanceUID: data.StudyInstanceUID,
    SeriesInfo: data.SeriesInfo,
    Tracer: data.Tracer,
    StudyDate: data.StudyDate,
    StudyDescription: data.StudyDescription,
  });
}

export function deleteFilefromPacs(data) {
  /// uploader 기준 backend temp file 삭제, process kill
  return axios.delete('testing/pacs/', {
    headers: {
      Authorization: 'jwt ' + data.token,
    },
  });
}

// Kevin1116 changed here
export function postFile(data) {
  const results = axios.post('testing/uploader/', data.obj, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
  if (data.setReloadChecker) {
    data.setReloadChecker(new Date().getTime());
  }
  return results;
}

export async function runFile(data) {
  const payload = {
    obj: data.obj.map((el) => ({ ...el, Delete: '' })),
    Tracer: data.Tracer,
    addToWorklist: data.addToWorklist,
  };
  if (process.env.REACT_APP_IS_CLOUD === 'true') {
    payload.product = data.product;
  }
  const results = await axios.put('testing/uploader/', payload);
  if (data.setReloadChecker) {
    const time = new Date().getTime();
    data.setReloadChecker(time);
  }
  return results;
}

export function postFile_sended(data) {
  return axios.post('testing/uploader_sended/', data.obj);
}

export function runFile_sended(data) {
  return axios.put('testing/uploader_sended/', {
    obj: data.obj.map((el) => ({ ...el, Delete: '' })),
    Tracer: data.Tracer,
    addToWorklist: data.addToWorklist,
  });
}

export function getFile_cached(data) {
  return axios.get(`testing/uploader_cached/${data.keyStr}/`);
}

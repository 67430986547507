import { coreItem } from '0_variables/coreItem';
import { removesessionStorage } from '0_variables/utils';
import { openSpinner } from '1_reduxs/reducers/modalReducer';
import { fetchFilesStore } from '1_reduxs/reducers/filesReducer';
import { initControl } from '1_reduxs/reducers/controllerReducer';
import { setLoginState } from '1_reduxs/reducers/loginReducer';
import { resetLicenseInfo } from '1_reduxs/reducers/licenseReducer';
import {
  Signin as SigninAPI,
  Registration as RegistrationAPI,
  ResetPassword as ResetPasswordAPI,
} from '2_services/loginApi';
import BTXDB from '2_services/btxdb';

export function startLoginThunk(values) {
  return async (dispatch) => {
    try {
      dispatch(
        openSpinner({
          status: true,
          percent: 0,
          message: 'Log in...',
        }),
      );

      const res = await SigninAPI(values);
      const token = res.data.token;
      const is_Cloud_Mode = coreItem['amyloid'].Cloud_Mode;
      // Cloud 모드일때, 서버로부터 user.is_active_mode 를 받아야함
      // Cloud 모드가 아닐때, 서버로부터 user 객체를 받지 않아야함
      if (is_Cloud_Mode) {
        const is_active_email = res.data.user.is_active_email; // user 객체가 없을경우 예외발생후 로그인 실패

        if (is_active_email) {
          // is_active_email이 true일때, 로그인 진행
          sessionStorage.setItem('username', values.username);
          sessionStorage.setItem('token', token);

          dispatch(
            setLoginThunk({
              username: values.username,
              token: token,
              logged: true,
            }),
          );
          return {
            response: true,
            message: 'login: Success',
            code: res.status,
            is_Cloud_Mode: is_Cloud_Mode,
            is_active_email: is_active_email,
          };
        } else {
          // is_active_email이 false 일때, 로그인 차단
          return {
            response: true,
            message: 'email is not verified',
            code: res.status,
            is_Cloud_Mode: is_Cloud_Mode,
            is_active_email: is_active_email,
          };
        }
      } else {
        // user 객체가 있으면 안됨

        if ('user' in res.data) {
          throw new Error('on-premise front is connected to cloud server.');
        }
        sessionStorage.setItem('username', values.username);
        sessionStorage.setItem('token', token);

        dispatch(
          setLoginThunk({
            username: values.username,
            token: token,
            logged: true,
          }),
        );
        return {
          response: true,
          message: 'login: Success',
          code: res.status,
          is_Cloud_Mode: is_Cloud_Mode,
          is_active_email: null,
        };
      }
    } catch (err) {
      removesessionStorage();
      return {
        response: false,
        message: 'login: Failed',
        code: err?.response?.status,
        is_Cloud_Mode: null,
        is_active_email: null,
      };
    } finally {
      dispatch(openSpinner({ status: false, percent: 0, message: '' }));
    }
  };
}

// Not used
// export function verify_token_api(values) {
//   return async (dispatch) => {
//     try {
//       dispatch(
//         actionModal.open_spinner({
//           status: true,
//           percent: 0,
//           message: 'Verfiying user account...',
//         }),
//       );
//       const res = await loginApi.TokenVerification(values);
//       const token = res.data.token;
//       if (!token) throw new Error('token expired');
//       const username = sessionStorage.getItem('username');
//       dispatch(
//         setLoginThunk({ username: username, token: token, logged: true }),
//       );
//       return { response: true, message: 'token: Verified' };
//     } catch (err) {
//       removesessionStorage();
//       dispatch(setLoginThunk({ username: '', token: '', logged: false }));
//       return { response: false, message: 'token: Expired' };
//     } finally {
//       dispatch(
//         actionModal.open_spinner({ status: false, percent: 0, message: '' }),
//       );
//     }
//   };
// }

export function registerUserThunk(values) {
  return async (dispatch) => {
    try {
      dispatch(
        openSpinner({
          status: true,
          percent: 0,
          message: 'Signing up...',
        }),
      );
      const res = await RegistrationAPI(values);

      return {
        response: true,
        message: 'Registration: Success',
        code: res.status,
      };
    } catch (err) {
      removesessionStorage();
      const responseMessage = err?.response?.data;
      const msg = Object.keys(responseMessage)
        .map((k, i) => '(' + (i + 1) + ') ' + k + ': ' + responseMessage[k])
        .join('\n');
      return {
        response: false,
        message: `Registration: Failed, \n${msg}`,
        code: err?.response?.status,
      };
    } finally {
      dispatch(openSpinner({ status: false, percent: 0, message: '' }));
    }
  };
}

export function resetPasswdThunk(values) {
  return async (dispatch) => {
    try {
      dispatch(
        openSpinner({
          status: true,
          percent: 0,
          message: 'Checkout account',
        }),
      );
      const res = await ResetPasswordAPI(
        { Authorization: 'JWT ' }, // token이 필요없으나 헤더가 필요함
        values,
      );
      return { response: true, message: 'Reset: Success', code: res.status };
    } catch (err) {
      const responseMessage = err?.response?.data;
      if (responseMessage === undefined) {
        return {
          response: false,
          message: `Network Error: Please check if Btxbrain-backend is running.`,
          code: undefined,
        };
      } else {
        const msg = Object.keys(responseMessage)
          .map((k, i) => '(' + (i + 1) + ') ' + k + ': ' + responseMessage[k])
          .join('\n');
        return {
          response: false,
          message: `Reset: ${msg}`,
          code: err?.response?.status,
        };
      }
    } finally {
      removesessionStorage();
      dispatch(openSpinner({ status: false, percent: 0, message: '' }));
    }
  };
}

export function setLoginThunk(obj) {
  return (dispatch) => {
    const { username, token, logged } = obj;

    dispatch(setLoginState({ username, token, logged }));

    // reset Database
    BTXDB.transaction('rw', BTXDB.slices, async () => {
      // await Promise.all(BTXDB.tables.map((table) => table.clear()));
      await BTXDB.slices.clear();
      console.log('DB clear!');
    });

    if (logged === false) {
      dispatch(fetchFilesStore({ items: [] }));
      dispatch(initControl());
      dispatch(resetLicenseInfo());
    }
  };
}

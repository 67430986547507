import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { BiUserCircle } from 'react-icons/bi';
import { CgTimer } from 'react-icons/cg';
import { coreItem } from '0_variables/coreItem';
import { viewEnable } from '0_variables/utils';
import { setLoginThunk } from '1_reduxs/actions/loginAction';
import { openVersionModal } from '1_reduxs/reducers/modalReducer';
import {
  dashboard as MEDIA_dashboard,
  blueDashboard as MEDIA_blueDashboard,
  upload as MEDIA_upload,
  blueUpload as MEDIA_blueUpload,
  view as MEDIA_view,
  blueView as MEDIA_blueView,
  analysis as MEDIA_analysis,
  blueAnalysis as MEDIA_blueAnalysis,
  setting as MEDIA_setting,
  blueSetting as MEDIA_blueSetting,
  logout as MEDIA_logout,
} from '6_media';
import BrtnxLogo from '6_media/svg/BrtnxLogo';
import { MenuButton } from './components';
import styles from './Menu.module.scss';

export default function Menu(props) {
  const username = sessionStorage.getItem('username');
  const params = useParams();
  const location = useLocation();
  const pageName = location.pathname.split('/')[2];
  const productName = params.product.toLowerCase();
  const productCoreItem = coreItem[productName];
  const CTN_Mode = productCoreItem.CTN_Mode;
  const control = useSelector((state) => state.control);
  const dispatch = useDispatch();

  const menuItem = (() => {
    if (!CTN_Mode)
      return {
        page: [
          {
            key: 0,
            enabled: true,
            name: 'Dashboard',
            unactiveIcon: MEDIA_dashboard,
            push: `/${params.product}/dashboard`,
            activeIcon: MEDIA_blueDashboard,
            callBack: () => undefined,
          },
          {
            key: 1,
            enabled: true,
            name: 'Upload',
            unactiveIcon: MEDIA_upload,
            push: `/${params.product}/upload`,
            activeIcon: MEDIA_blueUpload,
            callBack: () => undefined,
          },
          {
            key: 2,
            enabled: viewEnable(
              control[productName],
              control[productName].selectedFileID,
            ),
            name: 'View',
            unactiveIcon: MEDIA_view,
            push: `/${params.product}/view/${control[productName].selectedFileID}`,
            activeIcon: MEDIA_blueView,
            callBack: () => undefined,
          },
          {
            key: 3,
            enabled: control[productName].selectedFileID !== null,
            name: 'Analysis',
            unactiveIcon: MEDIA_analysis,
            push: `/${params.product}/analysis/${control[productName].selectedFileID}`,
            activeIcon: MEDIA_blueAnalysis,
            callBack: () => undefined,
          },
          // {key:4, enabled:control[productName].selectedFileID !== null, name:"Report", unactiveIcon:MEDIA_report, push:`/${params.product}/report/${control[productName].selectedFileID}`, activeIcon:MEDIA_blueReport, callBack:()=>(undefined)},
          {
            key: 4,
            enabled: true,
            name: 'Setting',
            unactiveIcon: MEDIA_setting,
            push: `/${params.product}/setting`,
            activeIcon: MEDIA_blueSetting,
            callBack: () => undefined,
          },
        ],
        logout: {
          key: 5,
          enabled: true,
          name: 'Log out',
          unactiveIcon: MEDIA_logout,
          push: '/signin',
          callBack: () => {
            dispatch(
              setLoginThunk({
                username: '',
                token: '',
                logged: false,
              }),
            );
          },
        },
      };
    else
      return {
        page: [
          {
            key: 0,
            enabled: true,
            name: 'Dashboard',
            unactiveIcon: MEDIA_dashboard,
            push: `/${params.product}/dashboard`,
            activeIcon: MEDIA_blueDashboard,
            callBack: () => undefined,
          },
          {
            key: 1,
            enabled: true,
            name: 'Upload',
            unactiveIcon: MEDIA_upload,
            push: `/${params.product}/upload`,
            activeIcon: MEDIA_blueUpload,
            callBack: () => undefined,
          },
          {
            key: 2,
            enabled: viewEnable(
              control[productName],
              control[productName].selectedFileID,
            ),
            name: 'View',
            unactiveIcon: MEDIA_view,
            push: `/${params.product}/view/${control[productName].selectedFileID}`,
            activeIcon: MEDIA_blueView,
            callBack: () => undefined,
          },
          // {key:3, enabled:control[productName].selectedFileID !== null, name:"Analysis", unactiveIcon:MEDIA_analysis, push:`/${params.product}/analysis/${control[productName].selectedFileID}`, activeIcon:MEDIA_blueAnalysis, callBack:()=>(undefined)},
          // {key:4, enabled:control[productName].selectedFileID !== null, name:"Report", unactiveIcon:MEDIA_report, push:`/${params.product}/report/${control[productName].selectedFileID}`, activeIcon:MEDIA_blueReport, callBack:()=>(undefined)},
          // {key:4, enabled:true, name:"Setting", unactiveIcon:MEDIA_setting, push:`/${params.product}/setting`, activeIcon:MEDIA_blueSetting, callBack:()=>(undefined)},
        ],
        logout: {
          key: 5,
          enabled: true,
          name: 'Log out',
          unactiveIcon: MEDIA_logout,
          push: '/signin',
          callBack: () =>
            dispatch(
              setLoginThunk({
                username: '',
                token: '',
                logged: false,
              }),
            ),
        },
      };
  })();
  return (
    <div
      className={`${styles['container']} ${
        pageName === 'report' ? styles.hidden : ''
      }`}
    >
      <div className={`${styles['up-items']}`}>
        <div
          // onClick={()=>{
          //   if (CTN_Mode) history.push('/signin');
          //   else history.push('/selectProduct');
          // }}
          className={`${styles['logo-container']}`}
        >
          <div
            className={`${styles['logo-style']}`}
            onClick={() => {
              dispatch(openVersionModal({ isOn: true }));
            }}
          >
            <BrtnxLogo width="50%" fill="white" />
          </div>
        </div>

        {menuItem.page.map((item, idx) => (
          <MenuButton key={item.key} item={item} />
        ))}
      </div>

      <div className={`${styles['down-items']}`}>
        <div className={`${styles['license-info']}`}>
          <div>
            <BiUserCircle size={'1vw'} strokeWidth={'0px'} />
            {(() => {
              if (props.remainCounts === 'inf') {
                return <span>{username}</span>;
              } else if (CTN_Mode) {
                return <span>{username}</span>;
              } else {
                return (
                  <span>{username + ' (' + props.remainCounts + ')'}</span>
                );
              }
            })()}
          </div>
          {props.endDate.trim() !== 'inf' && (
            <div>
              <CgTimer size={'1vw'} strokeWidth={'0px'} />
              <span>{props.endDate}</span>
            </div>
          )}
        </div>

        <MenuButton key={menuItem.logout.key} item={menuItem.logout} />
      </div>
    </div>
  );
}

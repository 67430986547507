import React from 'react';
import styles from './UploadingStep1.module.scss';
import { useParams } from 'react-router-dom';
import { coreItem } from '0_variables/coreItem';

export default function UploadingStep1({ stepIndex, uploadingStepProps }) {
  const params = useParams();
  const productName = params.product.toLowerCase();
  const productCoreItem = coreItem[productName];
  const { handleRef, selectTracer, setSelectTracer, executeScroll } =
    uploadingStepProps;

  return (
    <>
      <div
        className={styles.boxContainer}
        ref={(el) => handleRef(stepIndex, el)}
      >
        {/* <span className={styles.exitButton}
          onClick={()=>{ closeModal; deleteFiles; }
        }>
          {'X'}
        </span> */}
        <div className={styles.boxTitle}>{'Step1. Tracer Selection'}</div>

        <div className={styles.boxContent}>
          {productCoreItem.tracers.map((item, idx) => (
            <span
              key={`tracer_${idx}`}
              className={`${
                selectTracer.shortname === item.shortname ? styles.active : ''
              }`}
              onClick={() => setSelectTracer(item)}
              style={{
                border: `3px ${
                  selectTracer.shortname === item.shortname
                    ? item.color
                    : 'transparent'
                } solid`,
              }}
            >
              <div>
                {item.fullname
                  .replaceAll('18', '\u00B9\u2078')
                  .replaceAll('99mtc', '\u2079\u2079\u1d50Tc')}
              </div>
              <div>{item.commercialname}</div>
            </span>
          ))}
        </div>

        <div className={styles.buttonGroup}>
          <div
            className={styles.nextButton}
            onClick={() => {
              executeScroll(stepIndex + 1);
            }}
          >
            {'Next \u1433'}
          </div>
        </div>
      </div>
    </>
  );
}

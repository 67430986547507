import React, { useState, useRef, createRef, useCallback } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import chroma from 'chroma-js';
import { useDispatch } from 'react-redux';
import { MdDragHandle } from 'react-icons/md';
import { debounce } from 'lodash';
import { IoMdArrowDropleft } from 'react-icons/io';
import { BsNodeMinus } from 'react-icons/bs';
import { getSizebyProduct, ratio2Width, clamp } from '0_variables/utils';
import { coreItem } from '0_variables/coreItem';
import { updateSliceIndex } from '1_reduxs/reducers/controllerReducer';
import { StackViewer } from '4_routers/3_View/components';
import styles from './ViewerGroup.module.scss';

import * as cornerstone from 'cornerstone-core';
import * as cornerstoneMath from 'cornerstone-math';
import * as cornerstoneTools from 'cornerstone-tools';
import Hammer from 'hammerjs';
import * as cornerstoneWebImageLoader from 'cornerstone-web-image-loader';

cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneWebImageLoader.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;

export default function ViewerGroup(props) {
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const [yOffset, setYOffset] = useState({ y: 0, WC: 16384 });
  const [savedValue, setSavedValue] = useState([]);
  const headRef = useRef(null);
  const bodyRef = useRef(null);
  const footRef = useRef(null);
  const cmapCode = {
    name: props.cmap,
    from: 1,
    to: 255,
    code: props.inverted
      ? [...Array(256).keys()].reverse().map((i) =>
          chroma(
            cornerstone.colors
              .getColormap(props.cmap)
              .getColor(i)
              .map((el, idx) => (idx !== 3 ? el : el / 255)),
          ).name(),
        )
      : [...Array(256).keys()].map((i) =>
          chroma(
            cornerstone.colors
              .getColormap(props.cmap)
              .getColor(i)
              .map((el, idx) => (idx !== 3 ? el : el / 255)),
          ).name(),
        ),
  };

  const coronalImageIdIndex = props.defaultCoroanlIndex;
  const sagittalImageIdIndex = props.defaultSagittalndex;
  const axialImageIdIndex = props.defaultAxialIndex;
  const [sagittalSliceX, setSagittalSliceX] = useState(50);
  const [coronalSliceY, setCoronalSliceY] = useState(60);
  const [axialSliceZ, setAxialSliceZ] = useState(45);
  const [mipAngle, setMipAngle] = useState(0);
  const productName = params.product.toLowerCase();
  const pageName = location.pathname.split('/')[2];
  const productCoreItem = coreItem[productName];
  const CTN_Mode = productCoreItem.CTN_Mode;
  const viewerWidth = props.viewerWidth;

  const [PETRef] = useState([...Array(4)].map((_, i) => createRef()));
  const wwwcsynchronizer = new cornerstoneTools.Synchronizer(
    'cornerstoneimagerendered',
    cornerstoneTools.wwwcSynchronizer,
  );
  const referenceLinesync = new cornerstoneTools.Synchronizer(
    'cornerstonenewimage',
    cornerstoneTools.updateImageSynchronizer,
  );

  const height = (width, Direction) => {
    const size = getSizebyProduct(Direction);
    const ratio = ratio2Width(size);
    return width * ratio;
  };
  // const width = (height, Direction) => {
  //   const size = getSizebyProduct(Direction);
  //   const ratio = ratio2Height(size);
  //   return height * ratio;
  // };

  const changeCoronalSliceId = useCallback(
    debounce((index) => {
      dispatch(
        updateSliceIndex({
          productName,
          fileID: props.fileID,
          direction: 'coronal',
          sliceIndex: index,
        }),
      );
    }, 500),
    [dispatch, productName, props.fileID, setCoronalSliceY],
  );

  const changeSagittalSliceId = useCallback(
    debounce((index) => {
      dispatch(
        updateSliceIndex({
          productName,
          fileID: props.fileID,
          direction: 'sagittal',
          sliceIndex: index,
        }),
      );
    }, 500),
    [dispatch, productName, props.fileID, setSagittalSliceX],
  );

  const changeAxialSliceId = useCallback(
    debounce((index) => {
      dispatch(
        updateSliceIndex({
          productName,
          fileID: props.fileID,
          direction: 'axial',
          sliceIndex: index,
        }),
      );
    }, 500),
    [dispatch, productName, props.fileID, setAxialSliceZ],
  );

  const getImageID = (prefix, direction, index) => {
    const { inout, fileID } = props;
    return `${prefix}${pageName}://${inout}/${fileID}/${direction}/${index}`;
  };

  const getMNIImageID = (direction, index) => {
    return `mni_brtnx://${direction}/${index}`;
  };

  const MNI_options = {
    opacity: 1,
    // visible: props.visible,
    viewport: {
      colormap: 'gray',
    },
    name: 'MNI',
  };

  const PET_options = {
    opacity: props.opacity,
    viewport: {
      colormap: props.cmap,
    },
    name: 'PET',
  };

  const coronalStacks = [
    {
      imageIds: [...Array(109 + 20 - 1).keys()].map((v, i) =>
        getMNIImageID('coronal', i),
      ),
      currentImageIdIndex: coronalImageIdIndex,
      options: MNI_options,
    },
    {
      imageIds: [...Array(109 + 20 - 1).keys()].map((v, i) =>
        getImageID(productName, 'coronal', i),
      ),
      currentImageIdIndex: coronalImageIdIndex,
      options: PET_options,
    },
  ];
  const sagittalStacks = [
    {
      imageIds: [...Array(91 + 40).keys()].map((v, i) =>
        getMNIImageID('sagittal', i),
      ),
      currentImageIdIndex: sagittalImageIdIndex,
      options: MNI_options,
    },
    {
      imageIds: [...Array(91 + 40).keys()].map((v, i) =>
        getImageID(productName, 'sagittal', i),
      ),
      currentImageIdIndex: sagittalImageIdIndex,
      options: PET_options,
    },
  ];
  const axialStacks = [
    {
      imageIds: [...Array(91).keys()].map((v, i) => getMNIImageID('axial', i)),
      currentImageIdIndex: axialImageIdIndex,
      options: MNI_options,
    },
    {
      imageIds: [...Array(91).keys()].map((v, i) =>
        getImageID(productName, 'axial', i),
      ),
      currentImageIdIndex: axialImageIdIndex,
      options: PET_options,
    },
  ];
  const mipStacks = [
    {
      imageIds: [...Array(45).keys()].map((v, i) =>
        getImageID(productName, 'mip', i),
      ),
      currentImageIdIndex: 0,
      options: { ...PET_options, opacity: 1 },
    },
  ];

  const sliceProps = {
    inout: props.inout,
    pixelInfo: props.pixelInfo,
    // setPixelInfo:props.setPixelInfo,
    crosshair: props.crosshair,
    wwwcsynchronizer,
    referenceLinesync,
    // productName:props.match.params.product,
    inverted: props.inverted,
    clipConfig: props.clipConfig,
    // setWW:props.setWW,
    // setWC:props.setWC,
  };

  const mipProps = {
    inout: props.inout,
    pixelInfo: props.pixelInfo,
    // setPixelInfo:props.setPixelInfo,
    crosshair: props.crosshair,
    wwwcsynchronizer,
    referenceLinesync,
    // productName:props.match.params.product,
    inverted: props.inverted,
    clipConfig: props.clipConfig,
    // setWW:props.setWW,
    // setWC:props.setWC,
  };

  const depthInfo = {
    coronal: 109 + 20 - 1,
    sagittal: 91 + 40,
    axial: 91,
    mip: 45,
  };

  const windowCenter = (props.mipWMax + props.mipWMin) / 2;
  const windowWidth = props.mipWMax - props.mipWMin;
  const centerPercent = (windowCenter / 32767) * 100;
  const widthPercent = (windowWidth / 32767) * 100;

  return (
    <div
      style={{
        zIndex: props.sNorm ? '0' : '-1',
        position: 'absolute',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div className={styles.imageWrapper}>
        <div
          className={styles.divUpper}
          style={{ height: `${height(viewerWidth, 'coronal')}vw` }}
        >
          <div
            className={styles.divCoronal}
            style={{ width: `${viewerWidth}vw` }}
          >
            <StackViewer
              {...props}
              params={props.match.params}
              WMin={props.coronalWMin}
              WMax={props.coronalWMax}
              setWMin={props.setCoronalWMin}
              setWMax={props.setCoronalWMax}
              ref={PETRef[0]}
              productName={props.match.params.product}
              depthInfo={depthInfo}
              x={sagittalSliceX}
              y={90 - axialSliceZ}
              z={coronalSliceY}
              getSliceID={setCoronalSliceY}
              updateSliceId={changeCoronalSliceId}
              type={'slice'}
              direction={'coronal'}
              width={`102%`}
              height={`105%`}
              Stacks={coronalStacks}
              cornerstoneProps={sliceProps}
            />
          </div>
          <div
            className={styles.divSagittal}
            style={{ width: `${viewerWidth}vw` }}
          >
            <StackViewer
              {...props}
              params={props.match.params}
              WMin={props.sagittalWMin}
              WMax={props.sagittalWMax}
              setWMin={props.setSagittalWMin}
              setWMax={props.setSagittalWMax}
              ref={PETRef[1]}
              productName={props.match.params.product}
              depthInfo={depthInfo}
              x={coronalSliceY}
              y={90 - axialSliceZ}
              z={sagittalSliceX}
              getSliceID={setSagittalSliceX}
              updateSliceId={changeSagittalSliceId}
              type={'slice'}
              direction={'sagittal'}
              width={`102%`}
              height={`102%`}
              Stacks={sagittalStacks}
              cornerstoneProps={sliceProps}
            />
          </div>
        </div>

        <div
          className={styles.divLower}
          style={{ height: `${height(viewerWidth, 'axial')}vw` }}
        >
          <div
            className={styles.divAxial}
            style={{ width: `${viewerWidth}vw` }}
          >
            <StackViewer
              {...props}
              params={props.match.params}
              WMin={props.axialWMin}
              WMax={props.axialWMax}
              setWMin={props.setAxialWMin}
              setWMax={props.setAxialWMax}
              ref={PETRef[2]}
              productName={props.match.params.product}
              depthInfo={depthInfo}
              x={sagittalSliceX}
              y={128 - coronalSliceY}
              z={axialSliceZ}
              getSliceID={setAxialSliceZ}
              updateSliceId={changeAxialSliceId}
              type={'slice'}
              direction={'axial'}
              width={`102%`}
              height={`103%`}
              Stacks={axialStacks}
              cornerstoneProps={sliceProps}
            />
          </div>
          <div className={styles.divMip} style={{ width: `${viewerWidth}vw` }}>
            <StackViewer
              {...props}
              params={props.match.params}
              WMin={props.mipWMin}
              WMax={props.mipWMax}
              setWMin={props.setMipWMin}
              setWMax={props.setMipWMax}
              ref={PETRef[3]}
              productName={props.match.params.product}
              depthInfo={depthInfo}
              x={coronalSliceY}
              y={90 - axialSliceZ}
              z={sagittalSliceX}
              a={mipAngle}
              getSliceID={setMipAngle}
              updateSliceId={() => {}}
              type={'mip'}
              direction={'mip'}
              width={`140%`}
              height={`100%`}
              Stacks={mipStacks}
              cornerstoneProps={mipProps}
            />
          </div>
        </div>
      </div>

      <div
        className={styles.colorbarWrapper}
        onMouseUp={(e) => {
          e.stopPropagation();
          props.setColorbarPressed({
            top: false,
            center: false,
            bottom: false,
          });
        }}
        onMouseLeave={(e) => {
          e.stopPropagation();
          props.setColorbarPressed({
            top: false,
            center: false,
            bottom: false,
          });
        }}
      >
        <div
          className={styles.colorbarContainer}
          style={{
            position: 'relative',
            background: `linear-gradient(to top, ${
              cmapCode.code[cmapCode.from]
            } ${centerPercent}%, ${
              cmapCode.code[cmapCode.to]
            } ${centerPercent}%)`,
          }}
        >
          {(() => {
            if (props.sNorm && PETRef[0].current !== null) {
              const head = (windowCenter + windowWidth / 2) / 32767;
              const body = windowCenter / 32767;
              const foot = (windowCenter - windowWidth / 2) / 32767;

              return (
                <>
                  {savedValue.map((el) => (
                    <div
                      className={styles.savedvaluelist}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        position: 'absolute',
                        transform: 'translate(0%, -50%)',
                        top: `${el.pos}%`,
                        left: '90%',
                        width: '90%',
                      }}
                      onClick={() =>
                        setSavedValue(
                          savedValue
                            .filter((el2) => el.id !== el2.id)
                            .map((el3, idx) => ({ ...el3, id: idx })),
                        )
                      }
                    >
                      <BsNodeMinus size={'1.2vw'} />
                      <span>{el.value}</span>
                    </div>
                  ))}
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      position: 'absolute',
                      transform: 'translate(0%, -50%)',
                      top: `${clamp((1 - head) * 100, 0, 100)}%`,
                      left: '90%',
                      width: '90%',
                      userSelect: 'none',
                      pointerEvent: 'none',
                    }}
                  >
                    <IoMdArrowDropleft size={'1.2vw'} />
                    <span>
                      {CTN_Mode
                        ? Math.floor(head * props.pixelInfo.width)
                        : (head * props.pixelInfo.width).toFixed(2)}
                    </span>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      position: 'absolute',
                      transform: 'translate(0%, -50%)',
                      top: `${clamp((1 - body) * 100, 0, 100)}%`,
                      left: '90%',
                      width: '90%',
                      userSelect: 'none',
                      pointerEvent: 'none',
                    }}
                  >
                    <IoMdArrowDropleft size={'1.2vw'} />
                    <span>
                      {CTN_Mode
                        ? Math.floor(body * props.pixelInfo.width)
                        : (body * props.pixelInfo.width).toFixed(2)}
                    </span>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      position: 'absolute',
                      transform: 'translate(0%, -50%)',
                      top: `${clamp((1 - foot) * 100, 0, 100)}%`,
                      left: '90%',
                      width: '90%',
                      userSelect: 'none',
                      pointerEvent: 'none',
                    }}
                  >
                    <IoMdArrowDropleft size={'1.2vw'} />
                    <span>
                      {CTN_Mode
                        ? Math.floor(foot * props.pixelInfo.width)
                        : (foot * props.pixelInfo.width).toFixed(2)}
                    </span>
                  </div>
                </>
              );
            }
          })()}
          <div
            style={{
              height: '100%',
              width: '100%',
              overflow: 'hidden',
              border: '0px cyan solid',
              boxSizing: 'border-box',
              position: 'relative',
            }}
            onMouseMove={(e) => {
              e.stopPropagation();
              if (props.colorbarPressed.center) {
                const container = bodyRef.current.parentElement;
                const containerBounds = container.getBoundingClientRect();
                const containerHeight = containerBounds.height;

                const yPercentfromMouseDown =
                  1 - (yOffset.y - containerBounds.top) / containerHeight; // 여기서 중심으로부터의 offset을 제거해줘야함
                const currentCenterPercent = yOffset.WC / 32767;
                const yOffsetFromCenter =
                  yPercentfromMouseDown - currentCenterPercent;
                const yPercent =
                  1 - (e.clientY - containerBounds.top) / containerHeight;
                const updatedWC = (yPercent - yOffsetFromCenter) * 32767;
                const currentWidth = clamp(windowWidth, 0, 32767);
                const updateMax = clamp(
                  updatedWC + currentWidth / 2,
                  2000,
                  32767,
                );
                const updateMin = clamp(
                  updatedWC - currentWidth / 2,
                  0,
                  32767 - 2000,
                );
                props.setMipWMax(updateMax); //max
                props.setMipWMin(updateMin); //min
              } else if (props.colorbarPressed.top) {
                const container = bodyRef.current.parentElement;
                const containerBounds = container.getBoundingClientRect();
                const containerHeight = containerBounds.height;
                const yPercent =
                  1 - (e.clientY - containerBounds.top) / containerHeight;
                const updateMax = clamp(yPercent * 32767, 0, 32767);
                props.setMipWMax(updateMax); //max
              } else if (props.colorbarPressed.bottom) {
                const container = bodyRef.current.parentElement;
                const containerBounds = container.getBoundingClientRect();
                const containerHeight = containerBounds.height;
                const yPercent =
                  1 - (e.clientY - containerBounds.top) / containerHeight;
                const updateMin = clamp(yPercent * 32767, 0, 32767);
                props.setMipWMin(updateMin); //max
              }
            }}
          >
            {(() => {
              // color bar
              return (
                <div
                  ref={bodyRef}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignitems: 'center',
                    position: 'absolute',
                    top: `${100 - centerPercent}%`,
                    left: '50%',
                    height: `${widthPercent}%`,
                    width: '100%',
                    border: '0px blue solid',
                    boxSizing: 'border-box',
                    transform: 'translate(-50%, -50%)',
                    background: `linear-gradient(to top, ${cmapCode.code
                      .slice(cmapCode.from, cmapCode.to)
                      .join(',')})`,
                  }}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    props.setColorbarPressed({
                      top: false,
                      center: true,
                      bottom: false,
                    });
                    setYOffset({ y: e.clientY, WC: windowCenter });
                  }}
                >
                  <div
                    ref={headRef}
                    onMouseDown={(e) => {
                      e.stopPropagation();
                      props.setColorbarPressed({
                        top: true,
                        center: false,
                        bottom: false,
                      });
                      setYOffset({ y: e.clientY, WC: windowCenter });
                    }}
                    style={{
                      height: '10px',
                      border: '0px red solid',
                      color: `${
                        props.colorbarPressed.center ||
                        props.colorbarPressed.top
                          ? styles.activeColor
                          : 'red'
                      }`,
                      textAlign: 'center',
                      transform: 'translate(0%,0%)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {<MdDragHandle size={'1vw'} />}
                  </div>
                  <div
                    ref={footRef}
                    onMouseDown={(e) => {
                      e.stopPropagation();
                      props.setColorbarPressed({
                        top: false,
                        center: false,
                        bottom: true,
                      });
                      setYOffset({ y: e.clientY, WC: windowCenter });
                    }}
                    style={{
                      height: '10px',
                      border: '0px red solid',
                      color: `${
                        props.colorbarPressed.center ||
                        props.colorbarPressed.bottom
                          ? styles.activeColor
                          : 'red'
                      }`,
                      textAlign: 'center',
                      transform: 'translate(0%,0%)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <MdDragHandle size={'1vw'} />
                  </div>
                </div>
              );
            })()}
          </div>
        </div>
      </div>
    </div>
  );
}

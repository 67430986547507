import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { coreItem } from '0_variables/coreItem';
import { initImageLoader } from '0_variables/utils';
import { getSlices } from '2_services/sliceApi';
import { spinnerV2 as MEDIA_spinnerV2 } from '6_media';
import styles from './View.module.scss';
import { Container } from './components';

export default function View(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const control = useSelector((state) => state.control);
  const params = useParams();
  const productName = params.product.toLowerCase();
  const { tracers: tracerList } = coreItem[productName];
  const { openedFiles, selectedFileID } = control[productName];
  const [isLoadedSlices, setIsLoadedSlices] = useState(false);
  const [selectFileInfo, setFileInfo] = useState({
    fileID: '',
    Tracer: '',
    PatientName: '',
    defaultCoroanlIndex: 128 / 2 + 11,
    defaultSagittalndex: 130 / 2,
    defaultAxialIndex: 45 - 8,
  });

  useEffect(() => {
    if (
      selectFileInfo.fileID === '' ||
      selectFileInfo.fileID !== selectedFileID
    ) {
      const getSlicesByFile = async () => {
        setIsLoadedSlices(false);
        try {
          const sliceResult = await getSlices(selectedFileID);
          if (sliceResult.length) {
            initImageLoader(productName, 'view', { Slices: sliceResult });
            const selectedFile = openedFiles.find(
              (el) => el.fileID === selectedFileID,
            );

            setFileInfo({
              fileID: selectedFileID,
              Tracer: selectedFile.Tracer,
              PatientName: selectedFile.PatientName,
              defaultCoroanlIndex: selectedFile.defaultCoroanlIndex,
              defaultSagittalndex: selectedFile.defaultSagittalndex,
              defaultAxialIndex: selectedFile.defaultAxialIndex,
            });

            setIsLoadedSlices(true);
          }
        } catch (error) {
          console.error('get slide error : ', error);
          alert(
            'Image slices are not prepared yet. Please try again later.\n\n' +
              'If you continue to receive this message, it may be due to hardware issue.\n\n' +
              'Please inform it Brighotnix Imaging Inc.(support@brtnx.com)',
          );
          history.push(`/${productName}/upload`);
        }
      };

      if (selectedFileID) getSlicesByFile();
    }
  }, [
    dispatch,
    productName,
    selectFileInfo.fileID,
    openedFiles,
    selectedFileID,
    isLoadedSlices,
    history,
  ]);

  return (
    <>
      {/* loading.. */}
      {isLoadedSlices === false && (
        <div className={styles.loading}>
          <>
            <span className={styles.loadIcon}>
              <img
                src={MEDIA_spinnerV2}
                style={{ width: '50%' }}
                alt="spinnerV2"
              />
            </span>
            <span className={styles.loadMsg}>{'Loading...'}</span>
          </>
        </div>
      )}

      {/* loading done */}
      {isLoadedSlices && (
        <Container
          {...props}
          enabled={true}
          {...selectFileInfo}
          tracerList={tracerList}
          productName={productName}
        />
      )}
    </>
  );
}

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  amyloid: { valid: false, until: '', count: 0, isInitailized: false },
  dat: { valid: false, until: '', count: 0, isInitailized: false },
  fdg: { valid: false, until: '', count: 0, isInitailized: false },
  tau: { valid: false, until: '', count: 0, isInitailized: false },
  perfusion: { valid: false, until: '', count: 0, isInitailized: false },
};

const licenseSlice = createSlice({
  name: 'license',
  initialState,
  reducers: {
    resetLicenseInfo: (state) => {
      return initialState;
    },
    updateLicenseInfo: (state, action) => {
      const { productName, valid, until, count } = action.payload;
      state[productName] = { valid, until, count, isInitailized: true };
    },
    updateLicenseAll: (state, action) => {
      const { amyloid, dat, fdg, tau, perfusion } = action.payload;
      if (amyloid) state.amyloid = { ...amyloid, isInitailized: true };
      if (dat) state.dat = { ...dat, isInitailized: true };
      if (fdg) state.fdg = { ...fdg, isInitailized: true };
      if (tau) state.tau = { ...tau, isInitailized: true };
      if (perfusion) state.perfusion = { ...perfusion, isInitailized: true };
    },
  },
});

export const { resetLicenseInfo, updateLicenseInfo, updateLicenseAll } =
  licenseSlice.actions;
export default licenseSlice.reducer;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './BrainTable.module.scss';

export default function BrainTable({
  borderEnable = true,
  legend,
  colorcode,
  items,
  refName,
} = {}) {
  const params = useParams();
  const productName = params.product.toLowerCase();
  // TODO: RF랑 refName을 props drilling 할지, redux변수로 직접 읽어올지 고민중...
  // const settingOfProduct = setting[productName]
  // const tracerName = selectedFile['Tracer'];
  // const refName = settingOfProduct.defaultRef[tracerName]
  // const valueList = [40, 20, 70, 100, 50];

  const refStepIndex = {
    wcrbll: {
      ref: 'wcrbll',
      min: 0.8,
      max: 2.4,
      step: 0.2,
      default: 1.3,
      th_percent: 50,
      divide: 7,
    },
    wcrbll_spect: {
      ref: 'wcrbll',
      min: 0.3,
      max: 0.9,
      step: 0.1,
      default: 0.6,
      th_percent: 25,
      divide: 7,
    },
    crbllGM: {
      ref: 'crbllGM',
      min: 1.0,
      max: 2.2,
      step: 0.2,
      default: 1.6,
      th_percent: 50,
      divide: 7,
    },
    crbllGM_spect: {
      ref: 'crbllGM',
      min: 0.5,
      max: 1.1,
      step: 0.1,
      default: 0.8,
      th_percent: 25,
      divide: 7,
    },
    pons: {
      ref: 'pons',
      min: 0.6,
      max: 1.6,
      step: 0.2,
      default: 0.8,
      th_percent: 50,
      divide: 6,
    },
    cerebralWM: {
      ref: 'cerebralWM',
      min: 0.6,
      max: 1.6,
      step: 0.2,
      default: 0.8,
      th_percent: 50,
      divide: 6,
    },
    Occipital: {
      ref: 'Occipital',
      min: 1,
      max: 4,
      step: 0.5,
      default: 2.5,
      th_percent: 50,
      divide: 7,
    },
  };

  const [targetTracerRef, setTargetTracerRef] = useState('wcrbll');
  useEffect(() => {
    if (productName === 'dat') {
      setTargetTracerRef('Occipital');
    } else {
      if (refName === 'Whole cerebellum') {
        if (productName === 'perfusion') setTargetTracerRef('wcrbll_spect');
        else setTargetTracerRef('wcrbll');
      } else if (refName === 'Pons') {
        setTargetTracerRef('pons');
      } else if (refName === 'Cerebellar gray matter') {
        if (productName === 'perfusion') setTargetTracerRef('crbllGM_spect');
        else setTargetTracerRef('crbllGM');
      } else if (refName === 'Cerebral white matter') {
        setTargetTracerRef('cerebralWM');
      } else if (refName === 'Occipital cortex') {
        setTargetTracerRef('Occipital');
      }
    }
  }, [productName, refName]);

  return (
    <>
      <table className={styles.table}>
        <tbody className={styles.tbody}>
          {items?.map((item, idx) => {
            // debugger;
            const leftRatio = Math.min(
              100,
              Math.max(
                0,
                (
                  ((item.Lval - refStepIndex[targetTracerRef].min) /
                    (refStepIndex[targetTracerRef].max -
                      refStepIndex[targetTracerRef].min)) *
                  100
                ).toFixed(0),
              ),
            );
            const rightRatio = Math.min(
              100,
              Math.max(
                0,
                (
                  ((item.Rval - refStepIndex[targetTracerRef].min) /
                    (refStepIndex[targetTracerRef].max -
                      refStepIndex[targetTracerRef].min)) *
                  100
                ).toFixed(0),
              ),
            );
            const totalRatio = Math.min(
              100,
              Math.max(
                0,
                (
                  (((item.Lval + item.Rval) / 2 -
                    refStepIndex[targetTracerRef].min) /
                    (refStepIndex[targetTracerRef].max -
                      refStepIndex[targetTracerRef].min)) *
                  100
                ).toFixed(0),
              ),
            );

            // console.log(totalRatio)
            return (
              <tr key={idx}>
                <td
                  className={styles.svgImage}
                  style={{ borderWidth: borderEnable ? '' : '0' }}
                >
                  <div>
                    {item.BrainSVG({
                      Lobe1: totalRatio,
                      Lobe2: totalRatio,
                      Lobe3: totalRatio,
                      Lobe4: totalRatio,
                    })}
                  </div>
                  <div>{item.lobe}</div>
                </td>
                <td
                  className={styles.colorBar}
                  style={{ borderWidth: borderEnable ? '' : '0' }}
                >
                  <div
                    style={{
                      borderRight: `0.5vw ${colorcode[leftRatio]} solid`,
                      width: `${leftRatio}%`,
                      height: '50%',
                      display: 'flex',
                      alignItems: `${
                        item.lobe === 'Global Lobe' ? 'end' : 'center'
                      }`,
                    }}
                  >
                    <div
                      style={{
                        textAlign: `${leftRatio < 90 ? 'left' : 'right'}`,
                        transform: `${
                          leftRatio < 90
                            ? `${
                                item.lobe === 'Global Lobe'
                                  ? 'translate(150%, 0)'
                                  : 'translate(180%, 0)'
                              }`
                            : 'translate(-30%, 0)'
                        }`,
                      }}
                    >
                      <div style={{ opacity: '30%' }}>
                        {item.lobe === 'Global Lobe' ? '' : 'L'}
                      </div>
                      <div
                        style={{
                          opacity: `${
                            item.lobe === 'Global Lobe' ? '30%' : ''
                          }`,
                        }}
                      >
                        {item.lobe === 'Global Lobe'
                          ? 'Bilat.'
                          : item.Lval?.toFixed(2)}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      borderRight: `0.5vw ${colorcode[rightRatio]} solid`,
                      width: `${rightRatio}%`,
                      height: '50%',
                      display: 'flex',
                      alignItems: `${
                        item.lobe === 'Global Lobe' ? 'start' : 'center'
                      }`,
                    }}
                  >
                    <div
                      style={{
                        textAlign: `${rightRatio < 90 ? 'left' : 'right'}`,
                        transform: `${
                          rightRatio < 90
                            ? 'translate(180%, 0)'
                            : 'translate(-30%, 0)'
                        }`,
                      }}
                    >
                      {/* <div style={{border:"1px solid red",textAlign:`${rightRatio < 50 ? "left":"right"}`, transform:`${rightRatio < 50 ? "translate(180%, 0)":"translate(-30%, 0)"}`}}> */}
                      <div
                        style={{
                          opacity: `${
                            item.lobe === 'Global Lobe' ? '' : '30%'
                          }`,
                        }}
                      >
                        {item.lobe === 'Global Lobe'
                          ? item.Lval?.toFixed(2)
                          : 'R'}
                      </div>
                      <div>
                        {item.lobe === 'Global Lobe'
                          ? ''
                          : item.Rval?.toFixed(2)}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
          {/* <tr className={styles.trForPadding}></tr> */}
          <tr>
            <td>{legend}</td>
            <td>
              <div className={styles.divForPadding}>
                <div
                  style={{
                    background: `linear-gradient(to right, ${colorcode
                      .join(',')
                      .toString()}`,
                  }}
                  // className={productName === 'amyloid'? styles.amylGradient_bar: productName === 'fdg'? styles.fdgGraident_bar :productName === 'tau' ? styles.amylGradient_bar : styles.datGradient_bar}
                  className={styles.colorbar}
                ></div>
              </div>
              {/* <div className={productName === 'amyloid'? styles.amylGradient_bar: productName === 'fdg'? styles.fdgGraident_bar :productName === 'tau' ? styles.amyGraident_bar : styles.amyGraident_bar}></div> */}
              <div className={styles.labels}>
                {[...Array(refStepIndex[targetTracerRef]?.divide).keys()].map(
                  (val, idx) => {
                    const step = (
                      (refStepIndex[targetTracerRef]?.max -
                        refStepIndex[targetTracerRef]?.min) /
                      (refStepIndex[targetTracerRef]?.divide - 1)
                    ).toFixed(3);
                    return (
                      <span key={idx}>
                        {(
                          step * idx +
                          refStepIndex[targetTracerRef]?.min
                        ).toFixed(1)}
                      </span>
                    );
                  },
                )}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

import React from 'react';
import { useParams } from 'react-router-dom';
import chroma from 'chroma-js';
import { RiArrowLeftSFill } from 'react-icons/ri';
import {
  colormapJet as MEDIA_colormapJet,
  reportIcon3 as MEDIA_reportIcon3,
} from '6_media';
import styles from './ReportPage3.module.scss';
import ReportLayout from '../ReportLayout/ReportLayout';

export default function ReportPage3(props) {
  const params = useParams();
  const username = sessionStorage.getItem('username');
  const productName = params.product.toLowerCase();

  const hotColorSample = chroma.scale([
    '#fffedf',
    '#ffff3d',
    '#ffc700',
    '#ff6100',
    '#fe3c00',
  ]);
  const hotCmap = [...Array(101).keys()].map((v) =>
    hotColorSample(v / 100).toString(),
  );

  const ref = props.refName;
  const sliceRefScale = {
    cg: { min: 0.8, max: 2.6 },
    wc: { min: 0.6, max: 1.9 },
    pons: { min: 0.4, max: 1.3 },
    wm: { min: 0.4, max: 1.3 },
  };

  const surfaceRefScale = {
    cg: { max: 3.0, min: 1.6 },
    wc: { max: 2.25, min: 1.2 },
    pons: { max: 1.5, min: 0.8 },
    wm: { max: 1.5, min: 0.8 },
  };

  const DATImageContainer = () => {
    const fileID = props.selectedFile.fileID;

    const getImgPath = (direction, sliceID) => {
      const filePreFix = direction === 'axial' ? 'report_output_axial' : 'report_mip';
      return `${process.env.REACT_APP_BASE_URL}result/download/_imgs_archive/${fileID}/${filePreFix}_${sliceID}.png`;
    };

    const axialImageURLs = [40, 37, 34, 31, 28].map((sliceID) =>
      getImgPath('axial', sliceID),
    );
    const mipImageURLs = [5, 10, 15, 20, 25, 30, 35, 40].map((sliceID) =>
      getImgPath('mip', sliceID),
    );

    return (
      <>
        <div id={`report3-axial-${props.order}`} className={styles.SBRImage}>
          <div className={styles.SBRImage_box}>
            <div>
              {axialImageURLs.map((imagURL, index) => (
                <div key={`axial${index}`}>
                  <img src={imagURL} alt={`axial ${index}`} />
                </div>
              ))}
            </div>
          </div>
          <div className={styles.colorbarJet_dat}>
            <div>
              <img src={MEDIA_colormapJet} alt="jet colormap" />
              <div>
                <div></div>
                <div></div>
              </div>
            </div>
            <div>
              <div>SBR</div>
            </div>
          </div>
        </div>
        <div
          id={`report3-mip-${props.order}`}
          className={styles.MIPImageContainer}
          style={{
            padding: '10% 10%',
            border: '0px blue solid',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            backgroundColor: 'white',
          }}
        >
          {mipImageURLs.map((imagURL, index) => (
            <img key={`mip${index}`} src={imagURL} alt={`mip_${index}`} />
          ))}
        </div>
      </>
    );
  };

  const NotDatImageContainer = () => {
    const fileID = props.selectedFile.fileID;

    const getImgPath = (sliceID) => {
      const filePreFix = 'report_output_axial';
      return `${process.env.REACT_APP_BASE_URL}result/download/_imgs_archive/${fileID}/${filePreFix}_${sliceID}.png`;
    };

    const axialImageURLs = [
      [60, 55, 50, 45],
      [40, 35, 30, 25],
    ].map((sliceIDRow) => sliceIDRow.map((sliceID) => getImgPath(sliceID)));
    return (
      <>
        <div id={`report3-axial-${props.order}`} className={styles.SUVRImage}>
          <div
            id={`report3-axial-slices-${props.order}`}
            className={styles.SUVRImage_box}
            style={{ backgroundColor: 'white' }}
          >
            {axialImageURLs.map((imageURLRows, index) => (
              <div key={`axia_row_${index}`}>
                {imageURLRows.map((imagURL, nestedIdx) => (
                  <div key={`axial_${index}_${nestedIdx}`}>
                    <img src={imagURL} alt={`axial ${nestedIdx}`} />
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div
            id={`report3-axial-colorbar-${props.order}`}
            className={styles.colorbarJet}
          >
            <div>
              <img src={MEDIA_colormapJet} alt="jet colormap" />
              <div>
                <div>
                  <RiArrowLeftSFill size={'25px'} />
                  {sliceRefScale[ref]?.max}
                </div>
                <div>
                  <RiArrowLeftSFill size={'25px'} />
                  {sliceRefScale[ref]?.min}
                </div>
              </div>
            </div>
            <div>
              <div>SUVR</div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const SurfaceContainer = () => {
    const fileID = props.selectedFile.fileID;

    const getImgPath = (direction) => {
      return `${process.env.REACT_APP_BASE_URL}result/download/${username}/database/${fileID}/_${direction}_1.6.png`;
    };

    const surfaceImageURLs = [
      ['rlat', 'rmed'],
      ['llat', 'lmed'],
    ].map((sliceIDRow) => sliceIDRow.map((driection) => getImgPath(driection)));

    return (
      <>
        <div className={styles.imageTitle}>
          {(() => {
            switch (productName) {
              case 'amyloid':
                return 'SUVR rendered on brain surface';
              case 'dat':
                return '';
              case 'tau':
                return 'SUVR rendered on brain surface';
              case 'fdg':
                return 'SUVR rendered on brain surface';
              default:
                return '';
            }
          })()}
        </div>
        <div
          id={`report3-surface-${props.order}`}
          className={styles.surfaceImgWrapper}
        >
          <div id={`report3-surface-img-${props.order}`}>
            {surfaceImageURLs.map((imageURLRow, index) => (
              <div key={`axia_row_${index}`}>
                {imageURLRow.map((imageURL, nestedIdx) => (
                  <div key={`surface_${index}_${nestedIdx}`}>
                    <img
                      src={imageURL}
                      className={styles.surfaceImg}
                      alt={nestedIdx}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className={styles.colorbarHot}>
            <div id={`report3-surface-colormap-${props.order}`}>
              <div>
                {/* <img src={MEDIA_colormapHot}/> */}
                <div
                  style={{
                    background: `linear-gradient(to bottom, ${hotCmap
                      .join(',')
                      .toString()})`,
                    width: '15px',
                    height: '250px',
                    borderRadius: '10px',
                  }}
                />
                <div>
                  <div>
                    <RiArrowLeftSFill size={'25px'} />
                    {surfaceRefScale[ref]?.max}
                  </div>
                  <div>
                    <RiArrowLeftSFill size={'25px'} />
                    {surfaceRefScale[ref]?.min}
                  </div>
                </div>
              </div>
              <div>
                <div>SUVR</div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <ReportLayout {...props} PatientID={props.selectedFile.PatientID}>
        <div className={styles.ImageContainer}>
          <div className={styles.ReportTitle}>
            <img src={MEDIA_reportIcon3} alt="" />
            &nbsp;
            {(() => {
              switch (productName) {
                case 'amyloid':
                  return 'Amyloid-beta PET Image';
                case 'dat':
                  return 'DAT PET Image';
                case 'tau':
                  return 'Tau PET Image';
                case 'fdg':
                  return 'FDG PET Image';
                case 'perfusion':
                  return 'Perfusion Image';
                default:
                  return productName + ' PET Image';
              }
            })()}
          </div>
          <div className={styles.imageWrapper}>
            <div className={styles.imageTitle}>
              {(() => {
                switch (productName) {
                  case 'amyloid':
                    return 'Spatially normalized SUVR Image';
                  case 'dat':
                    return 'Spatially normalized SBR image';
                  case 'tau':
                    return 'Spatially normalized SUVR Image';
                  case 'fdg':
                    return 'Spatially normalized SUVR Image';
                  case 'perfusion':
                    return 'Spatially normalized SUVR Image';
                  default:
                    return '';
                }
              })()}
            </div>

            {(() => {
              if (productName === 'dat') return DATImageContainer();
              else return NotDatImageContainer();
            })()}
          </div>

          {/* Brain Surface */}

          <div className={styles.imageWrapper}>
            {productName !== 'dat' && SurfaceContainer()}
          </div>
        </div>
      </ReportLayout>
    </>
  );
}

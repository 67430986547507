import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { coreItem } from '0_variables/coreItem';
import { type1Selector, type2Selector } from '0_variables/utils';
import { openCustomROIModal } from '1_reduxs/reducers/modalReducer';
import { changeSettings } from '1_reduxs/reducers/settingReducer';
import { DropDownDiv } from '5_components';
import {
  Check as MEDIA_Check,
  unCheck as MEDIA_unCheck,
  justCheck as MEDIA_justCheck,
} from '6_media';
import { DefaultTracer } from '../../components';
import styles from './MainConfig.module.scss';

export default function MainConfig() {
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const pageName = location.pathname.split('/')[2];
  const productName = params.product.toLowerCase();
  const productCoreItem = coreItem[productName];
  const configContents = productCoreItem[pageName].bottom[0].cards[0].contents;
  const [atlasItem, refItem, regionItem, defaultTracerItem] = configContents;

  const settingByProduct = useSelector((state) => state.setting[productName]);
  const selectAtlas = settingByProduct.defaultAtlas;
  const selectRef = settingByProduct.defaultRef;
  const subRegionList = settingByProduct.list;
  const imageVerticalFlip = settingByProduct.imageVerticalFlip;

  const updateSettingToStore = (updatedSettingValue) => {
    const updatedSettings = {
      ...settingByProduct,
      ...updatedSettingValue,
    };
    dispatch(changeSettings({ productName, updatedSettings }));
  };

  const tracerColor = productCoreItem.tracers.reduce((obj, item) => {
    obj[item.shortname] = item.color;
    return obj;
  }, {});
  const atlasList = atlasItem.atlasList;
  const refList = refItem.refList;
  const atlasDropdown = {
    selectItem: selectAtlas,
    options: atlasList,
    setItem: (val) => {
      updateSettingToStore({ defaultAtlas: val });
    },
  };

  const refDropdown = Object.entries(selectRef).map((arr) => {
    const [tracerName, regionName] = arr;
    return {
      selectItem: regionName,
      options: refList,
      setItem: (val) => {
        updateSettingToStore({
          defaultRef: {
            ...selectRef,
            [tracerName]: val,
          },
        });
      },
    };
  });

  const striatum_pair = {
    'Melbourne Subcortical': {
      extrastriatumAtlas: 'AAL3, ATAG',
      firstRegion: [
        'Dorsal striatum',
        'Dorsal raphe nucleus',
        'Substantia nigra',
      ],
    },
    AAL3: {
      extrastriatumAtlas: 'ATAG',
      firstRegion: ['Dorsal raphe nucleus', 'Substantia nigra'],
    },
    'Harvard-Oxford': {
      extrastriatumAtlas: 'AAL3, ATAG',
      firstRegion: ['Caudate', 'Dorsal raphe nucleus', 'Substantia nigra'],
    },
  };

  const isShowAtlas = (multiAtlas, fullname) => {
    return (
      productName === 'dat' &&
      striatum_pair[multiAtlas].firstRegion.includes(fullname)
    );
  };

  const tempDeleteCustomROI = (selected) => {
    const primaryKeySUVR = selected.primaryKey;
    const primaryKeyCENTIL = subRegionList.find(
      (el) =>
        el.identifier === selected.identifier &&
        el.type === type2Selector[productName],
    )?.primaryKey;
    const filteredList = subRegionList.filter(
      (item) =>
        item.belongToForeignKey !== primaryKeySUVR &&
        item.belongToForeignKey !== primaryKeyCENTIL,
    );

    updateSettingToStore({ list: filteredList });
  };

  const filteredSubRegions = subRegionList.filter((subRegion) => {
    const { type, atlas, level, multiAtlas } = subRegion;
    const isInvalidLevel = level === 0;
    const isInvalidType = type === type1Selector[productName];
    const isSelectedAtlas =
      productName === 'dat'
        ? multiAtlas === selectAtlas
        : atlas === selectAtlas;
    return isInvalidLevel && isInvalidType && isSelectedAtlas;
  });

  return (
    <div className={styles.container} id="main_config_container">
      <div className={styles.container_dat}>
        <div className={styles.tracers}>
          <div className={styles.reference_region}>
            <div>{defaultTracerItem.subTitle}</div>
            <DefaultTracer />
          </div>
          <div className={styles.reference_region_dat}>
            <div>{refItem.subTitle}</div>
            <div className={styles.referece_tracer_dat}>
              {refItem.subContent.map((item, idx) => (
                <div key={item.tracer}>
                  <div
                    style={{
                      borderLeft: `0.35rem solid ${tracerColor[item.tracer]}`,
                    }}
                  >
                    {item.fullname
                      .replace('18', '\u00B9\u2078')
                      .replace('99mtc', '\u2079\u2079\u1d50Tc')}
                  </div>
                  <DropDownDiv
                    style={{ background: styles.dropdivColor }}
                    item={refDropdown[idx]}
                    styleName={'settingStyle'}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className={styles.atlas_dat_continer}>
          <div className={styles.brain_atlas_dat}>
            {productName === 'dat' ? 'Striatum Atlas' : atlasItem.subTitle}
            <DropDownDiv
              style={{ background: styles.dropdivColor }}
              item={atlasDropdown}
              styleName={'settingStyle'}
            />
          </div>
          {productName === 'dat' && (
            <div className={styles.brain_atlas_dat}>
              {'Extrastriatum Atlas'}
              <div className={styles.extraAtlas}>
                {striatum_pair[atlasDropdown.selectItem].extrastriatumAtlas}
              </div>
            </div>
          )}
          {productName === 'perfusion' && (
            <div>
              Flip for Perfusion Image
              <div className={styles.checkBox}>
                <div
                  onClick={() => {
                    updateSettingToStore({
                      imageVerticalFlip: !imageVerticalFlip,
                    });
                  }}
                >
                  <img
                    src={imageVerticalFlip ? MEDIA_Check : MEDIA_unCheck}
                    alt="vertical flip check box"
                  />
                </div>
                <div>Vertical Flip</div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className={styles.brain_regions_analyze}>
        {regionItem.subTitle}
        <div className={styles.brain_regions_analyze_subContent_dat}>
          {filteredSubRegions.map((item) => (
            <div key={`sub_regions_${item.identifier}`}>
              <div
                className={styles.brain_regions_analyze_subContent_atlasTitle}
                style={{
                  display: `${
                    isShowAtlas(item.multiAtlas, item.fullname) ? '' : 'none'
                  }`,
                }}
              >
                {item.atlas}
              </div>
              <div
                className={styles.brain_regions_analyze_subContent_title_dat}
              >
                <div>
                  <img
                    onClick={() => {
                      const update = subRegionList.map((el) =>
                        el.identifier === item.identifier
                          ? { ...el, isFolded: !el.isFolded }
                          : el,
                      );
                      updateSettingToStore({ list: update });
                    }}
                    src={item.isFolded ? MEDIA_unCheck : MEDIA_Check}
                    alt=""
                  />
                </div>
                <div>
                  {item.fullname} &nbsp;
                  <span
                    onClick={() => {
                      tempDeleteCustomROI(item);
                    }}
                    style={{ userSelect: 'none', cursor: 'pointer' }}
                  >
                    {item.static ? '' : '\u229D'}
                  </span>
                </div>
              </div>
              <div style={{ maxHeight: `${item.isFolded ? '0px' : ''}` }}>
                {subRegionList
                  .filter(
                    ({ belongToForeignKey, level }) =>
                      level === 1 && belongToForeignKey === item.primaryKey,
                  )
                  .map((subItem) => (
                    <div
                      key={`sub_regions_child_${subItem.identifier}`}
                      className={
                        styles[
                          `${
                            subItem.fullname.length > 16
                              ? 'brain_regions_analyze_subContent_subregion_overflow'
                              : 'brain_regions_analyze_subContent_subregion'
                          }`
                        ]
                      }
                    >
                      <div>{subItem.fullname}</div>
                      <div>
                        <img src={MEDIA_justCheck} alt="" />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
          <div
            onClick={() => {
              if (
                productName !== 'dat' ||
                atlasDropdown.selectItem === 'Melbourne Subcortical'
              ) {
                dispatch(openCustomROIModal({ isOn: true }));
              }
            }}
          >
            <div
              className={
                styles[
                  `${
                    productName !== 'dat' ||
                    atlasDropdown.selectItem === 'Melbourne Subcortical'
                      ? 'ROIButton'
                      : 'ROIButton_DAT'
                  }`
                ]
              }
            >
              <span style={{ fontSize: '2vw', userSelect: 'none' }}>{'+'}</span>
              <span style={{ fontSize: '0.9vw', fontWeight: '600' }}>
                Add a new ROI
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

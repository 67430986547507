import { createSlice } from '@reduxjs/toolkit';
import { removesessionStorage } from '0_variables/utils';

const initialState = {
  username: 'Anonymous',
  token: null,
  logged: false,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setLoginState: (state, action) => {
      const { username, token, logged } = action.payload;
      if (!logged) {
        removesessionStorage();
      }
      state.username = username;
      state.token = token;
      state.logged = logged;
    },
  },
});

export const { setLoginState } = loginSlice.actions;
export default loginSlice.reducer;

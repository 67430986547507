import React from 'react';
import {
  Check as MEDIA_Check,
  unCheck as MEDIA_unCheck,
  info as MEDIA_info,
  arrow_down as MEDIA_arrow_down,
  arrow_up as MEDIA_arrow_up,
} from '6_media';
import styles from './FileTable.module.scss';

const FileTableHead = ({
  columnTitle,
  selectedStatus,
  sortClick,
  handleSelectAll,
}) => {
  const isTableHead_SelectAll = columnTitle === 'Select';
  const isTableHead_Centiloid = columnTitle === 'Centiloid';
  const isSortClicked = Object.keys(sortClick).includes(columnTitle);

  return (
    <div>
      {isTableHead_SelectAll ? (
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={handleSelectAll}
        >
          <img
            src={selectedStatus ? MEDIA_Check : MEDIA_unCheck}
            style={{ width: '0.8vw' }}
            alt=""
          />
        </span>
      ) : (
        <span>
          {isTableHead_Centiloid ? (
            <div className={styles.centiolodInfoWrapper}>
              <img
                src={MEDIA_info}
                className={'tooltip-file-table'}
                data-tooltip-place="bottom"
                data-tooltip-content="Centiloid is calculated using whole cerebellum as a reference region."
                alt="info icon"
              />
              <div>{columnTitle}</div>
            </div>
          ) : (
            columnTitle
          )}
        </span>
      )}
      <div
        style={{
          visibility: isSortClicked ? '' : 'hidden',
        }}
      >
        <img
          src={
            sortClick[columnTitle] === 'ascend'
              ? MEDIA_arrow_down
              : MEDIA_arrow_up
          }
          alt=""
        />
      </div>
    </div>
  );
};
export default FileTableHead;

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { BiTrash } from 'react-icons/bi';
import { openSpinner } from '1_reduxs/reducers/modalReducer';
import { UploadingStep1, UploadingStep3Direct } from './../components';
import { fetchFilesStore } from '1_reduxs/reducers/filesReducer';
import { deleteFilefromPacs, runFile } from '2_services/uploadApi';
import styles from './UploadModal.module.scss';

export default function UploadModal(props) {
  const dispatch = useDispatch();
  const setting = useSelector((state) => state.setting);
  const setReloadChecker = useSelector((state) => state.modal.uploadModal.setReloadChecker);
  const params = useParams();
  const productName = params.product.toLowerCase();
  const [selectTracer, setSelectTracer] = useState(
    setting[productName].defaultTracer,
  );
  const [getResult, setGetResult] = useState([]);
  const [selectedIdx, setSelecteIdx] = useState(0);
  const stepRefArr = useRef([]);

  useEffect(() => {
    const structuredGetResult = props.getResult.map((item, idx) => ({
      ...item,
      BirthDate: item.Age,
      Modality: '',
      SeriesInfo: [],
      StudyDate: item.AcquisitionDateTime,
      StudyDescription: '',
      StudyInstanceUID: '',
      Tracer: selectTracer.shortname,
      Marker: '\u3008',
      Delete: <BiTrash size={'0.8vw'} />,
      seriesItem: [],
    }));

    setGetResult(structuredGetResult);
  }, [props.getResult, selectTracer]);

  const handleRef = (idx, el) => {
    stepRefArr.current[idx] = el;
  };

  const executeScroll = (idx) => {
    if (idx >= 0 && idx < 2) {
      setSelecteIdx(idx);
      stepRefArr.current[idx].scrollIntoView({
        behavior: 'smooth',
        inline: 'nearest',
      });
    } else {
      console.error('no works, ref index is out of range');
    }
  };

  const deleteFiles = useCallback(async () => {
    const token = sessionStorage.getItem('token');

    try {
      dispatch(
        openSpinner({
          status: true,
          length: 0,
          message: 'Loading...',
          target: 'NONE',
        }),
      );
      await deleteFilefromPacs({ token: token });
    } catch (err) {
      alert('Network Error: Please check if Btxbrain-backend is running.', err);
    } finally {
      dispatch(
        openSpinner({
          status: false,
          length: 0,
          message: '',
          target: 'COMPLETE',
        }),
      );
    }
  }, [dispatch]);

  const updateTracerHandeler = (FileList, updateTracer) => {
    const newFileList = FileList.map((fileData) => {
      const findTracer = updateTracer.find((el) => el.idx === fileData.id);

      return findTracer ? { ...fileData, Tracer: findTracer.tracer } : fileData;
    });

    setGetResult(newFileList);
  };

  const _deleteGetHandler = (item, seriesID, _) => {
    const filteredGetResult = getResult.filter((el1) => el1.id !== seriesID);
    setGetResult(filteredGetResult);
  };

  const runHandler = async (worklistChecker, getResult) => {
    const token = sessionStorage.getItem('token');
    try {
      // TODO: 나중에 서버에서 딥러닝을 Thread 방식으로 처리하도록 하게 되면 해제해야함
      // setTimeout(()=>{
      //   dispatch(actionModal.open_spinner({status:true, length:0, message:"running..."}));
      //   props.closeModal();
      //   dispatch(actionModal.open_spinner({status:false, length:0, message:""}));
      // }, 1000)
      dispatch(
        openSpinner({
          status: true,
          length: 0,
          message: 'Running...',
          target: 'UPLOAD',
        }),
      );
      const data = {
        token: token,
        obj: getResult,
        Tracer: selectTracer.shortname,
        addToWorklist: worklistChecker,
      };
      if (process.env.REACT_APP_IS_CLOUD === 'true') {
        data.product = productName;
        data.setReloadChecker = setReloadChecker;
      }
      const res = await runFile(data);
      dispatch(fetchFilesStore({ items: res.data }));
    } catch (e) {
      // TODO: 파일 분석 (직접업로드시) 실패 예외처리 필요.
      alert('runing failed, please check api server again.');
    } finally {
      // TODO: 이때 전체 fileList를 한번 받아서 갱신시켜줄 필요 있음
      // ***주기적으로 upload에서 request를 보내면서 갱신하는것은 통신효율적으로 좀 문제가 있음***
      dispatch(
        openSpinner({
          status: false,
          length: 0,
          message: '',
          target: 'UPLOAD',
        }),
      );
      props.closeModal();
    }
  };

  useEffect(() => {
    // remove backend upload files
    return () => {
      deleteFiles();
    };
  }, [deleteFiles]);

  const UploadingStep1Props = {
    selectTracer,
    setSelectTracer,
    handleRef,
    executeScroll,
  };
  const UploadingStep3DirectProps = {
    getResult,
    handleRef,
    executeScroll,
    _deleteGetHandler,
    runHandler,
    updateTracerHandeler,
    selectTracer,
    identifier: 'upload',
  };

  return (
    <div
      className={`${styles['container']}`}
      onClick={() => {
        if (window.confirm('Are you sure you want to cancel the upload?')) {
          props.closeModal();
        }
      }}
    >
      {/* <span className={styles.exitButton}
        onClick={()=>{ props.closeModal();deleteFiles(); }
      }>
        {'X'}
      </span> */}
      <div
        className={styles.stepContainer}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <UploadingStep1
          stepIndex={0}
          uploadingStepProps={UploadingStep1Props}
        />
        <UploadingStep3Direct
          stepIndex={1}
          isActivate={selectedIdx === 1}
          uploadingStepProps={UploadingStep3DirectProps}
        />
      </div>
    </div>
  );
}

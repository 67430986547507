import React, { useCallback, useEffect, useState } from 'react';
import styles from './ReportPages.module.scss';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { RefFactor } from '0_variables/utils';
import { ReportPage1, ReportPage2, ReportPage3 } from './components';

export default function ReportPages(props) {
  const history = useHistory();
  const params = useParams();
  const setting = useSelector((state) => state.setting);
  const fileList = useSelector((state) => state.fileList);
  const productName = params.product.toLowerCase();
  const [selectedFile] = useState(
    fileList.find(({ fileID }) => fileID === props.fileID),
  );
  const openFile = props.openFile;
  const [pageLength, setPageLength] = useState(3);

  const makePageLength = useCallback(() => {
    if (props.openFile === undefined) {
      return props.innerRef.length;
    } else {
      const tracer = props.openFile['Tracer'];
      if (tracer === '[18F]FPN') {
        return 3;
      } else return props.pageLength;
    }
  }, [props]);

  useEffect(() => {
    if (fileList.length === 0) {
      history.push(`/${productName}/upload`);
    }
  }, [fileList, history, productName]);

  useEffect(() => {
    if (Object.keys(props).length > 0) {
      if (openFile === undefined) {
        setPageLength(props.innerRef.length);
      } else {
        if (props.openFile['Tracer'] === '[18F]FPN') {
          const newPageLength = makePageLength();
          setPageLength(newPageLength);
        } else {
          setPageLength(props.pageLength);
        }
      }
    }
  }, [props, openFile, makePageLength]);

  const settingOfProduct = setting[productName];
  const tracerName = selectedFile['Tracer'];
  const refName = settingOfProduct.defaultRef[tracerName];
  const RF = RefFactor({
    productName,
    refName,
    ponsRF: selectedFile['ratio_gry2pons'],
    crblRF: selectedFile['ratio_gry2crbl'],
    wmRF: selectedFile['ratio_gry2wm'],
    cgRF: selectedFile['ratio_gry2cg'],
    global: 1 / selectedFile['Global'],
  });
  const RFforReport = (() => {
    if (refName === 'Pons') return 'pons';
    else if (refName === 'Whole cerebellum') return 'wc';
    else if (refName === 'Cerebellar gray matter') return 'cg';
    else if (refName === 'Cerebral white matter') return selectedFile['wm'];
    else return 'cg';
  })();

  return (
    <>
      {/* TODO: sliceReady는 ReportPage3에서 통신으로 이미지를 받아야했던 상황에 쓰였던건데 이제 필요없음 (나중에 삭제 필요) */}
      <div
        ref={(el) => {
          if (props.innerRef) props.innerRef[0].current = el;
        }}
        className={styles.A4pages}
      >
        <ReportPage1
          {...props}
          selectedFile={selectedFile}
          fileID={props.fileID}
          pageNumber={'1/' + pageLength}
          RF={RF}
        />
      </div>
      <div
        ref={(el) => {
          if (props.innerRef) props.innerRef[1].current = el;
        }}
        className={styles.A4pages}
      >
        <ReportPage2
          {...props}
          selectedFile={selectedFile}
          fileID={props.fileID}
          pageNumber={'2/' + pageLength}
          RF={RF}
          pageLength={pageLength}
          openFile={openFile}
        />
      </div>
      {pageLength === 4 && (
        <div
          ref={(el) => {
            if (props.innerRef) props.innerRef[2].current = el;
          }}
          className={styles.A4pages}
        >
          <ReportPage2
            {...props}
            selectedFile={selectedFile}
            fileID={props.fileID}
            pageNumber={'3/' + pageLength}
            RF={RF}
            pageLength={pageLength}
            openFile={openFile}
          />
        </div>
      )}

      <div
        ref={(el) => {
          if (props.openFile === undefined) {
            if (props.innerRef) {
              const pageLength = makePageLength();
              props.innerRef[pageLength === 3 ? 2 : 3].current = el;
            }
          } else {
          }
        }}
        className={styles.A4pages}
      >
        {/* TODO: imageIds를 여기서 만들어서 보내던 방식을 ReportPage3 내부에서 만들도록 변경함 (나중에 삭제 필요) */}
        <ReportPage3
          {...props}
          selectedFile={selectedFile}
          fileID={props.fileID}
          pageNumber={
            `${pageLength === 3 ? '3/' : '4/'}` + props.innerRef?.length
          }
          refName={RFforReport}
          pageLength={pageLength}
        />
      </div>
    </>
  );
}

import React, { useState } from 'react';
import styles from './ReportPage2.module.scss';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { coreItem } from '0_variables/coreItem';
import {
  reportAmyloidtable1,
  reportDATtable1,
  reportAmyloidDATtable2,
} from '0_variables/utils';
import { QuantTable, ReportGraph } from '5_components';
import { reportIcon2 as MEDIA_reportIcon2 } from '6_media';
import ReportLayout from '../ReportLayout/ReportLayout';

export default function ReportPage2(props) {
  const params = useParams();
  const setting = useSelector((state) => state.setting);
  const productName = params.product.toLowerCase();
  const productCoreItem = coreItem[productName];
  const [trClick, setTrClick] = useState([]);
  const [sortClick, setSortClick] = useState({});
  const [defaultAtlas] = useState(setting[productName].defaultAtlas);

  const table1Data = {
    amyloid: reportAmyloidtable1({
      settingOfProduct: setting['amyloid'],
      typeSelect: productCoreItem.type1Selector,
      tableItems: coreItem['amyloid']['analysis'].bottom[1].cards[0],
      selectedFile: props.selectedFile,
      defaultAtlas: defaultAtlas,
      RF: props.RF,
    }),
    dat: reportDATtable1({
      settingOfProduct: setting['dat'],
      typeSelect: productCoreItem.type1Selector,
      tableItems: coreItem['dat']['analysis'].bottom[1].cards[0],
      selectedFile: props.selectedFile,
      defaultAtlas: defaultAtlas,
      RF: props.RF,
    }),
    fdg: reportAmyloidtable1({
      settingOfProduct: setting['fdg'],
      typeSelect: productCoreItem.type1Selector,
      tableItems: coreItem['fdg']['analysis'].bottom[1].cards[0],
      selectedFile: props.selectedFile,
      defaultAtlas: defaultAtlas,
      RF: props.RF,
    }),
    tau: reportAmyloidtable1({
      settingOfProduct: setting['tau'],
      typeSelect: productCoreItem.type1Selector,
      tableItems: coreItem['tau']['analysis'].bottom[1].cards[0],
      selectedFile: props.selectedFile,
      defaultAtlas: defaultAtlas,
      RF: props.RF,
    }),
    perfusion: reportAmyloidtable1({
      settingOfProduct: setting['perfusion'],
      typeSelect: productCoreItem.type1Selector,
      tableItems: coreItem['perfusion']['analysis'].bottom[1].cards[0],
      selectedFile: props.selectedFile,
      defaultAtlas: defaultAtlas,
      RF: props.RF,
    }),
  };

  // debugger;
  // const table1Data = {
  //   "amyloid": reportAmyloidtable1({ subRegions:setting['amyloid'].list, typeSelect: productCoreItem.type1Selector, tableItems:coreItem['amyloid']['analysis'].bottom[1].cards[0], selectedFile: props.selectedFile, defaultAtlas:defaultAtlas, settingOfProduct: setting[productName], RF:props.RF }),
  //   "dat": reportDATtable1({     subRegions:setting['dat'].list,     typeSelect: productCoreItem.type1Selector, tableItems:coreItem['dat']['analysis'].bottom[1].cards[0], selectedFile: props.selectedFile, defaultAtlas:defaultAtlas , RF:props.RF}),
  //   "fdg": reportAmyloidtable1({     subRegions:setting['fdg'].list,     typeSelect: productCoreItem.type1Selector, tableItems:coreItem['fdg']['analysis'].bottom[1].cards[0], selectedFile: props.selectedFile, defaultAtlas:defaultAtlas , RF:props.RF}),
  //   "tau": reportAmyloidtable1({     subRegions:setting['tau'].list,     typeSelect: productCoreItem.type1Selector, tableItems:coreItem['tau']['analysis'].bottom[1].cards[0], selectedFile: props.selectedFile, defaultAtlas:defaultAtlas , RF:props.RF}),
  // }
  // debugger;
  // const makeTable2Data = () =>{
  //   if(props.selectedFile['Tracer'] === '[18F]FPN'){
  //     const table2Data = {
  //       "amyloid":reportAmyloidDATtable2({ settingOfProduct:setting['amyloid'], typeSelect: coreItem['amyloid'].type2Selector, tableItems:coreItem['amyloid']['analysis'].bottom[1].cards[1], selectedFile: props.selectedFile, defaultAtlas:defaultAtlas, RF:props.RF }),
  //       "dat":reportAmyloidDATtable2({     settingOfProduct:setting['dat'],     typeSelect: coreItem['dat'].type2Selector, tableItems:coreItem['dat']['analysis'].bottom[1].cards[1], selectedFile: props.selectedFile, defaultAtlas:defaultAtlas, RF:props.RF }),
  //     }
  //     return table2Data
  //   }else{
  //     const table2Data = {
  //       "amyloid":reportAmyloidDATtable2({ settingOfProduct:setting['amyloid'], typeSelect: coreItem['amyloid'].type2Selector, tableItems:coreItem['amyloid']['analysis'].bottom[1].cards[1], selectedFile: props.selectedFile, defaultAtlas:defaultAtlas, RF:props.RF }),
  //       "dat":reportAmyloidDATtable2({     settingOfProduct:setting['dat'],     typeSelect: coreItem['dat'].type2Selector, tableItems:coreItem['dat']['analysis'].bottom[1].cards[1], selectedFile: props.selectedFile, defaultAtlas:defaultAtlas, RF:props.RF }),
  //     }
  //     return table2Data
  //   }
  // }

  const table2Data = {
    amyloid: reportAmyloidDATtable2({
      settingOfProduct: setting['amyloid'],
      typeSelect: coreItem['amyloid'].type2Selector,
      tableItems: coreItem['amyloid']['analysis'].bottom[1].cards[1],
      selectedFile: props.selectedFile,
      defaultAtlas: defaultAtlas,
      RF: props.RF,
    }),
    dat: reportAmyloidDATtable2({
      settingOfProduct: setting['dat'],
      typeSelect: coreItem['dat'].type2Selector,
      tableItems: coreItem['dat']['analysis'].bottom[1].cards[1],
      selectedFile: props.selectedFile,
      defaultAtlas: defaultAtlas,
      RF: props.RF,
    }),
  };

  return (
    <ReportLayout {...props} PatientID={props.selectedFile.PatientID}>
      <div className={styles.QuantContainer}>
        <div className={styles.ReportTitle}>
          <img src={MEDIA_reportIcon2} alt="" />
          &nbsp;{'Quantification Results'}
        </div>

        <ReportGraph
          {...props}
          productName={productName}
          pageNumber={props.pageNumber}
          selectedFile={props.selectedFile}
          fileID={props.fileID}
          RF={props.RF}
        />

        <div
          className={styles.typeTableWrapper}
          style={{
            display:
              props.pageLength === 4 &&
              props.pageNumber.split('/').at(0) === '3'
                ? 'none'
                : '',
          }}
        >
          <div className={styles.tableTitle}>
            {(() => {
              switch (productName) {
                case 'amyloid':
                  return 'Regional SUVR';
                case 'dat':
                  return '';
                case 'fdg':
                  return 'Regional SUVR';
                case 'tau':
                  return 'Regional SUVR';
                case 'perfusion':
                  return 'Regional SUVR';
                default:
                  return 'Regional SUVR';
              }
            })()}
          </div>
          <div className={styles.tableContainer}>
            <QuantTable
              eventEnabled={false}
              tableHead={table1Data[productName].tableHead}
              items={table1Data[productName].items}
              trClick={trClick}
              setTrClick={setTrClick}
              sortClick={sortClick}
              setSortClick={setSortClick}
              reportTooltip={false}
            />
          </div>
        </div>

        <div
          className={styles.typeTableWrapper}
          style={{
            display:
              props.pageLength === 4 &&
              props.pageNumber.split('/').at(0) === '2'
                ? 'none'
                : '',
          }}
        >
          <div className={styles.tableTitle}>
            {(() => {
              if (props.selectedFile['Tracer'] === '[18F]FPN') {
                return;
              } else {
                switch (productName) {
                  case 'amyloid':
                    return 'Regional Centiloid';
                  case 'dat':
                    return 'Semiquantification';
                  case 'fdg':
                    return '';
                  case 'tau':
                    return '';
                  case 'perfusion':
                    return '';
                  default:
                    return '';
                }
              }
            })()}
          </div>
          <div className={styles.tableContainer}>
            {(() => {
              if (productName === 'amyloid' || productName === 'dat') {
                if (props.selectedFile['Tracer'] === '[18F]FPN') {
                  return <div></div>;
                } else {
                  return (
                    <QuantTable
                      eventEnabled={false}
                      tableHead={table2Data[productName].tableHead}
                      items={table2Data[productName].items}
                      trClick={trClick}
                      setTrClick={setTrClick}
                      sortClick={sortClick}
                      setSortClick={setSortClick}
                    />
                  );
                }
              }
            })()}
          </div>
        </div>
      </div>
    </ReportLayout>
  );
}

import React, { useState } from 'react';
import styles from './App.module.scss';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { coreItem } from '0_variables/coreItem';
import {
  Home,
  Dashboard,
  Upload,
  View,
  Analysis,
  Setting,
  Report,
} from './4_routers';
import { GlobalLayout } from '5_components';

function App() {
  const [reportOn, setReportOn] = useState(false);
  const [openedWorklist, setOpenedWorklist] = useState(false);
  const loginState = useSelector((state) => state.login);
  const [endDate, setEndDate] = useState('');
  const [remainCounts, setRemainCounts] = useState('');
  const time = new Date().getTime();
  console.log(time);
  const [reloadChecker, setReloadChecker] = useState(time);
  const productCoreItem = coreItem['amyloid'];
  const CTN_Mode = productCoreItem.CTN_Mode;

  const propsGlobalLayout = {
    reportOn,
    setReportOn,
    openedWorklist,
    setOpenedWorklist,
    remainCounts,
    setRemainCounts,
    endDate,
    setEndDate,
  };

  const sessionUserName = sessionStorage.getItem('username');
  const isLogin = loginState.logged || !!sessionUserName;

  return (
    <>
      {isLogin && (
        <>
          {CTN_Mode && (
            <div
              className={`${
                sessionUserName
                  ? styles.menuCTNIndicator
                  : styles.homeCTNIndicator
              }`}
            >
              <div>CTN project viewer only</div>
              {/* <div style={{display:`${loginStateFromsessionStorage ?  "none": ""}`}}>기존 버전과 다른 username으로 계정 생성 및 로그인해주세요</div> */}
            </div>
          )}
          <Switch>
            {/* <Route exact path="/redux" render={(props)=><ReduxDebugger/>}/> */}
            {/* <Route exact path="/reportiframe"    render={(props)=><ReportIframe/>}/> */}

            <Route exact path="/signin" render={(props) => <Home />} />
            <Route exact path="/signup" render={(props) => <Home />} />
            <Route exact path="/forgot" render={(props) => <Home />} />
            <Route exact path="/selectProduct" render={(props) => <Home />} />
            <Route
              exact
              path="/:product/dashboard"
              render={(props) => (
                <GlobalLayout {...props} {...propsGlobalLayout} reloadChecker={reloadChecker}>
                  <Dashboard {...props} />
                </GlobalLayout>
              )}
            />
            <Route
              exact
              path="/:product/upload"
              render={(props) => (
                <GlobalLayout {...props} {...propsGlobalLayout} reloadChecker={reloadChecker}>
                  <Upload
                    {...props}
                    {...{ setOpenedWorklist }}
                    endDate={endDate}
                    remainCounts={remainCounts}
                    setReloadChecker={setReloadChecker}
                  />
                </GlobalLayout>
              )}
            />
            <Route
              exact
              path="/:product/view/:fileID"
              render={(props) => (
                <GlobalLayout {...props} {...propsGlobalLayout} reloadChecker={reloadChecker}>
                  <View {...props} />
                </GlobalLayout>
              )}
            />
            <Route
              exact
              path="/:product/analysis/:fileID"
              render={(props) => (
                <GlobalLayout {...props} {...propsGlobalLayout} reloadChecker={reloadChecker}>
                  <Analysis {...props} />
                </GlobalLayout>
              )}
            />
            <Route
              exact
              path="/:product/setting"
              render={(props) => (
                <GlobalLayout {...props} {...propsGlobalLayout} reloadChecker={reloadChecker}>
                  <Setting {...props} />
                </GlobalLayout>
              )}
            />
            <Route
              exact
              path="/:product/report/:fileID"
              render={(props) => (
                <GlobalLayout {...props} {...propsGlobalLayout} reloadChecker={reloadChecker}>
                  <Report {...props} />
                </GlobalLayout>
              )}
            />
            <Route
              path="/"
              render={(props) => <Redirect to={{ pathname: '/signin' }} />}
            />
          </Switch>
        </>
      )}
      {!isLogin && (
        <Switch>
          <Route exact path="/signin" render={(props) => <Home />} />
          <Route exact path="/signup" render={(props) => <Home />} />
          <Route exact path="/forgot" render={(props) => <Home />} />
          <Route
            path="/"
            render={(props) => <Redirect to={{ pathname: '/signin' }} />}
          />
        </Switch>
      )}
    </>
  );
}

export default App;

import React, { useState } from 'react';
import styles from './Worklist.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { coreItem } from '0_variables/coreItem';
import {
  RefFactor,
  reportAmyloidtable1,
  reportDATtable1,
  reportAmyloidDATtable2,
  getTargetPathOnWorklist,
  openGroupFilesSelected,
  closeSingleFile,
  isAllCompleteandSelected,
  isAnySelected,
} from '0_variables/utils';
import { updateWorklistThunk } from '1_reduxs/actions/filesAction';
import { openSpinner } from '1_reduxs/reducers/modalReducer';
import { print } from '4_routers/6_Report/makePDF';
import { Buttons, Burger, FileTable } from '5_components';
import { minus as MEDIA_minus } from '6_media';

export default function Worklist(props) {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const productName = params.product.toLowerCase();
  const dispatch = useDispatch();
  const fileList = useSelector((state) => state.fileList);
  const setting = useSelector((state) => state.setting);
  const settingOfProduct = setting[productName];
  const productCoreItem = coreItem[productName];
  const tracerList = productCoreItem.tracers.map((obj) => obj.shortname);
  const filteredFileList = fileList.filter(
    (item) => tracerList.includes(item.Tracer) && item.Group !== 0,
  );
  const [sortClick, setSortClick] = useState({});
  const defaultAtlas = setting[productName].defaultAtlas;
  const tableHead = [
    { title: 'Select', fieldName: 'Select' },
    { title: 'Tracer', fieldName: 'Tracer' },
    // {title: 'Centiloid', fieldName:'Global_C'},
    { title: 'Patient name', fieldName: 'PatientName' },
    { title: 'Patient ID', fieldName: 'PatientID' },
    { title: 'DOB', fieldName: 'Age' },
    { title: 'Sex', fieldName: 'Sex' },
    // {title: 'Scan data', fieldName:'AcquisitionDateTime'},
    { title: 'Uploaded date', fieldName: 'Update' },
  ];

  const burgerFuncs = {
    downloadPDF: () => {
      const selectedFiles = fileList.filter(
        (item) =>
          tracerList.includes(item.Tracer) &&
          item.Select === true &&
          item.Group === 1,
      );
      const RFs = selectedFiles.map((selectedFile) =>
        RefFactor({
          productName,
          refName: settingOfProduct.defaultRef[selectedFile['Tracer']],
          ponsRF: selectedFile['ratio_gry2pons'],
          crblRF: selectedFile['ratio_gry2crbl'],
          wmRF: selectedFile['ratio_gry2wm'],
          cgRF: selectedFile['ratio_gry2cg'],
          global: 1 / selectedFile['Global'],
        }),
      );
      const selectedFilesSet = selectedFiles.map((selectedFile, idx) => ({
        ...selectedFile,
        RF: RFs[idx],
      }));
      const table1DataList = selectedFilesSet.map((selectedFile, idx) => ({
        amyloid: reportAmyloidtable1({
          settingOfProduct: setting['amyloid'],
          typeSelect: productCoreItem.type1Selector,
          tableItems: coreItem['amyloid']['analysis'].bottom[1].cards[0],
          selectedFile: selectedFile,
          defaultAtlas: defaultAtlas,
          RF: RFs[idx],
        }),
        dat: reportDATtable1({
          settingOfProduct: setting['dat'],
          typeSelect: productCoreItem.type1Selector,
          tableItems: coreItem['dat']['analysis'].bottom[1].cards[0],
          selectedFile: selectedFile,
          defaultAtlas: defaultAtlas,
          RF: RFs[idx],
        }),
        fdg: reportAmyloidtable1({
          settingOfProduct: setting['fdg'],
          typeSelect: productCoreItem.type1Selector,
          tableItems: coreItem['fdg']['analysis'].bottom[1].cards[0],
          selectedFile: selectedFile,
          defaultAtlas: defaultAtlas,
          RF: RFs[idx],
        }),
        tau: reportAmyloidtable1({
          settingOfProduct: setting['tau'],
          typeSelect: productCoreItem.type1Selector,
          tableItems: coreItem['tau']['analysis'].bottom[1].cards[0],
          selectedFile: selectedFile,
          defaultAtlas: defaultAtlas,
          RF: RFs[idx],
        }),
        perfusion: reportAmyloidtable1({
          settingOfProduct: setting['perfusion'],
          typeSelect: productCoreItem.type1Selector,
          tableItems: coreItem['perfusion']['analysis'].bottom[1].cards[0],
          selectedFile: selectedFile,
          defaultAtlas: defaultAtlas,
          RF: RFs[idx],
        }),
      }));
      const table2DataList = selectedFilesSet.map((selectedFile, idx) => ({
        amyloid: reportAmyloidDATtable2({
          settingOfProduct: setting['amyloid'],
          typeSelect: productCoreItem.type2Selector,
          tableItems: coreItem['amyloid']['analysis'].bottom[1].cards[1],
          selectedFile: selectedFile,
          defaultAtlas: defaultAtlas,
          RF: RFs[idx],
        }),
        dat: reportAmyloidDATtable2({
          settingOfProduct: setting['dat'],
          typeSelect: productCoreItem.type2Selector,
          tableItems: coreItem['dat']['analysis'].bottom[1].cards[1],
          selectedFile: selectedFile,
          defaultAtlas: defaultAtlas,
          RF: RFs[idx],
        }),
      }));

      const transferFunc = ({ message, percent, target }) => {
        dispatch(
          openSpinner({
            status: true,
            percent: percent,
            message: message,
            target: target,
          }),
        );
      };

      const spinnerController = (onoff) => {
        if (onoff)
          dispatch(
            openSpinner({
              status: true,
              percent: 0,
              message: 'Reading...',
              target: 'WORKLIST',
            }),
          );
        else {
          dispatch(
            openSpinner({
              status: true,
              length: 0,
              message: 'Complete',
              target: 'COMPLETE',
            }),
          );
          setTimeout(() => {
            dispatch(
              openSpinner({
                status: false,
                length: 0,
                message: 'Complete',
                target: 'COMPLETE',
              }),
            );
          }, 1000);
        }
      };

      // props.RF, props.selectedFile 가져오는 방법 필요
      const selectedAll = filteredFileList.filter(
        ({ Group, Select }) => Select === true && Group !== 0,
      );

      if (selectedAll.length === 0) {
        alert('No file is seleted.');
      } else {
        props.setReportOn(true);
        spinnerController(true);
        let newElRefs = props.elRefs;
        setTimeout(() => {
          for (var i = 0; i < props.elRefs.length; i++) {
            if (!props.selectedFileList[i].Complete) {
              newElRefs.splice(i, 1);
            }
          }
          props.setDownloadState(true);
          print(
            newElRefs,
            productName,
            selectedFiles,
            table1DataList,
            table2DataList,
            settingOfProduct,
            transferFunc,
            spinnerController,
            props.setReportOn,
            props.setDownloadState,
          );
        }, 1000);
      }
    },
  };

  const deleteFromWorklist = (status) => {
    const selectedFiles = fileList.filter(
      (item) =>
        tracerList.includes(item.Tracer) &&
        item.Select === true &&
        item.Group === 1,
    );
    dispatch(updateWorklistThunk(status, selectedFiles));
  };

  const openHandler = () => {
    // debugger;
    const targetPage = getTargetPathOnWorklist(
      location.pathname.split('/')[2].toLowerCase(),
    );
    const productList = fileList.filter((el) => tracerList.includes(el.Tracer));
    const selectedGroup = productList.filter(
      (el) => el.Group !== 0 && el.Select === true,
    );
    const filteredList = [
      ...productList.filter((el) => el.Opened === true && el.Complete === true),
      ...selectedGroup,
    ];
    const targetFile = selectedGroup.at(-1);
    openGroupFilesSelected({
      targetPage,
      filteredList,
      targetFile,
      productName,
      dispatch,
      history,
    });
    props.setOpenedWorklist(false);
  };

  const closeHandler = () => {
    const filteredFileList = fileList.filter(
      (item) =>
        tracerList.includes(item.Tracer) &&
        item.Select &&
        item.Opened &&
        item.Group !== 0,
    );
    filteredFileList.forEach((item) => {
      closeSingleFile(
        dispatch,
        item,
        location.pathname.split('/')[2].toLowerCase(),
        productName,
        history,
      );
    });
  };

  const funcSelector = {
    Worklist: () => {
      deleteFromWorklist(false);
    },
    Open: () => {
      openHandler();
    },
    Close: () => {
      closeHandler();
    },
  };

  const burgerProps = {
    Enable: isAllCompleteandSelected(filteredFileList, tracerList),
    isViewContainer: false,
    isWorklist: true,
    pdfExportWorklist: burgerFuncs.downloadPDF,
  };

  return (
    <>
      {props.openedWorklist && (
        <div
          className={styles.bg_worklist}
          onClick={() => props.setOpenedWorklist(!props.openedWorklist)}
        ></div>
      )}
      <div
        className={`${styles.container} ${
          props.openedWorklist ? styles.opened : styles.closed
        }`}
      >
        <div
          className={styles.worklistHandle}
          onClick={() => props.setOpenedWorklist(!props.openedWorklist)}
        >
          <div>WORKLIST</div>
        </div>
        <div className={styles.worklistBox}>
          <div className={styles.head}>
            <div
              onClick={() => {
                // dispatch(actionModal.open_spinner({status:true, length:0, message:'', target:''}));
                // debugger;
                props.setReportOn(!props.reportOn);
              }}
            >
              {'WORKLIST'}
            </div>
            <Burger {...burgerProps} />
          </div>
          <div className={styles.body}>
            <FileTable
              identifier={'worklist'}
              // currPage={currPage}
              // setCurrPage={setCurrPage}
              productName={productName}
              tableHead={tableHead}
              FileList={filteredFileList}
              sortClick={sortClick}
              setSortClick={setSortClick}
            />
          </div>
          <div className={styles.foot}>
            <div>
              <Buttons
                {...{
                  Enable: isAnySelected(filteredFileList, tracerList),
                  styleName: 'worklistBtn',
                  imgSrc: <img src={MEDIA_minus} alt="removeByWorklist" />,
                  title: 'Worklist',
                  func: { onClickHandler: funcSelector['Worklist'] },
                }}
              />
            </div>
            <div>
              <Buttons
                {...{
                  Enable: isAnySelected(filteredFileList, tracerList),
                  styleName: 'worklistBtn',
                  imgSrc: '',
                  title: 'Open',
                  func: { onClickHandler: funcSelector['Open'] },
                }}
              />
              <Buttons
                {...{
                  Enable: isAnySelected(filteredFileList, tracerList),
                  styleName: 'worklistBtn',
                  imgSrc: '',
                  title: 'Close',
                  func: { onClickHandler: funcSelector['Close'] },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { coreItem } from '0_variables/coreItem';
import { changeSettings } from '1_reduxs/reducers/settingReducer';
import { Check as MEDIA_Check, unCheck as MEDIA_unCheck } from '6_media';
import styles from './DefaultTracer.module.scss';

export default function DefaultTracer() {
  const params = useParams();
  const dispatch = useDispatch();
  const productName = params.product.toLowerCase();
  const productCoreItem = coreItem[productName];
  const settingByProduct = useSelector((state) => state.setting[productName]);
  const selectTracer = settingByProduct.defaultTracer;

  const tracerItems = productCoreItem.tracers.map((item) => {
    return {
      fullname: item.fullname,
      color: item.color,
      shortname: item.shortname,
    };
  });

  const updateSettingToStore = (updatedSettingValue) => {
    const updatedSettings = {
      ...settingByProduct,
      ...updatedSettingValue,
    };
    dispatch(changeSettings({ productName, updatedSettings }));
  };

  const changeDefaultTracer = (tracer) => {
    updateSettingToStore({
      defaultTracer: tracer,
    });
  };

  return (
    <div className={styles['container']}>
      <div className={styles['itemsContainer']}>
        {tracerItems.map((item) => (
          <div key={item.shortname} className={styles.items}>
            <div onClick={() => changeDefaultTracer(item)}>
              <img
                src={
                  selectTracer.shortname === item.shortname
                    ? MEDIA_Check
                    : MEDIA_unCheck
                }
                alt="check box"
              />
            </div>
            <div style={{ borderLeft: `0.35rem solid ${item.color}` }}>
              {item.fullname
                .replace('18', '\u00B9\u2078')
                .replace('99mtc', '\u2079\u2079\u1d50Tc')}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

import { combineReducers } from 'redux';

import loginReducer from './loginReducer';
import modalReducer from './modalReducer';
import controllerReducer from './controllerReducer';
import filesReducer from './filesReducer';
import settingReducer from './settingReducer';
import licenseReducer from './licenseReducer';
import viewOptionReducer from './viewOptionReducer';

const rootReducer = combineReducers({
  login: loginReducer,
  modal: modalReducer,
  fileList: filesReducer,
  control: controllerReducer,
  setting: settingReducer,
  license: licenseReducer,
  viewOption: viewOptionReducer,
});

export default rootReducer;

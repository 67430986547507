import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { coreItem } from '0_variables/coreItem';
import { RefFactor, dataFormatForBrainTable } from '0_variables/utils';
import { BrainTable } from '5_components';
import styles from './Analysis_card1.module.scss';

export default function AnalysisCard1() {
  const PAGE_NAME = 'analysis'; // analysis에서만 사용됨
  const params = useParams();
  const productName = params.product.toLowerCase();

  const settingOfProduct = useSelector((state) => state.setting[productName]);
  const selectedFile = useSelector((state) =>
    state.fileList.find(({ fileID }) => fileID === params.fileID),
  );

  const tracerName = selectedFile['Tracer'];
  const refName = settingOfProduct.defaultRef[tracerName];

  const analysisCard1Format =
    coreItem[productName][PAGE_NAME].bottom[0].cards[0];
  const { legend, colorcode, content } = analysisCard1Format;

  const RF = RefFactor({
    productName,
    refName,
    ponsRF: selectedFile['ratio_gry2pons'],
    crblRF: selectedFile['ratio_gry2crbl'],
    wmRF: selectedFile['ratio_gry2wm'],
    cgRF: selectedFile['ratio_gry2cg'],
    global: 1 / selectedFile['Global'],
  });

  const items = dataFormatForBrainTable({
    productName,
    columnList: content,
    selectedFile,
    settingOfProduct,
    RF,
  });

  return (
    <div className={styles.container} id="analysis_brain_table">
      <BrainTable
        legend={legend}
        colorcode={colorcode}
        items={items}
        refName={refName}
      />
    </div>
  );
}

import React, { useEffect, useState, createRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FaFilePdf, FaUpload } from 'react-icons/fa';
import { limitNum as LIMIT_NUM, generatePDF } from '0_variables/utils';
import { subRegions } from '0_variables/subRegions';
import {
  openSpinner,
  openReportPacsModal,
} from '1_reduxs/reducers/modalReducer';
import { ReportPages, ReportPacsModal } from '5_components';
import styles from './Report.module.scss';

const Report = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const params = useParams();
  const productName = params.product.toLowerCase();
  const control = useSelector((state) => state.control);
  const controlOfProduct = control[productName];
  const modalState = useSelector((state) => state.modal);
  const fileList = useSelector((state) => state.fileList);
  const [elRefs, setElRefs] = useState([]);
  const setting = useSelector((state) => state.setting);
  const settingOfProduct = setting[productName];
  const defaultRegionLength = subRegions[productName]
    .map((v) => v.level)
    .filter((lev) => lev === 0).length;
  const settingRegionLength = settingOfProduct.list
    .map((v) => v.level)
    .filter((lev) => lev === 0).length;
  const limitNum = LIMIT_NUM;
  const pageLength =
    settingRegionLength - defaultRegionLength > limitNum[productName] ? 4 : 3;

  const selectedFile = fileList.find(({ fileID }) => fileID === params.fileID);

  useEffect(() => {
    // TODO: 여러개 열었을때 elRef도 여러개 담을 수 있도록 변경해야함!

    setElRefs(
      [...Array(controlOfProduct.openedFiles.length)].map((el) =>
        [...Array(pageLength)].map((_, i2) => createRef()),
      ),
    );
  }, [controlOfProduct.openedFiles, pageLength]);

  useEffect(() => {
    dispatch(openReportPacsModal({ isOn: false }));
  }, [dispatch]);

  const downloadPDF = (idx, elRefs, wait, server = false) => {
    const spinnerController = (onoff) => {
      if (onoff)
        dispatch(
          openSpinner({
            status: true,
            percent: 0,
            message: 'Downloading...',
            target: 'REPORT',
          }),
        );
      else
        dispatch(
          openSpinner({
            status: false,
            percent: 0,
            message: 'Downloading...',
            target: 'REPORT',
          }),
        );
    };

    setTimeout(() => {
      const transferFunc = ({ message, percent, target }) => {
        dispatch(
          openSpinner({
            status: true,
            percent: percent,
            message: message,
            target: target,
          }),
        );
      };

      generatePDF(
        [elRefs],
        productName,
        selectedFile,
        setting,
        transferFunc,
        spinnerController,
        () => {},
        () => {},
        server,
        params.fileID,
      );
    }, 0);
  };
  return (
    <div className={styles.container}>
      {modalState.reportPacsModal.status && <ReportPacsModal {...props} />}
      <div className={styles.buttons}>
        <div
          onClick={() => {
            downloadPDF(
              controlOfProduct.openedFiles.findIndex(
                (el) => el.fileID === params.fileID,
              ),
              elRefs,
              1000,
              true,
            );
            dispatch(openReportPacsModal({ isOn: true }));
          }}
        >
          <FaUpload size={'0.8vw'} />
          &nbsp;{'PACS'}
        </div>
        <div
          onClick={() => {
            downloadPDF(
              controlOfProduct.openedFiles.findIndex(
                (el) => el.fileID === params.fileID,
              ),
              elRefs,
              1000,
              false,
            );
          }}
        >
          <FaFilePdf size={'0.8vw'} />
          &nbsp;{'PDF'}
        </div>
      </div>
      {controlOfProduct.openedFiles.map((el, i) => (
        <div
          key={el.fileID}
          className={styles.reportContainer}
          style={{
            visibility: params.fileID === el.fileID ? 'visible' : 'hidden',
          }}
        >
          <ReportPages
            {...props}
            order={i}
            fileID={el.fileID}
            innerRef={elRefs[i]}
            pageLength={pageLength}
            openFile={el}
          />
        </div>
      ))}
    </div>
  );
});
export default Report;

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import axios from 'axios';
import allReducers from '1_reduxs/reducers';
import createStore from '1_reduxs/store';
import { setLoginThunk } from '1_reduxs/actions/loginAction';
import { ScrollToTop } from '5_components';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

const store = createStore(allReducers);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <ScrollToTop />
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
reportWebVitals();

// axios config
const UNAUTHORIZED = 401;
// direct access to redux store.
const { dispatch } = store;

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

axios.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('token');
    try {
      if (token) {
        config.headers.Authorization = `jwt ${token}`;
      }
      return config;
    } catch (err) {
      console.error('[axios request config error]' + err);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status) {
      const { status } = error.response;
      if (status === UNAUTHORIZED) {
        dispatch(
          setLoginThunk({
            username: '',
            token: '',
            logged: false,
          }),
        );

        alert(
          "You've been logged out. This may have been caused by another account logged in at the same time. Please use one account at a time. ",
        );
      }
    }
    return Promise.reject(error);
  },
);

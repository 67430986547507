import {
  setSelectedFileID,
  closeFile,
  addGroupOpenFiles,
} from '1_reduxs/reducers/controllerReducer';

export const openFilesWithFileSelectThunk = (
  productName,
  selectedFiles,
  singleFile,
) => {
  return async (dispatch) => {
    dispatch(addGroupOpenFiles({ productName, selectedFiles }));
    if (singleFile !== null)
      dispatch(setSelectedFileID({ productName, selectedFile: singleFile }));
  };
};

export const closeFileThunk = (productName, singleFile) => {
  return async (dispatch) => {
    dispatch(closeFile({ productName, singleFile }));
  };
};

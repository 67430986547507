import {
  view as MEDIA_view,
  analysis as MEDIA_analysis,
  close as MEDIA_close,
  plus as MEDIA_plus,
  minus as MEDIA_minus,
  trash as MEDIA_trash
} from '6_media';
import {BrainLobe, StriatumLobe} from "6_media";
import chroma from 'chroma-js';
import { MdRefresh, MdSave } from 'react-icons/md';


export const SPECT_status = true;
export const initProductInfo = [
  {
    productFullName: 'BTXBrain - Amyloid',
    productShortName: 'amyloid',
    color: '#FF6D4C',
    validDate: '',
    license: false,
    isVisiable: false,
  },
  {
    productFullName: 'BTXBrain - DAT',
    productShortName: 'dat',
    color: '#F14CFF',
    validDate: '',
    license: false,
    isVisiable: false,
  },
  {
    productFullName: 'BTXBrain - FDG',
    productShortName: 'fdg',
    color: '#4CFF69',
    validDate: '',
    license: false,
    isVisiable: false,
  },
  {
    productFullName: 'BTXBrain - Tau',
    productShortName: 'tau',
    color: '#4C74FF',
    validDate: '',
    license: false,
    isVisiable: false,
  },
  {
    productFullName: 'BTXBrain - Perfusion',
    productShortName: 'perfusion',
    color: '#b737ff',
    validDate: '',
    license: false,
    isVisiable: false,
  },
];
export const coreItem = (()=>{
  // const amyloidCsample = chroma.scale(["#FFFEFD","#FFDFBE","#F9C697","#F7AB7E","#FF8075","#F26162","#E14349","#CF282B","#850303"])
  const amyloidCsample = chroma.scale(["#ffebda","#FFDFBE","#F9C697","#F7AB7E","#FF8075","#F26162","#E14349","#CF282B","#850303"])
  const amyloidCmap = [...Array(101).keys()].map(v => amyloidCsample(v/100).toString())
  // const datCsample     = chroma.scale(["#2400d8","#181cf7","#2857ff","#3d87ff","#56b0ff","#75d3ff","#99eaff","#bcf9ff","#eaffff"]);
  const datCsample     = chroma.scale(["#2400d8","#181cf7","#2857ff","#3d87ff","#56b0ff","#75d3ff","#99eaff","#bcf9ff","#ddfcff"]);
  const datCmap = [...Array(101).keys()].map(v => datCsample(v/100).toString())
  const fdgCsample = chroma.scale(["#000093","#0007ff","#005bff","#00a7ff","#00eeff","#2fffcf","#53ffab","#83ff7b","#cbff33","#fcff02","#ff8a00","#ff1300","#970000"])
  const fdgCmap = [...Array(101).keys()].map(v => fdgCsample(v/100).toString())
  // const perfusionCsample = chroma.scale(["#000093","#0007ff","#005bff","#00a7ff","#00eeff","#2fffcf","#53ffab","#83ff7b","#cbff33","#fcff02","#ff8a00","#ff1300","#970000"])
  // const perfusionCmap = [...Array(101).keys()].map(v => fdgCsample(v/100).toString())

  const Date7 = [...Array(7).keys()].map(i=>{
    const date = new Date();
    date.setDate(date.getDate()-i)
    return (
      date.getFullYear()+'-'+('0' + (date.getMonth()+1)).slice(-2)+'-'+('0' + date.getDate()).slice(-2)
    )
  });
  const QuantTable_type1Selector = {
    'amyloid': 'suvr',
    'dat': 'sbr',
    'fdg' :'suvr',
    'tau' :'suvr',
    'perfusion' :'suvr'
  }
  const QuantTable_type2Selector = {
    'amyloid': 'centiloid',
    'dat': 'semiquantification',
    'fdg' :'suvr',
    'tau' :'suvr',
    'perfusion' :'suvr'
  }
  const CTN_Mode = false;
  const Cloud_Mode = false;
  const IMAGE_VERTICAL_FLIP = false
  
  return {
    amyloid: (()=> {
      const productName = 'Amyloid';
      const productShortName = 'Amyloid';
      const productFullName = 'Amyloid';
      const type1Selector = QuantTable_type1Selector['amyloid'];
      const type2Selector = QuantTable_type2Selector['amyloid'];
      const tracers = [
        {fullname:'[18F]Flutemetamol', shortname:"[18F]FMM",  color:"#dfe403", commercialname:"Vizamyl"             },
        {fullname:'[18F]Florbetapir',  shortname:"[18F]FBP",  color:"#19ffd6", commercialname:"Amyvid(AV-45)"       },
        {fullname:'[18F]Florbetaben',  shortname:"[18F]FBB",  color:"#FF8042", commercialname:"Neuraceq(Bay-949172)"},
        {fullname:'[18F]Florapronol',  shortname:"[18F]FPN",  color:"#4472c4", commercialname:"Alzavue"             },
      ];
   
      const defaultCSVFormat =[
          {title:"Include data",   state:true, subOption:[{title:"Total", state:true}, {title:"Left & Right", state:true}]},
          {title:"SUVR",           state:true, subOption:[                                                               ]},
          {title:"Centiloid",      state:true, subOption:[                                                               ]},
          {title:"Transpose data", state:true, subOption:[                                                               ]},
      ]
      // let includeData = [{title:"Total", state:true}, {title:"Left & Right", state:true}]
      // const defaultCSVFormat =[
      //     {title:"Include data",   state:true, subOption:includeData},
      //     {title:"SUVR",           state:true, subOption:includeData},
      //     {title:"Centiloid",      state:true, subOption:includeData},
      //     {title:"Transpose data", state:true, subOption:includeData},
      // ]
      const defaultAtlas = "AAL3";
      // const defaultRef = {
      //   "[18F]FMM": "Pons",
      //   "[18F]FBP": "Cerebellar gray matter",
      //   "[18F]FBB": "Cerebellar gray matter",
      //   "[18F]FPN": "Cerebellar gray matter",
      // }
      const defaultRef = {
        "[18F]FMM": "Whole cerebellum",
        "[18F]FBP": "Whole cerebellum",
        "[18F]FBB": "Whole cerebellum",
        "[18F]FPN": "Whole cerebellum",
      }
      const defaultTracer = tracers[2];
      const defaultPInfoState = [
        {name:"Patient Name", state: true},
        {name:"Patient ID", state:true},
        {name:"DOB", state:true},
        {name:"Sex", state:true},
        {name:"Type & Date of Examination", state:true},
      ];
      const COLORMAP = [
        'invertedGray',
        'pet',
        'jet',
        'hot',
        'gray',
      ];
      return {
        CTN_Mode,
        Cloud_Mode,
        imageVerticalFlip: IMAGE_VERTICAL_FLIP,
        Date7:Date7,
        productName: productName, 
        productShortName:productShortName,
        productFullName: productFullName,
        tracers:tracers,
        defaultCSVFormat:defaultCSVFormat,
        defaultAtlas:defaultAtlas,
        defaultRef:defaultRef,
        defaultTracer:defaultTracer,
        defaultPInfoState:defaultPInfoState,
        type1Selector:type1Selector,
        type2Selector:type2Selector,
        COLORMAP:COLORMAP,
        worklist:{
          top:{
            item1:[
              // {title:"Export all to PDF Fast", var:"downloadPDFFast"},
              {title:"Export all to PDF", var:"downloadPDF"},
              // {title:"Export all to JPEG", var:"downloadJPEG"},
              {title:"Export all to CSV", var:"downloadCSV_Group"},
              // {title:"Export all to Nifti", var:"downloadNifti_Group"},
              {title:"Export all to NIFTI (SUVR)", var:"downloadNifitiSUVR_Group"},
              {title:"Export all to NIFTI (Raw)", var:"downloadNifitiIntensity_Group"},
            ]
          }
        },
        dashboard:{
          bottom:[
            {
              cards:[
                {title: "Weekly Analysis", Date:`${Date7[6]} - ${Date7[0]}`, componentName: "BarGraph"},
              ],
            },
            {
              cards:[
                {title: `${productName} Tracers`, componentName: "PieGraph"}
              ]
            },
          ]
        },
        upload:{
          top:{
            btnGroup1:[
              {title:'View', icon:<img src={MEDIA_view} alt='view'/>, funcName:"view"},
              {title:'Analysis', icon:<img src={MEDIA_analysis}  alt='analysis'/>, funcName:"analysis"},
              {title:'Close', icon:<img src={MEDIA_close}  alt='close'/>, funcName:"close"},
            ],
            btnGroup2:[
              {title:'Worklist', icon:<img src={MEDIA_plus} alt='worklist add'/>, funcName:"worklist_add"},
              {title:'Worklist', icon:<img src={MEDIA_minus} alt='worklist delete'/>, funcName:"worklist_delete"},
            ],
            btnGroup3:[
              {title:'Delete', icon:<img src={MEDIA_trash} alt='file delete'/>, funcName:"file_delete"},
            ],
            btnGroup4:[
              {title:'Connect to PACS', icon:"", funcName:"pacs"},
              {title:'Download samples', icon:"", funcName:"sample"},
              {title:'Upload', icon:"", funcName:"upload"},
            ],
          },
          bottom:{
            tableHead:[
              {title: 'Select', fieldName:'Select'},
              {title: 'Tracer', fieldName:'Tracer'},
              {title: 'Centiloid', fieldName:'Composite_C'},
              {title: 'Patient name', fieldName:'PatientName'},
              {title: 'Patient ID', fieldName:'PatientID'},
              {title: 'DOB', fieldName:'Age'},
              {title: 'Sex', fieldName:'Sex'},
              {title: 'Scan date', fieldName:'AcquisitionDateTime'},
              {title: 'Uploaded date', fieldName:'Update'},
            ],
            uploadingTableHead2:[
              {title: 'Select', fieldName:'Select'},
              {title: 'Patient ID', fieldName:'PatientID'},
              {title: 'Patient name', fieldName:'PatientName'},
              {title: 'DOB', fieldName:'BirthDate'},
              {title: 'Modality', fieldName: 'Modality'},
              {title: 'Study Date', fieldName: 'StudyDate'},
              {title: 'Study Description', fieldName: 'StudyDescription'},
              {title: 'Delete', fieldName: 'Delete'},
            ],
            uploadingTableHead3:[
              {title: 'Tracer', fieldName:'Tracer'},
              {title: 'Patient ID', fieldName:'PatientID'},
              {title: 'Patient name', fieldName:'PatientName'},
              {title: 'Series Description', fieldName: 'SeriesDescription'},
              {title: 'Delete', fieldName: 'Delete'},
            ],
            uploadingTableHead3Direct:[
              {title: 'Tracer', fieldName:'Tracer'},
              {title: 'Patient ID', fieldName:'PatientID'},
              {title: 'Patient name', fieldName:'PatientName'},
              // {title: 'Series Description', fieldName: 'SeriesDescription'},
              {title: 'Delete', fieldName: 'Delete'},
            ],
          },
        },
        view:{
          top:{
            item1:[
              {title:"Tracer", var:"Tracer"},
              {title:"Patient name", var:"PatientName" },
              {title:"Patient ID", var:"PatientID"},
              {title:"DOB", var:"Age"},
              {title:"Sex", var:"Sex"},
              {title:"Study Date", var:"AcquisitionDateTime"},
              {title:"Desc", var:"OriginalFileName"}
            ],
            item2:[
              {title:"Atlas", var:"defaultAtlas"},
              {title:"Reference", var:"defaultRef"},
            ],
            item3:[
              {title:"Generate a report", var:"openReport"},
              {title:"Export to CSV", var:"downloadCSV_Individual"},
              {title:"Export to NIFTI (SUVR)", var:"downloadNifitiSUVR_Individaul"},
              {title:"Export to NIFTI (Raw)", var:"downloadNifitiIntensity_Individaul"},
            ]
          },
        },
        analysis:{
          top:{
            item1:[
              {title:"Tracer", var:"Tracer"},
              {title:"Patient name", var:"PatientName" },
              {title:"Patient ID", var:"PatientID"},
              {title:"DOB", var:"Age"},
              {title:"Sex", var:"Sex"},
              {title:"Study Date", var:"AcquisitionDateTime"},
              {title:"Desc", var:"OriginalFileName"}
            ],
            item2:[
              {title:"Atlas", var:"defaultAtlas"},
              {title:"Reference", var:"defaultRef"},
            ],
            item3:[
              {title:"Generate a report", var:"openReport"},
              {title:"Export to CSV", var:"downloadCSV_Individual"},
              {title:"Export to NIFTI (SUVR)", var:"downloadNifitiSUVR_Individaul"},
              {title:"Export to NIFTI (Raw)", var:"downloadNifitiIntensity_Individaul"},
            ]
          },
          bottom:[
            {
              cards:[
                {
                  title: "Lobar SUVR", 
                  tableHead: ['image', 'value'], 
                  colorcode:amyloidCmap,
                  legend:'SUVR',
                  componentName: "Analysis_card1",
                  content:[
                    {atlas:"all", lobe:"Global Lobe",    Left:"Bilat.", Right:"Bilat.", Lvar:"Global",      Rvar:"Global",      BrainSVG:({Lobe1, Lobe2, Lobe3, Lobe4}={})=>{return <div style={{width:"55%"}}><BrainLobe fillFrontal={amyloidCmap[Lobe1]} fillParietal={amyloidCmap[Lobe2]} fillTemporal={amyloidCmap[Lobe3]} fillOccipital={amyloidCmap[Lobe4]}/></div>}}, 
                    {atlas:"all", lobe:"Frontal Lobe",   Left:"L",      Right:"R",      Lvar:"Frontal_L",   Rvar:"Frontal_R",   BrainSVG:({Lobe1, Lobe2, Lobe3, Lobe4}={})=>{return <div style={{width:"55%"}}><BrainLobe fillFrontal={amyloidCmap[Lobe1]} fillParietal={"gray"} fillTemporal={"gray"} fillOccipital={"gray"}/></div>}}, 
                    {atlas:"all", lobe:"Parietal Lobe",  Left:"L",      Right:"R",      Lvar:"Parietal_L",  Rvar:"Parietal_R",  BrainSVG:({Lobe1, Lobe2, Lobe3, Lobe4}={})=>{return <div style={{width:"55%"}}><BrainLobe fillFrontal={"gray"} fillParietal={amyloidCmap[Lobe2]} fillTemporal={"gray"} fillOccipital={"gray"}/></div>}}, 
                    {atlas:"all", lobe:"Temporal Lobe",  Left:"L",      Right:"R",      Lvar:"Temporal_L",  Rvar:"Temporal_R",  BrainSVG:({Lobe1, Lobe2, Lobe3, Lobe4}={})=>{return <div style={{width:"55%"}}><BrainLobe fillFrontal={"gray"} fillParietal={"gray"} fillTemporal={amyloidCmap[Lobe3]} fillOccipital={"gray"}/></div>}}, 
                    {atlas:"all", lobe:"Occipital Lobe", Left:"L",      Right:"R",      Lvar:"Occipital_L", Rvar:"Occipital_R", BrainSVG:({Lobe1, Lobe2, Lobe3, Lobe4}={})=>{return <div style={{width:"55%"}}><BrainLobe fillFrontal={"gray"} fillParietal={"gray"} fillTemporal={"gray"} fillOccipital={amyloidCmap[Lobe4]}/></div>}}, 
                  ]
                },
              ]
            },

            {
              cards:[
                {
                  title: "Regional SUVR", 
                  tableHead: ['Region', 'Total', 'Left', 'Right'], 
                  componentName: "Analysis_card2_1",
                  content:[
                    {atlas:"AAL3", fullname: "Global", varname: {Left:"Global",Right:"Global"}, 
                      subItem:[]},

                    {atlas:"AAL3", fullname: "Frontal", varname: {Left:"Frontal_L", Right:"Frontal_R"}, 
                      subItem:[
                        {fullname:"Precentral",        varname:{Left:"Precentral_L",        Right:"Precentral_R"       }},
                        {fullname:"Frontal Sup",       varname:{Left:"Frontal_Sup_L",       Right:"Frontal_Sup_R"      }},
                        {fullname:"Frontal Mid",       varname:{Left:"Frontal_Mid_L",       Right:"Frontal_Mid_R"      }},
                        {fullname:"Frontal Inf Oper",  varname:{Left:"Frontal_Inf_Oper_L",  Right:"Frontal_Inf_Oper_R" }},
                        {fullname:"Frontal Inf Tri",   varname:{Left:"Frontal_Inf_Tri_L",   Right:"Frontal_Inf_Tri_R"  }},
                        {fullname:"Frontal Inf Orb",   varname:{Left:"Frontal_Inf_Orb_L",   Right:"Frontal_Inf_Orb_R"  }},
                        {fullname:"Rolandic Oper",     varname:{Left:"Rolandic_Oper_L",     Right:"Rolandic_Oper_R"    }},
                        {fullname:"Supp Motor Area",   varname:{Left:"Supp_Motor_Area_L",   Right:"Supp_Motor_Area_R"  }},
                        {fullname:"Olfactory",         varname:{Left:"Olfactory_L",         Right:"Olfactory_R"        }},
                        {fullname:"Frontal Sup Med",   varname:{Left:"Frontal_Sup_Med_L",   Right:"Frontal_Sup_Med_R"  }},
                        {fullname:"Frontal Med Orb",   varname:{Left:"Frontal_Med_Orb_L",   Right:"Frontal_Med_Orb_R"  }},
                        {fullname:"Rectus",            varname:{Left:"Rectus_L",            Right:"Rectus_R"           }},
                        {fullname:"OFCmed",            varname:{Left:"OFCmed_L",            Right:"OFCmed_R"           }},
                        {fullname:"OFCant",            varname:{Left:"OFCant_L",            Right:"OFCant_R"           }},
                        {fullname:"OFCpost",           varname:{Left:"OFCpost_L",           Right:"OFCpost_R"          }},
                        {fullname:"OFClat",            varname:{Left:"OFClat_L",            Right:"OFClat_R"           }},
                        {fullname:"Insula",            varname:{Left:"Insula_L",            Right:"Insula_R"           }},
                        {fullname:"Cingulate Ant Sub", varname:{Left:"Cingulate_Ant_Sub_L", Right:"Cingulate_Ant_Sub_R"}},
                        {fullname:"Cingulate Ant Pre", varname:{Left:"Cingulate_Ant_Pre_L", Right:"Cingulate_Ant_Pre_R"}},
                        {fullname:"Cingulate Ant Sup", varname:{Left:"Cingulate_Ant_Sup_L", Right:"Cingulate_Ant_Sup_R"}},
                        {fullname:"Cingulate Mid",     varname:{Left:"Cingulate_Mid_L",     Right:"Cingulate_Mid_R"    }}
                      ]},

                    {atlas:"AAL3", fullname: "PCC-Precuneus", varname: {Left:"PCC_L", Right:"PCC_R"}, 
                      subItem:[
                        {fullname:"Cingulate Post", varname:{Left:"Cingulate_Post_L", Right:"Cingulate_Post_R"}},
                        {fullname:"Precuneus", varname:{Left:"Precuneus_L", Right:"Precuneus_R"}},
                      ]},

                    {atlas:"AAL3", fullname: "Lateral temporal", varname: {Left:"Lateral_temporal_L", Right:"Lateral_temporal_R"}, 
                      subItem:[
                        {fullname:"Heschl", varname: {Left:"Heschl_L", Right:"Heschl_R"}},
                        {fullname:"Temporal Sup", varname: {Left:"Temporal_Sup_L", Right:"Temporal_Sup_R"}},
                        {fullname:"Temporal Pole Sup", varname: {Left:"Temporal_Pole_Sup_L", Right:"Temporal_Pole_Sup_R"}},
                        {fullname:"Temporal Mid", varname: {Left:"Temporal_Mid_L", Right:"Temporal_Mid_R"}},
                        {fullname:"Temporal Pole Mid", varname: {Left:"Temporal_Pole_Mid_L", Right:"Temporal_Pole_Mid_R"}},
                        {fullname:"Temporal Inf", varname: {Left:"Temporal_Inf_L", Right:"Temporal_Inf_R"}},
                      ]},

                    {atlas:"AAL3", fullname: "Lateral parietal", varname: {Left:"Lateral_parietal_L", Right:"Lateral_parietal_R"}, 
                      subItem:[
                        {fullname: "Postcentral", varname: {Left:"Postcentral_L",Right:"Postcentral_R"}},
                        {fullname: "Parietal Sup", varname: {Left:"Parietal_Sup_L",Right:"Parietal_Sup_R"}},
                        {fullname: "Parietal Inf", varname: {Left:"Parietal_Inf_L",Right:"Parietal_Inf_R"}},
                        {fullname: "Supramarginal", varname: {Left:"Supramarginal_L",Right:"Supramarginal_R"}},
                        {fullname: "Angular", varname: {Left:"Angular_L",Right:"Angular_R"}},
                        {fullname: "Paracentral Lobule", varname: {Left:"Paracentral_Lobule_L",Right:"Paracentral_Lobule_R"}}
                      ]},

                    {atlas:"AAL3", fullname: "Medial temporal", varname: {Left:"Medial_temporal_L", Right:"Medial_temporal_R"}, 
                      subItem:[
                        {fullname: "Hippocampus", varname: {Left:"Hippocampus_L",Right:"Hippocampus_R"}},
                        {fullname: "Parahippocampal", varname: {Left:"Parahippocampal_L",Right:"Parahippocampal_R"}},
                        {fullname: "Amygdala", varname: {Left:"Amygdala_L",Right:"Amygdala_R"}},
                      ]},

                    {atlas:"AAL3", fullname: "Occipital", varname: {Left:"Occipital_L", Right:"Occipital_R"}, 
                      subItem:[
                        {fullname: "Calcarine", varname: {Left:"Calcarine_L",Right:"Calcarine_R"}},
                        {fullname: "Cuneus", varname: {Left:"Cuneus_L",Right:"Cuneus_R"}},
                        {fullname: "Lingual", varname: {Left:"Lingual_L",Right:"Lingual_R"}},
                        {fullname: "Occipital Sup", varname: {Left:"Occipital_Sup_L",Right:"Occipital_Sup_R"}},
                        {fullname: "Occipital Mid", varname: {Left:"Occipital_Mid_L",Right:"Occipital_Mid_R"}},
                        {fullname: "Occipital Inf", varname: {Left:"Occipital_Inf_L",Right:"Occipital_Inf_R"}},
                        {fullname: "Fusiform", varname: {Left:"Fusiform_L",Right:"Fusiform_R"}},
                      ]},
                    {atlas:"AAL3", fullname: "Basal ganglia", varname: {Left:"Basal_ganglia_L", Right:"Basal_ganglia_R"}, 
                      subItem:[
                        {fullname: "Caudate", varname: {Left:"Caudate_L",Right:"Caudate_R"}},
                        {fullname: "Putamen", varname: {Left:"Putamen_L",Right:"Putamen_R"}},
                        {fullname: "Pallidum", varname: {Left:"Pallidum_L",Right:"Pallidum_R"}},
                        {fullname: "Thalamus", varname: {Left:"Thalamus_L",Right:"Thalamus_R"}},
                        {fullname: "N Acc", varname: {Left:"N_Acc_L",Right:"N_Acc_R"}},
                      ]},
                    {atlas:"AAL3", fullname: "Brainstem", varname: {Left:"Brainstem_L", Right:"Brainstem_R"}, 
                      subItem:[]},

                    {atlas:"Harvard-Oxford", fullname: "Global", varname: {Left:"HO_Global",Right:"HO_Global"}, 
                      subItem:[]},

                    {atlas:"Harvard-Oxford", fullname: "Frontal", varname: {Left:"HO_Frontal_L", Right:"HO_Frontal_R"}, 
                      subItem:[
                        {fullname: "Frontal Pole", varname: {Left:"HO_Frontal_Pole_L",Right:"HO_Frontal_Pole_R"}},
                        {fullname: "Insula", varname: {Left:"HO_Insula_L",Right:"HO_Insula_R"}},
                        {fullname: "Frontal Sup", varname: {Left:"HO_Frontal_Sup_L",Right:"HO_Frontal_Sup_R"}},
                        {fullname: "Frontal Mid", varname: {Left:"HO_Frontal_Mid_L",Right:"HO_Frontal_Mid_R"}},
                        {fullname: "Frontal Inf Tri", varname: {Left:"HO_Frontal_Inf_Tri_L",Right:"HO_Frontal_Inf_Tri_R"}},
                        {fullname: "Frontal Inf Oper", varname: {Left:"HO_Frontal_Inf_Oper_L",Right:"HO_Frontal_Inf_Oper_R"}},
                        {fullname: "Precentral", varname: {Left:"HO_Precentral_L",Right:"HO_Precentral_R"}},
                        {fullname: "Frontal Med", varname: {Left:"HO_Frontal_Med_L",Right:"HO_Frontal_Med_R"}},
                        {fullname: "Supp Motor Area", varname: {Left:"HO_Supp_Motor_Area_L",Right:"HO_Supp_Motor_Area_R"}},
                        {fullname: "Cingulate Ant", varname: {Left:"HO_Cingulate_Ant_L",Right:"HO_Cingulate_Ant_R"}},
                        {fullname: "Frontal Orb", varname: {Left:"HO_Frontal_Orb_L",Right:"HO_Frontal_Orb_R"}},
                        {fullname: "Frontal Oper", varname: {Left:"HO_Frontal_Oper_L",Right:"HO_Frontal_Oper_R"}},
                        {fullname: "Central Oper", varname: {Left:"HO_Central_Oper_L",Right:"HO_Central_Oper_R"}},
                        {fullname: "Subcallosal", varname: {Left:"HO_Subcallosal_L",Right:"HO_Subcallosal_R"}},
                        {fullname: "Paracingulate", varname: {Left:"HO_Paracingulate_L",Right:"HO_Paracingulate_R"}},
                    ]},


                    {atlas:"Harvard-Oxford", fullname: "Retrosplenial", varname: {Left:"HO_PCC_L",Right:"HO_PCC_R"}, 
                      subItem:[
                        {fullname: "Cingulate Post", varname: {Left:"HO_Cingulate_Post_L",Right:"HO_Cingulate_Post_R"}},
                        {fullname: "Precuneus", varname: {Left:"HO_Precuneus_L",Right:"HO_Precuneus_R"}},
                    ]},

                    {atlas:"Harvard-Oxford", fullname: "Lateral temporal", varname: {Left:"HO_Lateral_temporal_L",Right:"HO_Lateral_temporal_R"}, 
                      subItem:[
                        {fullname: "Temporal Pole", varname: {Left:"HO_Temporal_Pole_L",Right:"HO_Temporal_Pole_R"}},
                        {fullname: "Temporal Sup Ant", varname: {Left:"HO_Temporal_Sup_Ant_L",Right:"HO_Temporal_Sup_Ant_R"}},
                        {fullname: "Temporal Sup Post", varname: {Left:"HO_Temporal_Sup_Post_L",Right:"HO_Temporal_Sup_Post_R"}},
                        {fullname: "Temporal Mid Ant", varname: {Left:"HO_Temporal_Mid_Ant_L",Right:"HO_Temporal_Mid_Ant_R"}},
                        {fullname: "Temporal Mid Post", varname: {Left:"HO_Temporal_Mid_Post_L",Right:"HO_Temporal_Mid_Post_R"}},
                        {fullname: "Temporal Mid Temporooccipital", varname: {Left:"HO_Temporal_Mid_Temporooccipital_L",Right:"HO_Temporal_Mid_Temporooccipital_R"}},
                        {fullname: "Temporal Inf Ant", varname: {Left:"HO_Temporal_Inf_Ant_L",Right:"HO_Temporal_Inf_Ant_R"}},
                        {fullname: "Temporal Inf Post", varname: {Left:"HO_Temporal_Inf_Post_L",Right:"HO_Temporal_Inf_Post_R"}},
                        {fullname: "Temporal Inf Temporooccipital", varname: {Left:"HO_Temporal_Inf_Temporooccipital_L",Right:"HO_Temporal_Inf_Temporooccipital_R"}},
                        {fullname: "Planum Polare", varname: {Left:"HO_Planum_Polare_L",Right:"HO_Planum_Polare_R"}},
                        {fullname: "Heschl", varname: {Left:"HO_Heschl_L",Right:"HO_Heschl_R"}},
                        {fullname: "Planum Temporale", varname: {Left:"HO_Planum_Temporale_L",Right:"HO_Planum_Temporale_R"}},
                        {fullname: "Temporal fusiform Ant", varname: {Left:"HO_Temporal_fusiform_Ant_L",Right:"HO_Temporal_fusiform_Ant_R"}},
                        {fullname: "Temporal fusiform Post", varname: {Left:"HO_Temporal_fusiform_Post_L",Right:"HO_Temporal_fusiform_Post_R"}},
                        {fullname: "Temporal Occipital Fusiform", varname: {Left:"HO_Temporal_Occipital_Fusiform_L",Right:"HO_Temporal_Occipital_Fusiform_R"}},
                    ]},

                    {atlas:"Harvard-Oxford", fullname: "Lateral parietal", varname: {Left:"HO_Lateral_parietal_L",Right:"HO_Lateral_parietal_R"}, 
                      subItem:[
                        {fullname: "Postcentral", varname: {Left:"HO_Postcentral_L",Right:"HO_Postcentral_R"}},
                        {fullname: "Parietal Sup Lobule", varname: {Left:"HO_Parietal_Sup_Lobule_L",Right:"HO_Parietal_Sup_Lobule_R"}},
                        {fullname: "Supramarginal Ant", varname: {Left:"HO_Supramarginal_Ant_L",Right:"HO_Supramarginal_Ant_R"}},
                        {fullname: "Supramarginal Post", varname: {Left:"HO_Supramarginal_Post_L",Right:"HO_Supramarginal_Post_R"}},
                        {fullname: "Angular", varname: {Left:"HO_Angular_L",Right:"HO_Angular_R"}},
                        {fullname: "Parietal Oper", varname: {Left:"HO_Parietal_Oper_L",Right:"HO_Parietal_Oper_R"}},
                    ]},
                    {atlas:"Harvard-Oxford", fullname: "Medial temporal", varname: {Left:"HO_Medial_temporal_L",Right:"HO_Medial_temporal_R"}, 
                      subItem:[
                        {fullname: "Parahippocampal Ant", varname: {Left:"HO_Parahippocampal_Ant_L",Right:"HO_Parahippocampal_Ant_R"}},
                        {fullname: "Parahippocampal Post", varname: {Left:"HO_Parahippocampal_Post_L",Right:"HO_Parahippocampal_Post_R"}},
                        {fullname: "Hippocampus", varname: {Left:"HO_Hippocampus_L",Right:"HO_Hippocampus_R"}},
                        {fullname: "Amygdala", varname: {Left:"HO_Amygdala_L",Right:"HO_Amygdala_R"}},
                    ]},
                    {atlas:"Harvard-Oxford", fullname: "Occipital", varname: {Left:"HO_Occipital_L",Right:"HO_Occipital_R"}, 
                      subItem:[
                        {fullname: "Occipital Lat_Sup", varname: {Left:"HO_Occipital_Lat_Sup_L",Right:"HO_Occipital_Lat_Sup_R"}},
                        {fullname: "Occipital Lat_Inf", varname: {Left:"HO_Occipital_Lat_Inf_L",Right:"HO_Occipital_Lat_Inf_R"}},
                        {fullname: "Intracalcarine", varname: {Left:"HO_Intracalcarine_L",Right:"HO_Intracalcarine_R"}},
                        {fullname: "Occipital Pole", varname: {Left:"HO_Occipital_Pole_L",Right:"HO_Occipital_Pole_R"}},
                        {fullname: "Cuneal", varname: {Left:"HO_Cuneal_L",Right:"HO_Cuneal_R"}},
                        {fullname: "Lingual", varname: {Left:"HO_Lingual_L",Right:"HO_Lingual_R"}},
                        {fullname: "Occipital Fusiform", varname: {Left:"HO_Occipital_Fusiform_L",Right:"HO_Occipital_Fusiform_R"}},
                        {fullname: "Supracalcarine", varname: {Left:"HO_Supracalcarine_L",Right:"HO_Supracalcarine_R"}},
                    ]},
                    {atlas:"Harvard-Oxford", fullname: "Basal ganglia", varname: {Left:"HO_Basal_ganglia_L",Right:"HO_Basal_ganglia_R"}, 
                      subItem:[
                        {fullname: "Caudate", varname: {Left:"HO_Caudate_L",Right:"HO_Caudate_R"}},
                        {fullname: "Putamen", varname: {Left:"HO_Putamen_L",Right:"HO_Putamen_R"}},
                        {fullname: "Pallidum", varname: {Left:"HO_Pallidum_L",Right:"HO_Pallidum_R"}},
                        {fullname: "Thalamus", varname: {Left:"HO_Thalamus_L",Right:"HO_Thalamus_R"}},
                        {fullname: "Accumbens", varname: {Left:"HO_Accumbens_L",Right:"HO_Accumbens_R"}},
                    ]},
                    {atlas:"Harvard-Oxford", fullname: "Brainstem", varname: {Left:"HO_Brainstem_L",Right:"HO_Brainstem_R"}, 
                      subItem:[]},

                  ]
                },
                {
                  title: "Regional Centiloid", 
                  tableHead: ['Region', 'Total', 'Left', 'Right', 'Centiloid'], 
                  componentName: "Analysis_card2_2",
                  content:[
                    // {atlas:"AAL3", fullname: "Composite", varname: {Left:"Composite_C",Right:"Composite_C", Centiloid:true}, subItem:[]},
                    // {atlas:"AAL3", fullname: "Frontal", varname: {Left:"Frontal_L_C", Right:"Frontal_R_C", Centiloid:true}, 
                    //   subItem:[
                    //     {fullname:"Precentral", varname:{Region:"", Left:"Precentral_L_C", Right:"Precentral_R_C", Centiloid:true}},
                    //     {fullname:"Frontal Sup", varname:{Left:"Frontal_Sup_L_C", Right:"Frontal_Sup_R_C", Centiloid:true}},
                    //     {fullname:"Frontal Mid", varname:{Left:"Frontal_Mid_L_C", Right:"Frontal_Mid_R_C", Centiloid:true}},
                    //     {fullname:"Frontal Inf Oper", varname:{Left:"Frontal_Inf_Oper_L_C", Right:"Frontal_Inf_Oper_R_C", Centiloid:true}},
                    //     {fullname:"Frontal Inf Tri", varname:{Left:"Frontal_Inf_Tri_L_C", Right:"Frontal_Inf_Tri_R_C", Centiloid:true}},
                    //     {fullname:"Frontal Inf Orb", varname:{Left:"Frontal_Inf_Orb_L_C", Right:"Frontal_Inf_Orb_R_C", Centiloid:true}},
                    //     {fullname:"Rolandic Oper", varname:{Left:"Rolandic_Oper_L_C", Right:"Rolandic_Oper_R_C"}},
                    //     {fullname:"Supp Motor Area", varname:{Left:"Supp_Motor_Area_L_C", Right:"Supp_Motor_Area_R_C", Centiloid:true}},
                    //     {fullname:"Olfactory", varname:{Left:"Olfactory_L_C", Right:"Olfactory_R_C"}},
                    //     {fullname:"Frontal Sup Med", varname:{Left:"Frontal_Sup_Med_L_C", Right:"Frontal_Sup_Med_R_C", Centiloid:true}},
                    //     {fullname:"Frontal Med Orb", varname:{Left:"Frontal_Med_Orb_L_C", Right:"Frontal_Med_Orb_R_C", Centiloid:true}},
                    //     {fullname:"Rectus", varname:{Left:"Rectus_L_C", Right:"Rectus_R_C", Centiloid:true}},
                    //     {fullname:"OFCmed", varname:{Left:"OFCmed_L_C", Right:"OFCmed_R_C", Centiloid:true}},
                    //     {fullname:"OFCant", varname:{Left:"OFCant_L_C", Right:"OFCant_R_C", Centiloid:true}},
                    //     {fullname:"OFCpost", varname:{Left:"OFCpost_L_C", Right:"OFCpost_R_C"}},
                    //     {fullname:"OFClat", varname:{Left:"OFClat_L_C", Right:"OFClat_R_C"}},
                    //     {fullname:"Insula", varname:{Left:"Insula_L_C", Right:"Insula_R_C"}},
                    //     {fullname:"Cingulate Ant Sub", varname:{Left:"Cingulate_Ant_Sub_L_C", Right:"Cingulate_Ant_Sub_R_C", Centiloid:true}},
                    //     {fullname:"Cingulate Ant Pre", varname:{Left:"Cingulate_Ant_Pre_L_C", Right:"Cingulate_Ant_Pre_R_C", Centiloid:true}},
                    //     {fullname:"Cingulate Ant Sup", varname:{Left:"Cingulate_Ant_Sup_L_C", Right:"Cingulate_Ant_Sup_R_C", Centiloid:true}},
                    //     {fullname:"Cingulate Mid", varname:{Left:"Cingulate_Mid_L_C", Right:"Cingulate_Mid_R_C", Centiloid:true}}
                    //   ]},

                    // {atlas:"AAL3", fullname: "PCC-Precuneus", varname: {Left:"PCC_L_C", Right:"PCC_R_C", Centiloid:true}, 
                    //   subItem:[
                    //     {fullname:"Cingulate Post", varname:{Left:"Cingulate_Post_L_C", Right:"Cingulate_Post_R_C", Centiloid:true}},
                    //     {fullname:"Precuneus", varname:{Left:"Precuneus_L_C", Right:"Precuneus_R_C", Centiloid:true}}
                    //   ]},
                    // {atlas:"AAL3", fullname: "Lateral temporal", varname: {Left:"Lateral_temporal_L_C", Right:"Lateral_temporal_R_C", Centiloid:true}, 
                    //   subItem:[
                    //     {fullname:"Heschl", varname:{Left:"Heschl_L_C", Right:"Heschl_R_C", Centiloid:true}},
                    //     {fullname:"Temporal Sup", varname:{Left:"Temporal_Sup_L_C", Right:"Temporal_Sup_R_C", Centiloid:true}},
                    //     {fullname:"Temporal Pole Sup", varname:{Left:"Temporal_Pole_Sup_L_C", Right:"Temporal_Pole_Sup_R_C", Centiloid:true}},
                    //     {fullname:"Temporal Mid", varname:{Left:"Temporal_Mid_L_C", Right:"Temporal_Mid_R_C", Centiloid:true}},
                    //     {fullname:"Temporal Pole Mid", varname:{Left:"Temporal_Pole_Mid_L_C", Right:"Temporal_Pole_Mid_R_C", Centiloid:true}},
                    //     {fullname:"Temporal Inf", varname:{Left:"Temporal_Inf_L_C", Right:"Temporal_Inf_R_C", Centiloid:true}},
                    //   ]},
                    // {atlas:"AAL3", fullname: "Lateral parietal", varname: {Left:"Lateral_parietal_L_C", Right:"Lateral_parietal_R_C", Centiloid:true}, 
                    //   subItem:[
                    //     {fullname:"Postcentral", varname:{Left:"Postcentral_L_C", Right:"Postcentral_R_C", Centiloid:true}},
                    //     {fullname:"Parietal Sup", varname:{Left:"Parietal_Sup_L_C", Right:"Parietal_Sup_R_C", Centiloid:true}},
                    //     {fullname:"Parietal Inf", varname:{Left:"Parietal_Inf_L_C", Right:"Parietal_Inf_R_C", Centiloid:true}},
                    //     {fullname:"Supramarginal", varname:{Left:"Supramarginal_L_C", Right:"Supramarginal_R_C", Centiloid:true}},
                    //     {fullname:"Angular", varname:{Left:"Angular_L_C", Right:"Angular_R_C", Centiloid:true}},
                    //     {fullname:"Paracentral Lobule", varname:{Left:"Paracentral_Lobule_L_C", Right:"Paracentral_Lobule_R_C", Centiloid:true}},
                    //   ]},
                    
                    // {atlas:"AAL3", fullname: "Medial temporal", varname: {Left:"Medial_temporal_L_C", Right:"Medial_temporal_R_C", Centiloid:true}, 
                    //   subItem:[
                    //     {fullname: "Hippocampus", varname: {Left:"Hippocampus_L_C",Right:"Hippocampus_R_C", Centiloid:true}},
                    //     {fullname: "Parahippocampal", varname: {Left:"Parahippocampal_L_C",Right:"Parahippocampal_R_C", Centiloid:true}},
                    //     {fullname: "Amygdala", varname: {Left:"Amygdala_L_C",Right:"Amygdala_R_C", Centiloid:true}},
                    //   ]},
                    // {atlas:"AAL3", fullname: "Occipital", varname: {Left:"Occipital_L_C", Right:"Occipital_R_C", Centiloid:true}, 
                    //   subItem:[
                    //     {fullname: "Calcarine", varname: {Left:"Calcarine_L_C",Right:"Calcarine_R_C", Centiloid:true}},
                    //     {fullname: "Cuneus", varname: {Left:"Cuneus_L_C",Right:"Cuneus_R_C", Centiloid:true}},
                    //     {fullname: "Lingual", varname: {Left:"Lingual_L_C",Right:"Lingual_R_C", Centiloid:true}},
                    //     {fullname: "Occipital Sup", varname: {Left:"Occipital_Sup_L_C",Right:"Occipital_Sup_R_C", Centiloid:true}},
                    //     {fullname: "Occipital Mid", varname: {Left:"Occipital_Mid_L_C",Right:"Occipital_Mid_R_C", Centiloid:true}},
                    //     {fullname: "Occipital Inf", varname: {Left:"Occipital_Inf_L_C",Right:"Occipital_Inf_R_C", Centiloid:true}},
                    //     {fullname: "Fusiform", varname: {Left:"Fusiform_L_C",Right:"Fusiform_R_C", Centiloid:true}},
                    //   ]},
                    // {atlas:"AAL3", fullname: "Basal ganglia", varname: {Left:"Basal_ganglia_L_C", Right:"Basal_ganglia_R_C", Centiloid:true}, 
                    //   subItem:[
                    //     {fullname: "Caudate", varname: {Left:"Caudate_L_C",Right:"Caudate_R_C", Centiloid:true}},
                    //     {fullname: "Putamen", varname: {Left:"Putamen_L_C",Right:"Putamen_R_C", Centiloid:true}},
                    //     {fullname: "Pallidum", varname: {Left:"Pallidum_L_C",Right:"Pallidum_R_C", Centiloid:true}},
                    //     {fullname: "Thalamus", varname: {Left:"Thalamus_L_C",Right:"Thalamus_R_C", Centiloid:true}},
                    //     {fullname: "N Acc", varname: {Left:"N_Acc_L_C",Right:"N_Acc_R_C", Centiloid:true}},
                    //   ]},
                    // {atlas:"AAL3", fullname: "Brainstem", varname: {Left:"Brainstem_L_C", Right:"Brainstem_R_C", Centiloid:true}, 
                    //   subItem:[]},

                    // {atlas:"Harvard-Oxford", fullname: "Global", varname: {Left:"Composite_C", Right:"Composite_C", Centiloid:true}, 
                    // subItem:[]},

                    // {atlas:"Harvard-Oxford", fullname: "Frontal", varname: {Left:"HO_Frontal_L_C", Right:"HO_Frontal_R_C", Centiloid:true}, 
                    //   subItem:[
                    //     {fullname: "Frontal Pole", varname: {Left:"HO_Frontal_Pole_L_C",Right:"HO_Frontal_Pole_R_C", Centiloid:true}},
                    //     {fullname: "Insula", varname: {Left:"HO_Insula_L_C",Right:"HO_Insula_R_C"}},
                    //     {fullname: "Frontal Sup", varname: {Left:"HO_Frontal_Sup_L_C",Right:"HO_Frontal_Sup_R_C", Centiloid:true}},
                    //     {fullname: "Frontal Mid", varname: {Left:"HO_Frontal_Mid_L_C",Right:"HO_Frontal_Mid_R_C", Centiloid:true}},
                    //     {fullname: "Frontal Inf Tri", varname: {Left:"HO_Frontal_Inf_Tri_L_C",Right:"HO_Frontal_Inf_Tri_R_C", Centiloid:true}},
                    //     {fullname: "Frontal Inf Oper", varname: {Left:"HO_Frontal_Inf_Oper_L_C",Right:"HO_Frontal_Inf_Oper_R_C", Centiloid:true}},
                    //     {fullname: "Precentral", varname: {Left:"HO_Precentral_L_C",Right:"HO_Precentral_R_C", Centiloid:true}},
                    //     {fullname: "Frontal Med", varname: {Left:"HO_Frontal_Med_L_C",Right:"HO_Frontal_Med_R_C", Centiloid:true}},
                    //     {fullname: "Supp Motor Area", varname: {Left:"HO_Supp_Motor_Area_L_C",Right:"HO_Supp_Motor_Area_R_C", Centiloid:true}},
                    //     {fullname: "Cingulate Ant", varname: {Left:"HO_Cingulate_Ant_L_C",Right:"HO_Cingulate_Ant_R_C", Centiloid:true}},
                    //     {fullname: "Frontal Orb", varname: {Left:"HO_Frontal_Orb_L_C",Right:"HO_Frontal_Orb_R_C", Centiloid:true}},
                    //     {fullname: "Frontal Oper", varname: {Left:"HO_Frontal_Oper_L_C",Right:"HO_Frontal_Oper_R_C", Centiloid:true}},
                    //     {fullname: "Central Oper", varname: {Left:"HO_Central_Oper_L_C",Right:"HO_Central_Oper_R_C", Centiloid:true}},
                    //     {fullname: "Subcallosal", varname: {Left:"HO_Subcallosal_L_C",Right:"HO_Subcallosal_R_C", Centiloid:true}},
                    //     {fullname: "Paracingulate", varname: {Left:"HO_Paracingulate_L_C",Right:"HO_Paracingulate_R_C", Centiloid:true}},
                    // ]},
                    // {atlas:"Harvard-Oxford", fullname: "Retrosplenial", varname: {Left:"HO_PCC_L_C", Right:"HO_PCC_R_C", Centiloid:true}, 
                    //   subItem:[
                    //     {fullname: "Cingulate Post", varname: {Left:"HO_Cingulate_Post_L_C",Right:"HO_Cingulate_Post_R_C", Centiloid:true}},
                    //     {fullname: "Precuneus", varname: {Left:"HO_Precuneus_L_C",Right:"HO_Precuneus_R_C", Centiloid:true}},
                    // ]},
                    // {atlas:"Harvard-Oxford", fullname: "Lateral temporal", varname: {Left:"HO_Lateral_temporal_L_C", Right:"HO_Lateral_temporal_R_C", Centiloid:true}, 
                    //   subItem:[
                    //     {fullname: "Temporal Pole", varname: {Left:"HO_Temporal_Pole_L_C",Right:"HO_Temporal_Pole_R_C", Centiloid:true}},
                    //     {fullname: "Temporal Sup Ant", varname: {Left:"HO_Temporal_Sup_Ant_L_C",Right:"HO_Temporal_Sup_Ant_R_C", Centiloid:true}},
                    //     {fullname: "Temporal Sup Post", varname: {Left:"HO_Temporal_Sup_Post_L_C",Right:"HO_Temporal_Sup_Post_R_C", Centiloid:true}},
                    //     {fullname: "Temporal Mid Ant", varname: {Left:"HO_Temporal_Mid_Ant_L_C",Right:"HO_Temporal_Mid_Ant_R_C", Centiloid:true}},
                    //     {fullname: "Temporal Mid Post", varname: {Left:"HO_Temporal_Mid_Post_L_C",Right:"HO_Temporal_Mid_Post_R_C", Centiloid:true}},
                    //     {fullname: "Temporal Mid Temporooccipital", varname: {Left:"HO_Temporal_Mid_Temporooccipital_L_C",Right:"HO_Temporal_Mid_Temporooccipital_R_C", Centiloid:true}},
                    //     {fullname: "Temporal Inf Ant", varname: {Left:"HO_Temporal_Inf_Ant_L_C",Right:"HO_Temporal_Inf_Ant_R_C", Centiloid:true}},
                    //     {fullname: "Temporal Inf Post", varname: {Left:"HO_Temporal_Inf_Post_L_C",Right:"HO_Temporal_Inf_Post_R_C", Centiloid:true}},
                    //     {fullname: "Temporal Inf Temporooccipital", varname: {Left:"HO_Temporal_Inf_Temporooccipital_L_C",Right:"HO_Temporal_Inf_Temporooccipital_R_C", Centiloid:true}},
                    //     {fullname: "Planum Polare", varname: {Left:"HO_Planum_Polare_L_C",Right:"HO_Planum_Polare_R_C", Centiloid:true}},
                    //     {fullname: "Heschl", varname: {Left:"HO_Heschl_L_C",Right:"HO_Heschl_R_C", Centiloid:true}},
                    //     {fullname: "Planum Temporale", varname: {Left:"HO_Planum_Temporale_L_C",Right:"HO_Planum_Temporale_R_C", Centiloid:true}},
                    //     {fullname: "Temporal fusiform Ant", varname: {Left:"HO_Temporal_fusiform_Ant_L_C",Right:"HO_Temporal_fusiform_Ant_R_C", Centiloid:true}},
                    //     {fullname: "Temporal fusiform Post", varname: {Left:"HO_Temporal_fusiform_Post_L_C",Right:"HO_Temporal_fusiform_Post_R_C", Centiloid:true}},
                    //     {fullname: "Temporal Occipital Fusiform", varname: {Left:"HO_Temporal_Occipital_Fusiform_L_C",Right:"HO_Temporal_Occipital_Fusiform_R_C", Centiloid:true}},
                    // ]},
                    // {atlas:"Harvard-Oxford", fullname: "Lateral parietal", varname: {Left:"HO_Lateral_parietal_L_C", Right:"HO_Lateral_parietal_R_C", Centiloid:true}, 
                    //   subItem:[
                    //     {fullname: "Postcentral", varname: {Left:"HO_Postcentral_L_C",Right:"HO_Postcentral_R_C", Centiloid:true}},
                    //     {fullname: "Parietal Sup Lobule", varname: {Left:"HO_Parietal_Sup_Lobule_L_C",Right:"HO_Parietal_Sup_Lobule_R_C", Centiloid:true}},
                    //     {fullname: "Supramarginal Ant", varname: {Left:"HO_Supramarginal_Ant_L_C",Right:"HO_Supramarginal_Ant_R_C", Centiloid:true}},
                    //     {fullname: "Supramarginal Post", varname: {Left:"HO_Supramarginal_Post_L_C",Right:"HO_Supramarginal_Post_R_C", Centiloid:true}},
                    //     {fullname: "Angular", varname: {Left:"HO_Angular_L_C",Right:"HO_Angular_R_C"}},
                    //     {fullname: "Parietal Oper", varname: {Left:"HO_Parietal_Oper_L_C",Right:"HO_Parietal_Oper_R_C", Centiloid:true}},
                    // ]},
                    // {atlas:"Harvard-Oxford", fullname: "Medial temporal", varname: {Left:"HO_Frontal_L_C", Right:"HO_Frontal_R_C", Centiloid:true}, 
                    //   subItem:[
                    //     {fullname: "Parahippocampal Ant", varname: {Left:"HO_Parahippocampal_Ant_L_C",Right:"HO_Parahippocampal_Ant_R_C", Centiloid:true}},
                    //     {fullname: "Parahippocampal Post", varname: {Left:"HO_Parahippocampal_Post_L_C",Right:"HO_Parahippocampal_Post_R_C", Centiloid:true}},
                    //     {fullname: "Hippocampus", varname: {Left:"HO_Hippocampus_L_C",Right:"HO_Hippocampus_R_C", Centiloid:true}},
                    //     {fullname: "Amygdala", varname: {Left:"HO_Amygdala_L_C",Right:"HO_Amygdala_R_C", Centiloid:true}},
                    // ]},
                    // {atlas:"Harvard-Oxford", fullname: "Occipital", varname: {Left:"HO_Frontal_L_C", Right:"HO_Frontal_R_C", Centiloid:true}, 
                    //   subItem:[
                    //     {fullname: "Occipital Lat_Sup", varname: {Left:"HO_Occipital_Lat_Sup_L_C",Right:"HO_Occipital_Lat_Sup_R_C", Centiloid:true}},
                    //     {fullname: "Occipital Lat_Inf", varname: {Left:"HO_Occipital_Lat_Inf_L_C",Right:"HO_Occipital_Lat_Inf_R_C", Centiloid:true}},
                    //     {fullname: "Intracalcarine", varname: {Left:"HO_Intracalcarine_L_C",Right:"HO_Intracalcarine_R_C", Centiloid:true}},
                    //     {fullname: "Occipital Pole", varname: {Left:"HO_Occipital_Pole_L_C",Right:"HO_Occipital_Pole_R_C", Centiloid:true}},
                    //     {fullname: "Cuneal", varname: {Left:"HO_Cuneal_L_C",Right:"HO_Cuneal_R_C", Centiloid:true}},
                    //     {fullname: "Lingual", varname: {Left:"HO_Lingual_L_C",Right:"HO_Lingual_R_C", Centiloid:true}},
                    //     {fullname: "Occipital Fusiform", varname: {Left:"HO_Occipital_Fusiform_L_C",Right:"HO_Occipital_Fusiform_R_C", Centiloid:true}},
                    //     {fullname: "Supracalcarine", varname: {Left:"HO_Supracalcarine_L_C",Right:"HO_Supracalcarine_R_C", Centiloid:true}},
                    // ]},
                    // {atlas:"Harvard-Oxford", fullname: "Basal ganglia", varname: {Left:"HO_Frontal_L_C", Right:"HO_Frontal_R_C", Centiloid:true}, 
                    //   subItem:[
                    //     {fullname: "Caudate", varname: {Left:"HO_Caudate_L_C",Right:"HO_Caudate_R_C", Centiloid:true}},
                    //     {fullname: "Putamen", varname: {Left:"HO_Putamen_L_C",Right:"HO_Putamen_R_C", Centiloid:true}},
                    //     {fullname: "Pallidum", varname: {Left:"HO_Pallidum_L_C",Right:"HO_Pallidum_R_C", Centiloid:true}},
                    //     {fullname: "Thalamus", varname: {Left:"HO_Thalamus_L_C",Right:"HO_Thalamus_R_C", Centiloid:true}},
                    //     {fullname: "Accumbens", varname: {Left:"HO_Accumbens_L_C",Right:"HO_Accumbens_R_C", Centiloid:true}},
                    // ]},
                    // {atlas:"Harvard-Oxford", fullname: "Brainstem", varname: {Left:"HO_Frontal_L_C", Right:"HO_Frontal_R_C", Centiloid:true}, 
                    //   subItem:[]},
                  ]
                }
              ]
            },

            { 
              cards:[
                {
                  title: "Surface Projection",
                  componentName: "Analysis_card3",
                  content:["surface image1", "surface image2", "surface image3", "surface image4"]
                },
              ]
            },

          ]
        },
        report:{
          page1:{

          },
          page2:{
            table1:{
              title: "Regional SUVR", 
              tableHead: ['Region', 'Total', 'Left', 'Right'], 
            }
          }
        },
        setting:{
          top:{
            btnGroup1:[
              {title:'Reset', icon:<MdRefresh />, funcName:'reset'},
              {title:'Save', icon:<MdSave />, funcName:'save'},
            ],
          },
          bottom:[
            {
              styles:{width:"65%", height:"98%"}, 
              cards:[
                {
                  title: "", 
                  componentName: "MainConfig",
                  contents:[
                    {
                      subTitle: "Brain Atlas", 
                      atlasList: ["AAL3","Harvard-Oxford"],
                      subContent:[
                        {default:"AAL3",options:["AAL3","Harvard-Oxford"],}
                      ]
                    }, 
                    {
                      subTitle: "Reference Region", 
                      refList: ["Pons", "Cerebellar gray matter", "Cerebral white matter", "Whole cerebellum"],
                      subContent:[
                        // TODO: default: (ref) 정보는 사용되지 않아 삭제함, defaultRef 라는 변수로 제품마다 상위 변수로 export함
                        { tracer: "[18F]FMM", fullname:"[18F]Flutemetamol", },
                        { tracer: "[18F]FBP", fullname:"[18F]Florbetapir",  },
                        { tracer: "[18F]FBB", fullname:"[18F]Florbetaben",  },
                        { tracer: "[18F]FPN", fullname:"[18F]Florapronol",  },
                      ]
                    }, 
                    {
                      subTitle: "Brain Regions To Analyze", 
                      subContent:[
                        {
                          atlas:"AAL3",
                          region: "Global", 
                          subRegion:[

                          ] 
                        },
                        {
                          atlas:"AAL3",
                          region: "Frontal", 
                          subRegion:[
                            "Precentral", "Frontal Sup", "Frontal Mid", "Frontal Inf Oper", "Frontal InfTri", "Frontal Inf Orb", "Rolandic Oper", 
                            "Supp Motor Area", "Olfactory", "Frontal Sup Med", "Frontal Med Orb", "Rectus", "OFCmed", "OFCant", "OFCpost", "OFClat",
                            "Insula", "Cingulate Ant Sub", "Cingulate Ant Pre", "Cingulate Ant Sup", "Cingulate Mid"
                          ] 
                        },
                        {
                          atlas:"AAL3",
                          region: "PCC-Precuneus", 
                          subRegion:[
                            "Cingulate Post", "Precuneus"
                          ] 
                        },
                        {
                          atlas:"AAL3",
                          region: "Lateral temporal", 
                          subRegion:[
                            "Heschl", "Temporal Sup", "Temporal Pole Sup", "Temporal Mid", "Temporal Pole Mid", "Temporal Inf"
                          ] 
                        },
                        {
                          atlas:"AAL3",
                          region: "Lateral parietal", 
                          subRegion:[
                            "Postcentral", "Parietal Sup", "Parietal Inf", "Supramarginal", "Angular", "Paracentral Lobule"
                          ] 
                        },
                        {
                          atlas:"AAL3",
                          region: "Medial temporal", 
                          subRegion:[
                            "Hippocampus", "Parahippocampal", "Amygdala"
                          ] 
                        },
                        {
                          atlas:"AAL3",
                          region: "Occipital", 
                          subRegion:[
                            "Calcarine", "Cuneus", "Lingual", "Occipital Sup", "Occipital Mid", "Occipital Inf", "Fusiform"
                          ] 
                        },
                        {
                          atlas:"AAL3",
                          region: "Basal ganglia", 
                          subRegion:[
                            "Caudate", "Putamen", "Pallidum", "Thalamus", "N Acc"
                          ] 
                        },
                        {
                          atlas:"AAL3",
                          region: "Brainstem", 
                          subRegion:[
                            
                          ] 
                        },
                        {
                          atlas:"Harvard-Oxford",
                          region: "Global", 
                          subRegion:[
                            
                          ] 
                        },
                        {
                          atlas:"Harvard-Oxford",
                          region: "Frontal", 
                          subRegion:[
                            "Frontal Pole", "Insula", "Frontal Sup", "Frontal Mid", "Frontal Inf Tri", "Frontal Inf Oper", "Precentral", "Frontal Med", 
                            "Supp Motor Area", "Cingulate Ant", "Frontal Orb", "Frontal Oper", "Central Oper", "Subcallosal", "Paracingulate"
                          ] 
                        },
                        {
                          atlas:"Harvard-Oxford",
                          region: "Retrosplenial", 
                          subRegion:[
                            "Cingulate Post", "Precuneus"
                          ] 
                        },
                        {
                          atlas:"Harvard-Oxford",
                          region: "Lateral temporal", 
                          subRegion:[
                            "Temporal Pole", "Temporal Sup Ant", "Temporal Sup Post", "Temporal Mid Ant", "Temporal Mid Post", "Temporal Mid Temporooccipital",
                            "Temporal Inf Ant", "Temporal Inf Post", "Temporal Inf Temporooccipital", "Planum Polare", "Heschl", "Planum Temporale", "Temporal fusiform Ant",
                            "Temporal fusiform Post", "Temporal Occipital Fusiform"
                          ] 
                        },
                        {
                          atlas:"Harvard-Oxford",
                          region: "Lateral parietal", 
                          subRegion:[
                            "Postcentral", "Parietal Sup Lobule", "Supramarginal Ant", "Supramarginal Post", "Angular", "Parietal Oper"
                          ] 
                        },
                        {
                          atlas:"Harvard-Oxford",
                          region: "Medial temporal", 
                          subRegion:[
                            "Parahippocampal Ant", "Parahippocampal Post", "Hippocampus", "Amygdala"
                          ] 
                        },
                        {
                          atlas:"Harvard-Oxford",
                          region: "Occipital lobe", 
                          subRegion:[
                            "Occipital Lat Sup", "Occipital Lat Inf", "Intracalcarine", "Occipital Pole", "Cuneal", "Lingual", "Occipital Fusiform", "Supracalcarine"
                          ] 
                        },
                        {
                          atlas:"Harvard-Oxford",
                          region: "Basal ganglia", 
                          subRegion:[
                            "Caudate", "Putamen", "Pallidum", "Thalamus", "Accumbens"
                          ] 
                        },
                        {
                          atlas:"Harvard-Oxford",
                          region: "Brainstem", 
                          subRegion:[
                            
                          ] 
                        },
                        
                      ]
                    },
                    {
                      subTitle: `Default ${productName} Tracer`, 
                      componentName: "DefaultTracer",
                    },
                  ],
                },
              ]
            },
            {
              styles:{width:"35%", height:"98%"}, 
              cards:[
                {
                  title: "CSV Formats",
                  componentName: "CSVFormat",
                },
                {
                  title: "Report Formats",
                  componentName: "ReportFormats",
                },
              ]
            },
          ]
        },
      }
    })(),

    dat: (()=> {
      const productName = 'DAT';
      const productShortName = 'Dopamine';
      const productFullName = 'Dopamine';
      const type1Selector = QuantTable_type1Selector['dat'];
      const type2Selector = QuantTable_type2Selector['dat'];
      const tracers = [
        {fullname:'[18F]FP-CIT', shortname:"[18F]FPCIT", color:"#F14CFF", commercialname:"PDVUE" },
      ];
      const defaultCSVFormat =[
          {title:"Include data",   state:true, subOption:[{title:"Total", state:true}, {title:"Left & Right", state:true}]},
          {title:"SUVR",           state:true, subOption:[                                                               ]},
          {title:"Centiloid",      state:true, subOption:[                                                               ]},
          {title:"Transpose data", state:true, subOption:[                                                               ]},
      ]
      const defaultAtlas = "Melbourne Subcortical";
      const defaultRef = {
        "[18F]FPCIT": "Occipital cortex",
      }
      const defaultTracer = tracers[0];
      const defaultPInfoState = [
        {name:"Patient Name", state: true},
        {name:"Patient ID", state:true},
        {name:"DOB", state:true},
        {name:"Sex", state:true},
        {name:"Type & Date of Examination", state:true},
      ];
      const COLORMAP = [
        'invertedGray',
        'pet',
        'jet',
        'hot',
        'gray',
      ];
      return {
        CTN_Mode,
        Cloud_Mode,
        imageVerticalFlip: IMAGE_VERTICAL_FLIP,
        Date7:Date7,
        productName: productName,
        productShortName:productShortName,
        productFullName:productFullName,
        tracers:tracers,
        defaultCSVFormat:defaultCSVFormat,
        defaultAtlas:defaultAtlas,
        defaultRef:defaultRef,
        defaultTracer:defaultTracer,
        defaultPInfoState:defaultPInfoState,
        type1Selector:type1Selector,
        type2Selector:type2Selector,
        COLORMAP:COLORMAP,
        worklist:{
          top:{
            item1:[
              // {title:"Export all to PDF Fast", var:"downloadPDFFast"},
              {title:"Export all to PDF", var:"downloadPDF"},
              // {title:"Export all to JPEG", var:"downloadJPEG"},
              {title:"Export all to CSV", var:"downloadCSV_Group"},
              // {title:"Export all to Nifti", var:"downloadNifti_Group"},
              {title:"Export all to NIFTI (SBR)", var:"downloadNifitiSUVR_Group"},
              {title:"Export all to NIFTI (Raw)", var:"downloadNifitiIntensity_Group"},
            ]
          }
        },
        dashboard:{
          bottom:[
            {
              cards:[
                {title: "Weekly Analysis", Date:`${Date7[6]} - ${Date7[0]}`, componentName: "BarGraph"},
              ],
            },
            {
              cards:[
                {title: `${productName} Tracers`, componentName: "PieGraph"}
              ]
            },
          ]
        },
        upload:{
          top:{
            btnGroup1:[
              {title:'View', icon:<img src={MEDIA_view} alt='view'/>, funcName:"view"},
              {title:'Analysis', icon:<img src={MEDIA_analysis}  alt='analysis'/>, funcName:"analysis"},
              {title:'Close', icon:<img src={MEDIA_close}  alt='close'/>, funcName:"close"},
            ],
            btnGroup2:[
              {title:'Worklist', icon:<img src={MEDIA_plus} alt='worklist add'/>, funcName:"worklist_add"},
              {title:'Worklist', icon:<img src={MEDIA_minus} alt='worklist delete'/>, funcName:"worklist_delete"},
            ],
            btnGroup3:[
              {title:'Delete', icon:<img src={MEDIA_trash} alt='file delete'/>, funcName:"file_delete"},
            ],
            btnGroup4:[
              {title:'Connect to PACS', icon:"", funcName:"pacs"},
              {title:'Upload', icon:"", funcName:"upload"},
            ],
          },
          bottom:{
            tableHead:[
              {title: 'Select', fieldName:'Select'},
              {title: 'Tracer', fieldName:'Tracer'},
              {title: 'SBR', fieldName:'DAT_Dorsal_striatum'},
              {title: 'Patient name', fieldName:'PatientName'},
              {title: 'Patient ID', fieldName:'PatientID'},
              {title: 'DOB', fieldName:'Age'},
              {title: 'Sex', fieldName:'Sex'},
              {title: 'Scan date', fieldName:'AcquisitionDateTime'},
              {title: 'Uploaded date', fieldName:'Update'},
            ],
            uploadingTableHead2:[
              {title: 'Select', fieldName:'Select'},
              // {title: 'Marker', fieldName:'Marker'},
              {title: 'Patient ID', fieldName:'PatientID'},
              {title: 'Patient name', fieldName:'PatientName'},
              {title: 'DOB', fieldName:'BirthDate'},
              {title: 'Modality', fieldName: 'Modality'},
              {title: 'Study Date', fieldName: 'StudyDate'},
              {title: 'Study Description', fieldName: 'StudyDescription'},
              {title: 'Delete', fieldName: 'Delete'},
            ],
            uploadingTableHead3:[
              {title: 'Patient ID', fieldName:'PatientID'},
              {title: 'Patient name', fieldName:'PatientName'},
              {title: 'Series Description', fieldName: 'SeriesDescription'},
              {title: 'Delete', fieldName: 'Delete'},
            ],
            uploadingTableHead3Direct:[
              {title: 'Tracer', fieldName:'Tracer'},
              {title: 'Patient ID', fieldName:'PatientID'},
              {title: 'Patient name', fieldName:'PatientName'},
              // {title: 'Series Description', fieldName: 'SeriesDescription'},
              {title: 'Delete', fieldName: 'Delete'},
            ],
          },
        },
        view:{
          top:{
            item1:[
              {title:"Tracer", var:"Tracer"},
              {title:"Patient name", var:"PatientName" },
              {title:"Patient ID", var:"PatientID"},
              {title:"DOB", var:"Age"},
              {title:"Sex", var:"Sex"},
              {title:"Study Date", var:"AcquisitionDateTime"},
              {title:"Desc", var:"OriginalFileName"}
            ],
            item2:[
              {title:"Atlas", var:"defaultAtlas"},
              {title:"Reference", var:"defaultRef"},
            ],
            item3:[
              {title:"Generate a report", var:"openReport"},
              {title:"Export to CSV", var:"downloadCSV_Individual"},
              {title:"Export to NIFTI (SBR)", var:"downloadNifitiSUVR_Individaul"},
              {title:"Export to NIFTI (Raw)", var:"downloadNifitiIntensity_Individaul"},
            ]
          },
        },
        analysis:{
          top:{
            item1:[
              {title:"Tracer", var:"Tracer"},
              {title:"Patient name", var:"PatientName" },
              {title:"Patient ID", var:"PatientID"},
              {title:"DOB", var:"Age"},
              {title:"Sex", var:"Sex"},
              {title:"Study Date", var:"AcquisitionDateTime"},
              {title:"Desc", var:"OriginalFileName"}
            ],
            item2:[
              {title:"Atlas", var:"defaultAtlas"},
              {title:"Reference", var:"defaultRef"},
            ],
            item3:[
              {title:"Generate a report", var:"openReport"},
              {title:"Export to CSV", var:"downloadCSV_Individual"},
              {title:"Export to NIFTI (SBR)", var:"downloadNifitiSUVR_Individaul"},
              {title:"Export to NIFTI (Raw)", var:"downloadNifitiIntensity_Individaul"},
            ]
          },
          bottom:[
            {
              cards:[
                {
                  title: "Striatal SBR",
                  tableHead: ['image', 'value'], 
                  colorcode:datCmap,
                  legend:'BR',
                  componentName: "Analysis_card1",
                  content:[
                    {atlas:"Melbourne Subcortical", lobe:"Dorsal striatum",   Left:"L",      Right:"R",      Lvar:"DAT_Dorsal_striatum_L",                                   Rvar:"DAT_Dorsal_striatum_R",                                   BrainSVG:({Lobe1, Lobe2, Lobe3, Lobe4})=>{return <div style={{width:"70%"}}><StriatumLobe fillCaudateA={datCmap[Lobe1]}   fillCaudateP={datCmap[Lobe2]} fillPutamenA={datCmap[Lobe3]} fillPutamenP={datCmap[Lobe4]} /></div>}}, 
                    {atlas:"Melbourne Subcortical", lobe:"Caudate",           Left:"L",      Right:"R",      Lvar:"DAT_Caudate_nucleus_L",                                   Rvar:"DAT_Caudate_nucleus_R",                                   BrainSVG:({Lobe1, Lobe2, Lobe3, Lobe4})=>{return <div style={{width:"70%"}}><StriatumLobe fillCaudateA={datCmap[Lobe1]}   fillCaudateP={datCmap[Lobe2]} fillPutamenA={"gray"}         fillPutamenP={"gray"}         /></div>}}, 
                    {atlas:"Melbourne Subcortical", lobe:"Putamen A",         Left:"L",      Right:"R",      Lvar:["DAT_Dorsoant_putamen_L", "DAT_Ventroant_putamen_L"],     Rvar:["DAT_Dorsoant_putamen_R", "DAT_Ventroant_putamen_R"],     BrainSVG:({Lobe1, Lobe2, Lobe3, Lobe4})=>{return <div style={{width:"70%"}}><StriatumLobe fillCaudateA={"gray"}           fillCaudateP={"gray"}         fillPutamenA={datCmap[Lobe3]} fillPutamenP={"gray"}         /></div>}}, 
                    {atlas:"Melbourne Subcortical", lobe:"Putamen P",         Left:"L",      Right:"R",      Lvar:["DAT_Dorsopost_putamen_L", "DAT_Ventropost_Putamen_L"],   Rvar:["DAT_Dorsopost_putamen_R", "DAT_Ventropost_Putamen_R"],   BrainSVG:({Lobe1, Lobe2, Lobe3, Lobe4})=>{return <div style={{width:"70%"}}><StriatumLobe fillCaudateA={"gray"}           fillCaudateP={"gray"}         fillPutamenA={"gray"}         fillPutamenP={datCmap[Lobe4]} /></div>}}, 
                    
                    {atlas:"AAL3",                  lobe:"Striatum",   Left:"L",      Right:"R",      Lvar:["DAT_AAL3_Caudate_L", "DAT_AAL3_Putamen_L"],                Rvar:["DAT_AAL3_Caudate_R", "DAT_AAL3_Putamen_R"],              BrainSVG:({Lobe1, Lobe2, Lobe3, Lobe4})=>{return <div style={{width:"70%"}}><StriatumLobe fillCaudateA={datCmap[Lobe1]}   fillCaudateP={datCmap[Lobe2]} fillPutamenA={datCmap[Lobe3]} fillPutamenP={datCmap[Lobe4]} /></div>}}, 
                    {atlas:"AAL3",                  lobe:"Caudate",           Left:"L",      Right:"R",      Lvar:"DAT_AAL3_Caudate_L",                                        Rvar:"DAT_AAL3_Caudate_R",                                      BrainSVG:({Lobe1, Lobe2, Lobe3, Lobe4})=>{return <div style={{width:"70%"}}><StriatumLobe fillCaudateA={datCmap[Lobe1]}   fillCaudateP={datCmap[Lobe2]} fillPutamenA={"gray"}         fillPutamenP={"gray"}         /></div>}}, 
                    {atlas:"AAL3",                  lobe:"Putamen",           Left:"L",      Right:"R",    Lvar:"DAT_AAL3_Putamen_L",                                        Rvar:"DAT_AAL3_Putamen_R",                                      BrainSVG:({Lobe1, Lobe2, Lobe3, Lobe4})=>{return <div style={{width:"70%"}}><StriatumLobe fillCaudateA={"gray"}           fillCaudateP={"gray"}         fillPutamenA={datCmap[Lobe3]} fillPutamenP={datCmap[Lobe4]} /></div>}}, 
                    // {atlas:"AAL3",                  lobe:"Putamen",           Left:"L",      Right:"R",    Lvar:"DAT_AAL3_Putamen_L",                                        Rvar:"DAT_AAL3_Putamen_R",                                      BrainSVG:({Lobe1, Lobe2, Lobe3, Lobe4})=>{return <div style={{width:"70%"}}><StriatumLobe fillCaudateA={"gray"}           fillCaudateP={"gray"}         fillPutamenA={datCmap[Lobe3]} fillPutamenP={datCmap[Lobe4]} /></div>}}, 
                                                                                                                                                                             
                    {atlas:"Harvard-Oxford",        lobe:"Striatum",   Left:"L",      Right:"R",      Lvar:["DAT_HO_Caudate_L", "DAT_HO_Putamen_L"],                    Rvar:["DAT_HO_Caudate_R", "DAT_HO_Putamen_R"],                  BrainSVG:({Lobe1, Lobe2, Lobe3, Lobe4})=>{return <div style={{width:"70%"}}><StriatumLobe fillCaudateA={datCmap[Lobe1]}   fillCaudateP={datCmap[Lobe2]} fillPutamenA={datCmap[Lobe3]} fillPutamenP={datCmap[Lobe4]} /></div>}}, 
                    {atlas:"Harvard-Oxford",        lobe:"Caudate",           Left:"L",      Right:"R",      Lvar:"DAT_HO_Caudate_L",                                          Rvar:"DAT_HO_Caudate_R",                                        BrainSVG:({Lobe1, Lobe2, Lobe3, Lobe4})=>{return <div style={{width:"70%"}}><StriatumLobe fillCaudateA={datCmap[Lobe1]}   fillCaudateP={datCmap[Lobe2]} fillPutamenA={"gray"}         fillPutamenP={"gray"}         /></div>}}, 
                    {atlas:"Harvard-Oxford",        lobe:"Putamen",           Left:"L",      Right:"R",      Lvar:"DAT_HO_Putamen_L",                                            Rvar:"DAT_HO_Putamen_R",                                        BrainSVG:({Lobe1, Lobe2, Lobe3, Lobe4})=>{return <div style={{width:"70%"}}><StriatumLobe fillCaudateA={"gray"}           fillCaudateP={"gray"}         fillPutamenA={datCmap[Lobe3]} fillPutamenP={datCmap[Lobe4]} /></div>}}, 
                    // {atlas:"Harvard-Oxford",        lobe:"Putamen P",       Left:"L",      Right:"R",      Lvar:"DAT_HO_Putamen_L",                                        Rvar:"DAT_HO_Putamen_R",                                        BrainSVG:({Lobe1, Lobe2, Lobe3, Lobe4})=>{return <div style={{width:"70%"}}><StriatumLobe fillCaudateA={"gray"}           fillCaudateP={"gray"}         fillPutamenA={datCmap[Lobe3]} fillPutamenP={datCmap[Lobe4]} /></div>}}, 
                  ]
                },
              ]
            },
            {
              cards:[
                {
                  title: "Regional SBR", 
                  tableHead: ['Region', 'Total', 'Left', 'Right'], 
                  componentName: "Analysis_card2_1",
                  content:[
                    // {atlas:"Melbourne Subcortical", fullname: "Dorsal striatum", varname: {Left:"DAT_Dorsal_striatum_L", Right:"DAT_Dorsal_striatum_R"}, 
                    //   subItem:[]},
                    // {atlas:"Melbourne Subcortical", fullname: "Caudate nucleus", varname: {Left:"DAT_Caudate_nucleus_L", Right:"DAT_Caudate_nucleus_R"}, 
                    //   subItem:[
                    //     {fullname:"Caudate DA",   varname:{Left:"DAT_Dorsoant_caudate_L",  Right:"DAT_Dorsoant_caudate_R"}},
                    //     {fullname:"Caudate VA",   varname:{Left:"DAT_Ventroant_caudate_L", Right:"DAT_Ventroant_caudate_R"}},
                    //     {fullname:"Caudate body", varname:{Left:"DAT_Caudate_body_L",      Right:"DAT_Caudate_body_R"}},
                    //     {fullname:"Caudate tail", varname:{Left:"DAT_Caudate_tail_L",      Right:"DAT_Caudate_tail_R"}},
                    //   ]},
                    // {atlas:"Melbourne Subcortical", fullname: "Putamen", varname: {Left:"DAT_Putamen_L", Right:"DAT_Putamen_R"}, 
                    //   subItem:[
                    //     {fullname:"Putamen DA", varname:{Left:"DAT_Dorsoant_putamen_L", Right:"DAT_Dorsoant_putamen_R"}},
                    //     {fullname:"Putamen VA", varname:{Left:"DAT_Ventroant_putamen_L", Right:"DAT_Ventroant_putamen_R"}},
                    //     {fullname:"Putamen DP", varname:{Left:"DAT_Dorsopost_putamen_L", Right:"DAT_Dorsopost_putamen_R"}},
                    //     {fullname:"Putamen VP", varname:{Left:"DAT_Ventropost_Putamen_L", Right:"DAT_Ventropost_Putamen_R"}}
                    //   ]},
                    // {atlas:"Melbourne Subcortical", fullname: "Pallidum", varname: {Left:"DAT_Pallidum_L", Right:"DAT_Pallidum_R"}, 
                    //   subItem:[
                    //     {fullname:"Ant Pallidum", varname:{Left:"DAT_Ant_pallidum_L", Right:"DAT_Ant_pallidum_R"}},
                    //     {fullname:"Post Pallidum", varname:{Left:"DAT_Post_pallidum_L", Right:"DAT_Post_pallidum_R"}}
                    //   ]},
                    // {atlas:"Melbourne Subcortical", fullname: "Ventral striatum", varname: {Left:"DAT_Ventral_striatum_L", Right:"DAT_Ventral_striatum_R"}, 
                    //   subItem:[]},
                      
                    // {atlas:"AAL3", fullname: "Caudate", varname: {Left:"DAT_AAL3_Caudate_L", Right:"DAT_AAL3_Caudate_R"}, 
                    //     subItem:[]},
                    // {atlas:"AAL3", fullname: "Putamen", varname: {Left:"DAT_AAL3_Putamen_L", Right:"DAT_AAL3_Putamen_R"}, 
                    //     subItem:[]},
                    // {atlas:"AAL3", fullname: "Pallidum", varname: {Left:"DAT_AAL3_Pallidum_L", Right:"DAT_AAL3_Pallidum_R"}, 
                    //     subItem:[]},

                    // {atlas:"Harvard-Oxford", fullname: "Caudate", varname: {Left:"DAT_HO_Caudate_L", Right:"DAT_HO_Caudate_R"}, 
                    //     subItem:[]},
                    // {atlas:"Harvard-Oxford", fullname: "Putamen", varname: {Left:"DAT_HO_Putamen_L", Right:"DAT_HO_Putamen_R"}, 
                    //     subItem:[]},
                    // {atlas:"Harvard-Oxford", fullname: "Pallidum", varname: {Left:"DAT_HO_Pallidum_L", Right:"DAT_HO_Pallidum_R"}, 
                    //     subItem:[]},
                  ]
                },

                {
                  title: "Semiquantification", 
                  tableHead: ['Measure', 'Total', 'Left', 'Right'], 
                  componentName: "Analysis_card2_2",
                  content:[
                    // {atlas:"Melbourne Subcortical", fullname: "Striatal asymmetry index", varname: {Left:"DAT_Striatal_asymmetry_index", Right:"DAT_Striatal_asymmetry_index"}, 
                    //   subItem:[]},
                    // {atlas:"Melbourne Subcortical", fullname: "Caudate asymmetry index", varname: {Left:"DAT_Caudate_asymmetry_index", Right:"DAT_Caudate_asymmetry_index"}, 
                    //   subItem:[]},
                    // {atlas:"Melbourne Subcortical", fullname: "Putamen asymmetry index", varname: {Left:"DAT_Putamen_asymmetry_index", Right:"DAT_Putamen_asymmetry_index"}, 
                    //   subItem:[]},
                    // {atlas:"Melbourne Subcortical", fullname: "Putamen-caudate ratio", varname: {Left:"DAT_Putamen_caudate_ratio_L", Right:"DAT_Putamen_caudate_ratio_R"}, 
                    //   subItem:[]},
                    // {atlas:"Melbourne Subcortical", fullname: "Caudate-putamen ratio", varname: {Left:"DAT_Caudate_putamen_ratio_L", Right:"DAT_Caudate_putamen_ratio_R"}, 
                    //   subItem:[]},
                    // {atlas:"Melbourne Subcortical", fullname: "Anterior-posterior putamen ratio", varname: {Left:"DAT_Anterior_posterior_putamen_ratio_L", Right:"DAT_Anterior_posterior_putamen_ratio_R"}, 
                    //   subItem:[]},

                    // {atlas:"AAL3", fullname: "Striatal asymmetry index", varname: {Left:"DAT_Striatal_asymmetry_index", Right:"DAT_Striatal_asymmetry_index"}, 
                    //   subItem:[]},
                    // {atlas:"AAL3", fullname: "Caudate asymmetry index", varname: {Left:"DAT_Caudate_asymmetry_index", Right:"DAT_Caudate_asymmetry_index"}, 
                    //   subItem:[]},
                    // {atlas:"AAL3", fullname: "Putamen asymmetry index", varname: {Left:"DAT_Putamen_asymmetry_index", Right:"DAT_Putamen_asymmetry_index"}, 
                    //   subItem:[]},
                    // {atlas:"AAL3", fullname: "Putamen-caudate ratio", varname: {Left:"DAT_Putamen_caudate_ratio_L", Right:"DAT_Putamen_caudate_ratio_R"}, 
                    //   subItem:[]},
                    // {atlas:"AAL3", fullname: "Caudate-putamen ratio", varname: {Left:"DAT_Caudate_putamen_ratio_L", Right:"DAT_Caudate_putamen_ratio_R"}, 
                    //   subItem:[]},
                    // {atlas:"AAL3", fullname: "Anterior-posterior putamen ratio", varname: {Left:"DAT_Anterior_posterior_putamen_ratio_L", Right:"DAT_Anterior_posterior_putamen_ratio_R"}, 
                    //   subItem:[]},
                      
                    // {atlas:"Harvard-Oxford", fullname: "Striatal asymmetry index", varname: {Left:"DAT_Striatal_asymmetry_index", Right:"DAT_Striatal_asymmetry_index"}, 
                    //   subItem:[]},
                    // {atlas:"Harvard-Oxford", fullname: "Caudate asymmetry index", varname: {Left:"DAT_Caudate_asymmetry_index", Right:"DAT_Caudate_asymmetry_index"}, 
                    //   subItem:[]},
                    // {atlas:"Harvard-Oxford", fullname: "Putamen asymmetry index", varname: {Left:"DAT_Putamen_asymmetry_index", Right:"DAT_Putamen_asymmetry_index"}, 
                    //   subItem:[]},
                    // {atlas:"Harvard-Oxford", fullname: "Putamen-caudate ratio", varname: {Left:"DAT_Putamen_caudate_ratio_L", Right:"DAT_Putamen_caudate_ratio_R"}, 
                    //   subItem:[]},
                    // {atlas:"Harvard-Oxford", fullname: "Caudate-putamen ratio", varname: {Left:"DAT_Caudate_putamen_ratio_L", Right:"DAT_Caudate_putamen_ratio_R"}, 
                    //   subItem:[]},
                    // {atlas:"Harvard-Oxford", fullname: "Anterior-posterior putamen ratio", varname: {Left:"DAT_Anterior_posterior_putamen_ratio_L", Right:"DAT_Anterior_posterior_putamen_ratio_R"}, 
                    //   subItem:[]},
                  ]
                },
              ]
            },
          ]
        },
        setting:{
          top:{
            btnGroup1:[
              {title:'Reset', icon:<MdRefresh/>, funcName:'reset'},
              {title:'Save', icon:<MdSave/>, funcName:'save'},
            ],
          },
          bottom:[
            {
              styles:{width:"65%", height:"98%"}, 
              cards:[
                {
                  title: "", 
                  componentName: "MainConfig",
                  contents:[
                    {
                      subTitle: "Brain Atlas", 
                      atlasList: [ "Melbourne Subcortical", "AAL3", "Harvard-Oxford", ],
                      subContent:[
                        { default:"Melbourne Subcortical", options:[ "Melbourne Subcortical", "AAL3", "Harvard-Oxford" ]}
                      ]
                    }, 
                    {
                      subTitle: "Reference Region", 
                      refList: ["Pons", "Cerebellar gray matter", "Cerebral white matter", "Whole cerebellum", "Occipital cortex"],
                      subContent:[
                        { tracer: "[18F]FPCIT", fullname:"[18F]FP-CIT", default:"Pons", },
                      ]
                    }, 
                    {
                      subTitle: "Brain Regions To Analyze", 
                      subContent:[
                        {
                          atlas:"Melbourne Subcortical",
                          region: "Caudate nucleus", 
                          subRegion:[
                            "Dorsoant Caudate", "Ventroant Caudate", "Caudate Tail", "Caudate Body"
                          ] 
                        },
                        {
                          atlas:"Melbourne Subcortical",
                          region: "Putamen", 
                          subRegion:[
                            "Dorsoant","Ventroant", "Dorsopost", "Ventropost"
                          ] 
                        },
                        {
                          atlas:"Melbourne Subcortical",
                          region: "Pallidum", 
                          subRegion:[
                            "Ant", "Post"
                          ] 
                        },
                        {
                          atlas:"Melbourne Subcortical",
                          region: "Ventral striatum", 
                          subRegion:[

                          ] 
                        },
                        {
                          atlas:"AAL3",
                          region: "Caudate", 
                          subRegion:[

                          ] 
                        },
                        {
                          atlas:"AAL3",
                          region: "Putamen", 
                          subRegion:[

                          ] 
                        },
                        {
                          atlas:"AAL3",
                          region: "Pallidum", 
                          subRegion:[

                          ] 
                        },
                        {
                          atlas:"Harvard-Oxford",
                          region: "Caudate", 
                          subRegion:[

                          ] 
                        },
                        {
                          atlas:"Harvard-Oxford",
                          region: "Putamen", 
                          subRegion:[

                          ] 
                        },
                        {
                          atlas:"Harvard-Oxford",
                          region: "Pallidum", 
                          subRegion:[

                          ] 
                        },
                        
                      ]
                    },
                    {
                      subTitle: `Default ${productName} Tracer`, 
                      componentName: "DefaultTracer",
                    },
                  ],
                },
              ]
            },
            {
              styles:{width:"35%", height:"98%"}, 
              cards:[
                {
                  title: "CSV Formats",
                  componentName: "CSVFormat",
                },
                {
                  title: "Report Formats", 
                  componentName: "ReportFormats",
                },
              ]
            },
          ]
        },
      }
    })(),

    fdg: (()=> {
      const productName = 'FDG';
      const productShortName = 'FDG';
      const productFullName = 'FDG';
      const type1Selector = QuantTable_type1Selector['fdg'];
      const type2Selector = QuantTable_type2Selector['fdg']; 
      const tracers = [
        {fullname:'[18F]FDG', shortname:"[18F]FDG", color:"#4CFF69"},
      ];
      const defaultCSVFormat =[
          {title:"Include data",   state:true, subOption:[{title:"Total", state:true}, {title:"Left & Right", state:true}]},
          {title:"SUVR",           state:true, subOption:[                                                               ]},
          {title:"Centiloid",      state:true, subOption:[                                                               ]},
          {title:"Transpose data", state:true, subOption:[                                                               ]},
      ]
      const defaultAtlas = "AAL3"
      const defaultRef = {
        // "[18F]FDG":"Global",
        "[18F]FDG":"Global cortex"
      }
      const defaultTracer = tracers[0];
      const defaultPInfoState = [
        {name:"Patient Name", state: true},
        {name:"Patient ID", state:true},
        {name:"DOB", state:true},
        {name:"Sex", state:true},
        {name:"Type & Date of Examination", state:true},
      ];
      const COLORMAP = [
        'invertedGray',
        'pet',
        'jet',
        'hot',
        'gray',
      ];
      return {
        CTN_Mode,
        Cloud_Mode,
        imageVerticalFlip: IMAGE_VERTICAL_FLIP,
        Date7:Date7,
        productName: productName,
        productShortName:productShortName,
        productFullName:productFullName,
        tracers:tracers,
        defaultCSVFormat:defaultCSVFormat,
        defaultAtlas:defaultAtlas,
        defaultRef:defaultRef,
        defaultTracer:defaultTracer,
        defaultPInfoState:defaultPInfoState,
        type1Selector:type1Selector,
        type2Selector:type2Selector,
        COLORMAP:COLORMAP,
        worklist:{
          top:{
            item1:[
              // {title:"Export all to PDF Fast", var:"downloadPDFFast"},
              {title:"Export all to PDF", var:"downloadPDF"},
              // {title:"Export all to JPEG", var:"downloadJPEG"},
              {title:"Export all to CSV", var:"downloadCSV_Group"},
              // {title:"Export all to Nifti", var:"downloadNifti_Group"},
              {title:"Export all to NIFTI (SUVR)", var:"downloadNifitiSUVR_Group"},
              {title:"Export all to NIFTI (Raw)", var:"downloadNifitiIntensity_Group"},
            ]
          }
        },
        dashboard:{
          bottom:[
            {
              cards:[
                {title: "Weekly Analysis", Date:`${Date7[6]} - ${Date7[0]}`, componentName: "BarGraph"},
              ],
            },
            {
              cards:[
                {title: `${productName} Tracers`, componentName: "PieGraph"}
              ]
            },
          ]
        },
        upload:{
          top:{
            btnGroup1:[
              {title:'View', icon:<img src={MEDIA_view} alt='view'/>, funcName:"view"},
              {title:'Analysis', icon:<img src={MEDIA_analysis}  alt='analysis'/>, funcName:"analysis"},
              {title:'Close', icon:<img src={MEDIA_close}  alt='close'/>, funcName:"close"},
            ],
            btnGroup2:[
              {title:'Worklist', icon:<img src={MEDIA_plus} alt='worklist add'/>, funcName:"worklist_add"},
              {title:'Worklist', icon:<img src={MEDIA_minus} alt='worklist delete'/>, funcName:"worklist_delete"},
            ],
            btnGroup3:[
              {title:'Delete', icon:<img src={MEDIA_trash} alt='file delete'/>, funcName:"file_delete"},
            ],
            btnGroup4:[
              {title:'Connect to PACS', icon:"", funcName:"pacs"},
              {title:'Upload', icon:"", funcName:"upload"},
            ],
          },
          bottom:{
            tableHead:[
              {title: 'Select', fieldName:'Select'},
              {title: 'Tracer', fieldName:'Tracer'},
              {title: 'Patient name', fieldName:'PatientName'},
              {title: 'Patient ID', fieldName:'PatientID'},
              {title: 'DOB', fieldName:'Age'},
              {title: 'Sex', fieldName:'Sex'},
              {title: 'Scan date', fieldName:'AcquisitionDateTime'},
              {title: 'Uploaded date', fieldName:'Update'},
            ],
            uploadingTableHead2:[
              {title: 'Select', fieldName:'Select'},
              {title: 'Patient ID', fieldName:'PatientID'},
              {title: 'Patient name', fieldName:'PatientName'},
              {title: 'DOB', fieldName:'BirthDate'},
              {title: 'Modality', fieldName: 'Modality'},
              {title: 'Study Date', fieldName: 'StudyDate'},
              {title: 'Study Description', fieldName: 'StudyDescription'},
              {title: 'Delete', fieldName: 'Delete'},
            ],
            uploadingTableHead3:[
              {title: 'Patient ID', fieldName:'PatientID'},
              {title: 'Patient name', fieldName:'PatientName'},
              {title: 'Series Description', fieldName: 'SeriesDescription'},
              {title: 'Delete', fieldName: 'Delete'},
            ],
            uploadingTableHead3Direct:[
              {title: 'Tracer', fieldName:'Tracer'},
              {title: 'Patient ID', fieldName:'PatientID'},
              {title: 'Patient name', fieldName:'PatientName'},
              // {title: 'Series Description', fieldName: 'SeriesDescription'},
              {title: 'Delete', fieldName: 'Delete'},
            ],
          },
        },
        view:{
          top:{
            item1:[
              {title:"Tracer", var:"Tracer"},
              {title:"Patient name", var:"PatientName" },
              {title:"Patient ID", var:"PatientID"},
              {title:"DOB", var:"Age"},
              {title:"Sex", var:"Sex"},
              {title:"Study Date", var:"AcquisitionDateTime"},
              {title:"Desc", var:"OriginalFileName"}
            ],
            item2:[
              {title:"Atlas", var:"defaultAtlas"},
              {title:"Reference", var:"defaultRef"},
            ],
            item3:[
              {title:"Generate a report", var:"openReport"},
              {title:"Export to CSV", var:"downloadCSV_Individual"},
              {title:"Export to NIFTI (SUVR)", var:"downloadNifitiSUVR_Individaul"},
              {title:"Export to NIFTI (Raw)", var:"downloadNifitiIntensity_Individaul"},
            ]
          },
        },
        analysis:{
          top:{
            item1:[
              {title:"Tracer", var:"Tracer"},
              {title:"Patient name", var:"PatientName" },
              {title:"Patient ID", var:"PatientID"},
              {title:"DOB", var:"Age"},
              {title:"Sex", var:"Sex"},
              {title:"Study Date", var:"AcquisitionDateTime"},
              {title:"Desc", var:"OriginalFileName"}
            ],
            item2:[
              {title:"Atlas", var:"defaultAtlas"},
              {title:"Reference", var:"defaultRef"},
            ],
            item3:[
              {title:"Generate a report", var:"openReport"},
              {title:"Export to CSV", var:"downloadCSV_Individual"},
              {title:"Export to NIFTI (SUVR)", var:"downloadNifitiSUVR_Individaul"},
              {title:"Export to NIFTI (Raw)", var:"downloadNifitiIntensity_Individaul"},
            ]
          },
          bottom:[
            {
              cards:[
                {
                  title: "Lobar SUVR", 
                  tableHead: ['image', 'value'], 
                  colorcode:fdgCmap,
                  legend:'SUVR',
                  componentName: "Analysis_card1",
                  content:[
                    {atlas:"all", lobe:"Global Lobe",    Left:"Bilat.", Right:"Bilat.", Lvar:"Global",      Rvar:"Global",      BrainSVG:({Lobe1, Lobe2, Lobe3, Lobe4}={})=>{return <div style={{width:"55%"}}><BrainLobe fillFrontal={fdgCmap[Lobe1]} fillParietal={fdgCmap[Lobe2]} fillTemporal={fdgCmap[Lobe3]} fillOccipital={fdgCmap[Lobe4]}/></div>}}, 
                    {atlas:"all", lobe:"Frontal Lobe",   Left:"L",      Right:"R",      Lvar:"Frontal_L",   Rvar:"Frontal_R",   BrainSVG:({Lobe1, Lobe2, Lobe3, Lobe4}={})=>{return <div style={{width:"55%"}}><BrainLobe fillFrontal={fdgCmap[Lobe1]} fillParietal={"gray"} fillTemporal={"gray"} fillOccipital={"gray"}/></div>}}, 
                    {atlas:"all", lobe:"Parietal Lobe",  Left:"L",      Right:"R",      Lvar:"Parietal_L",  Rvar:"Parietal_R",  BrainSVG:({Lobe1, Lobe2, Lobe3, Lobe4}={})=>{return <div style={{width:"55%"}}><BrainLobe fillFrontal={"gray"} fillParietal={fdgCmap[Lobe2]} fillTemporal={"gray"} fillOccipital={"gray"}/></div>}}, 
                    {atlas:"all", lobe:"Temporal Lobe",  Left:"L",      Right:"R",      Lvar:"Temporal_L",  Rvar:"Temporal_R",  BrainSVG:({Lobe1, Lobe2, Lobe3, Lobe4}={})=>{return <div style={{width:"55%"}}><BrainLobe fillFrontal={"gray"} fillParietal={"gray"} fillTemporal={fdgCmap[Lobe3]} fillOccipital={"gray"}/></div>}}, 
                    {atlas:"all", lobe:"Occipital Lobe", Left:"L",      Right:"R",      Lvar:"Occipital_L", Rvar:"Occipital_R", BrainSVG:({Lobe1, Lobe2, Lobe3, Lobe4}={})=>{return <div style={{width:"55%"}}><BrainLobe fillFrontal={"gray"} fillParietal={"gray"} fillTemporal={"gray"} fillOccipital={fdgCmap[Lobe4]}/></div>}}, 
                  ]
                },
              ]
            },

            {
              cards:[
                {
                  title: "Regional SUVR", 
                  tableHead: ['Region', 'Total', 'Left', 'Right'], 
                  componentName: "Analysis_card2_1",
                  content:[
                    {atlas:"AAL3", fullname: "Global", varname: {Left:"Global",Right:"Global"}, 
                      subItem:[]},

                    {atlas:"AAL3", fullname: "Frontal", varname: {Left:"Frontal_L", Right:"Frontal_R"}, 
                      subItem:[
                        {fullname:"Precentral",        varname:{Left:"Precentral_L",        Right:"Precentral_R"       }},
                        {fullname:"Frontal Sup",       varname:{Left:"Frontal_Sup_L",       Right:"Frontal_Sup_R"      }},
                        {fullname:"Frontal Mid",       varname:{Left:"Frontal_Mid_L",       Right:"Frontal_Mid_R"      }},
                        {fullname:"Frontal Inf Oper",  varname:{Left:"Frontal_Inf_Oper_L",  Right:"Frontal_Inf_Oper_R" }},
                        {fullname:"Frontal Inf Tri",   varname:{Left:"Frontal_Inf_Tri_L",   Right:"Frontal_Inf_Tri_R"  }},
                        {fullname:"Frontal Inf Orb",   varname:{Left:"Frontal_Inf_Orb_L",   Right:"Frontal_Inf_Orb_R"  }},
                        {fullname:"Rolandic Oper",     varname:{Left:"Rolandic_Oper_L",     Right:"Rolandic_Oper_R"    }},
                        {fullname:"Supp Motor Area",   varname:{Left:"Supp_Motor_Area_L",   Right:"Supp_Motor_Area_R"  }},
                        {fullname:"Olfactory",         varname:{Left:"Olfactory_L",         Right:"Olfactory_R"        }},
                        {fullname:"Frontal Sup Med",   varname:{Left:"Frontal_Sup_Med_L",   Right:"Frontal_Sup_Med_R"  }},
                        {fullname:"Frontal Med Orb",   varname:{Left:"Frontal_Med_Orb_L",   Right:"Frontal_Med_Orb_R"  }},
                        {fullname:"Rectus",            varname:{Left:"Rectus_L",            Right:"Rectus_R"           }},
                        {fullname:"OFCmed",            varname:{Left:"OFCmed_L",            Right:"OFCmed_R"           }},
                        {fullname:"OFCant",            varname:{Left:"OFCant_L",            Right:"OFCant_R"           }},
                        {fullname:"OFCpost",           varname:{Left:"OFCpost_L",           Right:"OFCpost_R"          }},
                        {fullname:"OFClat",            varname:{Left:"OFClat_L",            Right:"OFClat_R"           }},
                        {fullname:"Insula",            varname:{Left:"Insula_L",            Right:"Insula_R"           }},
                        {fullname:"Cingulate Ant Sub", varname:{Left:"Cingulate_Ant_Sub_L", Right:"Cingulate_Ant_Sub_R"}},
                        {fullname:"Cingulate Ant Pre", varname:{Left:"Cingulate_Ant_Pre_L", Right:"Cingulate_Ant_Pre_R"}},
                        {fullname:"Cingulate Ant Sup", varname:{Left:"Cingulate_Ant_Sup_L", Right:"Cingulate_Ant_Sup_R"}},
                        {fullname:"Cingulate Mid",     varname:{Left:"Cingulate_Mid_L",     Right:"Cingulate_Mid_R"    }}
                      ]},

                    {atlas:"AAL3", fullname: "PCC-Precuneus", varname: {Left:"PCC_L", Right:"PCC_R"}, 
                      subItem:[
                        {fullname:"Cingulate Post", varname:{Left:"Cingulate_Post_L", Right:"Cingulate_Post_R"}},
                        {fullname:"Precuneus", varname:{Left:"Precuneus_L", Right:"Precuneus_R"}},
                      ]},

                    {atlas:"AAL3", fullname: "Lateral temporal", varname: {Left:"Lateral_temporal_L", Right:"Lateral_temporal_R"}, 
                      subItem:[
                        {fullname:"Heschl", varname: {Left:"Heschl_L", Right:"Heschl_R"}},
                        {fullname:"Temporal Sup", varname: {Left:"Temporal_Sup_L", Right:"Temporal_Sup_R"}},
                        {fullname:"Temporal Pole Sup", varname: {Left:"Temporal_Pole_Sup_L", Right:"Temporal_Pole_Sup_R"}},
                        {fullname:"Temporal Mid", varname: {Left:"Temporal_Mid_L", Right:"Temporal_Mid_R"}},
                        {fullname:"Temporal Pole Mid", varname: {Left:"Temporal_Pole_Mid_L", Right:"Temporal_Pole_Mid_R"}},
                        {fullname:"Temporal Inf", varname: {Left:"Temporal_Inf_L", Right:"Temporal_Inf_R"}},
                      ]},

                    {atlas:"AAL3", fullname: "Lateral parietal", varname: {Left:"Lateral_parietal_L", Right:"Lateral_parietal_R"}, 
                      subItem:[
                        {fullname: "Postcentral", varname: {Left:"Postcentral_L",Right:"Postcentral_R"}},
                        {fullname: "Parietal Sup", varname: {Left:"Parietal_Sup_L",Right:"Parietal_Sup_R"}},
                        {fullname: "Parietal Inf", varname: {Left:"Parietal_Inf_L",Right:"Parietal_Inf_R"}},
                        {fullname: "Supramarginal", varname: {Left:"Supramarginal_L",Right:"Supramarginal_R"}},
                        {fullname: "Angular", varname: {Left:"Angular_L",Right:"Angular_R"}},
                        {fullname: "Paracentral Lobule", varname: {Left:"Paracentral_Lobule_L",Right:"Paracentral_Lobule_R"}}
                      ]},

                    {atlas:"AAL3", fullname: "Medial temporal", varname: {Left:"Medial_temporal_L", Right:"Medial_temporal_R"}, 
                      subItem:[
                        {fullname: "Hippocampus", varname: {Left:"Hippocampus_L",Right:"Hippocampus_R"}},
                        {fullname: "Parahippocampal", varname: {Left:"Parahippocampal_L",Right:"Parahippocampal_R"}},
                        {fullname: "Amygdala", varname: {Left:"Amygdala_L",Right:"Amygdala_R"}},
                      ]},

                    {atlas:"AAL3", fullname: "Occipital", varname: {Left:"Occipital_L", Right:"Occipital_R"}, 
                      subItem:[
                        {fullname: "Calcarine", varname: {Left:"Calcarine_L",Right:"Calcarine_R"}},
                        {fullname: "Cuneus", varname: {Left:"Cuneus_L",Right:"Cuneus_R"}},
                        {fullname: "Lingual", varname: {Left:"Lingual_L",Right:"Lingual_R"}},
                        {fullname: "Occipital Sup", varname: {Left:"Occipital_Sup_L",Right:"Occipital_Sup_R"}},
                        {fullname: "Occipital Mid", varname: {Left:"Occipital_Mid_L",Right:"Occipital_Mid_R"}},
                        {fullname: "Occipital Inf", varname: {Left:"Occipital_Inf_L",Right:"Occipital_Inf_R"}},
                        {fullname: "Fusiform", varname: {Left:"Fusiform_L",Right:"Fusiform_R"}},
                      ]},
                    {atlas:"AAL3", fullname: "Basal ganglia", varname: {Left:"Basal_ganglia_L", Right:"Basal_ganglia_R"}, 
                      subItem:[
                        {fullname: "Caudate", varname: {Left:"Caudate_L",Right:"Caudate_R"}},
                        {fullname: "Putamen", varname: {Left:"Putamen_L",Right:"Putamen_R"}},
                        {fullname: "Pallidum", varname: {Left:"Pallidum_L",Right:"Pallidum_R"}},
                        {fullname: "Thalamus", varname: {Left:"Thalamus_L",Right:"Thalamus_R"}},
                        {fullname: "N Acc", varname: {Left:"N_Acc_L",Right:"N_Acc_R"}},
                      ]},
                    {atlas:"AAL3", fullname: "Brainstem", varname: {Left:"Brainstem_L", Right:"Brainstem_R"}, 
                      subItem:[]},

                    {atlas:"Harvard-Oxford", fullname: "Global", varname: {Left:"HO_Global",Right:"HO_Global"}, 
                      subItem:[]},

                    {atlas:"Harvard-Oxford", fullname: "Frontal", varname: {Left:"HO_Frontal_L", Right:"HO_Frontal_R"}, 
                      subItem:[
                        {fullname: "Frontal Pole", varname: {Left:"HO_Frontal_Pole_L",Right:"HO_Frontal_Pole_R"}},
                        {fullname: "Insula", varname: {Left:"HO_Insula_L",Right:"HO_Insula_R"}},
                        {fullname: "Frontal Sup", varname: {Left:"HO_Frontal_Sup_L",Right:"HO_Frontal_Sup_R"}},
                        {fullname: "Frontal Mid", varname: {Left:"HO_Frontal_Mid_L",Right:"HO_Frontal_Mid_R"}},
                        {fullname: "Frontal Inf Tri", varname: {Left:"HO_Frontal_Inf_Tri_L",Right:"HO_Frontal_Inf_Tri_R"}},
                        {fullname: "Frontal Inf Oper", varname: {Left:"HO_Frontal_Inf_Oper_L",Right:"HO_Frontal_Inf_Oper_R"}},
                        {fullname: "Precentral", varname: {Left:"HO_Precentral_L",Right:"HO_Precentral_R"}},
                        {fullname: "Frontal Med", varname: {Left:"HO_Frontal_Med_L",Right:"HO_Frontal_Med_R"}},
                        {fullname: "Supp Motor Area", varname: {Left:"HO_Supp_Motor_Area_L",Right:"HO_Supp_Motor_Area_R"}},
                        {fullname: "Cingulate Ant", varname: {Left:"HO_Cingulate_Ant_L",Right:"HO_Cingulate_Ant_R"}},
                        {fullname: "Frontal Orb", varname: {Left:"HO_Frontal_Orb_L",Right:"HO_Frontal_Orb_R"}},
                        {fullname: "Frontal Oper", varname: {Left:"HO_Frontal_Oper_L",Right:"HO_Frontal_Oper_R"}},
                        {fullname: "Central Oper", varname: {Left:"HO_Central_Oper_L",Right:"HO_Central_Oper_R"}},
                        {fullname: "Subcallosal", varname: {Left:"HO_Subcallosal_L",Right:"HO_Subcallosal_R"}},
                        {fullname: "Paracingulate", varname: {Left:"HO_Paracingulate_L",Right:"HO_Paracingulate_R"}},
                    ]},


                    {atlas:"Harvard-Oxford", fullname: "Retrosplenial", varname: {Left:"HO_PCC_L",Right:"HO_PCC_R"}, 
                      subItem:[
                        {fullname: "Cingulate Post", varname: {Left:"HO_Cingulate_Post_L",Right:"HO_Cingulate_Post_R"}},
                        {fullname: "Precuneus", varname: {Left:"HO_Precuneus_L",Right:"HO_Precuneus_R"}},
                    ]},

                    {atlas:"Harvard-Oxford", fullname: "Lateral temporal", varname: {Left:"HO_Lateral_temporal_L",Right:"HO_Lateral_temporal_R"}, 
                      subItem:[
                        {fullname: "Temporal Pole", varname: {Left:"HO_Temporal_Pole_L",Right:"HO_Temporal_Pole_R"}},
                        {fullname: "Temporal Sup Ant", varname: {Left:"HO_Temporal_Sup_Ant_L",Right:"HO_Temporal_Sup_Ant_R"}},
                        {fullname: "Temporal Sup Post", varname: {Left:"HO_Temporal_Sup_Post_L",Right:"HO_Temporal_Sup_Post_R"}},
                        {fullname: "Temporal Mid Ant", varname: {Left:"HO_Temporal_Mid_Ant_L",Right:"HO_Temporal_Mid_Ant_R"}},
                        {fullname: "Temporal Mid Post", varname: {Left:"HO_Temporal_Mid_Post_L",Right:"HO_Temporal_Mid_Post_R"}},
                        {fullname: "Temporal Mid Temporooccipital", varname: {Left:"HO_Temporal_Mid_Temporooccipital_L",Right:"HO_Temporal_Mid_Temporooccipital_R"}},
                        {fullname: "Temporal Inf Ant", varname: {Left:"HO_Temporal_Inf_Ant_L",Right:"HO_Temporal_Inf_Ant_R"}},
                        {fullname: "Temporal Inf Post", varname: {Left:"HO_Temporal_Inf_Post_L",Right:"HO_Temporal_Inf_Post_R"}},
                        {fullname: "Temporal Inf Temporooccipital", varname: {Left:"HO_Temporal_Inf_Temporooccipital_L",Right:"HO_Temporal_Inf_Temporooccipital_R"}},
                        {fullname: "Planum Polare", varname: {Left:"HO_Planum_Polare_L",Right:"HO_Planum_Polare_R"}},
                        {fullname: "Heschl", varname: {Left:"HO_Heschl_L",Right:"HO_Heschl_R"}},
                        {fullname: "Planum Temporale", varname: {Left:"HO_Planum_Temporale_L",Right:"HO_Planum_Temporale_R"}},
                        {fullname: "Temporal fusiform Ant", varname: {Left:"HO_Temporal_fusiform_Ant_L",Right:"HO_Temporal_fusiform_Ant_R"}},
                        {fullname: "Temporal fusiform Post", varname: {Left:"HO_Temporal_fusiform_Post_L",Right:"HO_Temporal_fusiform_Post_R"}},
                        {fullname: "Temporal Occipital Fusiform", varname: {Left:"HO_Temporal_Occipital_Fusiform_L",Right:"HO_Temporal_Occipital_Fusiform_R"}},
                    ]},

                    {atlas:"Harvard-Oxford", fullname: "Lateral parietal", varname: {Left:"HO_Lateral_parietal_L",Right:"HO_Lateral_parietal_R"}, 
                      subItem:[
                        {fullname: "Postcentral", varname: {Left:"HO_Postcentral_L",Right:"HO_Postcentral_R"}},
                        {fullname: "Parietal Sup Lobule", varname: {Left:"HO_Parietal_Sup_Lobule_L",Right:"HO_Parietal_Sup_Lobule_R"}},
                        {fullname: "Supramarginal Ant", varname: {Left:"HO_Supramarginal_Ant_L",Right:"HO_Supramarginal_Ant_R"}},
                        {fullname: "Supramarginal Post", varname: {Left:"HO_Supramarginal_Post_L",Right:"HO_Supramarginal_Post_R"}},
                        {fullname: "Angular", varname: {Left:"HO_Angular_L",Right:"HO_Angular_R"}},
                        {fullname: "Parietal Oper", varname: {Left:"HO_Parietal_Oper_L",Right:"HO_Parietal_Oper_R"}},
                    ]},
                    {atlas:"Harvard-Oxford", fullname: "Medial temporal", varname: {Left:"HO_Medial_temporal_L",Right:"HO_Medial_temporal_R"}, 
                      subItem:[
                        {fullname: "Parahippocampal Ant", varname: {Left:"HO_Parahippocampal_Ant_L",Right:"HO_Parahippocampal_Ant_R"}},
                        {fullname: "Parahippocampal Post", varname: {Left:"HO_Parahippocampal_Post_L",Right:"HO_Parahippocampal_Post_R"}},
                        {fullname: "Hippocampus", varname: {Left:"HO_Hippocampus_L",Right:"HO_Hippocampus_R"}},
                        {fullname: "Amygdala", varname: {Left:"HO_Amygdala_L",Right:"HO_Amygdala_R"}},
                    ]},
                    {atlas:"Harvard-Oxford", fullname: "Occipital", varname: {Left:"HO_Occipital_L",Right:"HO_Occipital_R"}, 
                      subItem:[
                        {fullname: "Occipital Lat_Sup", varname: {Left:"HO_Occipital_Lat_Sup_L",Right:"HO_Occipital_Lat_Sup_R"}},
                        {fullname: "Occipital Lat_Inf", varname: {Left:"HO_Occipital_Lat_Inf_L",Right:"HO_Occipital_Lat_Inf_R"}},
                        {fullname: "Intracalcarine", varname: {Left:"HO_Intracalcarine_L",Right:"HO_Intracalcarine_R"}},
                        {fullname: "Occipital Pole", varname: {Left:"HO_Occipital_Pole_L",Right:"HO_Occipital_Pole_R"}},
                        {fullname: "Cuneal", varname: {Left:"HO_Cuneal_L",Right:"HO_Cuneal_R"}},
                        {fullname: "Lingual", varname: {Left:"HO_Lingual_L",Right:"HO_Lingual_R"}},
                        {fullname: "Occipital Fusiform", varname: {Left:"HO_Occipital_Fusiform_L",Right:"HO_Occipital_Fusiform_R"}},
                        {fullname: "Supracalcarine", varname: {Left:"HO_Supracalcarine_L",Right:"HO_Supracalcarine_R"}},
                    ]},
                    {atlas:"Harvard-Oxford", fullname: "Basal ganglia", varname: {Left:"HO_Basal_ganglia_L",Right:"HO_Basal_ganglia_R"}, 
                      subItem:[
                        {fullname: "Caudate", varname: {Left:"HO_Caudate_L",Right:"HO_Caudate_R"}},
                        {fullname: "Putamen", varname: {Left:"HO_Putamen_L",Right:"HO_Putamen_R"}},
                        {fullname: "Pallidum", varname: {Left:"HO_Pallidum_L",Right:"HO_Pallidum_R"}},
                        {fullname: "Thalamus", varname: {Left:"HO_Thalamus_L",Right:"HO_Thalamus_R"}},
                        {fullname: "Accumbens", varname: {Left:"HO_Accumbens_L",Right:"HO_Accumbens_R"}},
                    ]},
                    {atlas:"Harvard-Oxford", fullname: "Brainstem", varname: {Left:"HO_Brainstem_L",Right:"HO_Brainstem_R"}, 
                      subItem:[]},

                  ]
                },
                // {
                //   title: "Regional SUVR", 
                //   tableHead: ['Region', 'Total', 'Left', 'Right', 'Centiloid'], 
                //   componentName: "Analysis_card2_2",
                //   content:[
                //     {atlas:"AAL3", fullname: "Composite", varname: {Left:"Composite_C",Right:"Composite_C", Centiloid:true}, subItem:[]},
                //     {atlas:"AAL3", fullname: "Frontal", varname: {Left:"Frontal_L_C", Right:"Frontal_R_C", Centiloid:true}, 
                //       subItem:[
                //         {fullname:"Precentral", varname:{Region:"", Left:"Precentral_L_C", Right:"Precentral_R_C", Centiloid:true}},
                //         {fullname:"Frontal Sup", varname:{Left:"Frontal_Sup_L_C", Right:"Frontal_Sup_R_C", Centiloid:true}},
                //         {fullname:"Frontal Mid", varname:{Left:"Frontal_Mid_L_C", Right:"Frontal_Mid_R_C", Centiloid:true}},
                //         {fullname:"Frontal Inf Oper", varname:{Left:"Frontal_Inf_Oper_L_C", Right:"Frontal_Inf_Oper_R_C", Centiloid:true}},
                //         {fullname:"Frontal Inf Tri", varname:{Left:"Frontal_Inf_Tri_L_C", Right:"Frontal_Inf_Tri_R_C", Centiloid:true}},
                //         {fullname:"Frontal Inf Orb", varname:{Left:"Frontal_Inf_Orb_L_C", Right:"Frontal_Inf_Orb_R_C", Centiloid:true}},
                //         {fullname:"Rolandic Oper", varname:{Left:"Rolandic_Oper_L_C", Right:"Rolandic_Oper_R_C"}},
                //         {fullname:"Supp Motor Area", varname:{Left:"Supp_Motor_Area_L_C", Right:"Supp_Motor_Area_R_C", Centiloid:true}},
                //         {fullname:"Olfactory", varname:{Left:"Olfactory_L_C", Right:"Olfactory_R_C"}},
                //         {fullname:"Frontal Sup Med", varname:{Left:"Frontal_Sup_Med_L_C", Right:"Frontal_Sup_Med_R_C", Centiloid:true}},
                //         {fullname:"Frontal Med Orb", varname:{Left:"Frontal_Med_Orb_L_C", Right:"Frontal_Med_Orb_R_C", Centiloid:true}},
                //         {fullname:"Rectus", varname:{Left:"Rectus_L_C", Right:"Rectus_R_C", Centiloid:true}},
                //         {fullname:"OFCmed", varname:{Left:"OFCmed_L_C", Right:"OFCmed_R_C", Centiloid:true}},
                //         {fullname:"OFCant", varname:{Left:"OFCant_L_C", Right:"OFCant_R_C", Centiloid:true}},
                //         {fullname:"OFCpost", varname:{Left:"OFCpost_L_C", Right:"OFCpost_R_C"}},
                //         {fullname:"OFClat", varname:{Left:"OFClat_L_C", Right:"OFClat_R_C"}},
                //         {fullname:"Insula", varname:{Left:"Insula_L_C", Right:"Insula_R_C"}},
                //         {fullname:"Cingulate Ant Sub", varname:{Left:"Cingulate_Ant_Sub_L_C", Right:"Cingulate_Ant_Sub_R_C", Centiloid:true}},
                //         {fullname:"Cingulate Ant Pre", varname:{Left:"Cingulate_Ant_Pre_L_C", Right:"Cingulate_Ant_Pre_R_C", Centiloid:true}},
                //         {fullname:"Cingulate Ant Sup", varname:{Left:"Cingulate_Ant_Sup_L_C", Right:"Cingulate_Ant_Sup_R_C", Centiloid:true}},
                //         {fullname:"Cingulate Mid", varname:{Left:"Cingulate_Mid_L_C", Right:"Cingulate_Mid_R_C", Centiloid:true}}
                //       ]},

                //     {atlas:"AAL3", fullname: "PCC-Precuneus", varname: {Left:"PCC_L_C", Right:"PCC_R_C", Centiloid:true}, 
                //       subItem:[
                //         {fullname:"Cingulate Post", varname:{Left:"Cingulate_Post_L_C", Right:"Cingulate_Post_R_C", Centiloid:true}},
                //         {fullname:"Precuneus", varname:{Left:"Precuneus_L_C", Right:"Precuneus_R_C", Centiloid:true}}
                //       ]},
                //     {atlas:"AAL3", fullname: "Lateral temporal", varname: {Left:"Lateral_temporal_L_C", Right:"Lateral_temporal_R_C", Centiloid:true}, 
                //       subItem:[
                //         {fullname:"Heschl", varname:{Left:"Heschl_L_C", Right:"Heschl_R_C", Centiloid:true}},
                //         {fullname:"Temporal Sup", varname:{Left:"Temporal_Sup_L_C", Right:"Temporal_Sup_R_C", Centiloid:true}},
                //         {fullname:"Temporal Pole Sup", varname:{Left:"Temporal_Pole_Sup_L_C", Right:"Temporal_Pole_Sup_R_C", Centiloid:true}},
                //         {fullname:"Temporal Mid", varname:{Left:"Temporal_Mid_L_C", Right:"Temporal_Mid_R_C", Centiloid:true}},
                //         {fullname:"Temporal Pole Mid", varname:{Left:"Temporal_Pole_Mid_L_C", Right:"Temporal_Pole_Mid_R_C", Centiloid:true}},
                //         {fullname:"Temporal Inf", varname:{Left:"Temporal_Inf_L_C", Right:"Temporal_Inf_R_C", Centiloid:true}},
                //       ]},
                //     {atlas:"AAL3", fullname: "Lateral parietal", varname: {Left:"Lateral_parietal_L_C", Right:"Lateral_parietal_R_C", Centiloid:true}, 
                //       subItem:[
                //         {fullname:"Postcentral", varname:{Left:"Postcentral_L_C", Right:"Postcentral_R_C", Centiloid:true}},
                //         {fullname:"Parietal Sup", varname:{Left:"Parietal_Sup_L_C", Right:"Parietal_Sup_R_C", Centiloid:true}},
                //         {fullname:"Parietal Inf", varname:{Left:"Parietal_Inf_L_C", Right:"Parietal_Inf_R_C", Centiloid:true}},
                //         {fullname:"Supramarginal", varname:{Left:"Supramarginal_L_C", Right:"Supramarginal_R_C", Centiloid:true}},
                //         {fullname:"Angular", varname:{Left:"Angular_L_C", Right:"Angular_R_C", Centiloid:true}},
                //         {fullname:"Paracentral Lobule", varname:{Left:"Paracentral_Lobule_L_C", Right:"Paracentral_Lobule_R_C", Centiloid:true}},
                //       ]},
                    
                //     {atlas:"AAL3", fullname: "Medial temporal", varname: {Left:"Medial_temporal_L_C", Right:"Medial_temporal_R_C", Centiloid:true}, 
                //       subItem:[
                //         {fullname: "Hippocampus", varname: {Left:"Hippocampus_L_C",Right:"Hippocampus_R_C", Centiloid:true}},
                //         {fullname: "Parahippocampal", varname: {Left:"Parahippocampal_L_C",Right:"Parahippocampal_R_C", Centiloid:true}},
                //         {fullname: "Amygdala", varname: {Left:"Amygdala_L_C",Right:"Amygdala_R_C", Centiloid:true}},
                //       ]},
                //     {atlas:"AAL3", fullname: "Occipital", varname: {Left:"Occipital_L_C", Right:"Occipital_R_C", Centiloid:true}, 
                //       subItem:[
                //         {fullname: "Calcarine", varname: {Left:"Calcarine_L_C",Right:"Calcarine_R_C", Centiloid:true}},
                //         {fullname: "Cuneus", varname: {Left:"Cuneus_L_C",Right:"Cuneus_R_C", Centiloid:true}},
                //         {fullname: "Lingual", varname: {Left:"Lingual_L_C",Right:"Lingual_R_C", Centiloid:true}},
                //         {fullname: "Occipital Sup", varname: {Left:"Occipital_Sup_L_C",Right:"Occipital_Sup_R_C", Centiloid:true}},
                //         {fullname: "Occipital Mid", varname: {Left:"Occipital_Mid_L_C",Right:"Occipital_Mid_R_C", Centiloid:true}},
                //         {fullname: "Occipital Inf", varname: {Left:"Occipital_Inf_L_C",Right:"Occipital_Inf_R_C", Centiloid:true}},
                //         {fullname: "Fusiform", varname: {Left:"Fusiform_L_C",Right:"Fusiform_R_C", Centiloid:true}},
                //       ]},
                //     {atlas:"AAL3", fullname: "Basal ganglia", varname: {Left:"Basal_ganglia_L_C", Right:"Basal_ganglia_R_C", Centiloid:true}, 
                //       subItem:[
                //         {fullname: "Caudate", varname: {Left:"Caudate_L_C",Right:"Caudate_R_C", Centiloid:true}},
                //         {fullname: "Putamen", varname: {Left:"Putamen_L_C",Right:"Putamen_R_C", Centiloid:true}},
                //         {fullname: "Pallidum", varname: {Left:"Pallidum_L_C",Right:"Pallidum_R_C", Centiloid:true}},
                //         {fullname: "Thalamus", varname: {Left:"Thalamus_L_C",Right:"Thalamus_R_C", Centiloid:true}},
                //         {fullname: "N Acc", varname: {Left:"N_Acc_L_C",Right:"N_Acc_R_C", Centiloid:true}},
                //       ]},
                //     {atlas:"AAL3", fullname: "Brainstem", varname: {Left:"Brainstem_L_C", Right:"Brainstem_R_C", Centiloid:true}, 
                //       subItem:[]},

                //     {atlas:"Harvard-Oxford", fullname: "Global", varname: {Left:"Composite_C", Right:"Composite_C", Centiloid:true}, 
                //     subItem:[]},

                //     {atlas:"Harvard-Oxford", fullname: "Frontal", varname: {Left:"HO_Frontal_L_C", Right:"HO_Frontal_R_C", Centiloid:true}, 
                //       subItem:[
                //         {fullname: "Frontal Pole", varname: {Left:"HO_Frontal_Pole_L_C",Right:"HO_Frontal_Pole_R_C", Centiloid:true}},
                //         {fullname: "Insula", varname: {Left:"HO_Insula_L_C",Right:"HO_Insula_R_C"}},
                //         {fullname: "Frontal Sup", varname: {Left:"HO_Frontal_Sup_L_C",Right:"HO_Frontal_Sup_R_C", Centiloid:true}},
                //         {fullname: "Frontal Mid", varname: {Left:"HO_Frontal_Mid_L_C",Right:"HO_Frontal_Mid_R_C", Centiloid:true}},
                //         {fullname: "Frontal Inf Tri", varname: {Left:"HO_Frontal_Inf_Tri_L_C",Right:"HO_Frontal_Inf_Tri_R_C", Centiloid:true}},
                //         {fullname: "Frontal Inf Oper", varname: {Left:"HO_Frontal_Inf_Oper_L_C",Right:"HO_Frontal_Inf_Oper_R_C", Centiloid:true}},
                //         {fullname: "Precentral", varname: {Left:"HO_Precentral_L_C",Right:"HO_Precentral_R_C", Centiloid:true}},
                //         {fullname: "Frontal Med", varname: {Left:"HO_Frontal_Med_L_C",Right:"HO_Frontal_Med_R_C", Centiloid:true}},
                //         {fullname: "Supp Motor Area", varname: {Left:"HO_Supp_Motor_Area_L_C",Right:"HO_Supp_Motor_Area_R_C", Centiloid:true}},
                //         {fullname: "Cingulate Ant", varname: {Left:"HO_Cingulate_Ant_L_C",Right:"HO_Cingulate_Ant_R_C", Centiloid:true}},
                //         {fullname: "Frontal Orb", varname: {Left:"HO_Frontal_Orb_L_C",Right:"HO_Frontal_Orb_R_C", Centiloid:true}},
                //         {fullname: "Frontal Oper", varname: {Left:"HO_Frontal_Oper_L_C",Right:"HO_Frontal_Oper_R_C", Centiloid:true}},
                //         {fullname: "Central Oper", varname: {Left:"HO_Central_Oper_L_C",Right:"HO_Central_Oper_R_C", Centiloid:true}},
                //         {fullname: "Subcallosal", varname: {Left:"HO_Subcallosal_L_C",Right:"HO_Subcallosal_R_C", Centiloid:true}},
                //         {fullname: "Paracingulate", varname: {Left:"HO_Paracingulate_L_C",Right:"HO_Paracingulate_R_C", Centiloid:true}},
                //     ]},
                //     {atlas:"Harvard-Oxford", fullname: "Retrosplenial", varname: {Left:"HO_PCC_L_C", Right:"HO_PCC_R_C", Centiloid:true}, 
                //       subItem:[
                //         {fullname: "Cingulate Post", varname: {Left:"HO_Cingulate_Post_L_C",Right:"HO_Cingulate_Post_R_C", Centiloid:true}},
                //         {fullname: "Precuneus", varname: {Left:"HO_Precuneus_L_C",Right:"HO_Precuneus_R_C", Centiloid:true}},
                //     ]},
                //     {atlas:"Harvard-Oxford", fullname: "Lateral temporal", varname: {Left:"HO_Lateral_temporal_L_C", Right:"HO_Lateral_temporal_R_C", Centiloid:true}, 
                //       subItem:[
                //         {fullname: "Temporal Pole", varname: {Left:"HO_Temporal_Pole_L_C",Right:"HO_Temporal_Pole_R_C", Centiloid:true}},
                //         {fullname: "Temporal Sup Ant", varname: {Left:"HO_Temporal_Sup_Ant_L_C",Right:"HO_Temporal_Sup_Ant_R_C", Centiloid:true}},
                //         {fullname: "Temporal Sup Post", varname: {Left:"HO_Temporal_Sup_Post_L_C",Right:"HO_Temporal_Sup_Post_R_C", Centiloid:true}},
                //         {fullname: "Temporal Mid Ant", varname: {Left:"HO_Temporal_Mid_Ant_L_C",Right:"HO_Temporal_Mid_Ant_R_C", Centiloid:true}},
                //         {fullname: "Temporal Mid Post", varname: {Left:"HO_Temporal_Mid_Post_L_C",Right:"HO_Temporal_Mid_Post_R_C", Centiloid:true}},
                //         {fullname: "Temporal Mid Temporooccipital", varname: {Left:"HO_Temporal_Mid_Temporooccipital_L_C",Right:"HO_Temporal_Mid_Temporooccipital_R_C", Centiloid:true}},
                //         {fullname: "Temporal Inf Ant", varname: {Left:"HO_Temporal_Inf_Ant_L_C",Right:"HO_Temporal_Inf_Ant_R_C", Centiloid:true}},
                //         {fullname: "Temporal Inf Post", varname: {Left:"HO_Temporal_Inf_Post_L_C",Right:"HO_Temporal_Inf_Post_R_C", Centiloid:true}},
                //         {fullname: "Temporal Inf Temporooccipital", varname: {Left:"HO_Temporal_Inf_Temporooccipital_L_C",Right:"HO_Temporal_Inf_Temporooccipital_R_C", Centiloid:true}},
                //         {fullname: "Planum Polare", varname: {Left:"HO_Planum_Polare_L_C",Right:"HO_Planum_Polare_R_C", Centiloid:true}},
                //         {fullname: "Heschl", varname: {Left:"HO_Heschl_L_C",Right:"HO_Heschl_R_C", Centiloid:true}},
                //         {fullname: "Planum Temporale", varname: {Left:"HO_Planum_Temporale_L_C",Right:"HO_Planum_Temporale_R_C", Centiloid:true}},
                //         {fullname: "Temporal fusiform Ant", varname: {Left:"HO_Temporal_fusiform_Ant_L_C",Right:"HO_Temporal_fusiform_Ant_R_C", Centiloid:true}},
                //         {fullname: "Temporal fusiform Post", varname: {Left:"HO_Temporal_fusiform_Post_L_C",Right:"HO_Temporal_fusiform_Post_R_C", Centiloid:true}},
                //         {fullname: "Temporal Occipital Fusiform", varname: {Left:"HO_Temporal_Occipital_Fusiform_L_C",Right:"HO_Temporal_Occipital_Fusiform_R_C", Centiloid:true}},
                //     ]},
                //     {atlas:"Harvard-Oxford", fullname: "Lateral parietal", varname: {Left:"HO_Lateral_parietal_L_C", Right:"HO_Lateral_parietal_R_C", Centiloid:true}, 
                //       subItem:[
                //         {fullname: "Postcentral", varname: {Left:"HO_Postcentral_L_C",Right:"HO_Postcentral_R_C", Centiloid:true}},
                //         {fullname: "Parietal Sup Lobule", varname: {Left:"HO_Parietal_Sup_Lobule_L_C",Right:"HO_Parietal_Sup_Lobule_R_C", Centiloid:true}},
                //         {fullname: "Supramarginal Ant", varname: {Left:"HO_Supramarginal_Ant_L_C",Right:"HO_Supramarginal_Ant_R_C", Centiloid:true}},
                //         {fullname: "Supramarginal Post", varname: {Left:"HO_Supramarginal_Post_L_C",Right:"HO_Supramarginal_Post_R_C", Centiloid:true}},
                //         {fullname: "Angular", varname: {Left:"HO_Angular_L_C",Right:"HO_Angular_R_C"}},
                //         {fullname: "Parietal Oper", varname: {Left:"HO_Parietal_Oper_L_C",Right:"HO_Parietal_Oper_R_C", Centiloid:true}},
                //     ]},
                //     {atlas:"Harvard-Oxford", fullname: "Medial temporal", varname: {Left:"HO_Frontal_L_C", Right:"HO_Frontal_R_C", Centiloid:true}, 
                //       subItem:[
                //         {fullname: "Parahippocampal Ant", varname: {Left:"HO_Parahippocampal_Ant_L_C",Right:"HO_Parahippocampal_Ant_R_C", Centiloid:true}},
                //         {fullname: "Parahippocampal Post", varname: {Left:"HO_Parahippocampal_Post_L_C",Right:"HO_Parahippocampal_Post_R_C", Centiloid:true}},
                //         {fullname: "Hippocampus", varname: {Left:"HO_Hippocampus_L_C",Right:"HO_Hippocampus_R_C", Centiloid:true}},
                //         {fullname: "Amygdala", varname: {Left:"HO_Amygdala_L_C",Right:"HO_Amygdala_R_C", Centiloid:true}},
                //     ]},
                //     {atlas:"Harvard-Oxford", fullname: "Occipital", varname: {Left:"HO_Frontal_L_C", Right:"HO_Frontal_R_C", Centiloid:true}, 
                //       subItem:[
                //         {fullname: "Occipital Lat_Sup", varname: {Left:"HO_Occipital_Lat_Sup_L_C",Right:"HO_Occipital_Lat_Sup_R_C", Centiloid:true}},
                //         {fullname: "Occipital Lat_Inf", varname: {Left:"HO_Occipital_Lat_Inf_L_C",Right:"HO_Occipital_Lat_Inf_R_C", Centiloid:true}},
                //         {fullname: "Intracalcarine", varname: {Left:"HO_Intracalcarine_L_C",Right:"HO_Intracalcarine_R_C", Centiloid:true}},
                //         {fullname: "Occipital Pole", varname: {Left:"HO_Occipital_Pole_L_C",Right:"HO_Occipital_Pole_R_C", Centiloid:true}},
                //         {fullname: "Cuneal", varname: {Left:"HO_Cuneal_L_C",Right:"HO_Cuneal_R_C", Centiloid:true}},
                //         {fullname: "Lingual", varname: {Left:"HO_Lingual_L_C",Right:"HO_Lingual_R_C", Centiloid:true}},
                //         {fullname: "Occipital Fusiform", varname: {Left:"HO_Occipital_Fusiform_L_C",Right:"HO_Occipital_Fusiform_R_C", Centiloid:true}},
                //         {fullname: "Supracalcarine", varname: {Left:"HO_Supracalcarine_L_C",Right:"HO_Supracalcarine_R_C", Centiloid:true}},
                //     ]},
                //     {atlas:"Harvard-Oxford", fullname: "Basal ganglia", varname: {Left:"HO_Frontal_L_C", Right:"HO_Frontal_R_C", Centiloid:true}, 
                //       subItem:[
                //         {fullname: "Caudate", varname: {Left:"HO_Caudate_L_C",Right:"HO_Caudate_R_C", Centiloid:true}},
                //         {fullname: "Putamen", varname: {Left:"HO_Putamen_L_C",Right:"HO_Putamen_R_C", Centiloid:true}},
                //         {fullname: "Pallidum", varname: {Left:"HO_Pallidum_L_C",Right:"HO_Pallidum_R_C", Centiloid:true}},
                //         {fullname: "Thalamus", varname: {Left:"HO_Thalamus_L_C",Right:"HO_Thalamus_R_C", Centiloid:true}},
                //         {fullname: "Accumbens", varname: {Left:"HO_Accumbens_L_C",Right:"HO_Accumbens_R_C", Centiloid:true}},
                //     ]},
                //     {atlas:"Harvard-Oxford", fullname: "Brainstem", varname: {Left:"HO_Frontal_L_C", Right:"HO_Frontal_R_C", Centiloid:true}, 
                //       subItem:[]},
                //   ]
                // }
              ]
            },

            { 
              cards:[
                {
                  title: "Surface Projection",
                  componentName: "Analysis_card3",
                  content:["surface image1", "surface image2", "surface image3", "surface image4"]
                },
              ]
            },

          ]
          // bottom:[
          //   {
          //     cards:[
          //       {
          //         title: "Lobar SUVR",
          //         componentName: "Analysis_card1",
          //         content:["Global Lobe", "Frontal Lobe", "Parietal Lobe", "Temporal Lobe", "Occipital Lobe"]
          //       }
          //     ]
          //   },
          //   {
          //     cards:[
          //       {
          //         title: "Regional SUVR", tableHead: ['Region', 'Total', 'Left', 'Right'], 
          //         componentName: "Analysis_card2_1",
          //         content:[
          //           {atlas:"AAL3", fullname: "Dorsal striatum", varname: "DAT_Dorsal_striatum", subItem:[]},
          //           {atlas:"AAL3", fullname: "Caudate nucleus", varname: "DAT_Caudate_nucleus", subItem:[{fullname:"Caudate DA", varname:"DAT_Dorsoant_caudate"},{fullname:"Caudate VA", varname:"DAT_Ventroant_caudate"}]},
          //           {atlas:"AAL3", fullname: "Putamen", varname: "DAT_Putamen", subItem:[{fullname:"Putamen DA", varname:"DAT_Dorsoant_putamen"},{fullname:"Putamen VA", varname:"DAT_Ventroant_putamen"},{fullname:"Putamen DP", varname:"DAT_Dorsopost_putamen"},{fullname:"Putamen VP", varname:"DAT_Ventropost_Putamen"}]},
          //           {atlas:"AAL3", fullname: "Pallidum", varname: "DAT_Pallidum", subItem:[{fullname:"Ant Pallidum", varname:"DAT_Ant_pallidum"},{fullname:"Post Pallidum", varname:"DAT_Post_pallidum"}]},
          //           {atlas:"AAL3", fullname: "Ventral striatum", varname: "DAT_Ventral_striatum", subItem:[]},
          //         ]
          //       },
          //       {
          //         title: "Regional Centiloid", tableHead: ['Region', 'Total', 'Left', 'Right'], 
          //         componentName: "Analysis_card2_2",
          //         content:[
          //           {atlas:"AAL3", fullname: "Striatal asymmetry index", varname: "DAT_Striatal_asymmetry_index", subItem:[]},
          //           {atlas:"AAL3", fullname: "Caudate asymmetry index", varname: "DAT_Caudate_asymmetry_index", subItem:[]},
          //           {atlas:"AAL3", fullname: "Putamen asymmetry index", varname: "DAT_Putamen_asymmetry_index", subItem:[]},
          //           {atlas:"AAL3", fullname: "Putamen-caudate ratio", varname: "DAT_Putamen_caudate_ratio", subItem:[]},
          //           {atlas:"AAL3", fullname: "Caudate-putamen ratio", varname: "DAT_Caudate_putamen_ratio", subItem:[]},
          //           {atlas:"AAL3", fullname: "Anterior-posterior putamen ratio", varname: "DAT_Anterior_posterior_putamen_ratio", subItem:[]},
          //         ]
          //       }
          //     ]
          //   },
          //   {
          //     cards:[
          //       {
          //         title: "Brain Surface",
          //         componentName: "Analysis_card3",
          //         content:["surface image1", "surface image2", "surface image3", "surface image4"]
          //       }
          //     ]
          //   },
          // ]
        },
        setting:{
          top:{
            btnGroup1:[
              {title:'Reset', icon:<MdRefresh/>, funcName:'reset'},
              {title:'Save', icon:<MdSave/>, funcName:'save'},
            ],
          },
          bottom:[
            {
              styles:{width:"65%", height:"98%"}, 
              cards:[
                {
                  title: "", 
                  componentName: "MainConfig",
                  contents:[
                    {
                      subTitle: "Brain Atlas", 
                      atlasList: ["AAL3","Harvard-Oxford"],
                      subContent:[
                        {default:"AAL3",options:["AAL3","Harvard-Oxford"],}
                      ]
                    }, 
                    {
                      subTitle: "Reference Region", 
                      refList: ["Pons", "Cerebellar gray matter", "Cerebral white matter", "Whole cerebellum", "Global cortex"],
                      subContent:[
                        { tracer: "[18F]FDG", fullname:"[18F]FDG", },
                      ]
                    }, 
                    {
                      subTitle: "Brain Regions To Analyze", 
                      subContent:[
                        {
                          atlas:"AAL3",
                          region: "Global", 
                          subRegion:[

                          ] 
                        },
                        {
                          atlas:"AAL3",
                          region: "Frontal", 
                          subRegion:[
                            "Precentral", "Frontal Sup", "Frontal Mid", "Frontal Inf Oper", "Frontal InfTri", "Frontal Inf Orb", "Rolandic Oper", 
                            "Supp Motor Area", "Olfactory", "Frontal Sup Med", "Frontal Med Orb", "Rectus", "OFCmed", "OFCant", "OFCpost", "OFClat",
                            "Insula", "Cingulate Ant Sub", "Cingulate Ant Pre", "Cingulate Ant Sup", "Cingulate Mid"
                          ] 
                        },
                        {
                          atlas:"AAL3",
                          region: "PCC-Precuneus", 
                          subRegion:[
                            "Cingulate Post", "Precuneus"
                          ] 
                        },
                        {
                          atlas:"AAL3",
                          region: "Lateral temporal", 
                          subRegion:[
                            "Heschl", "Temporal Sup", "Temporal Pole Sup", "Temporal Mid", "Temporal Pole Mid", "Temporal Inf"
                          ] 
                        },
                        {
                          atlas:"AAL3",
                          region: "Lateral parietal", 
                          subRegion:[
                            "Postcentral", "Parietal Sup", "Parietal Inf", "Supramarginal", "Angular", "Paracentral Lobule"
                          ] 
                        },
                        {
                          atlas:"AAL3",
                          region: "Medial temporal", 
                          subRegion:[
                            "Hippocampus", "Parahippocampal", "Amygdala"
                          ] 
                        },
                        {
                          atlas:"AAL3",
                          region: "Occipital", 
                          subRegion:[
                            "Calcarine", "Cuneus", "Lingual", "Occipital Sup", "Occipital Mid", "Occipital Inf", "Fusiform"
                          ] 
                        },
                        {
                          atlas:"AAL3",
                          region: "Basal ganglia", 
                          subRegion:[
                            "Caudate", "Putamen", "Pallidum", "Thalamus", "N Acc"
                          ] 
                        },
                        {
                          atlas:"AAL3",
                          region: "Brainstem", 
                          subRegion:[
                            
                          ] 
                        },
                        {
                          atlas:"Harvard-Oxford",
                          region: "Global", 
                          subRegion:[
                            
                          ] 
                        },
                        {
                          atlas:"Harvard-Oxford",
                          region: "Frontal", 
                          subRegion:[
                            "Frontal Pole", "Insula", "Frontal Sup", "Frontal Mid", "Frontal Inf Tri", "Frontal Inf Oper", "Precentral", "Frontal Med", 
                            "Supp Motor Area", "Cingulate Ant", "Frontal Orb", "Frontal Oper", "Central Oper", "Subcallosal", "Paracingulate"
                          ] 
                        },
                        {
                          atlas:"Harvard-Oxford",
                          region: "Retrosplenial", 
                          subRegion:[
                            "Cingulate Post", "Precuneus"
                          ] 
                        },
                        {
                          atlas:"Harvard-Oxford",
                          region: "Lateral temporal", 
                          subRegion:[
                            "Temporal Pole", "Temporal Sup Ant", "Temporal Sup Post", "Temporal Mid Ant", "Temporal Mid Post", "Temporal Mid Temporooccipital",
                            "Temporal Inf Ant", "Temporal Inf Post", "Temporal Inf Temporooccipital", "Planum Polare", "Heschl", "Planum Temporale", "Temporal fusiform Ant",
                            "Temporal fusiform Post", "Temporal Occipital Fusiform"
                          ] 
                        },
                        {
                          atlas:"Harvard-Oxford",
                          region: "Lateral parietal", 
                          subRegion:[
                            "Postcentral", "Parietal Sup Lobule", "Supramarginal Ant", "Supramarginal Post", "Angular", "Parietal Oper"
                          ] 
                        },
                        {
                          atlas:"Harvard-Oxford",
                          region: "Medial temporal", 
                          subRegion:[
                            "Parahippocampal Ant", "Parahippocampal Post", "Hippocampus", "Amygdala"
                          ] 
                        },
                        {
                          atlas:"Harvard-Oxford",
                          region: "Occipital lobe", 
                          subRegion:[
                            "Occipital Lat Sup", "Occipital Lat Inf", "Intracalcarine", "Occipital Pole", "Cuneal", "Lingual", "Occipital Fusiform", "Supracalcarine"
                          ] 
                        },
                        {
                          atlas:"Harvard-Oxford",
                          region: "Basal ganglia", 
                          subRegion:[
                            "Caudate", "Putamen", "Pallidum", "Thalamus", "Accumbens"
                          ] 
                        },
                        {
                          atlas:"Harvard-Oxford",
                          region: "Brainstem", 
                          subRegion:[
                            
                          ] 
                        },
                        
                      ]
                    },
                    {
                      subTitle: `Default ${productName} Tracer`, 
                      componentName: "DefaultTracer",
                    },
                  ],
                },
              ]
            },
            {
              styles:{width:"35%", height:"98%"}, 
              cards:[
                {
                  title: "CSV Formats",
                  componentName: "CSVFormat",
                },
                {
                  title: "Report Formats", 
                  componentName: "ReportFormats",
                },
              ]
            },
          ]
        },
      }
    })(),

    tau: (()=> {
      const productName = 'Tau';
      const productShortName = 'Tau';
      const productFullName = 'Tau';  
      const type1Selector = QuantTable_type1Selector['tau'];
      const type2Selector = QuantTable_type2Selector['tau']; 
      const tracers = [
        {fullname:'[18F]TAU', shortname:"[18F]TAU", color:"#4C74FF"},
      ];
      const defaultCSVFormat =[
          {title:"Include data",   state:true, subOption:[{title:"Total", state:true}, {title:"Left & Right", state:true}]},
          {title:"SUVR",           state:true, subOption:[                                                               ]},
          {title:"Centiloid",      state:true, subOption:[                                                               ]},
          {title:"Transpose data", state:true, subOption:[                                                               ]},
      ]
      const defaultAtlas = "AAL3";
      const defaultRef = {
        "[18F]TAU":"Cerebellar gray matter"
      }
      const defaultTracer = tracers[0];
      const defaultPInfoState = [
        {name:"Patient Name", state: true},
        {name:"Patient ID", state:true},
        {name:"DOB", state:true},
        {name:"Sex", state:true},
        {name:"Type & Date of Examination", state:true},
      ];
      const COLORMAP = [
        'invertedGray',
        'pet',
        'jet',
        'hot',
        'gray',
      ];
      return {
        CTN_Mode,
        Cloud_Mode,
        imageVerticalFlip: IMAGE_VERTICAL_FLIP,
        Date7:Date7,
        productName: productName,
        productShortName:productShortName,
        productFullName:productFullName,
        tracers:tracers,
        defaultCSVFormat:defaultCSVFormat,
        defaultAtlas:defaultAtlas,
        defaultRef:defaultRef,
        defaultTracer:defaultTracer,
        defaultPInfoState:defaultPInfoState,
        type1Selector:type1Selector,
        type2Selector:type2Selector,
        COLORMAP:COLORMAP,
        worklist:{
          top:{
            item1:[
              // {title:"Export all to PDF Fast", var:"downloadPDFFast"},
              {title:"Export all to PDF", var:"downloadPDF"},
              // {title:"Export all to JPEG", var:"downloadJPEG"},
              {title:"Export all to CSV", var:"downloadCSV_Group"},
              // {title:"Export all to Nifti", var:"downloadNifti_Group"},
              {title:"Export all to NIFTI (SUVR)", var:"downloadNifitiSUVR_Group"},
              {title:"Export all to NIFTI (Raw)", var:"downloadNifitiIntensity_Group"},
            ]
          }
        },
        dashboard:{
          bottom:[
            {
              cards:[
                {title: "Weekly Analysis", Date:`${Date7[6]} - ${Date7[0]}`, componentName: "BarGraph"},
              ],
            },
            {
              cards:[
                {title: `${productName} Tracers`, componentName: "PieGraph"}
              ]
            },
          ]
        },
        upload:{
          top:{
            btnGroup1:[
              {title:'View', icon:<img src={MEDIA_view} alt='view'/>, funcName:"view"},
              {title:'Analysis', icon:<img src={MEDIA_analysis}  alt='analysis'/>, funcName:"analysis"},
              {title:'Close', icon:<img src={MEDIA_close}  alt='close'/>, funcName:"close"},
            ],
            btnGroup2:[
              {title:'Worklist', icon:<img src={MEDIA_plus} alt='worklist add'/>, funcName:"worklist_add"},
              {title:'Worklist', icon:<img src={MEDIA_minus} alt='worklist delete'/>, funcName:"worklist_delete"},
            ],
            btnGroup3:[
              {title:'Delete', icon:<img src={MEDIA_trash} alt='file delete'/>, funcName:"file_delete"},
            ],
            btnGroup4:[
              {title:'Connect to PACS', icon:"", funcName:"pacs"},
              {title:'Upload', icon:"", funcName:"upload"},
            ],
          },
          bottom:{
            tableHead:[
              {title: 'Select', fieldName:'Select'},
              {title: 'Tracer', fieldName:'Tracer'},
              {title: 'Patient name', fieldName:'PatientName'},
              {title: 'Patient ID', fieldName:'PatientID'},
              {title: 'DOB', fieldName:'Age'},
              {title: 'Sex', fieldName:'Sex'},
              {title: 'Scan date', fieldName:'AcquisitionDateTime'},
              {title: 'Uploaded date', fieldName:'Update'},
            ],
            uploadingTableHead2:[
              {title: 'Select', fieldName:'Select'},
              {title: 'Patient ID', fieldName:'PatientID'},
              {title: 'Patient name', fieldName:'PatientName'},
              {title: 'DOB', fieldName:'BirthDate'},
              {title: 'Modality', fieldName: 'Modality'},
              {title: 'Study Date', fieldName: 'StudyDate'},
              {title: 'Study Description', fieldName: 'StudyDescription'},
              {title: 'Delete', fieldName: 'Delete'},
            ],
            uploadingTableHead3:[
              {title: 'Patient ID', fieldName:'PatientID'},
              {title: 'Patient name', fieldName:'PatientName'},
              {title: 'Series Description', fieldName: 'SeriesDescription'},
              {title: 'Delete', fieldName: 'Delete'},
            ],
            uploadingTableHead3Direct:[
              {title: 'Tracer', fieldName:'Tracer'},
              {title: 'Patient ID', fieldName:'PatientID'},
              {title: 'Patient name', fieldName:'PatientName'},
              // {title: 'Series Description', fieldName: 'SeriesDescription'},
              {title: 'Delete', fieldName: 'Delete'},
            ],
          },
        },
        view:{
          top:{
            item1:[
              {title:"Tracer", var:"Tracer"},
              {title:"Patient name", var:"PatientName" },
              {title:"Patient ID", var:"PatientID"},
              {title:"DOB", var:"Age"},
              {title:"Sex", var:"Sex"},
              {title:"Study Date", var:"AcquisitionDateTime"},
              {title:"Desc", var:"OriginalFileName"}
            ],
            item2:[
              {title:"Atlas", var:"defaultAtlas"},
              {title:"Reference", var:"defaultRef"},
            ],
            item3:[
              {title:"Generate a report", var:"openReport"},
              {title:"Export to CSV", var:"downloadCSV_Individual"},
              {title:"Export to NIFTI (SUVR)", var:"downloadNifitiSUVR_Individaul"},
              {title:"Export to NIFTI (Raw)", var:"downloadNifitiIntensity_Individaul"},
            ]
          },
        },
        analysis:{
          top:{
            item1:[
              {title:"Tracer", var:"Tracer"},
              {title:"Patient name", var:"PatientName" },
              {title:"Patient ID", var:"PatientID"},
              {title:"DOB", var:"Age"},
              {title:"Sex", var:"Sex"},
              {title:"Study Date", var:"AcquisitionDateTime"},
              {title:"Desc", var:"OriginalFileName"}
            ],
            item2:[
              {title:"Atlas", var:"defaultAtlas"},
              {title:"Reference", var:"defaultRef"},
            ],
            item3:[
              {title:"Generate a report", var:"openReport"},
              {title:"Export to CSV", var:"downloadCSV_Individual"},
              {title:"Export to NIFTI (SUVR)", var:"downloadNifitiSUVR_Individaul"},
              {title:"Export to NIFTI (Raw)", var:"downloadNifitiIntensity_Individaul"},
            ]
          },
          bottom:[
            {
              cards:[
                {
                  title: "Lobar SUVR", 
                  tableHead: ['image', 'value'], 
                  colorcode:amyloidCmap,
                  legend:'SUVR',
                  componentName: "Analysis_card1",
                  content:[
                    {atlas:"all", lobe:"Global Lobe",    Left:"Bilat.", Right:"Bilat.", Lvar:"Global",      Rvar:"Global",      BrainSVG:({Lobe1, Lobe2, Lobe3, Lobe4}={})=>{return <div style={{width:"55%"}}><BrainLobe fillFrontal={amyloidCmap[Lobe1]} fillParietal={amyloidCmap[Lobe2]} fillTemporal={amyloidCmap[Lobe3]} fillOccipital={amyloidCmap[Lobe4]}/></div>}}, 
                    {atlas:"all", lobe:"Frontal Lobe",   Left:"L",      Right:"R",      Lvar:"Frontal_L",   Rvar:"Frontal_R",   BrainSVG:({Lobe1, Lobe2, Lobe3, Lobe4}={})=>{return <div style={{width:"55%"}}><BrainLobe fillFrontal={amyloidCmap[Lobe1]} fillParietal={"gray"} fillTemporal={"gray"} fillOccipital={"gray"}/></div>}}, 
                    {atlas:"all", lobe:"Parietal Lobe",  Left:"L",      Right:"R",      Lvar:"Parietal_L",  Rvar:"Parietal_R",  BrainSVG:({Lobe1, Lobe2, Lobe3, Lobe4}={})=>{return <div style={{width:"55%"}}><BrainLobe fillFrontal={"gray"} fillParietal={amyloidCmap[Lobe2]} fillTemporal={"gray"} fillOccipital={"gray"}/></div>}}, 
                    {atlas:"all", lobe:"Temporal Lobe",  Left:"L",      Right:"R",      Lvar:"Temporal_L",  Rvar:"Temporal_R",  BrainSVG:({Lobe1, Lobe2, Lobe3, Lobe4}={})=>{return <div style={{width:"55%"}}><BrainLobe fillFrontal={"gray"} fillParietal={"gray"} fillTemporal={amyloidCmap[Lobe3]} fillOccipital={"gray"}/></div>}}, 
                    {atlas:"all", lobe:"Occipital Lobe", Left:"L",      Right:"R",      Lvar:"Occipital_L", Rvar:"Occipital_R", BrainSVG:({Lobe1, Lobe2, Lobe3, Lobe4}={})=>{return <div style={{width:"55%"}}><BrainLobe fillFrontal={"gray"} fillParietal={"gray"} fillTemporal={"gray"} fillOccipital={amyloidCmap[Lobe4]}/></div>}}, 
                  ]
                },
              ]
            },

            {
              cards:[
                {
                  title: "Regional SUVR", 
                  tableHead: ['Region', 'Total', 'Left', 'Right'], 
                  componentName: "Analysis_card2_1",
                  content:[
                    {atlas:"AAL3", fullname: "Global", varname: {Left:"Global",Right:"Global"}, 
                      subItem:[]},

                    {atlas:"AAL3", fullname: "Frontal", varname: {Left:"Frontal_L", Right:"Frontal_R"}, 
                      subItem:[
                        {fullname:"Precentral",        varname:{Left:"Precentral_L",        Right:"Precentral_R"       }},
                        {fullname:"Frontal Sup",       varname:{Left:"Frontal_Sup_L",       Right:"Frontal_Sup_R"      }},
                        {fullname:"Frontal Mid",       varname:{Left:"Frontal_Mid_L",       Right:"Frontal_Mid_R"      }},
                        {fullname:"Frontal Inf Oper",  varname:{Left:"Frontal_Inf_Oper_L",  Right:"Frontal_Inf_Oper_R" }},
                        {fullname:"Frontal Inf Tri",   varname:{Left:"Frontal_Inf_Tri_L",   Right:"Frontal_Inf_Tri_R"  }},
                        {fullname:"Frontal Inf Orb",   varname:{Left:"Frontal_Inf_Orb_L",   Right:"Frontal_Inf_Orb_R"  }},
                        {fullname:"Rolandic Oper",     varname:{Left:"Rolandic_Oper_L",     Right:"Rolandic_Oper_R"    }},
                        {fullname:"Supp Motor Area",   varname:{Left:"Supp_Motor_Area_L",   Right:"Supp_Motor_Area_R"  }},
                        {fullname:"Olfactory",         varname:{Left:"Olfactory_L",         Right:"Olfactory_R"        }},
                        {fullname:"Frontal Sup Med",   varname:{Left:"Frontal_Sup_Med_L",   Right:"Frontal_Sup_Med_R"  }},
                        {fullname:"Frontal Med Orb",   varname:{Left:"Frontal_Med_Orb_L",   Right:"Frontal_Med_Orb_R"  }},
                        {fullname:"Rectus",            varname:{Left:"Rectus_L",            Right:"Rectus_R"           }},
                        {fullname:"OFCmed",            varname:{Left:"OFCmed_L",            Right:"OFCmed_R"           }},
                        {fullname:"OFCant",            varname:{Left:"OFCant_L",            Right:"OFCant_R"           }},
                        {fullname:"OFCpost",           varname:{Left:"OFCpost_L",           Right:"OFCpost_R"          }},
                        {fullname:"OFClat",            varname:{Left:"OFClat_L",            Right:"OFClat_R"           }},
                        {fullname:"Insula",            varname:{Left:"Insula_L",            Right:"Insula_R"           }},
                        {fullname:"Cingulate Ant Sub", varname:{Left:"Cingulate_Ant_Sub_L", Right:"Cingulate_Ant_Sub_R"}},
                        {fullname:"Cingulate Ant Pre", varname:{Left:"Cingulate_Ant_Pre_L", Right:"Cingulate_Ant_Pre_R"}},
                        {fullname:"Cingulate Ant Sup", varname:{Left:"Cingulate_Ant_Sup_L", Right:"Cingulate_Ant_Sup_R"}},
                        {fullname:"Cingulate Mid",     varname:{Left:"Cingulate_Mid_L",     Right:"Cingulate_Mid_R"    }}
                      ]},

                    {atlas:"AAL3", fullname: "PCC-Precuneus", varname: {Left:"PCC_L", Right:"PCC_R"}, 
                      subItem:[
                        {fullname:"Cingulate Post", varname:{Left:"Cingulate_Post_L", Right:"Cingulate_Post_R"}},
                        {fullname:"Precuneus", varname:{Left:"Precuneus_L", Right:"Precuneus_R"}},
                      ]},

                    {atlas:"AAL3", fullname: "Lateral temporal", varname: {Left:"Lateral_temporal_L", Right:"Lateral_temporal_R"}, 
                      subItem:[
                        {fullname:"Heschl", varname: {Left:"Heschl_L", Right:"Heschl_R"}},
                        {fullname:"Temporal Sup", varname: {Left:"Temporal_Sup_L", Right:"Temporal_Sup_R"}},
                        {fullname:"Temporal Pole Sup", varname: {Left:"Temporal_Pole_Sup_L", Right:"Temporal_Pole_Sup_R"}},
                        {fullname:"Temporal Mid", varname: {Left:"Temporal_Mid_L", Right:"Temporal_Mid_R"}},
                        {fullname:"Temporal Pole Mid", varname: {Left:"Temporal_Pole_Mid_L", Right:"Temporal_Pole_Mid_R"}},
                        {fullname:"Temporal Inf", varname: {Left:"Temporal_Inf_L", Right:"Temporal_Inf_R"}},
                      ]},

                    {atlas:"AAL3", fullname: "Lateral parietal", varname: {Left:"Lateral_parietal_L", Right:"Lateral_parietal_R"}, 
                      subItem:[
                        {fullname: "Postcentral", varname: {Left:"Postcentral_L",Right:"Postcentral_R"}},
                        {fullname: "Parietal Sup", varname: {Left:"Parietal_Sup_L",Right:"Parietal_Sup_R"}},
                        {fullname: "Parietal Inf", varname: {Left:"Parietal_Inf_L",Right:"Parietal_Inf_R"}},
                        {fullname: "Supramarginal", varname: {Left:"Supramarginal_L",Right:"Supramarginal_R"}},
                        {fullname: "Angular", varname: {Left:"Angular_L",Right:"Angular_R"}},
                        {fullname: "Paracentral Lobule", varname: {Left:"Paracentral_Lobule_L",Right:"Paracentral_Lobule_R"}}
                      ]},

                    {atlas:"AAL3", fullname: "Medial temporal", varname: {Left:"Medial_temporal_L", Right:"Medial_temporal_R"}, 
                      subItem:[
                        {fullname: "Hippocampus", varname: {Left:"Hippocampus_L",Right:"Hippocampus_R"}},
                        {fullname: "Parahippocampal", varname: {Left:"Parahippocampal_L",Right:"Parahippocampal_R"}},
                        {fullname: "Amygdala", varname: {Left:"Amygdala_L",Right:"Amygdala_R"}},
                      ]},

                    {atlas:"AAL3", fullname: "Occipital", varname: {Left:"Occipital_L", Right:"Occipital_R"}, 
                      subItem:[
                        {fullname: "Calcarine", varname: {Left:"Calcarine_L",Right:"Calcarine_R"}},
                        {fullname: "Cuneus", varname: {Left:"Cuneus_L",Right:"Cuneus_R"}},
                        {fullname: "Lingual", varname: {Left:"Lingual_L",Right:"Lingual_R"}},
                        {fullname: "Occipital Sup", varname: {Left:"Occipital_Sup_L",Right:"Occipital_Sup_R"}},
                        {fullname: "Occipital Mid", varname: {Left:"Occipital_Mid_L",Right:"Occipital_Mid_R"}},
                        {fullname: "Occipital Inf", varname: {Left:"Occipital_Inf_L",Right:"Occipital_Inf_R"}},
                        {fullname: "Fusiform", varname: {Left:"Fusiform_L",Right:"Fusiform_R"}},
                      ]},
                    {atlas:"AAL3", fullname: "Basal ganglia", varname: {Left:"Basal_ganglia_L", Right:"Basal_ganglia_R"}, 
                      subItem:[
                        {fullname: "Caudate", varname: {Left:"Caudate_L",Right:"Caudate_R"}},
                        {fullname: "Putamen", varname: {Left:"Putamen_L",Right:"Putamen_R"}},
                        {fullname: "Pallidum", varname: {Left:"Pallidum_L",Right:"Pallidum_R"}},
                        {fullname: "Thalamus", varname: {Left:"Thalamus_L",Right:"Thalamus_R"}},
                        {fullname: "N Acc", varname: {Left:"N_Acc_L",Right:"N_Acc_R"}},
                      ]},
                    {atlas:"AAL3", fullname: "Brainstem", varname: {Left:"Brainstem_L", Right:"Brainstem_R"}, 
                      subItem:[]},

                    {atlas:"Harvard-Oxford", fullname: "Global", varname: {Left:"HO_Global",Right:"HO_Global"}, 
                      subItem:[]},

                    {atlas:"Harvard-Oxford", fullname: "Frontal", varname: {Left:"HO_Frontal_L", Right:"HO_Frontal_R"}, 
                      subItem:[
                        {fullname: "Frontal Pole", varname: {Left:"HO_Frontal_Pole_L",Right:"HO_Frontal_Pole_R"}},
                        {fullname: "Insula", varname: {Left:"HO_Insula_L",Right:"HO_Insula_R"}},
                        {fullname: "Frontal Sup", varname: {Left:"HO_Frontal_Sup_L",Right:"HO_Frontal_Sup_R"}},
                        {fullname: "Frontal Mid", varname: {Left:"HO_Frontal_Mid_L",Right:"HO_Frontal_Mid_R"}},
                        {fullname: "Frontal Inf Tri", varname: {Left:"HO_Frontal_Inf_Tri_L",Right:"HO_Frontal_Inf_Tri_R"}},
                        {fullname: "Frontal Inf Oper", varname: {Left:"HO_Frontal_Inf_Oper_L",Right:"HO_Frontal_Inf_Oper_R"}},
                        {fullname: "Precentral", varname: {Left:"HO_Precentral_L",Right:"HO_Precentral_R"}},
                        {fullname: "Frontal Med", varname: {Left:"HO_Frontal_Med_L",Right:"HO_Frontal_Med_R"}},
                        {fullname: "Supp Motor Area", varname: {Left:"HO_Supp_Motor_Area_L",Right:"HO_Supp_Motor_Area_R"}},
                        {fullname: "Cingulate Ant", varname: {Left:"HO_Cingulate_Ant_L",Right:"HO_Cingulate_Ant_R"}},
                        {fullname: "Frontal Orb", varname: {Left:"HO_Frontal_Orb_L",Right:"HO_Frontal_Orb_R"}},
                        {fullname: "Frontal Oper", varname: {Left:"HO_Frontal_Oper_L",Right:"HO_Frontal_Oper_R"}},
                        {fullname: "Central Oper", varname: {Left:"HO_Central_Oper_L",Right:"HO_Central_Oper_R"}},
                        {fullname: "Subcallosal", varname: {Left:"HO_Subcallosal_L",Right:"HO_Subcallosal_R"}},
                        {fullname: "Paracingulate", varname: {Left:"HO_Paracingulate_L",Right:"HO_Paracingulate_R"}},
                    ]},


                    {atlas:"Harvard-Oxford", fullname: "Retrosplenial", varname: {Left:"HO_PCC_L",Right:"HO_PCC_R"}, 
                      subItem:[
                        {fullname: "Cingulate Post", varname: {Left:"HO_Cingulate_Post_L",Right:"HO_Cingulate_Post_R"}},
                        {fullname: "Precuneus", varname: {Left:"HO_Precuneus_L",Right:"HO_Precuneus_R"}},
                    ]},

                    {atlas:"Harvard-Oxford", fullname: "Lateral temporal", varname: {Left:"HO_Lateral_temporal_L",Right:"HO_Lateral_temporal_R"}, 
                      subItem:[
                        {fullname: "Temporal Pole", varname: {Left:"HO_Temporal_Pole_L",Right:"HO_Temporal_Pole_R"}},
                        {fullname: "Temporal Sup Ant", varname: {Left:"HO_Temporal_Sup_Ant_L",Right:"HO_Temporal_Sup_Ant_R"}},
                        {fullname: "Temporal Sup Post", varname: {Left:"HO_Temporal_Sup_Post_L",Right:"HO_Temporal_Sup_Post_R"}},
                        {fullname: "Temporal Mid Ant", varname: {Left:"HO_Temporal_Mid_Ant_L",Right:"HO_Temporal_Mid_Ant_R"}},
                        {fullname: "Temporal Mid Post", varname: {Left:"HO_Temporal_Mid_Post_L",Right:"HO_Temporal_Mid_Post_R"}},
                        {fullname: "Temporal Mid Temporooccipital", varname: {Left:"HO_Temporal_Mid_Temporooccipital_L",Right:"HO_Temporal_Mid_Temporooccipital_R"}},
                        {fullname: "Temporal Inf Ant", varname: {Left:"HO_Temporal_Inf_Ant_L",Right:"HO_Temporal_Inf_Ant_R"}},
                        {fullname: "Temporal Inf Post", varname: {Left:"HO_Temporal_Inf_Post_L",Right:"HO_Temporal_Inf_Post_R"}},
                        {fullname: "Temporal Inf Temporooccipital", varname: {Left:"HO_Temporal_Inf_Temporooccipital_L",Right:"HO_Temporal_Inf_Temporooccipital_R"}},
                        {fullname: "Planum Polare", varname: {Left:"HO_Planum_Polare_L",Right:"HO_Planum_Polare_R"}},
                        {fullname: "Heschl", varname: {Left:"HO_Heschl_L",Right:"HO_Heschl_R"}},
                        {fullname: "Planum Temporale", varname: {Left:"HO_Planum_Temporale_L",Right:"HO_Planum_Temporale_R"}},
                        {fullname: "Temporal fusiform Ant", varname: {Left:"HO_Temporal_fusiform_Ant_L",Right:"HO_Temporal_fusiform_Ant_R"}},
                        {fullname: "Temporal fusiform Post", varname: {Left:"HO_Temporal_fusiform_Post_L",Right:"HO_Temporal_fusiform_Post_R"}},
                        {fullname: "Temporal Occipital Fusiform", varname: {Left:"HO_Temporal_Occipital_Fusiform_L",Right:"HO_Temporal_Occipital_Fusiform_R"}},
                    ]},

                    {atlas:"Harvard-Oxford", fullname: "Lateral parietal", varname: {Left:"HO_Lateral_parietal_L",Right:"HO_Lateral_parietal_R"}, 
                      subItem:[
                        {fullname: "Postcentral", varname: {Left:"HO_Postcentral_L",Right:"HO_Postcentral_R"}},
                        {fullname: "Parietal Sup Lobule", varname: {Left:"HO_Parietal_Sup_Lobule_L",Right:"HO_Parietal_Sup_Lobule_R"}},
                        {fullname: "Supramarginal Ant", varname: {Left:"HO_Supramarginal_Ant_L",Right:"HO_Supramarginal_Ant_R"}},
                        {fullname: "Supramarginal Post", varname: {Left:"HO_Supramarginal_Post_L",Right:"HO_Supramarginal_Post_R"}},
                        {fullname: "Angular", varname: {Left:"HO_Angular_L",Right:"HO_Angular_R"}},
                        {fullname: "Parietal Oper", varname: {Left:"HO_Parietal_Oper_L",Right:"HO_Parietal_Oper_R"}},
                    ]},
                    {atlas:"Harvard-Oxford", fullname: "Medial temporal", varname: {Left:"HO_Medial_temporal_L",Right:"HO_Medial_temporal_R"}, 
                      subItem:[
                        {fullname: "Parahippocampal Ant", varname: {Left:"HO_Parahippocampal_Ant_L",Right:"HO_Parahippocampal_Ant_R"}},
                        {fullname: "Parahippocampal Post", varname: {Left:"HO_Parahippocampal_Post_L",Right:"HO_Parahippocampal_Post_R"}},
                        {fullname: "Hippocampus", varname: {Left:"HO_Hippocampus_L",Right:"HO_Hippocampus_R"}},
                        {fullname: "Amygdala", varname: {Left:"HO_Amygdala_L",Right:"HO_Amygdala_R"}},
                    ]},
                    {atlas:"Harvard-Oxford", fullname: "Occipital", varname: {Left:"HO_Occipital_L",Right:"HO_Occipital_R"}, 
                      subItem:[
                        {fullname: "Occipital Lat_Sup", varname: {Left:"HO_Occipital_Lat_Sup_L",Right:"HO_Occipital_Lat_Sup_R"}},
                        {fullname: "Occipital Lat_Inf", varname: {Left:"HO_Occipital_Lat_Inf_L",Right:"HO_Occipital_Lat_Inf_R"}},
                        {fullname: "Intracalcarine", varname: {Left:"HO_Intracalcarine_L",Right:"HO_Intracalcarine_R"}},
                        {fullname: "Occipital Pole", varname: {Left:"HO_Occipital_Pole_L",Right:"HO_Occipital_Pole_R"}},
                        {fullname: "Cuneal", varname: {Left:"HO_Cuneal_L",Right:"HO_Cuneal_R"}},
                        {fullname: "Lingual", varname: {Left:"HO_Lingual_L",Right:"HO_Lingual_R"}},
                        {fullname: "Occipital Fusiform", varname: {Left:"HO_Occipital_Fusiform_L",Right:"HO_Occipital_Fusiform_R"}},
                        {fullname: "Supracalcarine", varname: {Left:"HO_Supracalcarine_L",Right:"HO_Supracalcarine_R"}},
                    ]},
                    {atlas:"Harvard-Oxford", fullname: "Basal ganglia", varname: {Left:"HO_Basal_ganglia_L",Right:"HO_Basal_ganglia_R"}, 
                      subItem:[
                        {fullname: "Caudate", varname: {Left:"HO_Caudate_L",Right:"HO_Caudate_R"}},
                        {fullname: "Putamen", varname: {Left:"HO_Putamen_L",Right:"HO_Putamen_R"}},
                        {fullname: "Pallidum", varname: {Left:"HO_Pallidum_L",Right:"HO_Pallidum_R"}},
                        {fullname: "Thalamus", varname: {Left:"HO_Thalamus_L",Right:"HO_Thalamus_R"}},
                        {fullname: "Accumbens", varname: {Left:"HO_Accumbens_L",Right:"HO_Accumbens_R"}},
                    ]},
                    {atlas:"Harvard-Oxford", fullname: "Brainstem", varname: {Left:"HO_Brainstem_L",Right:"HO_Brainstem_R"}, 
                      subItem:[]},

                  ]
                },
              ]
            },
            { 
              cards:[
                {
                  title: "Surface Projection",
                  componentName: "Analysis_card3",
                  content:["surface image1", "surface image2", "surface image3", "surface image4"]
                },
              ]
            },

          ]
        },
        setting:{
          top:{
            btnGroup1:[
              {title:'Reset', icon:<MdRefresh/>, funcName:'reset'},
              {title:'Save', icon:<MdSave/>, funcName:'save'},
            ],
          },
          bottom:[
            {
              styles:{width:"65%", height:"98%"}, 
              cards:[
                {
                  title: "", 
                  componentName: "MainConfig",
                  contents:[
                    {
                      subTitle: "Brain Atlas", 
                      atlasList: ["AAL3","Harvard-Oxford"],
                      subContent:[
                        {default:"AAL3",options:["AAL3","Harvard-Oxford"],}
                      ]
                    }, 
                    {
                      subTitle: "Reference Region", 
                      refList: ["Pons", "Cerebellar gray matter", "Cerebral white matter", "Whole cerebellum"],
                      subContent:[
                        { tracer: "[18F]TAU", fullname:"[18F]TAU", },
                      ]
                    }, 
                    {
                      subTitle: "Brain Regions To Analyze", 
                      subContent:[
                        {
                          atlas:"AAL3",
                          region: "Global", 
                          subRegion:[

                          ] 
                        },
                        {
                          atlas:"AAL3",
                          region: "Frontal", 
                          subRegion:[
                            "Precentral", "Frontal Sup", "Frontal Mid", "Frontal Inf Oper", "Frontal InfTri", "Frontal Inf Orb", "Rolandic Oper", 
                            "Supp Motor Area", "Olfactory", "Frontal Sup Med", "Frontal Med Orb", "Rectus", "OFCmed", "OFCant", "OFCpost", "OFClat",
                            "Insula", "Cingulate Ant Sub", "Cingulate Ant Pre", "Cingulate Ant Sup", "Cingulate Mid"
                          ] 
                        },
                        {
                          atlas:"AAL3",
                          region: "PCC-Precuneus", 
                          subRegion:[
                            "Cingulate Post", "Precuneus"
                          ] 
                        },
                        {
                          atlas:"AAL3",
                          region: "Lateral temporal", 
                          subRegion:[
                            "Heschl", "Temporal Sup", "Temporal Pole Sup", "Temporal Mid", "Temporal Pole Mid", "Temporal Inf"
                          ] 
                        },
                        {
                          atlas:"AAL3",
                          region: "Lateral parietal", 
                          subRegion:[
                            "Postcentral", "Parietal Sup", "Parietal Inf", "Supramarginal", "Angular", "Paracentral Lobule"
                          ] 
                        },
                        {
                          atlas:"AAL3",
                          region: "Medial temporal", 
                          subRegion:[
                            "Hippocampus", "Parahippocampal", "Amygdala"
                          ] 
                        },
                        {
                          atlas:"AAL3",
                          region: "Occipital", 
                          subRegion:[
                            "Calcarine", "Cuneus", "Lingual", "Occipital Sup", "Occipital Mid", "Occipital Inf", "Fusiform"
                          ] 
                        },
                        {
                          atlas:"AAL3",
                          region: "Basal ganglia", 
                          subRegion:[
                            "Caudate", "Putamen", "Pallidum", "Thalamus", "N Acc"
                          ] 
                        },
                        {
                          atlas:"AAL3",
                          region: "Brainstem", 
                          subRegion:[
                            
                          ] 
                        },
                        {
                          atlas:"Harvard-Oxford",
                          region: "Global", 
                          subRegion:[
                            
                          ] 
                        },
                        {
                          atlas:"Harvard-Oxford",
                          region: "Frontal", 
                          subRegion:[
                            "Frontal Pole", "Insula", "Frontal Sup", "Frontal Mid", "Frontal Inf Tri", "Frontal Inf Oper", "Precentral", "Frontal Med", 
                            "Supp Motor Area", "Cingulate Ant", "Frontal Orb", "Frontal Oper", "Central Oper", "Subcallosal", "Paracingulate"
                          ] 
                        },
                        {
                          atlas:"Harvard-Oxford",
                          region: "Retrosplenial", 
                          subRegion:[
                            "Cingulate Post", "Precuneus"
                          ] 
                        },
                        {
                          atlas:"Harvard-Oxford",
                          region: "Lateral temporal", 
                          subRegion:[
                            "Temporal Pole", "Temporal Sup Ant", "Temporal Sup Post", "Temporal Mid Ant", "Temporal Mid Post", "Temporal Mid Temporooccipital",
                            "Temporal Inf Ant", "Temporal Inf Post", "Temporal Inf Temporooccipital", "Planum Polare", "Heschl", "Planum Temporale", "Temporal fusiform Ant",
                            "Temporal fusiform Post", "Temporal Occipital Fusiform"
                          ] 
                        },
                        {
                          atlas:"Harvard-Oxford",
                          region: "Lateral parietal", 
                          subRegion:[
                            "Postcentral", "Parietal Sup Lobule", "Supramarginal Ant", "Supramarginal Post", "Angular", "Parietal Oper"
                          ] 
                        },
                        {
                          atlas:"Harvard-Oxford",
                          region: "Medial temporal", 
                          subRegion:[
                            "Parahippocampal Ant", "Parahippocampal Post", "Hippocampus", "Amygdala"
                          ] 
                        },
                        {
                          atlas:"Harvard-Oxford",
                          region: "Occipital lobe", 
                          subRegion:[
                            "Occipital Lat Sup", "Occipital Lat Inf", "Intracalcarine", "Occipital Pole", "Cuneal", "Lingual", "Occipital Fusiform", "Supracalcarine"
                          ] 
                        },
                        {
                          atlas:"Harvard-Oxford",
                          region: "Basal ganglia", 
                          subRegion:[
                            "Caudate", "Putamen", "Pallidum", "Thalamus", "Accumbens"
                          ] 
                        },
                        {
                          atlas:"Harvard-Oxford",
                          region: "Brainstem", 
                          subRegion:[
                            
                          ] 
                        },
                        
                      ]
                    },
                    {
                      subTitle: `Default ${productName} Tracer`, 
                      componentName: "DefaultTracer",
                    },
                  ],
                },
              ]
            },
            {
              styles:{width:"35%", height:"98%"}, 
              cards:[
                {
                  title: "CSV Formats",
                  componentName: "CSVFormat",
                },
                {
                  title: "Report Formats", 
                  componentName: "ReportFormats",
                },
              ]
            },
          ]
        },
      }
    })(),

    perfusion: (()=> {
      const productName = 'Perfusion';
      const productShortName = 'Perfusion';
      const productFullName = 'Perfusion';  
      const type1Selector = QuantTable_type1Selector['perfusion'];
      const type2Selector = QuantTable_type2Selector['perfusion']; 
      const tracers = [
        {fullname:'[99mtc]ECD', shortname:"[99mtc]ECD", color:"#935eff"},
        {fullname:'[99mtc]HMPAO', shortname:"[99mtc]HMPAO", color:"#ff3363"},
      ];
      const defaultCSVFormat =[
          {title:"Include data",   state:true, subOption:[{title:"Total", state:true}, {title:"Left & Right", state:true}]},
          {title:"SUVR",           state:true, subOption:[                                                               ]},
          {title:"Centiloid",      state:true, subOption:[                                                               ]},
          {title:"Transpose data", state:true, subOption:[                                                               ]},
      ]
      const defaultAtlas = "AAL3";
      const defaultRef = {
        "[99mtc]ECD":"Global cortex",
        "[99mtc]HMPAO":"Global cortex"
      }
      const defaultTracer = tracers[0];
      const defaultPInfoState = [
        {name:"Patient Name", state: true},
        {name:"Patient ID", state:true},
        {name:"DOB", state:true},
        {name:"Sex", state:true},
        {name:"Type & Date of Examination", state:true},
      ];
      const COLORMAP = [
        'invertedGray',
        'pet',
        'jet',
        'hot',
        'gray',
      ];
      return {
        CTN_Mode,
        Cloud_Mode,
        imageVerticalFlip: IMAGE_VERTICAL_FLIP,
        Date7:Date7,
        productName: productName,
        productShortName:productShortName,
        productFullName:productFullName,
        tracers:tracers,
        defaultCSVFormat:defaultCSVFormat,
        defaultAtlas:defaultAtlas,
        defaultRef:defaultRef,
        defaultTracer:defaultTracer,
        defaultPInfoState:defaultPInfoState,
        type1Selector:type1Selector,
        type2Selector:type2Selector,
        COLORMAP:COLORMAP,
        worklist:{
          top:{
            item1:[
              // {title:"Export all to PDF Fast", var:"downloadPDFFast"},
              {title:"Export all to PDF", var:"downloadPDF"},
              // {title:"Export all to JPEG", var:"downloadJPEG"},
              {title:"Export all to CSV", var:"downloadCSV_Group"},
              // {title:"Export all to Nifti", var:"downloadNifti_Group"},
              {title:"Export all to NIFTI (SUVR)", var:"downloadNifitiSUVR_Group"},
              {title:"Export all to NIFTI (Raw)", var:"downloadNifitiIntensity_Group"},
            ]
          }
        },
        dashboard:{
          bottom:[
            {
              cards:[
                {title: "Weekly Analysis", Date:`${Date7[6]} - ${Date7[0]}`, componentName: "BarGraph"},
              ],
            },
            {
              cards:[
                {title: `${productName} Tracers`, componentName: "PieGraph"}
              ]
            },
          ]
        },
        upload:{
          top:{
            btnGroup1:[
              {title:'View', icon:<img src={MEDIA_view} alt='view'/>, funcName:"view"},
              {title:'Analysis', icon:<img src={MEDIA_analysis}  alt='analysis'/>, funcName:"analysis"},
              {title:'Close', icon:<img src={MEDIA_close}  alt='close'/>, funcName:"close"},
            ],
            btnGroup2:[
              {title:'Worklist', icon:<img src={MEDIA_plus} alt='worklist add'/>, funcName:"worklist_add"},
              {title:'Worklist', icon:<img src={MEDIA_minus} alt='worklist delete'/>, funcName:"worklist_delete"},
            ],
            btnGroup3:[
              {title:'Delete', icon:<img src={MEDIA_trash} alt='file delete'/>, funcName:"file_delete"},
            ],
            btnGroup4:[
              {title:'Connect to PACS', icon:"", funcName:"pacs"},
              {title:'Upload', icon:"", funcName:"upload"},
            ],
          },
          bottom:{
            tableHead:[
              {title: 'Select', fieldName:'Select'},
              {title: 'Tracer', fieldName:'Tracer'},
              {title: 'Patient name', fieldName:'PatientName'},
              {title: 'Patient ID', fieldName:'PatientID'},
              {title: 'DOB', fieldName:'Age'},
              {title: 'Sex', fieldName:'Sex'},
              {title: 'Scan date', fieldName:'AcquisitionDateTime'},
              {title: 'Uploaded date', fieldName:'Update'},
            ],
            uploadingTableHead2:[
              {title: 'Select', fieldName:'Select'},
              {title: 'Patient ID', fieldName:'PatientID'},
              {title: 'Patient name', fieldName:'PatientName'},
              {title: 'DOB', fieldName:'BirthDate'},
              {title: 'Modality', fieldName: 'Modality'},
              {title: 'Study Date', fieldName: 'StudyDate'},
              {title: 'Study Description', fieldName: 'StudyDescription'},
              {title: 'Delete', fieldName: 'Delete'},
            ],
            uploadingTableHead3:[
              {title: 'Patient ID', fieldName:'PatientID'},
              {title: 'Patient name', fieldName:'PatientName'},
              {title: 'Series Description', fieldName: 'SeriesDescription'},
              {title: 'Flip', fieldName:'Flip'},
              {title: 'Delete', fieldName: 'Delete'},
            ],
            uploadingTableHead3Direct:[
              {title: 'Tracer', fieldName:'Tracer'},
              {title: 'Patient ID', fieldName:'PatientID'},
              {title: 'Patient name', fieldName:'PatientName'},
              {title: 'Flip', fieldName:'Flip'},
              // {title: 'Series Description', fieldName: 'SeriesDescription'},
              {title: 'Delete', fieldName: 'Delete'},
            ],
          },
        },
        view:{
          top:{
            item1:[
              {title:"Tracer", var:"Tracer"},
              {title:"Patient name", var:"PatientName" },
              {title:"Patient ID", var:"PatientID"},
              {title:"DOB", var:"Age"},
              {title:"Sex", var:"Sex"},
              {title:"Study Date", var:"AcquisitionDateTime"},
              {title:"Desc", var:"OriginalFileName"}
            ],
            item2:[
              {title:"Atlas", var:"defaultAtlas"},
              {title:"Reference", var:"defaultRef"},
            ],
            item3:[
              {title:"Generate a report", var:"openReport"},
              {title:"Export to CSV", var:"downloadCSV_Individual"},
              {title:"Export to NIFTI (SUVR)", var:"downloadNifitiSUVR_Individaul"},
              {title:"Export to NIFTI (Raw)", var:"downloadNifitiIntensity_Individaul"},
            ]
          },
        },
        analysis:{
          top:{
            item1:[
              {title:"Tracer", var:"Tracer"},
              {title:"Patient name", var:"PatientName" },
              {title:"Patient ID", var:"PatientID"},
              {title:"DOB", var:"Age"},
              {title:"Sex", var:"Sex"},
              {title:"Study Date", var:"AcquisitionDateTime"},
              {title:"Desc", var:"OriginalFileName"}
            ],
            item2:[
              {title:"Atlas", var:"defaultAtlas"},
              {title:"Reference", var:"defaultRef"},
            ],
            item3:[
              {title:"Generate a report", var:"openReport"},
              {title:"Export to CSV", var:"downloadCSV_Individual"},
              {title:"Export to NIFTI (SUVR)", var:"downloadNifitiSUVR_Individaul"},
              {title:"Export to NIFTI (Raw)", var:"downloadNifitiIntensity_Individaul"},
            ]
          },
          bottom:[
            {
              cards:[
                {
                  title: "Lobar SUVR", 
                  tableHead: ['image', 'value'], 
                  // #19 brainTable colorcode 변경 fdg와 동일하게
                  colorcode:fdgCmap,
                  // colorcode:amyloidCmap,
                  legend:'SUVR',
                  componentName: "Analysis_card1",
                  content:[
                    {atlas:"all", lobe:"Global Lobe",    Left:"Bilat.", Right:"Bilat.", Lvar:"Global",      Rvar:"Global",      BrainSVG:({Lobe1, Lobe2, Lobe3, Lobe4}={})=>{return <div style={{width:"55%"}}><BrainLobe fillFrontal={fdgCmap[Lobe1]} fillParietal={fdgCmap[Lobe2]} fillTemporal={fdgCmap[Lobe3]} fillOccipital={fdgCmap[Lobe4]}/></div>}}, 
                    {atlas:"all", lobe:"Frontal Lobe",   Left:"L",      Right:"R",      Lvar:"Frontal_L",   Rvar:"Frontal_R",   BrainSVG:({Lobe1, Lobe2, Lobe3, Lobe4}={})=>{return <div style={{width:"55%"}}><BrainLobe fillFrontal={fdgCmap[Lobe1]} fillParietal={"gray"} fillTemporal={"gray"} fillOccipital={"gray"}/></div>}}, 
                    {atlas:"all", lobe:"Parietal Lobe",  Left:"L",      Right:"R",      Lvar:"Parietal_L",  Rvar:"Parietal_R",  BrainSVG:({Lobe1, Lobe2, Lobe3, Lobe4}={})=>{return <div style={{width:"55%"}}><BrainLobe fillFrontal={"gray"} fillParietal={fdgCmap[Lobe2]} fillTemporal={"gray"} fillOccipital={"gray"}/></div>}}, 
                    {atlas:"all", lobe:"Temporal Lobe",  Left:"L",      Right:"R",      Lvar:"Temporal_L",  Rvar:"Temporal_R",  BrainSVG:({Lobe1, Lobe2, Lobe3, Lobe4}={})=>{return <div style={{width:"55%"}}><BrainLobe fillFrontal={"gray"} fillParietal={"gray"} fillTemporal={fdgCmap[Lobe3]} fillOccipital={"gray"}/></div>}}, 
                    {atlas:"all", lobe:"Occipital Lobe", Left:"L",      Right:"R",      Lvar:"Occipital_L", Rvar:"Occipital_R", BrainSVG:({Lobe1, Lobe2, Lobe3, Lobe4}={})=>{return <div style={{width:"55%"}}><BrainLobe fillFrontal={"gray"} fillParietal={"gray"} fillTemporal={"gray"} fillOccipital={fdgCmap[Lobe4]}/></div>}}, 
                  ]
                },
              ]
            },

            {
              cards:[
                {
                  title: "Regional SUVR", 
                  tableHead: ['Region', 'Total', 'Left', 'Right'], 
                  componentName: "Analysis_card2_1",
                  content:[
                    {atlas:"AAL3", fullname: "Global", varname: {Left:"Global",Right:"Global"}, 
                      subItem:[]},

                    {atlas:"AAL3", fullname: "Frontal", varname: {Left:"Frontal_L", Right:"Frontal_R"}, 
                      subItem:[
                        {fullname:"Precentral",        varname:{Left:"Precentral_L",        Right:"Precentral_R"       }},
                        {fullname:"Frontal Sup",       varname:{Left:"Frontal_Sup_L",       Right:"Frontal_Sup_R"      }},
                        {fullname:"Frontal Mid",       varname:{Left:"Frontal_Mid_L",       Right:"Frontal_Mid_R"      }},
                        {fullname:"Frontal Inf Oper",  varname:{Left:"Frontal_Inf_Oper_L",  Right:"Frontal_Inf_Oper_R" }},
                        {fullname:"Frontal Inf Tri",   varname:{Left:"Frontal_Inf_Tri_L",   Right:"Frontal_Inf_Tri_R"  }},
                        {fullname:"Frontal Inf Orb",   varname:{Left:"Frontal_Inf_Orb_L",   Right:"Frontal_Inf_Orb_R"  }},
                        {fullname:"Rolandic Oper",     varname:{Left:"Rolandic_Oper_L",     Right:"Rolandic_Oper_R"    }},
                        {fullname:"Supp Motor Area",   varname:{Left:"Supp_Motor_Area_L",   Right:"Supp_Motor_Area_R"  }},
                        {fullname:"Olfactory",         varname:{Left:"Olfactory_L",         Right:"Olfactory_R"        }},
                        {fullname:"Frontal Sup Med",   varname:{Left:"Frontal_Sup_Med_L",   Right:"Frontal_Sup_Med_R"  }},
                        {fullname:"Frontal Med Orb",   varname:{Left:"Frontal_Med_Orb_L",   Right:"Frontal_Med_Orb_R"  }},
                        {fullname:"Rectus",            varname:{Left:"Rectus_L",            Right:"Rectus_R"           }},
                        {fullname:"OFCmed",            varname:{Left:"OFCmed_L",            Right:"OFCmed_R"           }},
                        {fullname:"OFCant",            varname:{Left:"OFCant_L",            Right:"OFCant_R"           }},
                        {fullname:"OFCpost",           varname:{Left:"OFCpost_L",           Right:"OFCpost_R"          }},
                        {fullname:"OFClat",            varname:{Left:"OFClat_L",            Right:"OFClat_R"           }},
                        {fullname:"Insula",            varname:{Left:"Insula_L",            Right:"Insula_R"           }},
                        {fullname:"Cingulate Ant Sub", varname:{Left:"Cingulate_Ant_Sub_L", Right:"Cingulate_Ant_Sub_R"}},
                        {fullname:"Cingulate Ant Pre", varname:{Left:"Cingulate_Ant_Pre_L", Right:"Cingulate_Ant_Pre_R"}},
                        {fullname:"Cingulate Ant Sup", varname:{Left:"Cingulate_Ant_Sup_L", Right:"Cingulate_Ant_Sup_R"}},
                        {fullname:"Cingulate Mid",     varname:{Left:"Cingulate_Mid_L",     Right:"Cingulate_Mid_R"    }}
                      ]},

                    {atlas:"AAL3", fullname: "PCC-Precuneus", varname: {Left:"PCC_L", Right:"PCC_R"}, 
                      subItem:[
                        {fullname:"Cingulate Post", varname:{Left:"Cingulate_Post_L", Right:"Cingulate_Post_R"}},
                        {fullname:"Precuneus", varname:{Left:"Precuneus_L", Right:"Precuneus_R"}},
                      ]},

                    {atlas:"AAL3", fullname: "Lateral temporal", varname: {Left:"Lateral_temporal_L", Right:"Lateral_temporal_R"}, 
                      subItem:[
                        {fullname:"Heschl", varname: {Left:"Heschl_L", Right:"Heschl_R"}},
                        {fullname:"Temporal Sup", varname: {Left:"Temporal_Sup_L", Right:"Temporal_Sup_R"}},
                        {fullname:"Temporal Pole Sup", varname: {Left:"Temporal_Pole_Sup_L", Right:"Temporal_Pole_Sup_R"}},
                        {fullname:"Temporal Mid", varname: {Left:"Temporal_Mid_L", Right:"Temporal_Mid_R"}},
                        {fullname:"Temporal Pole Mid", varname: {Left:"Temporal_Pole_Mid_L", Right:"Temporal_Pole_Mid_R"}},
                        {fullname:"Temporal Inf", varname: {Left:"Temporal_Inf_L", Right:"Temporal_Inf_R"}},
                      ]},

                    {atlas:"AAL3", fullname: "Lateral parietal", varname: {Left:"Lateral_parietal_L", Right:"Lateral_parietal_R"}, 
                      subItem:[
                        {fullname: "Postcentral", varname: {Left:"Postcentral_L",Right:"Postcentral_R"}},
                        {fullname: "Parietal Sup", varname: {Left:"Parietal_Sup_L",Right:"Parietal_Sup_R"}},
                        {fullname: "Parietal Inf", varname: {Left:"Parietal_Inf_L",Right:"Parietal_Inf_R"}},
                        {fullname: "Supramarginal", varname: {Left:"Supramarginal_L",Right:"Supramarginal_R"}},
                        {fullname: "Angular", varname: {Left:"Angular_L",Right:"Angular_R"}},
                        {fullname: "Paracentral Lobule", varname: {Left:"Paracentral_Lobule_L",Right:"Paracentral_Lobule_R"}}
                      ]},

                    {atlas:"AAL3", fullname: "Medial temporal", varname: {Left:"Medial_temporal_L", Right:"Medial_temporal_R"}, 
                      subItem:[
                        {fullname: "Hippocampus", varname: {Left:"Hippocampus_L",Right:"Hippocampus_R"}},
                        {fullname: "Parahippocampal", varname: {Left:"Parahippocampal_L",Right:"Parahippocampal_R"}},
                        {fullname: "Amygdala", varname: {Left:"Amygdala_L",Right:"Amygdala_R"}},
                      ]},

                    {atlas:"AAL3", fullname: "Occipital", varname: {Left:"Occipital_L", Right:"Occipital_R"}, 
                      subItem:[
                        {fullname: "Calcarine", varname: {Left:"Calcarine_L",Right:"Calcarine_R"}},
                        {fullname: "Cuneus", varname: {Left:"Cuneus_L",Right:"Cuneus_R"}},
                        {fullname: "Lingual", varname: {Left:"Lingual_L",Right:"Lingual_R"}},
                        {fullname: "Occipital Sup", varname: {Left:"Occipital_Sup_L",Right:"Occipital_Sup_R"}},
                        {fullname: "Occipital Mid", varname: {Left:"Occipital_Mid_L",Right:"Occipital_Mid_R"}},
                        {fullname: "Occipital Inf", varname: {Left:"Occipital_Inf_L",Right:"Occipital_Inf_R"}},
                        {fullname: "Fusiform", varname: {Left:"Fusiform_L",Right:"Fusiform_R"}},
                      ]},
                    {atlas:"AAL3", fullname: "Basal ganglia", varname: {Left:"Basal_ganglia_L", Right:"Basal_ganglia_R"}, 
                      subItem:[
                        {fullname: "Caudate", varname: {Left:"Caudate_L",Right:"Caudate_R"}},
                        {fullname: "Putamen", varname: {Left:"Putamen_L",Right:"Putamen_R"}},
                        {fullname: "Pallidum", varname: {Left:"Pallidum_L",Right:"Pallidum_R"}},
                        {fullname: "Thalamus", varname: {Left:"Thalamus_L",Right:"Thalamus_R"}},
                        {fullname: "N Acc", varname: {Left:"N_Acc_L",Right:"N_Acc_R"}},
                      ]},
                    {atlas:"AAL3", fullname: "Brainstem", varname: {Left:"Brainstem_L", Right:"Brainstem_R"}, 
                      subItem:[]},

                    {atlas:"Harvard-Oxford", fullname: "Global", varname: {Left:"HO_Global",Right:"HO_Global"}, 
                      subItem:[]},

                    {atlas:"Harvard-Oxford", fullname: "Frontal", varname: {Left:"HO_Frontal_L", Right:"HO_Frontal_R"}, 
                      subItem:[
                        {fullname: "Frontal Pole", varname: {Left:"HO_Frontal_Pole_L",Right:"HO_Frontal_Pole_R"}},
                        {fullname: "Insula", varname: {Left:"HO_Insula_L",Right:"HO_Insula_R"}},
                        {fullname: "Frontal Sup", varname: {Left:"HO_Frontal_Sup_L",Right:"HO_Frontal_Sup_R"}},
                        {fullname: "Frontal Mid", varname: {Left:"HO_Frontal_Mid_L",Right:"HO_Frontal_Mid_R"}},
                        {fullname: "Frontal Inf Tri", varname: {Left:"HO_Frontal_Inf_Tri_L",Right:"HO_Frontal_Inf_Tri_R"}},
                        {fullname: "Frontal Inf Oper", varname: {Left:"HO_Frontal_Inf_Oper_L",Right:"HO_Frontal_Inf_Oper_R"}},
                        {fullname: "Precentral", varname: {Left:"HO_Precentral_L",Right:"HO_Precentral_R"}},
                        {fullname: "Frontal Med", varname: {Left:"HO_Frontal_Med_L",Right:"HO_Frontal_Med_R"}},
                        {fullname: "Supp Motor Area", varname: {Left:"HO_Supp_Motor_Area_L",Right:"HO_Supp_Motor_Area_R"}},
                        {fullname: "Cingulate Ant", varname: {Left:"HO_Cingulate_Ant_L",Right:"HO_Cingulate_Ant_R"}},
                        {fullname: "Frontal Orb", varname: {Left:"HO_Frontal_Orb_L",Right:"HO_Frontal_Orb_R"}},
                        {fullname: "Frontal Oper", varname: {Left:"HO_Frontal_Oper_L",Right:"HO_Frontal_Oper_R"}},
                        {fullname: "Central Oper", varname: {Left:"HO_Central_Oper_L",Right:"HO_Central_Oper_R"}},
                        {fullname: "Subcallosal", varname: {Left:"HO_Subcallosal_L",Right:"HO_Subcallosal_R"}},
                        {fullname: "Paracingulate", varname: {Left:"HO_Paracingulate_L",Right:"HO_Paracingulate_R"}},
                    ]},


                    {atlas:"Harvard-Oxford", fullname: "Retrosplenial", varname: {Left:"HO_PCC_L",Right:"HO_PCC_R"}, 
                      subItem:[
                        {fullname: "Cingulate Post", varname: {Left:"HO_Cingulate_Post_L",Right:"HO_Cingulate_Post_R"}},
                        {fullname: "Precuneus", varname: {Left:"HO_Precuneus_L",Right:"HO_Precuneus_R"}},
                    ]},

                    {atlas:"Harvard-Oxford", fullname: "Lateral temporal", varname: {Left:"HO_Lateral_temporal_L",Right:"HO_Lateral_temporal_R"}, 
                      subItem:[
                        {fullname: "Temporal Pole", varname: {Left:"HO_Temporal_Pole_L",Right:"HO_Temporal_Pole_R"}},
                        {fullname: "Temporal Sup Ant", varname: {Left:"HO_Temporal_Sup_Ant_L",Right:"HO_Temporal_Sup_Ant_R"}},
                        {fullname: "Temporal Sup Post", varname: {Left:"HO_Temporal_Sup_Post_L",Right:"HO_Temporal_Sup_Post_R"}},
                        {fullname: "Temporal Mid Ant", varname: {Left:"HO_Temporal_Mid_Ant_L",Right:"HO_Temporal_Mid_Ant_R"}},
                        {fullname: "Temporal Mid Post", varname: {Left:"HO_Temporal_Mid_Post_L",Right:"HO_Temporal_Mid_Post_R"}},
                        {fullname: "Temporal Mid Temporooccipital", varname: {Left:"HO_Temporal_Mid_Temporooccipital_L",Right:"HO_Temporal_Mid_Temporooccipital_R"}},
                        {fullname: "Temporal Inf Ant", varname: {Left:"HO_Temporal_Inf_Ant_L",Right:"HO_Temporal_Inf_Ant_R"}},
                        {fullname: "Temporal Inf Post", varname: {Left:"HO_Temporal_Inf_Post_L",Right:"HO_Temporal_Inf_Post_R"}},
                        {fullname: "Temporal Inf Temporooccipital", varname: {Left:"HO_Temporal_Inf_Temporooccipital_L",Right:"HO_Temporal_Inf_Temporooccipital_R"}},
                        {fullname: "Planum Polare", varname: {Left:"HO_Planum_Polare_L",Right:"HO_Planum_Polare_R"}},
                        {fullname: "Heschl", varname: {Left:"HO_Heschl_L",Right:"HO_Heschl_R"}},
                        {fullname: "Planum Temporale", varname: {Left:"HO_Planum_Temporale_L",Right:"HO_Planum_Temporale_R"}},
                        {fullname: "Temporal fusiform Ant", varname: {Left:"HO_Temporal_fusiform_Ant_L",Right:"HO_Temporal_fusiform_Ant_R"}},
                        {fullname: "Temporal fusiform Post", varname: {Left:"HO_Temporal_fusiform_Post_L",Right:"HO_Temporal_fusiform_Post_R"}},
                        {fullname: "Temporal Occipital Fusiform", varname: {Left:"HO_Temporal_Occipital_Fusiform_L",Right:"HO_Temporal_Occipital_Fusiform_R"}},
                    ]},

                    {atlas:"Harvard-Oxford", fullname: "Lateral parietal", varname: {Left:"HO_Lateral_parietal_L",Right:"HO_Lateral_parietal_R"}, 
                      subItem:[
                        {fullname: "Postcentral", varname: {Left:"HO_Postcentral_L",Right:"HO_Postcentral_R"}},
                        {fullname: "Parietal Sup Lobule", varname: {Left:"HO_Parietal_Sup_Lobule_L",Right:"HO_Parietal_Sup_Lobule_R"}},
                        {fullname: "Supramarginal Ant", varname: {Left:"HO_Supramarginal_Ant_L",Right:"HO_Supramarginal_Ant_R"}},
                        {fullname: "Supramarginal Post", varname: {Left:"HO_Supramarginal_Post_L",Right:"HO_Supramarginal_Post_R"}},
                        {fullname: "Angular", varname: {Left:"HO_Angular_L",Right:"HO_Angular_R"}},
                        {fullname: "Parietal Oper", varname: {Left:"HO_Parietal_Oper_L",Right:"HO_Parietal_Oper_R"}},
                    ]},
                    {atlas:"Harvard-Oxford", fullname: "Medial temporal", varname: {Left:"HO_Medial_temporal_L",Right:"HO_Medial_temporal_R"}, 
                      subItem:[
                        {fullname: "Parahippocampal Ant", varname: {Left:"HO_Parahippocampal_Ant_L",Right:"HO_Parahippocampal_Ant_R"}},
                        {fullname: "Parahippocampal Post", varname: {Left:"HO_Parahippocampal_Post_L",Right:"HO_Parahippocampal_Post_R"}},
                        {fullname: "Hippocampus", varname: {Left:"HO_Hippocampus_L",Right:"HO_Hippocampus_R"}},
                        {fullname: "Amygdala", varname: {Left:"HO_Amygdala_L",Right:"HO_Amygdala_R"}},
                    ]},
                    {atlas:"Harvard-Oxford", fullname: "Occipital", varname: {Left:"HO_Occipital_L",Right:"HO_Occipital_R"}, 
                      subItem:[
                        {fullname: "Occipital Lat_Sup", varname: {Left:"HO_Occipital_Lat_Sup_L",Right:"HO_Occipital_Lat_Sup_R"}},
                        {fullname: "Occipital Lat_Inf", varname: {Left:"HO_Occipital_Lat_Inf_L",Right:"HO_Occipital_Lat_Inf_R"}},
                        {fullname: "Intracalcarine", varname: {Left:"HO_Intracalcarine_L",Right:"HO_Intracalcarine_R"}},
                        {fullname: "Occipital Pole", varname: {Left:"HO_Occipital_Pole_L",Right:"HO_Occipital_Pole_R"}},
                        {fullname: "Cuneal", varname: {Left:"HO_Cuneal_L",Right:"HO_Cuneal_R"}},
                        {fullname: "Lingual", varname: {Left:"HO_Lingual_L",Right:"HO_Lingual_R"}},
                        {fullname: "Occipital Fusiform", varname: {Left:"HO_Occipital_Fusiform_L",Right:"HO_Occipital_Fusiform_R"}},
                        {fullname: "Supracalcarine", varname: {Left:"HO_Supracalcarine_L",Right:"HO_Supracalcarine_R"}},
                    ]},
                    {atlas:"Harvard-Oxford", fullname: "Basal ganglia", varname: {Left:"HO_Basal_ganglia_L",Right:"HO_Basal_ganglia_R"}, 
                      subItem:[
                        {fullname: "Caudate", varname: {Left:"HO_Caudate_L",Right:"HO_Caudate_R"}},
                        {fullname: "Putamen", varname: {Left:"HO_Putamen_L",Right:"HO_Putamen_R"}},
                        {fullname: "Pallidum", varname: {Left:"HO_Pallidum_L",Right:"HO_Pallidum_R"}},
                        {fullname: "Thalamus", varname: {Left:"HO_Thalamus_L",Right:"HO_Thalamus_R"}},
                        {fullname: "Accumbens", varname: {Left:"HO_Accumbens_L",Right:"HO_Accumbens_R"}},
                    ]},
                    {atlas:"Harvard-Oxford", fullname: "Brainstem", varname: {Left:"HO_Brainstem_L",Right:"HO_Brainstem_R"}, 
                      subItem:[]},

                  ]
                },
              ]
            },
            { 
              cards:[
                {
                  title: "Surface Projection",
                  componentName: "Analysis_card3",
                  content:["surface image1", "surface image2", "surface image3", "surface image4"]
                },
              ]
            },

          ]
        },
        setting:{
          top:{
            btnGroup1:[
              {title:'Reset', icon:<MdRefresh/>, funcName:'reset'},
              {title:'Save', icon:<MdSave/>, funcName:'save'},
            ],
          },
          bottom:[
            {
              styles:{width:"65%", height:"98%"}, 
              cards:[
                {
                  title: "", 
                  componentName: "MainConfig",
                  contents:[
                    {
                      subTitle: "Brain Atlas", 
                      atlasList: ["AAL3","Harvard-Oxford"],
                      subContent:[
                        {default:"AAL3",options:["AAL3","Harvard-Oxford"],}
                      ]
                    }, 
                    {
                      subTitle: "Reference Region", 
                      refList: ["Pons", "Cerebellar gray matter", "Cerebral white matter", "Whole cerebellum", "Global cortex"],
                      subContent:[
                        { tracer: "[99mtc]ECD", fullname:"[99mtc]ECD", },
                        { tracer: '[99mtc]HMPAO', fullname:'[99mtc]HMPAO', },
                      ]
                    }, 
                    {
                      subTitle: "Brain Regions To Analyze", 
                      subContent:[
                        {
                          atlas:"AAL3",
                          region: "Global", 
                          subRegion:[

                          ] 
                        },
                        {
                          atlas:"AAL3",
                          region: "Frontal", 
                          subRegion:[
                            "Precentral", "Frontal Sup", "Frontal Mid", "Frontal Inf Oper", "Frontal InfTri", "Frontal Inf Orb", "Rolandic Oper", 
                            "Supp Motor Area", "Olfactory", "Frontal Sup Med", "Frontal Med Orb", "Rectus", "OFCmed", "OFCant", "OFCpost", "OFClat",
                            "Insula", "Cingulate Ant Sub", "Cingulate Ant Pre", "Cingulate Ant Sup", "Cingulate Mid"
                          ] 
                        },
                        {
                          atlas:"AAL3",
                          region: "PCC-Precuneus", 
                          subRegion:[
                            "Cingulate Post", "Precuneus"
                          ] 
                        },
                        {
                          atlas:"AAL3",
                          region: "Lateral temporal", 
                          subRegion:[
                            "Heschl", "Temporal Sup", "Temporal Pole Sup", "Temporal Mid", "Temporal Pole Mid", "Temporal Inf"
                          ] 
                        },
                        {
                          atlas:"AAL3",
                          region: "Lateral parietal", 
                          subRegion:[
                            "Postcentral", "Parietal Sup", "Parietal Inf", "Supramarginal", "Angular", "Paracentral Lobule"
                          ] 
                        },
                        {
                          atlas:"AAL3",
                          region: "Medial temporal", 
                          subRegion:[
                            "Hippocampus", "Parahippocampal", "Amygdala"
                          ] 
                        },
                        {
                          atlas:"AAL3",
                          region: "Occipital", 
                          subRegion:[
                            "Calcarine", "Cuneus", "Lingual", "Occipital Sup", "Occipital Mid", "Occipital Inf", "Fusiform"
                          ] 
                        },
                        {
                          atlas:"AAL3",
                          region: "Basal ganglia", 
                          subRegion:[
                            "Caudate", "Putamen", "Pallidum", "Thalamus", "N Acc"
                          ] 
                        },
                        {
                          atlas:"AAL3",
                          region: "Brainstem", 
                          subRegion:[
                            
                          ] 
                        },
                        {
                          atlas:"Harvard-Oxford",
                          region: "Global", 
                          subRegion:[
                            
                          ] 
                        },
                        {
                          atlas:"Harvard-Oxford",
                          region: "Frontal", 
                          subRegion:[
                            "Frontal Pole", "Insula", "Frontal Sup", "Frontal Mid", "Frontal Inf Tri", "Frontal Inf Oper", "Precentral", "Frontal Med", 
                            "Supp Motor Area", "Cingulate Ant", "Frontal Orb", "Frontal Oper", "Central Oper", "Subcallosal", "Paracingulate"
                          ] 
                        },
                        {
                          atlas:"Harvard-Oxford",
                          region: "Retrosplenial", 
                          subRegion:[
                            "Cingulate Post", "Precuneus"
                          ] 
                        },
                        {
                          atlas:"Harvard-Oxford",
                          region: "Lateral temporal", 
                          subRegion:[
                            "Temporal Pole", "Temporal Sup Ant", "Temporal Sup Post", "Temporal Mid Ant", "Temporal Mid Post", "Temporal Mid Temporooccipital",
                            "Temporal Inf Ant", "Temporal Inf Post", "Temporal Inf Temporooccipital", "Planum Polare", "Heschl", "Planum Temporale", "Temporal fusiform Ant",
                            "Temporal fusiform Post", "Temporal Occipital Fusiform"
                          ] 
                        },
                        {
                          atlas:"Harvard-Oxford",
                          region: "Lateral parietal", 
                          subRegion:[
                            "Postcentral", "Parietal Sup Lobule", "Supramarginal Ant", "Supramarginal Post", "Angular", "Parietal Oper"
                          ] 
                        },
                        {
                          atlas:"Harvard-Oxford",
                          region: "Medial temporal", 
                          subRegion:[
                            "Parahippocampal Ant", "Parahippocampal Post", "Hippocampus", "Amygdala"
                          ] 
                        },
                        {
                          atlas:"Harvard-Oxford",
                          region: "Occipital lobe", 
                          subRegion:[
                            "Occipital Lat Sup", "Occipital Lat Inf", "Intracalcarine", "Occipital Pole", "Cuneal", "Lingual", "Occipital Fusiform", "Supracalcarine"
                          ] 
                        },
                        {
                          atlas:"Harvard-Oxford",
                          region: "Basal ganglia", 
                          subRegion:[
                            "Caudate", "Putamen", "Pallidum", "Thalamus", "Accumbens"
                          ] 
                        },
                        {
                          atlas:"Harvard-Oxford",
                          region: "Brainstem", 
                          subRegion:[
                            
                          ] 
                        },
                        
                      ]
                    },
                    {
                      subTitle: `Default ${productName} Tracer`, 
                      componentName: "DefaultTracer",
                    },
                  ],
                },
              ]
            },
            {
              styles:{width:"35%", height:"98%"}, 
              cards:[
                {
                  title: "CSV Formats",
                  componentName: "CSVFormat",
                },
                {
                  title: "Report Formats", 
                  componentName: "ReportFormats",
                },
              ]
            },
          ]
        },
      }
    })()
  }
})()
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { coreItem } from '0_variables/coreItem';
import { Burger } from '5_components';
import styles from './Information.module.scss';

export default function Information({ burgerProps }) {
  const params = useParams();
  const location = useLocation();
  const productName = params.product.toLowerCase();
  const pageName = location.pathname.split('/')[2];
  const fileList = useSelector((state) => state.fileList);
  const settingOfProduct = useSelector((state) => state.setting[productName]);
  const selectedFile = fileList.find(({ fileID }) => fileID === params.fileID);
  const productCoreItem = coreItem[productName];
  const CTN_Mode = productCoreItem.CTN_Mode;
  const { top } = productCoreItem[pageName];
  const tracerList = productCoreItem.tracers;

  const [patientInfo, setPatientInfo] = useState([]);
  const [templateInfo, setTemplateInfo] = useState([]);

  useEffect(() => {
    if (selectedFile) {
      const tracerColor = tracerList.reduce((obj, item) => {
        obj[item.shortname] = item.color;
        return obj;
      }, {});

      const tempPatientInfo = top.item1.map((item) => {
        let leftBorderStyle = 'none';
        let content = selectedFile[item.var];

        if (item.title === 'Tracer') {
          leftBorderStyle = `0.35rem solid ${tracerColor[content]}`;
          content =
            ' ' +
            content
              .replace('18', '\u00B9\u2078')
              .replace('99mtc', '\u2079\u2079\u1d50Tc');
        }

        return {
          title: item.title,
          content,
          className: item.title.replaceAll(' ', '_'),
          leftBorderStyle,
        };
      });
      setPatientInfo(tempPatientInfo);
    }
  }, [selectedFile, top.item1, tracerList]);

  useEffect(() => {
    if (selectedFile) {
      const striatum_pair = {
        'Melbourne Subcortical': {
          extrastriatumAtlas: 'AAL3, ATAG',
          firstRegion: [
            'Dorsal striatum',
            'Dorsal raphe nucleus',
            'Substantia nigra',
          ],
        },
        AAL3: {
          extrastriatumAtlas: 'ATAG',
          firstRegion: ['Dorsal raphe nucleus', 'Substantia nigra'],
        },
        'Harvard-Oxford': {
          extrastriatumAtlas: 'AAL3, ATAG',
          firstRegion: ['Caudate', 'Substantia nigra'],
        },
      };

      const tempTemplateInfo = top.item2.map((item) => {
        let content = settingOfProduct[item.var];

        if (item.title === 'Atlas' && productName === 'dat') {
          content = content + ', ' + striatum_pair[content].extrastriatumAtlas;
        } else if (item.title === 'Reference') {
          content = content[selectedFile.Tracer];
        }

        return {
          title: item.title,
          content,
        };
      });
      setTemplateInfo(tempTemplateInfo);
    }
  }, [productName, selectedFile, settingOfProduct, top.item2]);

  return (
    <div className={styles.container}>
      <div className={styles.infoContainer}>
        <div className={styles.pInfo}>
          {patientInfo.map((item, i) => (
            <div key={i}>
              <div>{item.title}</div>
              <div
                className={`${styles[item.className]} tooltip-inf`}
                data-tooltip-content={item.content}
              >
                <div className={styles.wrapper}>
                  <span
                    style={{
                      borderLeft: item.leftBorderStyle,
                    }}
                  >
                    {item.content}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
        {!CTN_Mode && (
          <div className={styles.ref_indicator}>
            {templateInfo.map((item, i) => (
              <div key={i}>
                <div>{item.title}</div>
                <div>
                  <span>{item.content}</span>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {!CTN_Mode && burgerProps !== null && (
        <div className={styles.burger}>
          <Burger {...burgerProps} />
        </div>
      )}

      <ReactTooltip
        anchorSelect=".tooltip-inf"
        place="top"
        opacity="1"
        offset={3}
      />
    </div>
  );
}

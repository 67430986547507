import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { RiCheckboxBlankLine, RiCheckboxFill } from 'react-icons/ri';
import { coreItem } from '0_variables/coreItem';
import { PacsTable } from '5_components';
import styles from './UploadingStep3.module.scss';

export default function UploadingStep3({
  stepIndex,
  isActivate,
  uploadingStepProps,
}) {
  const { getResult: searchResults, selectTracer } = uploadingStepProps;
  const params = useParams();
  const location = useLocation();
  const productName = params.product.toLowerCase();
  const productCoreItem = coreItem[productName];
  const pageName = location.pathname.split('/')[2];
  const tableHead = productCoreItem[pageName].bottom.uploadingTableHead3;
  const userName = sessionStorage.getItem('username');
  const jpgUrlHead = `${process.env.REACT_APP_BASE_URL}result/download/${userName}/uploader/`;
  const imageVerticalFlip = useSelector(
    (state) => state.setting[productName].imageVerticalFlip,
  );
  const [worklistChecker, setWorklistChecker] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [sortClick, setSortClick] = useState({});
  const [flipStatus, setFlipStatus] = useState([]);
  const [sortedList, setSortedList] = useState([]); // 정렬된 리스트
  const [tracerInfoList, setTracerInfoList] = useState([]); // tracer list by file

  const _clickHandler = useCallback(
    (selectedObj) => {
      if (isActivate) {
        if (selectedObj === null) {
          setSelectedItem(null);
        } else {
          const newSelectedItem = searchResults.find(
            (el) => el.id === selectedObj.id,
          );
          setSelectedItem(newSelectedItem);
        }
      }
    },
    [isActivate, searchResults],
  );

  useEffect(() => {
    const tempList = searchResults.sort((a, b) => {
      if (Object.keys(sortClick).length !== 0) {
        const targetKey = tableHead.find(
          (v) => v.title === Object.keys(sortClick)[0],
        ).fieldName;
        if (Object.values(sortClick)[0] === 'ascend')
          return a[targetKey] < b[targetKey] ? -1 : 1;
        else if (Object.values(sortClick)[0] === 'descend') {
          return b[targetKey] < a[targetKey] ? -1 : 1;
        } else return b.id - a.id;
      } else {
        return b.id - a.id;
      }
    });

    // tracer info by file
    const tempTracerList = tempList.map((fileData) => ({
      idx: fileData.id,
      clickState: false,
      tracer: fileData.Tracer ? fileData.Tracer : selectTracer.shortname,
    }));

    setTracerInfoList(tempTracerList);
    setSortedList([...tempList]);
  }, [searchResults, sortClick, tableHead, selectTracer]);

  useEffect(() => {
    if (isActivate && sortedList.length) {
      if (imageVerticalFlip) setFlipStatus(sortedList.map((v) => v.id));

      // 첫번째 항목 선택
      _clickHandler(sortedList[0]);
    }
  }, [_clickHandler, imageVerticalFlip, isActivate, sortedList]);

  return (
    <>
      <div
        className={styles.boxContainer}
        ref={(el) => uploadingStepProps.handleRef(stepIndex, el)}
      >
        <span
          className={styles.backButton}
          onClick={() => {
            uploadingStepProps.executeScroll(stepIndex - 1);
            uploadingStepProps.deleteFiles();
          }}
        >
          {'\u1438 Back'}
        </span>

        <div className={styles.boxTitle}>
          {/* <div><label for='PatientID'>        Patient ID         <input id='PatientID'        value={inputs.PatientID}        name="PatientID"        placeholder="0000-0000"           onChange={handleChange}/></label></div>
          <div><label for='StudyDate'>        Study Date         <input id='StudyDate'        value={inputs.StudyDate}        name="StudyDate"        placeholder="YYYYMMDD - YYYYMMDD" onChange={handleChange}/></label></div>
          <div><label for='StudyDescription'> Study Description  <input id='StudyDescription' value={inputs.StudyDescription} name="StudyDescription" placeholder="Tracer Memo"         onChange={handleChange}/></label></div>
          <div className={styles.searchBtn} onClick={()=>findHandler()}>  Search</div> */}
        </div>

        <div className={styles.boxContent}>
          {isActivate && (
            <PacsTable
              productName={productName}
              tableHead={tableHead}
              sortedList={sortedList}
              sortClick={sortClick}
              setSortClick={setSortClick}
              deleteHandler={(item, seriesID, _) => {
                uploadingStepProps._deleteGetHandler(item, seriesID, _);
                _clickHandler(null);
              }}
              clickHandler={(obj) => {
                _clickHandler(obj);
              }}
              doubleClickHandler={(v1) => {}}
              foldable={false}
              selectedItem={selectedItem}
              updateSelectPatient={null}
              updateTracerHandeler={uploadingStepProps.updateTracerHandeler}
              tracerInfoList={tracerInfoList}
              identifier={uploadingStepProps.identifier}
              flipStatus={flipStatus}
              setFlipStatus={setFlipStatus}
            />
          )}
          <div className={`${styles.previewImg}`}>
            <div>
              {selectedItem?.FileName && (
                <img
                  className={
                    flipStatus.includes(selectedItem?.id)
                      ? styles.verticalFlip
                      : 'none'
                  }
                  src={
                    jpgUrlHead +
                    selectedItem?.FileName?.split('.')[0] +
                    '_hy.jpg'
                  }
                  alt=""
                />
              )}
            </div>
            <div>
              {selectedItem?.FileName && (
                <img
                  className={
                    flipStatus.includes(selectedItem?.id)
                      ? styles.verticalFlip
                      : 'none'
                  }
                  src={
                    jpgUrlHead +
                    selectedItem?.FileName?.split('.')[0] +
                    '_hx.jpg'
                  }
                  alt=""
                />
              )}
            </div>
            <div>
              {selectedItem?.FileName && (
                <img
                  src={
                    jpgUrlHead +
                    selectedItem?.FileName?.split('.')[0] +
                    '_hz.jpg'
                  }
                  alt=""
                />
              )}
            </div>
            <div></div>
          </div>
        </div>

        <div className={styles.buttonGroup}>
          <div
            className={styles.nextButton}
            onClick={() => {
              const newGetResult = uploadingStepProps.getResult.map((item) => {
                return { ...item, Flip: flipStatus.includes(item.id) };
              });
              uploadingStepProps.runHandler(worklistChecker, newGetResult);
            }}
          >
            {'Run'}
          </div>
          <div className={styles.optionText}>
            {(() => {
              if (worklistChecker)
                return (
                  <RiCheckboxFill
                    color={styles['icon-color']}
                    size={styles['icon-size']}
                    onClick={() => setWorklistChecker(!worklistChecker)}
                  />
                );
              else
                return (
                  <RiCheckboxBlankLine
                    size={styles['icon-size']}
                    onClick={() => setWorklistChecker(!worklistChecker)}
                  />
                );
            })()}
            <div>&nbsp;{'Add to worklist right away'}</div>
          </div>
        </div>
      </div>
    </>
  );
}

import React from 'react';
import styles from './ReportPage1.module.scss';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { coreItem } from '0_variables/coreItem';
import { RefFactor, dataFormatForBrainTable } from '0_variables/utils';
import { BrainTable, PatientInfoTable } from '5_components';
import {
  reportIcon1 as MEDIA_reportIcon1,
  reportIcon2 as MEDIA_reportIcon2,
} from '6_media';
import ReportLayout from '../ReportLayout/ReportLayout';

export default function ReportPage1(props) {
  const params = useParams();
  const productName = params.product.toLowerCase();
  const pageName = 'analysis';
  const productCoreItem = coreItem[productName];
  const bottomCards = productCoreItem[pageName].bottom;
  const setting = useSelector((state) => state.setting);

  const settingOfProduct = setting[productName];
  const tracerName = props.selectedFile['Tracer'];
  const refName = settingOfProduct.defaultRef[tracerName];

  const tracerNames = {
    '[18F]FMM': '[18F]Flutemetamol',
    '[18F]FBP': '[18F]Florbetapir',
    '[18F]FBB': '[18F]Florbetaben',
    '[18F]FPN': '[18F]Florapronol',
    '[18F]FPCIT': '[18F]FP-CIT',
    '[18F]FDG': '[18F]FDG',
    '[18F]TAU': '[18F]TAU',
    '[99mtc]HMPAO': '[99mtc]HMPAO',
    '[99mtc]ECD': '[99mtc]ECD',
  };

  const RF = RefFactor({
    productName,
    refName,
    ponsRF: props.selectedFile['ratio_gry2pons'],
    crblRF: props.selectedFile['ratio_gry2crbl'],
    wmRF: props.selectedFile['ratio_gry2wm'],
    cgRF: props.selectedFile['ratio_gry2cg'],
    global: 1 / props.selectedFile['Global'],
  });

  const dataForCard1 = dataFormatForBrainTable({
    productName,
    columnList: bottomCards[0].cards[0].content,
    selectedFile: props.selectedFile,
    settingOfProduct: setting[productName],
    RF,
  });
  return (
    <>
      <ReportLayout {...props} PatientID={props.selectedFile.PatientID}>
        <div className={styles.PatientInfoContainer}>
          <div className={styles.ReportTitle}>
            <img src={MEDIA_reportIcon1} alt="" />
            &nbsp;{'Patient Information'}
            <div style={{ visibility: 'hidden' }}>{props.fileID}</div>
          </div>
          <div className={styles.PatientInfoTable}>
            <PatientInfoTable
              id={`patientInfo-table-${props.order}`}
              contents={{
                'Patient ID': props.selectedFile.PatientID,
                Name: props.selectedFile.PatientName,
                DOB: props.selectedFile.Age,
                Sex: props.selectedFile.Sex,
                'Study Date': props.selectedFile.AcquisitionDateTime,
                'Study Description': tracerNames[props.selectedFile.Tracer],
              }}
            />
          </div>
        </div>
        <div className={styles.BrainTableContainer}>
          <div className={styles.ReportTitle}>
            <img src={MEDIA_reportIcon2} alt="" />
            &nbsp;{'Quantification Results'}
          </div>
          <div className={styles.BrainTable}>
            <div id={`brain-table-${props.order}`}>
              <BrainTable
                borderEnable={false}
                legend={bottomCards[0].cards[0].legend}
                colorcode={bottomCards[0].cards[0].colorcode}
                items={dataForCard1}
                refName={refName}
                RF={RF}
              />
            </div>
          </div>
        </div>
      </ReportLayout>
    </>
  );
}

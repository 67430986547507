import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  clipConfig: { status: false, speed: 20 },
  // opacity: CTN_Mode ? 1 : productName === 'dat' ? 1 : 0.6,
  opacity: 0.6,
  // colormap: productName !== 'dat' ? 'jet' : 'invertGray',
  colorMap: 'jet',
  showCrosshair: true,
  showNormalized: true,
  showInverted: false,
  window_max_out: 32767,
  window_min_out: 0,
  window_max_in: 32767,
  window_min_in: 0,
};

const viewOptionsSlice = createSlice({
  name: 'viewOptions',
  initialState,
  reducers: {
    resetOptions: () => initialState,
    updateViewOption: (state, action) => {
      const { optionName, updateValue } = action.payload;
      state[optionName] = updateValue;
    },
  },
});

export const { resetOptions, updateViewOption } = viewOptionsSlice.actions;

export default viewOptionsSlice.reducer;

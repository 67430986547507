import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { BiTrash } from 'react-icons/bi';
import { ItemLength as getItemLength } from '0_variables/utils';
import { openSpinner } from '1_reduxs/reducers/modalReducer';
import { fetchFilesStore } from '1_reduxs/reducers/filesReducer';
import { deleteFilefromPacs, postPacs, runFile } from '2_services/uploadApi';
import {
  UploadingStep1,
  UploadingStep2,
  UploadingStep3,
} from './../components';
import styles from './PacsModal.module.scss';

export default function PacsModal(props) {
  const dispatch = useDispatch();
  const setting = useSelector((state) => state.setting);
  const setReloadChecker = useSelector((state) => state.modal.uploadModal.setReloadChecker);
  const params = useParams();
  const productName = params.product.toLowerCase();
  const [selectTracer, setSelectTracer] = useState(
    setting[productName].defaultTracer,
  );
  const [findResult, setFindResult] = useState([]);
  const [getResult, setGetResult] = useState([]);
  const [selectedIdx, setSelecteIdx] = useState(0);

  const stepRefArr = useRef([]);

  const handleRef = (idx, el) => {
    stepRefArr.current[idx] = el;
  };

  const executeScroll = (idx) => {
    if (idx >= 0 && idx < 3) {
      setSelecteIdx(idx);
      stepRefArr.current[idx].scrollIntoView({
        behavior: 'smooth',
        inline: 'nearest',
      });
    } else {
      console.error('no works, ref index is out of range');
    }
  };

  const deleteFiles = useCallback(async () => {
    const token = sessionStorage.getItem('token');

    try {
      dispatch(
        openSpinner({
          status: true,
          length: 0,
          message: 'Loading...',
          target: 'NONE',
        }),
      );
      await deleteFilefromPacs({ token: token });
    } catch (err) {
      alert('Network Error: Please check if Btxbrain-backend is running.', err);
    } finally {
      dispatch(
        openSpinner({
          status: false,
          length: 0,
          message: '',
          target: 'COMPLETE',
        }),
      );
    }
  }, [dispatch]);

  const findHandler = async (inputs) => {
    const token = sessionStorage.getItem('token');
    let res;
    try {
      dispatch(
        openSpinner({
          status: true,
          length: 0,
          message: 'Searching...',
          target: 'NONE',
        }),
      );
      res = await postPacs({
        Method: 'find',
        Tracer: selectTracer.shortname,
        PatientID: inputs.PatientID,
        StudyDate: inputs.StudyDate,
        StudyDescription: inputs.StudyDescription,
        token: token,
      });
    } catch (err) {
      // sessionStorage.removeItem('username')
      // sessionStorage.removeItem('token')
      // dispatch(actionsLogin.setLoginRedux({ username: "", token: "", logged: false }))
      // TODO: PACS 검색(find) 실패했을 때 오류처리 필요
    } finally {
      dispatch(
        openSpinner({
          status: false,
          length: 0,
          message: '',
          target: 'PACS',
        }),
      );
    }
    if (res.data.length === 0) {
      alert('Failed to find any matching data. Please try other search terms.');
    } else {
      const structuredFindResult = res.data.map((item, idx) => ({
        ...item,
        id: idx,
        Select: false,
        seriesItem: item.SeriesInfo[0].map((el, idx) => ({
          Select: false,
          id: idx,
          Marker: '\u2937', //"↳",
          PatientID: item.SeriesInfo[0][idx], //"↳",U+3008
          PatientName: item.SeriesInfo[3][idx],
          StudyDescription: item.SeriesInfo[1][idx],
          Modality: item.SeriesInfo[2][idx],
          Delete: <BiTrash size={'0.8vw'} />,
          // StudyDate:    item.SeriesInfo[3][idx],
        })),
        Delete: <BiTrash size={'0.8vw'} />,
      }));

      setFindResult(structuredFindResult);
    }
  };

  const _deleteFindHandler = (item, studyID, seriesID) => {
    if (seriesID === -1) {
      const filterResult = findResult.filter((el1) => el1.id !== studyID);
      setFindResult(filterResult);
    } else {
      const targetStudy = findResult.find((el1) => el1.id === studyID);
      const filteredSeriesItem = targetStudy.seriesItem.filter(
        (el2) => el2.id !== seriesID,
      );
      if (filteredSeriesItem.length === 0) {
        const filterResult = findResult.filter((el1) => el1.id !== studyID);
        setFindResult(filterResult);
      } else {
        const targetIdx = targetStudy.seriesItem.findIndex(
          (el2) => el2.id === seriesID,
        );
        const filteredSeriesInfo = targetStudy.SeriesInfo.map((item1, idx1) =>
          item1.filter((item2, idx2) => idx2 !== targetIdx),
        );
        targetStudy.seriesItem = filteredSeriesItem;
        targetStudy.SeriesInfo = filteredSeriesInfo;
        setFindResult([...findResult]);
      }
    }
  };

  const updateSelectPatient = (PatientState, studyID) => {
    const studyID_Object = findResult.find((v) => v.id === studyID);
    const updateState_seriesItem = {
      ...studyID_Object,
      Select: PatientState,
      seriesItem: studyID_Object.seriesItem.map((v) => ({
        ...v,
        Select: PatientState,
      })),
    };
    const update_findResult = findResult.map((v) =>
      v.id === studyID ? updateState_seriesItem : v,
    );
    setFindResult([...update_findResult]);
  };

  const _selectFindHandler = (item, studyId, seriesID) => {
    const newFindResult = findResult.map((studyFile) => {
      if (studyFile.id === studyId) {
        const findSeriesItem = studyFile.seriesItem.find(
          ({ id }) => id === seriesID,
        );

        if (findSeriesItem) {
          findSeriesItem.Select = !findSeriesItem.Select;
        }

        studyFile.Select = studyFile.seriesItem.some((v) => v.Select);
      }

      return studyFile;
    });
    setFindResult([...newFindResult]);
  };

  const getHandler = async () => {
    const Array_PatientID = findResult.map((v) => v.PatientID);
    const Array_PatientName = findResult.map((v) => v.PatientName);
    const Array_StudyDate = findResult.map((v) => v.StudyDate);
    const Array_StudyDescription = findResult.map((v) => v.StudyDescription);
    const Array_StudyInstanceUID = findResult.map((v) => v.StudyInstanceUID);
    const Array_SeriesInfo = findResult.map((v1) => {
      const selectArr = v1.seriesItem.map((v2) => v2.Select);
      const seriesInfo = v1.SeriesInfo;
      const filteredSeriesInfo = seriesInfo.map((v2) =>
        v2.filter((v3, i3) => selectArr[i3]),
      );
      return filteredSeriesInfo;
    });
    const ItemLength = getItemLength(findResult);
    const token = sessionStorage.getItem('token');

    let res;
    let tic, toc;
    let pacsSpeed;
    try {
      // debugger;
      dispatch(
        openSpinner({
          status: true,
          message: 'Downloading...',
          target: '',
        }),
      );
      // dispatch(actionModal.open_spinner({status:true, length:0, message:`${'COUNTER-'+ItemLength}`}));
      tic = new Date().getTime();
      // debugger;
      res = await postPacs({
        Method: 'get',
        Tracer: selectTracer.shortname,
        PatientID: [Array_PatientID[0]],
        PatientName: [Array_PatientName[0]],
        StudyInstanceUID: [Array_StudyInstanceUID[0]],
        SeriesInfo: [Array_SeriesInfo[0]],
        StudyDate: [Array_StudyDate[0]],
        StudyDescription: [Array_StudyDescription[0]],
        token: token,
      });
      toc = new Date().getTime();
      pacsSpeed = 1 / Math.ceil((toc - tic) / 1000);
    } catch (err) {
      // sessionStorage.removeItem('username')
      // sessionStorage.removeItem('token')
      // dispatch(actionsLogin.setLoginRedux({ username: "", token: "", logged: false }))
      // TODO: PACS 다운로드(get)명령 에러 처리 필요
      alert(
        'Failed to download the file(s). Please check your connection to PACS system.',
      );
    }

    try {
      dispatch(
        openSpinner({
          status: true,
          length: { seriesNum: ItemLength + 1, speed: pacsSpeed },
          message: 'Downloading...',
          target: 'PACS',
        }),
      );
      // dispatch(actionModal.open_spinner({status:true, length:0, message:`${'COUNTER-'+ItemLength}`}));
      // debugger;
      res = await postPacs({
        Method: 'get',
        Tracer: selectTracer.shortname,
        PatientID: Array_PatientID,
        PatientName: Array_PatientName,
        StudyInstanceUID: Array_StudyInstanceUID,
        SeriesInfo: Array_SeriesInfo,
        StudyDate: Array_StudyDate,
        StudyDescription: Array_StudyDescription,
        token: token,
      });
    } catch (err) {
      // sessionStorage.removeItem('username')
      // sessionStorage.removeItem('token')
      // dispatch(actionsLogin.setLoginRedux({ username: "", token: "", logged: false }))
      // TODO: PACS 다운로드(get)명령 에러 처리 필요
      alert(
        'Failed to download the file(s). Please check your connection to PACS system.',
      );
    } finally {
      dispatch(
        openSpinner({
          status: true,
          length: 0,
          message: 'Complete',
          target: 'COMPLETE',
        }),
      );
      setTimeout(() => {
        dispatch(
          openSpinner({
            status: false,
            length: 0,
            message: 'Complete',
            target: 'COMPLETE',
          }),
        );
      }, 1000);
    }

    if (res.data.length === 0) {
      alert('Failed to find any matching data. Please try other search terms.');
    } else {
      const structuredGetResult = res.data.map((item, idx) => ({
        ...item,
        Marker: '\u3008',
        Delete: <BiTrash size={'0.8vw'} />,
        seriesItem: [],
      }));
      setGetResult(structuredGetResult);
    }
  };

  const updateTracerHandeler = (FileList, updateTracer) => {
    const newFileList = FileList.map((fileData) => {
      const findTracer = updateTracer.find((el) => el.idx === fileData.id);

      return findTracer ? { ...fileData, Tracer: findTracer.tracer } : fileData;
    });

    setGetResult(newFileList);
  };

  const _deleteGetHandler = (item, seriesID, _) => {
    const filteredGetResult = getResult.filter((el1) => el1.id !== seriesID);
    setGetResult(filteredGetResult);
  };

  const runHandler = async (worklistChecker, getResult) => {
    const token = sessionStorage.getItem('token');
    try {
      const ItemLength = getItemLength(findResult);
      dispatch(
        openSpinner({
          status: true,
          length: ItemLength,
          message: 'Downloading...',
          target: 'PACS',
        }),
      );
      
      const data = {
        token: token,
        obj: getResult,
        Tracer: selectTracer.shortname,
        addToWorklist: worklistChecker,
      };
      if (process.env.REACT_APP_IS_CLOUD === 'true') {
        data.product = productName;
        data.setReloadChecker = setReloadChecker;
      }
      const res = await runFile(data);
      dispatch(fetchFilesStore({ items: res.data }));
    } catch (e) {
      // TODO: 파일 분석 시작 (run) 명령에 대한 에러처리 필요
      alert('Failed to running data(s), Please check api server again.');
    } finally {
      // TODO: 이때 전체 fileList를 한번 받아서 갱신시켜줄 필요 있음
      // ***주기적으로 upload에서 request를 보내면서 갱신하는것은 통신효율적으로 좀 문제가 있음***
      dispatch(
        openSpinner({
          status: false,
          length: 0,
          message: '',
          target: 'PACS',
        }),
      );
      props.closeModal();
    }
  };

  useEffect(() => {
    // remove backend upload files
    return () => {
      deleteFiles();
    };
  }, [deleteFiles]);

  const UploadingStep1Props = {
    selectTracer,
    setSelectTracer,
    handleRef,
    executeScroll,
  };
  const UploadingStep2Props = {
    findResult,
    handleRef,
    executeScroll,
    findHandler,
    _deleteFindHandler,
    _selectFindHandler,
    getHandler,
    updateSelectPatient,
    identifier: 'pacs',
  };
  const UploadingStep3Props = {
    getResult,
    handleRef,
    executeScroll,
    _deleteGetHandler,
    runHandler,
    deleteFiles,
    updateTracerHandeler,
    selectTracer,
    identifier: 'pacs',
  };

  return (
    <div
      className={`${styles['container']}`}
      onClick={() => {
        if (window.confirm('Are you sure you want to cancel the upload?')) {
          props.closeModal();
          deleteFiles();
        }
      }}
    >
      <div
        className={styles.stepContainer}
        id="pacs_modal"
        onClick={(e) => {
          e.stopPropagation();
          // dispatch(actionModal.open_spinner({status:true, length:0, message:'Complete', target:'COMPLETE'}));
        }}
      >
        <UploadingStep1
          stepIndex={0}
          uploadingStepProps={UploadingStep1Props}
        />
        <UploadingStep2
          stepIndex={1}
          isActivate={selectedIdx === 1}
          uploadingStepProps={UploadingStep2Props}
        />
        <UploadingStep3
          stepIndex={2}
          isActivate={selectedIdx === 2}
          uploadingStepProps={UploadingStep3Props}
        />
      </div>
    </div>
  );
}

import * as filesApi from '2_services/filesApi';
import { openSpinner } from '1_reduxs/reducers/modalReducer';
import { fetchFilesStore, closeAllFiles } from '1_reduxs/reducers/filesReducer';

export function checkFileThunk({ check_list }) {
  return async (dispatch, getState) => {
    try {
      const token = sessionStorage.getItem('token');

      const res = await filesApi.check_file({ token: token, check_list });
      const check_array = res.data;
      const do_update = check_array.some((el) => el.Complete);
      const check_fileID = check_array.map((el) => el.fileID);

      if (do_update) {
        const fileList = getState().fileList.map((el) =>
          check_fileID.includes(el.fileID)
            ? check_array.find(({ fileID }) => fileID === el.fileID)
            : el,
        );
        dispatch(fetchFilesStore({ items: fileList }));
      }
    } catch (err) {
      // alert("filesAction/fetch_api error");
      // dispatch(openSpinner({status:false, percent:0}));
    } finally {
      // dispatch(openSpinner({status:false, percent:0}));
      // dispatch(openSpinner({status:false, percent:0, message:""}));
    }
  };
}

export function getFilesThunk() {
  return async (dispatch) => {
    const token = sessionStorage.getItem('token');
    let res;
    try {
      dispatch(
        openSpinner({
          status: true,
          percent: 0,
          message: 'Loading...',
        }),
      );
      res = await filesApi.fetchFiles({ token: token });
      dispatch(fetchFilesStore({ items: res.data }));
    } catch (err) {
      // alert("filesAction/fetch_api error");
      // dispatch(openSpinner({status:false, percent:0}));
    } finally {
      dispatch(closeAllFiles());
      dispatch(openSpinner({ status: false, percent: 0 }));
      // dispatch(openSpinner({status:false, percent:0, message:""}));
    }
  };
}

export function updateWorklistThunk(status, selectedFiles) {
  return async (dispatch, getState) => {
    const token = sessionStorage.getItem('token');
    try {
      await filesApi.update_worklist({
        token: token,
        obj: selectedFiles,
        addToWorklist: status,
      });
      const targetFileIDs = selectedFiles.map((el) => el.fileID);
      const fileList = getState().fileList.map((el) =>
        targetFileIDs.includes(el.fileID)
          ? { ...el, Group: status ? 1 : 0 }
          : el,
      );
      dispatch(fetchFilesStore({ items: fileList }));
    } catch (err) {
      alert('filesAction/update_worklist error');
    }
  };
}

export function deleteApiThunk(record, product, setReloadChecker) {
  return async (dispatch) => {
    try {
      const token = sessionStorage.getItem('token');
      const res = await filesApi.deleteSelection({ token: token, obj: record, product, setReloadChecker });
      dispatch(fetchFilesStore({ items: res.data }));
    } catch (err) {
      alert(
        'Connection lost. Please check your connection to the server, and try logging in again.',
      );
    }
  };
}

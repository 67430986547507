import { coreItem } from '0_variables/coreItem';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ...Object.keys(coreItem).reduce((obj, productName) => {
    obj[productName] = {
      selectedFileID: null,
      openedFiles: [
        // { id: 0, fileID: "100", Tracer:"[18F]FBB" },
        // { id: 1, fileID: "101", Tracer:"[18F]FBP" },
      ],
      reportItems: {},
    };
    return obj;
  }, {}),
};

const controllerSlice = createSlice({
  name: 'controller',
  initialState,
  reducers: {
    initControl: () => {
      return initialState;
    },
    setSelectedFileID: (state, { payload }) => {
      const { productName, selectedFile } = payload;
      state[productName].selectedFileID = selectedFile.fileID;
    },
    closeFile: (state, { payload }) => {
      const { productName, singleFile } = payload;
      const openedFiles = state[productName].openedFiles;
      const clickedIdx = openedFiles.findIndex(
        (item) => item.fileID === singleFile.fileID,
      );
      const filteredFiles = openedFiles.filter(
        (item) => item.fileID !== singleFile.fileID,
      );

      let updatedFileID = null;
      if (filteredFiles.length > 0) {
        const currentFileID = state[productName].selectedFileID;
        const clickedFileID = singleFile.fileID;
        const updatedIdx = Math.max(
          0,
          Math.min(clickedIdx, filteredFiles.length - 1),
        );
        updatedFileID =
          currentFileID === clickedFileID
            ? filteredFiles[updatedIdx].fileID
            : currentFileID;
      }

      state[productName].selectedFileID = updatedFileID;
      state[productName].openedFiles = filteredFiles;
    },
    addGroupOpenFiles: (state, { payload }) => {
      const { productName, selectedFiles } = payload;
      const openedFiles = state[productName].openedFiles;
      const filteredFiles = selectedFiles
        .filter(
          (file) =>
            !openedFiles.some(
              (openedFile) => openedFile.fileID === file.fileID,
            ),
        )
        .map((file) => ({
          fileID: file.fileID,
          Tracer: file.Tracer,
          PatientName: file.PatientName,
          // 각 이미지리스트의 중심
          defaultCoroanlIndex: 128 / 2 + 11,
          defaultSagittalndex: 130 / 2,
          defaultAxialIndex: 45 - 8,
        }));
      const mergedFiles = [...openedFiles, ...filteredFiles];
      state[productName].openedFiles = mergedFiles;
    },
    updateSliceIndex: (state, { payload }) => {
      const { productName, fileID, direction, sliceIndex } = payload;
      const openedFiles = state[productName].openedFiles;

      openedFiles.forEach((file) => {
        if (file.fileID === fileID) {
          switch (direction) {
            case 'coronal':
              file.defaultCoroanlIndex = sliceIndex;
              break;
            case 'sagittal':
              file.defaultSagittalndex = sliceIndex;
              break;
            case 'axial':
              file.defaultAxialIndex = sliceIndex;
              break;
            default:
              break;
          }
        }
      });
    },
  },
});

export const {
  initControl,
  setSelectedFileID,
  closeFile,
  addGroupOpenFiles,
  updateSliceIndex,
} = controllerSlice.actions;

export default controllerSlice.reducer;

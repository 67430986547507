import React from 'react';
import styles from './BarGraph.module.scss';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { coreItem } from '0_variables/coreItem';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

export default function BarGraph() {
  const params = useParams();
  const productName = params.product.toLowerCase();
  const recent7dates = coreItem[productName].Date7;
  const productCoreItem = coreItem[productName];
  const tracerList = productCoreItem.tracers;
  const tracerShortNameList = tracerList.map((v) => v.shortname);
  const fileList = useSelector((state) =>
    state.fileList.filter((item) => tracerShortNameList.includes(item.Tracer)),
  );

  const NofTracerByDate = recent7dates.map((date) =>
    fileList.filter((v, i) => v.Update.split(' ')[0] === date),
  );

  const data = NofTracerByDate.map((item0, index) =>
    tracerShortNameList.reduce((obj, tracer) => {
      obj['name'] = recent7dates[index];
      obj[
        tracer
          .replace('18', '\u00B9\u2078')
          .replace('99mtc', '\u2079\u2079\u1d50Tc')
      ] = item0.filter((item) => item.Tracer === tracer).length;
      obj['Total'] = item0.length;
      return obj;
    }, {}),
  ).reverse();

  return (
    <div className={styles['container']}>
      <div className={styles['graphContainer']}>
        <ResponsiveContainer width="100%" height="90%">
          <ComposedChart
            width={500}
            height={400}
            data={data}
            margin={{
              top: 30,
              right: 20,
              bottom: 20,
              left: 20,
            }}
            fontSize={'0.7vw'}
          >
            {/* <CartesianGrid  /> */}
            <XAxis
              dataKey="name"
              scale="band"
              interval={0}
              tick={{ fill: 'white' }}
              dy={15}
              tickFormatter={(date) => date.slice(5)}
            />
            <YAxis
              tick={{ fill: 'white' }}
              width={40}
              height={100}
              tickFormatter={(value) => (Number.isInteger(value) ? value : '')}
            />
            <Tooltip
              contentStyle={{ backgroundColor: '#292d30', textAlign: 'center' }}
            />
            {/* <Legend className="legend" iconSize={15} iconType={'wye'} height={1} wrapperStyle={{position: 'relative', marginTop: '5vw'}} /> */}
            {/* <Area type="monotone" dataKey="amt" fill="#8884d8" stroke="#8884d8" /> */}
            {tracerList.map((item, idx) => (
              <Bar
                key={idx}
                dataKey={item.shortname
                  .replace('18', '\u00B9\u2078')
                  .replace('99mtc', '\u2079\u2079\u1d50Tc')}
                barSize={20}
                stackId="a"
                fill={item.color}
              />
            ))}
            {/* <Bar dataKey={`[\u00B9\u2078F]FMM`} barSize={20} stackId="a" fill="#dfe403" />
            <Bar dataKey={`[\u00B9\u2078F]FBP`} barSize={20} stackId="a" fill="#19ffd6" />
            <Bar dataKey={`[\u00B9\u2078F]FBB`} barSize={20} stackId="a" fill="#ff6d4c" />
            <Bar dataKey={`[\u00B9\u2078F]FPN`} barSize={20} stackId="a" fill="#4472c4" /> */}

            <Line type="monotone" dataKey={'Total'} stroke="#afb1b4" />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      <div className={styles['legendContainer']}>
        {tracerList.map((v, i) => (
          <div key={i} style={{ borderLeft: `6px ${v.color} solid` }}>
            {v.shortname
              .replace('18', '\u00B9\u2078')
              .replace('99mtc', '\u2079\u2079\u1d50Tc')}
          </div>
        ))}
      </div>
    </div>
  );
}

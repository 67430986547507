import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import  { saveAs } from 'file-saver';
import axios from "axios";
import JSZip from 'jszip';
import domtoimage from 'dom-to-image';
import { isEqual } from "lodash";

import { coreItem} from "0_variables/coreItem";
import {subRegions} from "0_variables/subRegions";
import MNI_Axial from "0_variables/MNI152_T1_2mm_z";
import MNI_Coronal from "0_variables/MNI152_T1_2mm_y";
import MNI_Sagittal from "0_variables/MNI152_T1_2mm_x";

import {
  openSelectedFile,
  openSingleFile,
  closeSingleFile as closeSingleFileAction,
} from '1_reduxs/reducers/filesReducer';
import * as actionsControl from "1_reduxs/actions/controllerAction";

import {
  logo as MEDIA_LOGO,
  noImage_dat_top as MEDIA_noImage_dat_top,
  noImage_dat_bottom as MEDIA_noImage_dat_bottom,
  noImage_amyloid_top as MEDIA_noImage_amyloid_top, 
  noImage_amyloid_bottom as MEDIA_noImage_amyloid_bottom,
  reportIcon1 as MEDIA_reportIcon1,
  reportIcon2 as MEDIA_reportIcon2,
  reportIcon3 as MEDIA_reportIcon3,
} from "6_media";

import { montserrat_medium } from "7_font/montserrat/Montserrat-Medium-normal";
import { montserrat_medium_italic } from "7_font/montserrat/Montserrat-LightItalic-normal";
import { montserrat_bold } from "7_font/montserrat/Montserrat-Bold-normal";
import { montserrat_bold_semi } from "7_font/montserrat/Montserrat-SemiBold-normal";
import { montserrat_light } from "7_font/montserrat/Montserrat-Light-normal";
import { notosans_medium_normal } from "7_font/montserrat/NotoSans-Medium-normal";

import * as cornerstone from "cornerstone-core";
import * as cornerstoneMath from "cornerstone-math";
import * as cornerstoneTools from "cornerstone-tools";
import Hammer from "hammerjs";
import * as cornerstoneWebImageLoader from "cornerstone-web-image-loader";


cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneWebImageLoader.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;


export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  // console.log(k, i, bytes, Math.pow(k, i), (bytes / Math.pow(k, i) / 10))
  // return parseFloat((bytes / Math.pow(k, i) / 10).toFixed(1));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const ItemSizeKB = (arr) => {
  let totalSizeKB = 0
  arr.forEach((v, i) => { totalSizeKB = totalSizeKB + Math.floor(v.size / 1024) })
  // return formatBytes(totalSize)
  return totalSizeKB
}
export const ItemSizeB = (arr) => {
  let totalSizeB = 0
  arr.forEach((v, i) => { totalSizeB = totalSizeB + v.size })
  // return formatBytes(totalSize)
  console.log(totalSizeB)
  return totalSizeB
}

export const ItemLength = (findResult) => {
  let count = 0
  findResult.forEach((v, i) => { count = count + v.seriesItem.length })
  return count
}

export const limitNum = {
  'amyloid': 1,
  'dat': 1,
  'fdg': 30,
  'tau': 30,
  'perfusion': 30,
}


export const ratio2Width = ({ width, height }) => height / width;
export const ratio2Height = ({ width, height }) => width / height;
export const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
export const type1Selector = {
  'amyloid': 'suvr',
  'dat': 'sbr',
  'fdg': 'suvr',
  'tau': 'suvr',
  'perfusion': 'suvr',
}
export const type2Selector = {
  'amyloid': 'centiloid',
  // TODO: dat, fdg, tau는 type2 selector 필요없음 나중에 점검 필요 (임시로 채움)
  'dat': 'semiquantification',
  'fdg': 'centiloid',
  'tau': 'centiloid',
  'perfusion': 'centiloid',
}

// TODO: 나중에 selectedFile의 Ref Factor를 가져오는 방법 고민 필요
export const RefFactor = ({ productName, refName, ponsRF, crblRF, wmRF, cgRF, global }) => {
  // if (global){
  //   debugger;
  // }
  if (productName !== 'dat') {
    if (refName === "Pons") return ponsRF;
    else if (refName === "Whole cerebellum") return crblRF;
    //TODO: amyloid용 refFactor 받아와야함
    else if (refName === "Cerebral white matter") return wmRF;
    else if (refName === "Global cortex") return global; // for FDG
    else return 1; // Cerebellar gray matter
  } else {
    if (refName === "Pons") return ponsRF;
    else if (refName === "Cerebellar gray matter") return cgRF;
    else if (refName === "Cerebral white matter") return wmRF;
    else if (refName === "Whole cerebellum") return crblRF;
    else return 1; // occipital cortex
  }
}

export const isChangedObjects = (prevObj, currObj) => {
  // debugger;
  const result = !isEqual(prevObj, currObj)
  return result;
}


  export const dataFormatForBrainTable = ({
    productName,
    columnList,
    selectedFile,
    settingOfProduct,
    RF,
  }) => {
    let valuePrefix = '';
    let filteredColumnList;
    const selectedAtlas = settingOfProduct.defaultAtlas;
    if (productName === 'dat') {
      filteredColumnList = columnList.filter(
        (obj) => obj.atlas === selectedAtlas,
      );
    } else {
      valuePrefix = selectedAtlas === 'AAL3' ? '' : 'HO_';
      filteredColumnList = columnList.filter((obj) => obj.atlas === 'all');
    }

    const format = filteredColumnList.map((item) => {
      // TODO: DW 나중에 확인 필요: DAT일 때에만 item.Lvar이 string이 아닌 경우가 발생하므로 "사실상 DAT 제품을 위한 로직임"
      if (productName === 'dat' && Array.isArray(item.Lvar)) {
        const getWeightedSum = (valueNameList, direction /* Left | Right */) => {
          return valueNameList.reduce(
            (acc, valueName) => {
              const targetRegion = settingOfProduct.list.find(
                (region) =>
                  region.atlas === selectedAtlas &&
                  region.varname[direction] === valueName,
              );
              acc.sum += selectedFile[valueName] * targetRegion.weight[direction];
              acc.weight += targetRegion.weight[direction];
              return acc;
            },
            { sum: 0, weight: 0 },
          );
        };

        const ObjL = getWeightedSum(item.Lvar, 'Left');
        const ObjR = getWeightedSum(item.Rvar, 'Right');

        const MeanOfWeightedSumLeft = ObjL.sum / ObjL.weight;
        const MeanOfWeightedSumRight = ObjR.sum / ObjR.weight;
        return {
          ...item,
          Lval: MeanOfWeightedSumLeft * RF,
          Rval: MeanOfWeightedSumRight * RF,
        };
      } else {
        return {
          ...item,
          Lval: selectedFile[`${valuePrefix}${item.Lvar}`] * RF,
          Rval: selectedFile[`${valuePrefix}${item.Rvar}`] * RF,
        };
      }
    });

    return format;
  };



export const metaDataLoader = async () => {

  "use strict";

  function metaDataProvider(type, imageId) {
    // amyloidview://{type}/{fileID}/{Direction}/{sliceID}
    // amyloidview://output/1/coronal/75
    const identifier = imageId.split(/[:,/]+/);
    const direction = identifier[3];
    const sliceID = Number(identifier[4]);
    const scale = 1;
    const cX = 1;
    const cY = 1;
    const cZ = 1;

    let width = 91;
    let height = 91;
    if (direction === "coronal") { width = 91 + 40; height = 91 }
    else if (direction === "sagittal") { width = 109 + 20; height = 91 }
    else if (direction === "axial") { width = 91 + 40; height = 109 + 20 }
    else if (direction === "mip") { width = 109 + 20; height = 91 }

    if (type === 'imagePlaneModule') {
      if (direction === 'coronal') {
        return {
          frameOfReferenceUID: '1.2.3.4.5',
          rows: 91,
          columns: 91 + 40,
          rowCosines: [0, 1, 0],
          columnCosines: [0, 0, 1],
          imagePositionPatient: [scale * sliceID + cY, cX, cZ], //coronal plane에서 [xxx, sagittal line, axial line]
          columnPixelSpacing: 1,
          rowPixelSpacing: 1,
        }
      }
      else if (direction === 'sagittal') {
        return {
          frameOfReferenceUID: '1.2.3.4.5',
          rows: 91,
          columns: 109 + 20,
          rowCosines: [1, 0, 0],
          columnCosines: [0, 0, 1],
          imagePositionPatient: [cY, scale * sliceID + cX, cZ], //sagittal plane에서 [coroanl line, xxx, axial line]
          columnPixelSpacing: 1,
          rowPixelSpacing: 1,
        }
      }
      else if (direction === 'axial') {
        return {
          frameOfReferenceUID: '1.2.3.4.5',
          rows: 109 + 20,
          columns: 91 + 40,
          rowCosines: [0, 1, 0],
          columnCosines: [-1, 0, 0],
          imagePositionPatient: [cY + 109 + 20, cX, -scale * sliceID + cZ + 91], //axial plane에서  [coronal line, sagittal line, xxx]
          columnPixelSpacing: 1,
          rowPixelSpacing: 1,
        }
      }
    }
  }
  cornerstone.metaData.addProvider(metaDataProvider);
};

export const reportImageLoader = async (productName, pageName, fileList) => {
  "use strict";
  // debugger;
  // const DB = fileList;
  // const DBarray = fileList.map(el=>el.reportItems)
  const DB = fileList.reduce((obj, el) => {
    obj['reportItems'] = {
      ...obj['reportItems'],
      ...el.reportItems,
    }
    return obj;
  }, {}).reportItems

  function getReportImage(imageId) {
    // debugger;
    const identifier = imageId.split(/[:,/]+/)
    const ImageLoaderName = identifier[0]
    const FileID = identifier[1]
    const Direction = identifier[2]
    const SliceID = Number(identifier[3])

    const size = getSizebyProduct(Direction);
    const width = size.width;
    const height = size.height;

    const getPixelData = () => {
      // const keyPairJSON = sessionStorage.getItem(FileID);
      // const data = JSON.parse(keyPairJSON)[imageId];
      // debugger;
      const data = DB[imageId];
      // console.log(data);
      try {
        if (data !== null && data !== undefined) {
          return getPixelDataFromBase64(data)
        } else {
          throw "unknown imageId"
        }
      } catch (e) {
        // debugger;
      }
      // if (data !== null && data !== undefined) {
      //   debugger;
      //   return getPixelDataFromBase64(data)
      // } else {
      //   throw "unknowm imageId"
      // }
    }

    var image = {
      imageId: imageId,
      minPixelValue: 0,
      maxPixelValue: 32767,
      slope: 1.0,
      intercept: 0,
      windowCenter: 16384,
      windowWidth: 32767,
      getPixelData: getPixelData,
      rows: height,
      columns: width,
      height: height,
      width: width,
      color: false,
      columnPixelSpacing: 2,
      rowPixelSpacing: 2,
      sizeInBytes: width * height * 2,
    };
    return {
      promise: new Promise((resolve) => {
        resolve(image);
      }),
      cancelFn: undefined
    };
  }
  if (pageName === 'report') {
    cornerstone.registerImageLoader(productName + pageName, getReportImage);
  }
}

export const initImageLoader = async (productName, pageName, file) => {
  const Cloud_Mode = coreItem[productName].Cloud_Mode;
  // const openedFilesofProduct = controlOfProduct.openedFiles;
  const mniDB = {
    'axial': MNI_Axial,
    'coronal': MNI_Coronal,
    'sagittal': MNI_Sagittal,
  }
  
  function getMNIImage(imageId) {
    // mni_brtnx://${direction}/${index}
    // mni_brtnx://coronal/75
    const [, Direction, SliceID] = imageId.split(/[:,/]+/);
    
    const size = getSizebyProduct(Direction);
    const width = size.width;
    const height = size.height;

    function getPixelData() {
      const data = mniDB[Direction][SliceID];

      if (data !== null)
        return getPixelDataFromBase64(data);
      else
        throw "unknowm imageId"
    }

    var image = {
      imageId: imageId,
      minPixelValue: 0,
      maxPixelValue: 32767,
      suvrSlope: 1,
      slope: 1.0,
      intercept: 0,
      windowCenter: 16384,
      windowWidth: 32767,
      getPixelData: getPixelData,
      rows: height,
      columns: width,
      height: height,
      width: width,
      color: false,
      columnPixelSpacing: 2,
      rowPixelSpacing: 2,
      sizeInBytes: width * height * 2,
    };

    return {
      promise: new Promise((resolve) => {
        resolve(image);
      }),
      cancelFn: undefined
    };
  }
  function getSliceImage(imageId) {
    // amyloidview://{type}/{fileID}/{Direction}/{sliceID}
    // amyloidview://output/1/coronal/75
    const identifier = imageId.split(/[:,/]+/)
    // const ImageLoaderName = identifier[0]
    const Type = identifier[1];
    // const FileID = identifier[2];
    const Direction = identifier[3];
    const SliceID = Number(identifier[4]);

    const size = getSizebyProduct(Direction);
    const width = size.width;
    const height = size.height;

    // const file = openedFilesofProduct.find(el => el.fileID === FileID)
    // const suvrSlope = Type === 'output' ? (file.out_suvr_max - file.out_suvr_min) / 32767 : (file.in_suvr_max - file.in_suvr_min) / 32767;

    function getPixelData() {
      const data = file.Slices.find(el => el.Type === Type && el.Direction === Direction && el.ImageID === SliceID).B64Data;

      if (data !== null)
        if (Cloud_Mode) {
          if (Direction === 'mip') return getPixelDataFromBase64(data);
          else if (Direction === 'sagittal') return getPixelDataFromBase64PETsagittal(data);
          else return getPixelDataFromBase64PET(data);
        } else {
          return getPixelDataFromBase64(data);
        }
      else
        throw "unknowm imageId"
    }

    var image = {
      imageId: imageId,
      minPixelValue: 0,
      maxPixelValue: 32767,
      // suvrSlope: suvrSlope, // not used
      slope: 1,
      intercept: 0,
      windowCenter: 16384,
      windowWidth: 32767,
      getPixelData: getPixelData,
      rows: height,
      columns: width,
      height: height,
      width: width,
      color: false,
      columnPixelSpacing: 2,
      rowPixelSpacing: 2,
      sizeInBytes: width * height * 2,
    };

    return {
      promise: new Promise((resolve) => {
        resolve(image);
      }),
      cancelFn: undefined
    };
  }

  // view에서만 쓰고 있음
  if (pageName === 'view') {
    // Removes all images from cache
    cornerstone.imageCache.purgeCache();

    cornerstone.registerImageLoader('mni_brtnx', getMNIImage);
    cornerstone.registerImageLoader(productName + pageName, getSliceImage);
  };
}

export const getSizebyProduct = (Direction) => {
  if (Direction === 'coronal') return { width: 91 + 40, height: 91 }
  else if (Direction === 'sagittal') return { width: 109 + 20, height: 91 }
  else if (Direction === 'axial') return { width: 91 + 40, height: 109 + 20 }
  else if (Direction === "mip") return { width: 109 + 20, height: 91 }
  else return { width: 91, height: 91 }
}

export const reportImageIdsbyProduct = (productName, fileID) => {
  if (productName === 'amyloid') {
    return [
      'amyloidreport://' + fileID + '/axial/' + 60,
      'amyloidreport://' + fileID + '/axial/' + 55,
      'amyloidreport://' + fileID + '/axial/' + 50,
      'amyloidreport://' + fileID + '/axial/' + 45,
      'amyloidreport://' + fileID + '/axial/' + 40,
      'amyloidreport://' + fileID + '/axial/' + 35,
      'amyloidreport://' + fileID + '/axial/' + 30,
      'amyloidreport://' + fileID + '/axial/' + 25,
    ]
  } else if (productName === 'dat') {
    return [
      'datreport://' + fileID + '/axial/' + 40,
      'datreport://' + fileID + '/axial/' + 37,
      'datreport://' + fileID + '/axial/' + 34,
      'datreport://' + fileID + '/axial/' + 31,
      'datreport://' + fileID + '/axial/' + 28,
      'datreport://' + fileID + '/mip/' + 5,
      'datreport://' + fileID + '/mip/' + 10,
      'datreport://' + fileID + '/mip/' + 15,
      'datreport://' + fileID + '/mip/' + 20,
      'datreport://' + fileID + '/mip/' + 25,
      'datreport://' + fileID + '/mip/' + 30,
      'datreport://' + fileID + '/mip/' + 35,
      'datreport://' + fileID + '/mip/' + 40,
    ]
  } else if (productName === 'fdg') {
    // TODO: FDG와 TAU 제품에 대한 데이터 룰 필요함
    return [
      'fdgreport://' + fileID + '/axial/' + 60,
      'fdgreport://' + fileID + '/axial/' + 55,
      'fdgreport://' + fileID + '/axial/' + 50,
      'fdgreport://' + fileID + '/axial/' + 45,
      'fdgreport://' + fileID + '/axial/' + 40,
      'fdgreport://' + fileID + '/axial/' + 35,
      'fdgreport://' + fileID + '/axial/' + 30,
      'fdgreport://' + fileID + '/axial/' + 25,
    ]
  } else if (productName === 'tau') {
    return [
      'taureport://' + fileID + '/axial/' + 60,
      'taureport://' + fileID + '/axial/' + 55,
      'taureport://' + fileID + '/axial/' + 50,
      'taureport://' + fileID + '/axial/' + 45,
      'taureport://' + fileID + '/axial/' + 40,
      'taureport://' + fileID + '/axial/' + 35,
      'taureport://' + fileID + '/axial/' + 30,
      'taureport://' + fileID + '/axial/' + 25,
    ]
  }
}

export const str2pixelData = (str) => {
  var buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
  var bufView = new Int16Array(buf);
  var index = 0;
  for (var i = 0, strLen = str.length; i < strLen; i += 2) {
    var lower = str.charCodeAt(i);
    var upper = str.charCodeAt(i + 1);
    // debugger;
    bufView[index] = lower + (upper << 8);
    index++;
  }
  return bufView;
}

export const getPixelDataFromBase64 = (base64PixelData) => {
  var pixelDataAsString = window.atob(base64PixelData);
  var pixelData = str2pixelData(pixelDataAsString);
  return pixelData;
}

export const getPixelDataFromBase64PET = (base64PixelData) => {
  var pixelDataAsString = window.atob(base64PixelData);
  var pixelData = str2pixelDataPET(pixelDataAsString);
  return pixelData;
}

export const getPixelDataFromBase64PETsagittal = (base64PixelData) => {
  var pixelDataAsString = window.atob(base64PixelData);
  var pixelData = str2pixelDataPETsagittal(pixelDataAsString);
  return pixelData;
}
export const str2pixelDataPETsagittal = (str) => {
  var buf = new ArrayBuffer(str.length); // 2 bytes for each char
  var bufView = new Int16Array(buf);
  var index = 0;
  for (var i = 0, strLen = str.length; i < strLen; i += 2) {
    var lower = str.charCodeAt(i);
    var upper = str.charCodeAt(i + 1);
    bufView[index] = lower + (upper << 8);
    index++;
  }
  const originArr = Array.from(bufView)
  let Arr2D = []
  while (originArr.length) Arr2D.push(originArr.splice(0, 65));
  // const newArr = scaleConcat(Arr2D, 2);
  // const newArr = LinearInterp2D(Arr2D, 2);
  const newArr = CubicInterp2D(Arr2D);
  // debugger;
  // const newArr = interpolateArray(Arr2D, Arr2D.length *2);
  bufView = Int16Array.from(newArr.flat())
  return bufView;
}

export const str2pixelDataPET = (str) => {
  var buf = new ArrayBuffer(str.length); // 2 bytes for each char
  var bufView = new Int16Array(buf);
  // var bufView2 = new Int16Array(buf);
  var index = 0;
  for (var i = 0, strLen = str.length; i < strLen; i += 2) {
    var lower = str.charCodeAt(i);
    var upper = str.charCodeAt(i + 1);
    bufView[index] = lower + (upper << 8);
    index++;
  }

  const originArr = Array.from(bufView)
  let Arr2D = []
  while (originArr.length) Arr2D.push(originArr.splice(0, 66));
  // while(newData.length) newArr2D.push(newData.splice(0,66*2-1));


  // const newArr = scaleConcat(Arr2D, 2);
  // const newArr = LinearInterp2D(Arr2D, 2);
  const newArr = CubicInterp2D(Arr2D);
  bufView = Int16Array.from(newArr.flat())
  return bufView;
}
export const closeSingleFile = (dispatch, singleFile, currentPage, productName, history) => {
  dispatch(actionsControl.closeFileThunk(productName, singleFile));
  dispatch(closeSingleFileAction({fileID : singleFile.fileID}));
}

export const getTargetPathOnWorklist = (currentPage) => {
  if (currentPage === 'dashboard' || currentPage === 'upload' || currentPage === 'setting') {
    return 'view';
  } else {
    return currentPage;
  }
}

// export const amyloidCSVFormatFromWorklist = (settingOfProduct, filteredFileList, productCoreItem, defaultAtlas,godThanksSUVR, godThanksCentiloid) => {
//   const selectedAll = filteredFileList.filter(({Group, Select})=>Select===true && Group !== 0);
//   if (selectedAll.length === 0) {
//     alert('No file selected on the worklist, please select file');
//   } else {
//     const bottomCards = productCoreItem["analysis"].bottom;
//     const srcSUVR = bottomCards[1].cards[0].content.filter(({atlas})=>atlas===defaultAtlas);
//     const srcCentiloid = bottomCards[1].cards[1].content.filter(({atlas})=>atlas===defaultAtlas);
//     const structuredData = selectedAll.reduce((arr, srcData, idx)=>{

//       const tracerName = srcData['Tracer'];
//       const refName = settingOfProduct.defaultRef[tracerName];
//       const shortRefName = getShortName(refName);
//       const ref = {fullName:refName, shortName:shortRefName};
//       const structData = structAmyloidCSV({ref, atlas:defaultAtlas, srcData:srcData, srcSUVR:srcSUVR, srcCentiloid:srcCentiloid, godThanksSUVR, godThanksCentiloid}); 
//       return idx === 0 ? arr=[...structData.headRows, ...structData.bodyRows]:arr=[...arr, ...structData.bodyRows];
//     },[])
//     const fname = 'fnameForWorklist.csv'
//     generateCSV({data:structuredData, transpose:true, fname})
//   }
// }

export const getShortName = (refName) => {
  if (refName === "Whole cerebellum") {
    const shortName = "wcrbll"
    return shortName;
  } else if (refName === "Cerebellar gray matter") {
    const shortName = "crbllGM"
    return shortName;
  } else if (refName === "Pons") {
    const shortName = "pons"
    return shortName;
  } else if (refName === "Cerebral white matter") {
    const shortName = "cerebralWM"
    return shortName;
  } else if (refName === "Occipital cortex") {
    const shortName = "occi"
    return shortName;
  } else if (refName === "Global cortex") {
    const shortName = "global"
    return shortName;
  } else {
    const shortName = "wcrbll"
    return shortName;
  }
}

export const datCSVFormatFromWorklist = (settingOfProduct, filteredFileList, productCoreItem, defaultAtlas, godThanksSBR, godThanksSemiquatification) => {
  const selectedAll = filteredFileList.filter(({ Group, Select }) => Select === true && Group !== 0);
  if (selectedAll.length === 0) {
    alert('No file is seleted. ');
  } else {
    const bottomCards = productCoreItem["analysis"].bottom;
    const srcSBR = bottomCards[1].cards[0].content.filter(({ atlas }) => atlas === defaultAtlas);
    const srcSemiquant = bottomCards[1].cards[1].content.filter(({ atlas }) => atlas === defaultAtlas);
    const structuredData = selectedAll.reduce((arr, srcData, idx) => {
      const tracerName = srcData['Tracer'];
      const refName = settingOfProduct.defaultRef[tracerName];
      const shortRefName = getShortName(refName);
      const ref = { fullName: refName, shortName: shortRefName };
      const structData = structDATCSV({ ref, atlas: defaultAtlas, srcData: srcData, srcSBR: srcSBR, srcSemiquant: srcSemiquant, godThanksSBR, godThanksSemiquatification });
      return idx === 0 ? arr = [...structData.headRows, ...structData.bodyRows] : arr = [...arr, ...structData.bodyRows];
    }, [])
    const fname = 'brtnx_quantified.csv'
    generateCSV({ data: structuredData, transpose: true, fname })
  }
}

export const fdgCSVFormatFromWorklist = (filteredFileList, productCoreItem, defaultAtlas) => {
  alert('fdgCSVFormat')
}

export const tauCSVFormatFromWorklist = (filteredFileList, productCoreItem, defaultAtlas) => {
  alert('tauCSVFormat')
}

export const openGroupFilesSelected = ({ targetPage, filteredList, targetFile, productName, dispatch, history }) => {
  // debugger;
  dispatch(actionsControl.openFilesWithFileSelectThunk(productName, filteredList, targetFile));
  dispatch(openSelectedFile({productName, openState: true}));
  history.push(`/${productName}/${targetPage}/${targetFile.fileID}`);
}

export const openSingleFileWithoutSelect = ({ targetPage, history, dispatch, singleFile, fileList, tracerList, productName }) => {
  const temp = fileList.filter((el) => tracerList.includes(el.Tracer) && (el.fileID === singleFile.fileID || el.Opened === true));
  if (singleFile.Complete) dispatch(actionsControl.openFilesWithFileSelectThunk(productName, temp, singleFile));
  if (singleFile.Complete) dispatch(openSingleFile({fileID: singleFile.fileID}));
  if (singleFile.Complete) history.push(`/${productName}/${targetPage}/${singleFile.fileID}`);
}

export const isAnySelected = (fileList, tracerList) => {
  // debugger;
  const selectedFiles = fileList.filter(el => tracerList.includes(el.Tracer) && el.Select === true);
  const isAllNotComplete = selectedFiles.every(el => el.Complete === false)
  if (selectedFiles.length === 0 || isAllNotComplete) return false;
  else return true;
}
export const isAllCompleteandSelected = (fileList, tracerList) => {
  // debugger;
  const selectedFiles = fileList.filter(el => tracerList.includes(el.Tracer) && el.Select === true);
  const isSomeNotComplete = selectedFiles.some(el => el.Complete === false)
  // console.log('selectedFiles.length === 0, isSomeNotComplete, A || B:', selectedFiles.length === 0, isSomeNotComplete, selectedFiles.length === 0 || isSomeNotComplete)


  if (selectedFiles.length > 0) {
    if (isSomeNotComplete) return false
    else return true
  } else return false
}

export const isAnySelectedforDelete = (fileList, tracerList) => {
  // debugger;
  const selectedFiles = fileList.filter(el => tracerList.includes(el.Tracer) && el.Select === true);
  if (selectedFiles.length === 0) return false;
  else return true;
}

export const removesessionStorage = () => {
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("username");
}

export const getCanvasBlob = (canvas) => {
  return new Promise(function (resolve, reject) {
    canvas.toBlob(function (blob) {
      resolve(blob)
    }, 'image/jpeg')
  })
}

export const generateJPEG = async (elRefs, func, func2, func3) => {

  func(true)
  // debugger;
  //* worklist 에서 보낸 elrefs랑 받는 elrefs가 다름 확인필요 ㅠㅠ..
  const zip = new JSZip();
  const totalFiles = elRefs.length;
  const eachPages = elRefs[0].length
  const totalPages = totalFiles * eachPages;
  let count = 0;
  const promiseAll = Promise.all(elRefs.map(async (elRef, fileNum) => {
    const PatientID = elRef[0].current.childNodes[0].childNodes[2].childNodes[0].childNodes[1].childNodes[0].childNodes[1].childNodes[1].childNodes[0].textContent
    const PatientName = elRef[0].current.childNodes[0].childNodes[2].childNodes[0].childNodes[1].childNodes[0].childNodes[0].childNodes[1].childNodes[0].textContent
    const tracerName = (elRef[0].current.childNodes[0].childNodes[2].childNodes[0].childNodes[1].childNodes[0].childNodes[5].childNodes[1].childNodes[0].textContent).replace('\u00B9\u2078', '18').replace('\u2079\u2079\u1d50'+'Tc', '99mtc')
    const AcquisitionDateTime = elRef[0].current.childNodes[0].childNodes[2].childNodes[0].childNodes[1].childNodes[0].childNodes[4].childNodes[1].childNodes[0].textContent
    const refName = (elRef[0].current.childNodes[0].childNodes[3].childNodes[1].textContent).split(':').at(-1).trim()



    const handleTask = async (pageNum, ref, func3) => {
      const fname = fileNum + "_" + PatientID + "_" + PatientName + "_" + tracerName + "_" + AcquisitionDateTime + "_" + refName + "_" + pageNum + ".jpg"
      console.log('jpg:', fname)

      // const currentPage = fileNum * eachPages + (pageNum+1)
      count += 1
      console.log(count, totalPages, (count / totalPages * 100).toFixed(0))
      const currentPercent = (count / totalPages * 100).toFixed(0)
      func3({ message: "Downloading...", percent: currentPercent, target: "JPEG" })
      // func3(currentPercent+"%")

      const canvasItem = await html2canvas(ref.current, { allowTaint: true, useCORS: true });
      const blob = getCanvasBlob(canvasItem);
      zip.file(fname, blob);
    }

    for (const [pageNum, ref] of elRef.entries()) {
      await handleTask(pageNum, ref, func3)
    }
    return true;


    // const finalResult = Promise.all(elRef.map( async (ref,pageNum)=>{

    //   const fname = fileNum+"_"+PatientID +"_"+PatientName+"_"+tracerName+"_"+AcquisitionDateTime+"_"+refName+"_"+pageNum+".jpg"
    //   console.log('jpg:', fname)

    //   const currentPage = fileNum * eachPages + (pageNum+1)
    //   const currentPercent = (currentPage/totalPages*100).toFixed(0)
    //   console.log(currentPercent, currentPage, totalPages)
    //   setTimeout(()=>{
    //     func3(currentPercent+"%")
    //   },0)

    //   const canvasItem = await html2canvas(ref.current, {allowTaint:true, useCORS:true});
    //   const blob = getCanvasBlob(canvasItem);
    //   zip.file(fname, blob);
    // }))

    // return finalResult;


  }))

  promiseAll.then(() => {
    console.log("complete: ", promiseAll);
    const currentPercent = 100
    setTimeout(() => {
      func3({ message: "Downloading...", percent: currentPercent, target: "JPEG" })
    }, 0)
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, 'brtnx(jpg).zip');
      func(false)
      func2(false)
    });
  })
}



// export const generatePDF = async (elRefs, func, func2, func3) => {

//   let input_time_normal = new Date()
//   // console.log("input time", input_time_normal)
//   func(true)
//   const pdfArr = elRefs.map(_ => new jsPDF('p', 'mm', 'A4'))
//   const width = pdfArr[0].internal.pageSize.getWidth();
//   const height = pdfArr[0].internal.pageSize.getHeight();

//   let zip = new JSZip();
//   const totalFiles = elRefs.length;
//   const eachPages = elRefs[0].length
//   const totalPages = totalFiles * eachPages;
//   let count = 0

//   const promiseAll = Promise.all(elRefs.map(async (elRef, fileNum) => {

//     const PatientID = elRef[0].current.childNodes[0].childNodes[2].childNodes[0].childNodes[1].childNodes[0].childNodes[1].childNodes[1].childNodes[0].textContent
//     const PatientName = elRef[0].current.childNodes[0].childNodes[2].childNodes[0].childNodes[1].childNodes[0].childNodes[0].childNodes[1].childNodes[0].textContent
//     const tracerName = (elRef[0].current.childNodes[0].childNodes[2].childNodes[0].childNodes[1].childNodes[0].childNodes[5].childNodes[1].childNodes[0].textContent).replace('\u00B9\u2078', '18')
//     const AcquisitionDateTime = elRef[0].current.childNodes[0].childNodes[2].childNodes[0].childNodes[1].childNodes[0].childNodes[4].childNodes[1].childNodes[0].textContent
//     const refName = (elRef[0].current.childNodes[0].childNodes[3].childNodes[1].textContent).split(':').at(-1).trim()
//     const fname = fileNum + "_" + PatientID + "_" + PatientName + "_" + tracerName + "_" + AcquisitionDateTime + "_" + refName + ".pdf"
//     console.log('pdf:', fname)

//     // const canvasArray = async () =>{
//     //   for (const [pageNum,ref] of elRef.entries()) {
//     //     await handleTask(pageNum,ref,func3)
//     //   }
//     //   return canvasArray;
//     // }

//     // const canvasArray2 = await Promise.all(elRef.map( (ref,pageNum)=>{

//     //   const currentPage = fileNum * eachPages + (pageNum+1)
//     //   const currentPercent = (currentPage/totalPages*100).toFixed(0)
//     //   console.log(currentPercent, currentPage, totalPages)
//     //   setTimeout(()=>{
//     //     func3(currentPercent+"%")
//     //   },0)

//     //   return html2canvas(ref.current, {allowTaint:true, useCORS:true})
//     // }));
//     const canvasArray = []
//     for (const [pageNum, ref] of elRef.entries()) {
//       count += 1;
//       const canv = await (async (pageNum, ref, func3, count) => {
//         const currentPage = fileNum * eachPages + (pageNum + 1)
//         const currentPercent = (count / totalPages * 100).toFixed(0)
//         console.log(currentPercent, currentPage, count, totalPages)
//         // func3(currentPercent+"%")

//         func3({ message: "Downloading...", percent: currentPercent, target: "PDF" })

//         return await html2canvas(ref.current, { allowTaint: true, useCORS: true, scale: 1.5 })
//       })(pageNum, ref, func3, count)
//       canvasArray.push(canv)
//     }

//     const pdfFile = await Promise.all(canvasArray.map((canvas, pageNum) => {

//       // const imgData = new jsPDF('p', 'mm');
//       // pdfArr[fileNum].addImage(imgData, 'JPEG', 0, 0, width, height);

//       const imgData = canvas.toDataURL('image/jpeg',);
//       // pdfArr[fileNum].addImage(canvas, 'JPEG', 0, 0, width, height);
//       pdfArr[fileNum].addImage(imgData, 'JPEG', 0, 0, width, height);

//       if (pageNum < canvasArray.length - 1) pdfArr[fileNum].addPage();
//       else {
//         pdfArr[0].save(fname)
//         func(false)
//       }
//       // else zip.file(fname, pdfArr[fileNum].output('blob'));

//       const currentPage = fileNum * eachPages + (pageNum + 1)
//       const currentPercent = (currentPage / totalPages * 100).toFixed(0)
//       console.log(currentPercent, currentPage, totalPages)
//       setTimeout(() => {
//         func3({ message: "Compressing...", percent: currentPercent, target: "PDF" })

//       }, 0)

//       return pageNum;
//     }))

//     // 단일 PDF 파일로 병합 후, Zip파일로 압축
//     return pdfFile;
//   }))

 

//   // Zip파일 다운로드
//   // promiseAll.then(() => {
//   //   console.log("complete: ", promiseAll);
//   //   setTimeout(() => {
//   //     // func3("Downloading...")
//   //     func3({ message: "Downloading...", percent: 100, target: "PDF" })
//   //     let output_time_normal = new Date()
//   //     // console.log("output time",output_time_normal)
//   //     const time_variation_sec = (output_time_normal.getTime() - input_time_normal.getTime()) / 1000
//   //     console.log("time_variation_sec", time_variation_sec)
//   //   }, 0)
//   //   zip.generateAsync({ type: 'blob' }).then((content) => {
//   //     saveAs(content, 'brtnx(pdf).zip');
//   //     func(false)
//   //     func2(false)
//   //   });
//   // });


// }

export const generatePDF = async (refs, productName,selectedFile,setting, transferFunc, spinnerController, func1, func2, serverState, fileID) => {
  
  transferFunc({message:"Please wait...", target:""})
// export const generatePDFSaveToServer = async (elRefs, func, func2, func3) => {
  let input_time_fast = new Date()
  const settingOfProduct = setting[productName];
  const defaultRegionLength = subRegions[productName].map(v=> v.level).filter(lev => lev===0).length;
  const settingRegionLength = settingOfProduct.list.map(v => v.level).filter(lev => lev===0).length;
  const limitNum_report = limitNum[productName]
  const makePageLength = () =>{
    if(selectedFile.Tracer === '[18F]FPN'){
      if(settingRegionLength-defaultRegionLength>30){
        return 4
      }else{
        return 3
      }
    }else if(settingRegionLength-defaultRegionLength>0){
      return 4
    }else return 3
  }
  const pageLength = makePageLength()
  // const pageLength = settingRegionLength-defaultRegionLength>limitNum_report ? 4 : 3
  const productCoreItem = coreItem[productName];
  const defaultAtlas = setting[productName].defaultAtlas
  const refName = settingOfProduct.defaultRef[selectedFile['Tracer']]
  const RF = RefFactor({productName, refName, ponsRF:selectedFile["ratio_gry2pons"], crblRF:selectedFile["ratio_gry2crbl"], wmRF:selectedFile["ratio_gry2wm"], cgRF:selectedFile["ratio_gry2cg"], global:1/selectedFile['Global']})
  // debugger;
  const table1DataList = {
      "amyloid": reportAmyloidtable1({ settingOfProduct:setting['amyloid'], typeSelect: productCoreItem.type1Selector, tableItems:coreItem['amyloid']['analysis'].bottom[1].cards[0], selectedFile: selectedFile, defaultAtlas:defaultAtlas, RF:RF }),
      "dat": reportDATtable1({     settingOfProduct:setting['dat'],     typeSelect: productCoreItem.type1Selector, tableItems:coreItem['dat']['analysis'].bottom[1].cards[0], selectedFile: selectedFile, defaultAtlas:defaultAtlas , RF:RF}),
      "fdg": reportAmyloidtable1({     settingOfProduct:setting['fdg'],     typeSelect: productCoreItem.type1Selector, tableItems:coreItem['fdg']['analysis'].bottom[1].cards[0], selectedFile: selectedFile, defaultAtlas:defaultAtlas , RF:RF}),
      "tau": reportAmyloidtable1({     settingOfProduct:setting['tau'],     typeSelect: productCoreItem.type1Selector, tableItems:coreItem['tau']['analysis'].bottom[1].cards[0], selectedFile: selectedFile, defaultAtlas:defaultAtlas , RF:RF}),
      "perfusion": reportAmyloidtable1({     settingOfProduct:setting['perfusion'],     typeSelect: productCoreItem.type1Selector, tableItems:coreItem['perfusion']['analysis'].bottom[1].cards[0], selectedFile: selectedFile, defaultAtlas:defaultAtlas , RF:RF}),
  }

  const table2DataList = {
    "amyloid":reportAmyloidDATtable2({ settingOfProduct:setting['amyloid'], typeSelect: productCoreItem.type2Selector, tableItems:coreItem['amyloid']['analysis'].bottom[1].cards[1], selectedFile: selectedFile, defaultAtlas:defaultAtlas, RF:RF }),
    "dat":reportAmyloidDATtable2({     settingOfProduct:setting['dat'],     typeSelect: productCoreItem.type2Selector, tableItems:coreItem['dat']['analysis'].bottom[1].cards[1], selectedFile: selectedFile, defaultAtlas:defaultAtlas, RF:RF }),
  }

  
  // debugger;

  const product_full_name = () => {
    if (productName === 'amyloid') return 'Amyloid'
    else if(productName === 'dat') return 'Dopamine'
    else if(productName === 'tau') return 'Tau'
    else if(productName === 'fdg') return 'FDG'
    else if(productName === 'perfusion') return 'Perfusion'
    else return 'Amyloid'
  } 

  const suvrOrSbr = productName ==='dat' ? ' SBR ' : ' SUVR '
  const imgCollection = []
  let temp
  // debugger;
  
  
  
  for (const [idx,ref] of refs[0].entries()) {
    const selectedFileID = parseInt(refs[0][idx][0].current.childNodes[0].childNodes[2].childNodes[0].childNodes[0].childNodes[3].textContent)
    
    // debugger;
    if (selectedFile.id === selectedFileID){
      if (productName === 'dat') {
        const initialPercent = (idx/refs[0].length)*100
        const plusPercent = 100/(refs[0].length*4)
        const img_scale = 2
        try{
          const brainTable = await domtoimage.toPng(document.getElementById(`brain-table-${idx}`), {
            //improve image quality
            width: document.getElementById(`brain-table-${idx}`).clientWidth * img_scale,
            height: document.getElementById(`brain-table-${idx}`).clientHeight * img_scale,
            style:{
              transformOrign: 'center',
              transform: `scale(${img_scale}) translateX(+12%) translateY(+13%)`,  
            }
          })
          temp = initialPercent+plusPercent
          transferFunc({message:"Making PDF...", percent: temp.toFixed(0), target:"PDF"})
    
          const report2Graph = await html2canvas(document.getElementById(`report2-graph-${idx}`),{scale:1.5}).then(canvas=>canvas.toDataURL('image/jpeg'));
          temp = temp+plusPercent
          transferFunc({message:"Making PDF...", percent: temp.toFixed(0), target:"PDF"})
    
          const report3Axial = await domtoimage.toPng(document.getElementById(`report3-axial-${idx}`),)
          temp = temp+plusPercent
          transferFunc({message:"Making PDF...", percent: temp.toFixed(0), target:"PDF"})
    
          const report3Mip = await domtoimage.toPng(document.getElementById(`report3-mip-${idx}`),)
          temp = temp+plusPercent
          transferFunc({message:"Making PDF...", percent: temp.toFixed(0), target:"PDF"})
    
          const imgResult = {
            'brainTable': brainTable,
            'report2Graph': report2Graph,
            'report3Axial': report3Axial,
            'report3Mip': report3Mip,
          };
          imgCollection.push(imgResult)

        }catch{
          const brainTable = await domtoimage.toPng(document.getElementById(`brain-table-${idx}`), {
            //improve image quality
            width: document.getElementById(`brain-table-${idx}`).clientWidth * img_scale,
            height: document.getElementById(`brain-table-${idx}`).clientHeight * img_scale,
            style:{
              transformOrign: 'center',
              transform: `scale(${img_scale}) translateX(+12%) translateY(+13%)`,  
            }
          })
          temp = initialPercent+plusPercent
          transferFunc({message:"Making PDF...", percent: temp.toFixed(0), target:"PDF"})
    
          const report2Graph = await html2canvas(document.getElementById(`report2-graph-${idx}`),{scale:1.5}).then(canvas=>canvas.toDataURL('image/jpeg'));
          temp = temp+plusPercent
          transferFunc({message:"Making PDF...", percent: temp.toFixed(0), target:"PDF"})
    
          const report3Axial = MEDIA_noImage_dat_top
          temp = temp+plusPercent
          transferFunc({message:"Making PDF...", percent: temp.toFixed(0), target:"PDF"})
    
          const report3Mip = MEDIA_noImage_dat_bottom
          temp = temp+plusPercent
          transferFunc({message:"Making PDF...", percent: temp.toFixed(0), target:"PDF"})
    
          const imgResult = {
            'brainTable': brainTable,
            'report2Graph': report2Graph,
            'report3Axial': report3Axial,
            'report3Mip': report3Mip,
          };
          imgCollection.push(imgResult)

        }
      } else {
        const initialPercent = (idx/refs[0].length)*100
        const plusPercent = 100/(refs[0].length*4)
        // const sumPercent = initialPercent + plusPercent
        const img_scale = 3

        try{
          const brainTable = await domtoimage.toPng(document.getElementById(`brain-table-${idx}`), {
          //imporve image quality
          width: document.getElementById(`brain-table-${idx}`).clientWidth * img_scale,
          height: document.getElementById(`brain-table-${idx}`).clientHeight * img_scale,
          style:{
            transformOrign: 'center',
            transform: `scale(${img_scale}) translateX(+11%) translateY(+12%)`,      
          }
          })
          temp = initialPercent+plusPercent
          transferFunc({message:"Making PDF...", percent: temp.toFixed(0), target:"PDF"})
    
          const report2Graph = await domtoimage.toPng(document.getElementById(`report2-graph-${idx}`), {
          //imporve image quality
          width: document.getElementById(`report2-graph-${idx}`).clientWidth * img_scale,
          height: document.getElementById(`report2-graph-${idx}`).clientHeight * img_scale,
          style:{
            transformOrign: 'center',
            transform: `scale(${img_scale}) translateX(-22%) translateY(+12%)`,      
          }
          })
          temp = temp+plusPercent
          transferFunc({message:"Making PDF...", percent: temp.toFixed(0), target:"PDF"})
    
          const report3Axial = await domtoimage.toPng(document.getElementById(`report3-axial-slices-${idx}`), {quality: 1})
          temp = temp+plusPercent
          transferFunc({message:"Making PDF...", percent: temp.toFixed(0), target:"PDF"})
    
          const report3Axial_colormap = await domtoimage.toPng(document.getElementById(`report3-axial-colorbar-${idx}`),
          {
            width:( document.getElementById(`report3-axial-colorbar-${idx}`).clientWidth)*img_scale ,
            height: (document.getElementById(`report3-axial-colorbar-${idx}`).clientHeight)*img_scale ,
            style:{
              transformOrign: 'center',
              transform: `scale(${img_scale}) translateX(-30%) translateY(-22%)`,      
            }
          }
          )
    
          const report3Surface = await domtoimage.toPng(document.getElementById(`report3-surface-img-${idx}`),)
          const report3Surface_colormap = await domtoimage.toPng(document.getElementById(`report3-surface-colormap-${idx}`),{
            width:( document.getElementById(`report3-surface-colormap-${idx}`).clientWidth)*img_scale ,
            height: (document.getElementById(`report3-surface-colormap-${idx}`).clientHeight)*img_scale ,
            style:{
              transformOrign: 'center',
              transform: `scale(${img_scale}) translateX(-30%) translateY(-22%)`,      
            }
          })
          temp = temp+plusPercent
          transferFunc({message:"Making PDF...", percent: temp.toFixed(0), target:"PDF"})
    
          const imgResult = {
            'brainTable': brainTable,
            'report2Graph': report2Graph,
            'report3Axial': report3Axial,
            'report3Axial_colormap': report3Axial_colormap,
            'report3Surface': report3Surface,
            'report3Surface_colormap': report3Surface_colormap,
          };
    
          imgCollection.push(imgResult)

        }catch{
          const brainTable = await domtoimage.toPng(document.getElementById(`brain-table-${idx}`), {
            //imporve image quality
            width: document.getElementById(`brain-table-${idx}`).clientWidth * img_scale,
            height: document.getElementById(`brain-table-${idx}`).clientHeight * img_scale,
            style:{
              transformOrign: 'center',
              transform: `scale(${img_scale}) translateX(+11%) translateY(+12%)`,      
            }
            })
            temp = initialPercent+plusPercent
            transferFunc({message:"Making PDF...", percent: temp.toFixed(0), target:"PDF"})
      
            const report2Graph = await domtoimage.toPng(document.getElementById(`report2-graph-${idx}`), {
            //imporve image quality
            width: document.getElementById(`report2-graph-${idx}`).clientWidth * img_scale,
            height: document.getElementById(`report2-graph-${idx}`).clientHeight * img_scale,
            style:{
              transformOrign: 'center',
              transform: `scale(${img_scale}) translateX(-22%) translateY(+12%)`,      
            }
            })
            temp = temp+plusPercent
            transferFunc({message:"Making PDF...", percent: temp.toFixed(0), target:"PDF"})
            const report3Axial = MEDIA_noImage_amyloid_top
            const report3Axial_colormap = await domtoimage.toPng(document.getElementById(`report3-axial-colorbar-${idx}`),
            {
              width:( document.getElementById(`report3-axial-colorbar-${idx}`).clientWidth)*img_scale ,
              height: (document.getElementById(`report3-axial-colorbar-${idx}`).clientHeight)*img_scale ,
              style:{
                transformOrign: 'center',
                transform: `scale(${img_scale}) translateX(-30%) translateY(-22%)`,      
              }
            }
            )
            const report3Surface = MEDIA_noImage_amyloid_bottom
            const report3Surface_colormap = await domtoimage.toPng(document.getElementById(`report3-surface-colormap-${idx}`),{
              width:( document.getElementById(`report3-surface-colormap-${idx}`).clientWidth)*img_scale ,
              height: (document.getElementById(`report3-surface-colormap-${idx}`).clientHeight)*img_scale ,
              style:{
                transformOrign: 'center',
                transform: `scale(${img_scale}) translateX(-30%) translateY(-22%)`,      
              }
            })
            temp = temp+plusPercent
            transferFunc({message:"Making PDF...", percent: temp.toFixed(0), target:"PDF"})
      
            const imgResult = {
              'brainTable': brainTable,
              'report2Graph': report2Graph,
              'report3Axial': report3Axial,
              'report3Axial_colormap': report3Axial_colormap,
              'report3Surface': report3Surface,
              'report3Surface_colormap': report3Surface_colormap,
            };
      
            imgCollection.push(imgResult)
        }
      }
      if (selectedFile.id === parseInt(fileID)){
      
      
        const pdf = refs.map(_=>new jsPDF("p", "mm", "a4"))
        const setFont = (pdf) => {
          pdf.addFileToVFS("montserrat_italic.ttf", montserrat_medium_italic);
          pdf.addFont("montserrat_italic.ttf", "montserrat", "italic");
          pdf.addFileToVFS("montserrat_medium.ttf", montserrat_medium);
          pdf.addFont("montserrat_medium.ttf", "montserrat", "normal");
          pdf.addFileToVFS("montserrat_bold.ttf", montserrat_bold);
          pdf.addFont("montserrat_bold.ttf", "montserrat", "bold");
          pdf.addFileToVFS("montserrat_bold_semi.ttf", montserrat_bold_semi);
          pdf.addFont("montserrat_bold_semi.ttf", "montserrat", "bold_semi")
          pdf.addFileToVFS("montserrat_light.ttf", montserrat_light);
          pdf.addFont("montserrat_light.ttf", "montserrat", "light") 
          pdf.addFileToVFS("notosans_medium_normal.ttf", notosans_medium_normal) 
          pdf.addFont("notosans_medium_normal.ttf", "noto", "medium")   
        }
        
      
        const header = (pdf) => {
          const today = new Date();
          const year = today.getFullYear(); 
          const month = today.getMonth() + 1; 
          const date = today.getDate();
        
          pdf.setFont("montserrat", "italic")
          pdf.setFontSize(8);
          pdf.setTextColor(179,179, 179)
          if(productName === 'perfusion'){

            pdf.text(20,10,'This product has not been approved by the MFDS and FDA')
            pdf.text(20,13,'and is not intended to diagnose, treat, cure, or prevent any disease.')
          }
          pdf.text(180,10, 'Last Printed')
          pdf.text(184,13, date + '/' + month + '/' + year)    
        }
      
        const mainTitle = (pdf) => {
          pdf.setFillColor(90, 122, 181)
          pdf.rect(20,15,1,20, 'F')
          
          pdf.setFont("montserrat", "bold")
          pdf.setFontSize(20)
          pdf.setTextColor(0,0, 0)
          pdf.text(25,23, 'BTXBrain-' + product_full_name())
        
          pdf.setFont("montserrat", "light")
          pdf.setFontSize(13)
          productName === 'perfusion' ? pdf.text(25,31, 'AI-based Automatic '+ product_full_name() + ' SPECT Quantification Report') :   
          pdf.text(25,31, 'AI-based Automatic '+ product_full_name() + ' PET Quantification Report')        
        }
        
        const subTitle = (pdf,iconSrc,a,b, msg, x, y) => {
          pdf.addImage(iconSrc, 'PNG', a,b ,5.5,5)
          pdf.setFont("montserrat", "bold")
          pdf.setTextColor(90, 122, 181)
          pdf.text(x,y, msg)    
        }
        
        const tableTitle = (pdf,msg, x, y) => {
          pdf.setFont("montserrat", "normal")
          pdf.setFontSize(12)
          pdf.setTextColor(0, 0, 0)
          pdf.text(x, y, msg)
        }
        
        const footer = (pdf, pageNum, opacity) => {
          pdf.setFont("montserrat", "normal")
          pdf.setFontSize(8);
          pdf.setTextColor(179,179, 179)
          pdf.text(20, 270, 'Quantification analysis details')
          pdf.setFont("montserrat", "light")
        
          pdf.text(20, 273, 'Atlas used: ' +  settingOfProduct.defaultAtlas +' / Reference region: '+ settingOfProduct.defaultRef[selectedFile.Tracer])
          pdf.addImage(MEDIA_LOGO, 'PNG', 20, 276, 60, 4)
          pdf.text(180,280, 'PatientID : ' + selectedFile.PatientID)
          pdf.text(185,283, 'page '+ pageNum +'/'+pageLength)
          pdf.setFont("montserrat", 'bold')
          pdf.setFontSize(50)
          pdf.saveGraphicsState();
          pdf.setGState(new pdf.GState({opacity: opacity}))
          // if (productName==="perfusion"){
          //   pdf.text(50,190, 'FOR RESEARCH', null, 30)
          //   pdf.text(50,210, 'PURPOSES ONLY', null, 30)
          // }
          pdf.saveGraphicsState();
          pdf.setGState(new pdf.GState({opacity: 1}))
        }
        
        const pdfCollection = []
      
        for (const [idx,item] of imgCollection.entries()){
          const tracer_fullName = (tracer) => {
            if(tracer === '[18F]FBP') return '[18F]Florbetapir'
            else if(tracer === '[18F]FMM') return '[18F]Flutemetamol'
            else if(tracer === '[18F]FBB') return '[18F]Florbetaben'
            else if(tracer === '[18F]FPN') return '[18F]Florapronol'
            else if(tracer === '[18F]FPCIT') return '[18F]FP-CIT'
            else if(tracer === '[18F]FDG') return '[18F]FDG'
            else if(tracer === '[18F]TAU') return '[18F]TAU'
            else if(tracer === '[99mtc]ECD') return '[99mtc]ECD'
            else if(tracer === '[99mtc]HMPAO') return '[99mtc]HMPAO'
            else return '[18F]Florbetapir'
          }
        
          const patient_info = [
            ["Patient Name", selectedFile.PatientName],
            ["Patient ID", selectedFile.PatientID],
            ["DOB", selectedFile.Age],
            ["Sex", selectedFile.Sex],
            ["Study Date", selectedFile.AcquisitionDateTime],
            ["Study Description", tracer_fullName(selectedFile.Tracer).replace('18', '\u00B9\u2078').replace('99mtc', '\u2079\u2079\u1d50'+'Tc')]
          ]
        
          const patient_info_state = [
            settingOfProduct.defaultPInfoState[0].state,
            settingOfProduct.defaultPInfoState[1].state,
            settingOfProduct.defaultPInfoState[2].state,
            settingOfProduct.defaultPInfoState[3].state,
            settingOfProduct.defaultPInfoState[4].state,
            true
          ]
        
          const headerName_region = productName === 'dat' ? 'Measure' : 'Region';
          const data_columns_1 = [
            {title: "Region", dataKey : "Region"},
            {title:"Total", dataKey : "Total"},
            {title: "Left", dataKey:"Left"},
            {title: "Right", dataKey:"Right"},
          ]
          const data_columns_2 = [
            {title: headerName_region, dataKey : headerName_region},
            {title:"Total", dataKey : "Total"},
            {title: "Left", dataKey:"Left"},
            {title: "Right", dataKey:"Right"},
          ]
          
          const data_rows = table1DataList[productName].items
          for (var i =0; i<data_rows.length; i++){
            data_rows[i].Total = data_rows[i].Total.toFixed(2)
            data_rows[i].Left = data_rows[i].Left.toFixed(2)
            data_rows[i].Right = data_rows[i].Right.toFixed(2)
          }
          
          const data_rows_2 = productName === 'dat' || productName==='amyloid'? table2DataList[productName].items :""
        
          
          for (var i=0; i<data_rows_2.length; i++){
            const semiquantification_percent_mark = ['Striatal asymmetry index', 'Caudate asymmetry index','Putamen asymmetry index' ]
            if(semiquantification_percent_mark.includes(data_rows_2[i].Measure)){
              data_rows_2[i].Measure = data_rows_2[i].Measure + ', %'
            }
          }
          
          if (productName === 'dat' || productName==='amyloid') {
            for (var i =0; i<data_rows_2.length; i++){
              data_rows_2[i].Total = data_rows_2[i].Total.toFixed(2)
              data_rows_2[i].Left = data_rows_2[i].Left.toFixed(2)
              data_rows_2[i].Right = data_rows_2[i].Right.toFixed(2)
            }
          }
        
          setFont(pdf[idx]);
          header(pdf[idx]);
          mainTitle(pdf[idx]);
        
          subTitle(pdf[idx], MEDIA_reportIcon1,20,42, 'Patient Information', 30, 46)
          const new_paitent_info = []
          for (var i=0; i<patient_info.length; i++){
            if (patient_info_state[i]){
              new_paitent_info.push(patient_info[i])
            }
          }
          
          pdf[idx].autoTable({
            body: new_paitent_info,
            // body: patient_info_state.map((item, idx) => item ? patient_info[idx]: ''),
            margin:{top:53, left:31},
            theme: 'plain',
            
            didParseCell : (body)=>{
              body.cell.styles.fontSize = 11
              const header = ["Patient Name", "Patient ID","DOB","Sex","Study Date", "Study Description"]
              if (header.includes(body.cell.text[0])){
                body.cell.styles.font = 'montserrat'
                body.cell.styles.fontStyle = "bold_semi"
                body.cell.styles.cellWidth =46
                // debugger;
              }else{
                body.cell.styles.font = 'noto'
                body.cell.styles.fontStyle = "medium"
              }
            }
          })
          // debugger;
          //brain table
          subTitle(pdf[idx],MEDIA_reportIcon2,20,112, 'Quantification Results', 30, 116)
          if (productName === 'dat') {
            footer(pdf[idx], 1, 0.2, idx);
            pdf[idx].addImage(item.brainTable, 'PNG', 30, 120, Math.ceil(140*1.07), 120);
            transferFunc({message:"Please wait...", target:""})
          } else {
            footer(pdf[idx], 1, 0.2, idx);
            pdf[idx].addImage(item.brainTable, 'PNG', 30, 120, Math.ceil(140*1.07), 130);
            transferFunc({message:"Please wait...", target:""})
          } 
        
          // page 2
          pdf[idx].addPage();
          header(pdf[idx]);
          mainTitle(pdf[idx]);
          subTitle(pdf[idx],MEDIA_reportIcon2,20,42,'Quantification Results', 30, 46);
          
          // d3 graph
          if (productName === 'dat') {
            // tableTitle('Striatal SBR', 30, 58)
            pdf[idx].addImage(item.report2Graph, 'PNG', 20, 50, Math.ceil(3.4*55), 55);
          } else {
            pdf[idx].addImage(item.report2Graph, 'PNG', 3, 50, Math.ceil(3.4*60), 60);
            tableTitle(pdf[idx],'Regional SUVR', 30, 112)
          }    
          //*SUVR or SBR table
          const table1_startY = productName !== 'dat' ? 115: 105
          const table_fontSize = productName !== 'dat' ? 11 : 10
          pdf[idx].autoTable(data_columns_1, data_rows, {
            startY:table1_startY,
            margin:{left:30},
            tableWidth : 155,
            theme:'plain',
            styles : {
              halign :'center',
            },
            headStyles : {
              fillColor : [202,202,202],
            },          
            didParseCell : (body) => {
              body.cell.styles.fontSize = table_fontSize
              // body.cell.contentHeight = 0.2
              // body.cell.height = 0.2
              body.cell.styles.minCellHeight =0.2
              if (body.section === 'head'){
                body.cell.styles.font = 'montserrat'
                body.cell.styles.fontStyle = "bold_semi"
              }else {
                // body.table.styles.bodyStyles = {
                //   height : 5,
                
                // }
                // debugger;
                const Total = parseFloat(body.row.raw.Total.toString())
                const Left = parseFloat(body.row.raw.Left.toString());
                const Right = parseFloat(body.row.raw.Right.toString());
                body.cell.styles.font = 'montserrat'
                body.cell.styles.fontStyle = "light"
                
                body.row.raw.Total = Total.toFixed(2);
                body.row.raw.Left = Left.toFixed(2)
                body.row.raw.Right = Right.toFixed(2)
              }
            },
            createCell:(cell, opts) => {
              cell.styles.cellPadding = 50;
            }
          })



          if (pageLength === 4){
            footer(pdf[idx], 2, 0.202,idx)
            transferFunc({message:"Please wait...", target:""})
            pdf[idx].addPage();
            header(pdf[idx]);
            mainTitle(pdf[idx]);
            subTitle(pdf[idx], MEDIA_reportIcon2,20,42,'Quantification Results', 30, 46);
            if (productName === 'amyloid' || productName ==='dat'){
              const titleContent = productName === 'amyloid'? 'Regional Centiloid' : 'Semiquantification'
              const table2_startY = productName === 'amyloid' ? 60: 60
              tableTitle(pdf[idx],titleContent, 30, table2_startY-3)
              //Centiloid table
              pdf[idx].autoTable(data_columns_2, data_rows_2, {
                startY:table2_startY,
                margin:{left:30},
                tableWidth : 155,
                theme:'plain',
                styles : {
                  halign :'center'
                },
                headStyles : {
                  fillColor : [202,202,202],
                },
                didParseCell : (body) => {
                  body.cell.styles.fontSize = table_fontSize
                  if (body.section === 'head'){
                    body.cell.styles.font = 'montserrat'
                    body.cell.styles.fontStyle = "bold_semi"
                  }else {
                    const Total = parseFloat(body.row.raw.Total.toString());
                    const Left = parseFloat(body.row.raw.Left.toString());
                    const Right = parseFloat(body.row.raw.Right.toString());
                    body.cell.styles.font = 'montserrat'
                    body.cell.styles.fontStyle = "light"
                    body.row.raw.Total = Total.toFixed(2)
                    body.row.raw.Left = Left.toFixed(2)
                    body.row.raw.Right = Right.toFixed(2)
                  }
                }
              })
              footer(pdf[idx],3, 0.201, idx);
              transferFunc({message:"Please wait...", target:""})
            }
            
          }else {
            if(selectedFile.Tracer === '[18F]FPN'){
              
            }else if(productName === 'amyloid' || productName ==='dat'){
                const titleContent = productName === 'amyloid'? 'Regional Centiloid' : 'Semiquantification'
                const table2_startY = productName === 'amyloid' ? 195: 212
                tableTitle(pdf[idx],titleContent, 30, table2_startY-3)
                //Centiloid table
                pdf[idx].autoTable(data_columns_2, data_rows_2, {
                  startY:table2_startY,
                  margin:{left:30},
                  tableWidth : 155,
                  theme:'plain',
                  styles : {
                    halign :'center'
                  },
                  headStyles : {
                    fillColor : [202,202,202],
                  },
                  didParseCell : (body) => {
                    body.cell.styles.fontSize = table_fontSize
                    if (body.section === 'head'){
                      body.cell.styles.font = 'montserrat'
                      body.cell.styles.fontStyle = "bold_semi"
                    }else {
                      const Total = parseFloat(body.row.raw.Total.toString());
                      const Left = parseFloat(body.row.raw.Left.toString());
                      const Right = parseFloat(body.row.raw.Right.toString());
                      body.cell.styles.font = 'montserrat'
                      body.cell.styles.fontStyle = "light"
                      body.row.raw.Total = Total.toFixed(2)
                      body.row.raw.Left = Left.toFixed(2)
                      body.row.raw.Right = Right.toFixed(2)
                    }
                  }
                })
            }
            footer(pdf[idx],2, 0.201, idx);
            transferFunc({message:"Please wait...", target:""})
          }
        
          //*second table 
            pdf[idx].addPage();
            header(pdf[idx]);
            mainTitle(pdf[idx]);
            subTitle(pdf[idx],MEDIA_reportIcon3,20,42, product_full_name() + " PET Image",30,46)
        
            tableTitle(pdf[idx],"Spatially normalized" + suvrOrSbr + "Image", 35, 58)
        
          
            const report3AxialSize = productName === 'dat' ? {'width': Math.ceil(2.3*80), 'height': 30}:{'width':Math.ceil(2*80), 'height':62}
            // const report3AxialSize = productName === 'dat' ? {'width': Math.ceil(2.3*80), 'height': 30}:{'width':Math.ceil(2.3*80), 'height':62}
            pdf[idx].addImage(item.report3Axial, 'PNG', 20, 65, report3AxialSize.width, report3AxialSize.height);
            productName !== 'dat' && pdf[idx].addImage(item.report3Axial_colormap, 'PNG', 180, 66, 30, 60)
        
            productName !== 'dat' && tableTitle(pdf[idx],"SUVR rendered on brain surface", 35, 148)
            productName === 'dat' ? pdf[idx].addImage(item.report3Mip, 'PNG', 25, 100, Math.ceil(2.3*80), 80) : pdf[idx].addImage(item.report3Surface, 'PNG', 20, 160, Math.ceil(2*80), 80); //surface image
            productName !== 'dat' && pdf[idx].addImage(item.report3Surface_colormap, 'PNG', 175, 166, 35, 80);
            footer(pdf[idx], pageLength ,0.206, idx);
            transferFunc({message:"Please wait...", target:""})
        
            pdfCollection.push(pdf)
            
        }
        const zip = new JSZip();
        pdf.forEach((p,i)=>{
        //   //*디버깅용
          const selectedFile_report = selectedFile
          const fname = selectedFile_report.id+'_'+selectedFile_report.PatientID+'_'+selectedFile_report.PatientName+'_'+selectedFile_report.Tracer+'_'+selectedFile_report.AcquisitionDateTime+'_'+settingOfProduct.defaultRef[selectedFile_report.Tracer]+'.pdf';
          if (!serverState){
            
        
            p.save(fname)
            spinnerController(false)
          }else{
            zip.file(fname, p.output('blob'))
          }
        })
      
        if (serverState){
          zip.generateAsync({type:'base64'}).then((content) => {
            var token = sessionStorage.getItem('token')
            var frm = new FormData();
            frm.append("data", content);
            axios.post(
              process.env.REACT_APP_BASE_URL + "testing/report_upload/",
              frm,
              {
                headers: {
                  'Authorization': 'jwt ' + token,
                  'Content-Type': "multipart/form-data"
                }
              // }).then(response => {
              //   console.log(response);
              })
              .catch(error => {
                console.error(error);
              })
        
            spinnerController(false)
          });
          
        }
      
      
      }  
      
    }
  }
  

}

export const generatePDFSaveToServer = async (refs, productName,selectedFile,setting, transferFunc, spinnerController) => {
  transferFunc({message:"Please wait...", target:""})
// export const generatePDFSaveToServer = async (elRefs, func, func2, func3) => {
  let input_time_fast = new Date()
  const settingOfProduct = setting[productName];
  const defaultRegionLength = subRegions[productName].map(v=> v.level).filter(lev => lev===0).length;
  const settingRegionLength = settingOfProduct.list.map(v => v.level).filter(lev => lev===0).length;
  const limitNum_report = limitNum[productName]
  const pageLength = settingRegionLength-defaultRegionLength>limitNum_report ? 4 : 3
  const productCoreItem = coreItem[productName];
  const defaultAtlas = setting[productName].defaultAtlas
  const refName = settingOfProduct.defaultRef[selectedFile['Tracer']]
  const RF = RefFactor({productName, refName, ponsRF:selectedFile["ratio_gry2pons"], crblRF:selectedFile["ratio_gry2crbl"], wmRF:selectedFile["ratio_gry2wm"], cgRF:selectedFile["ratio_gry2cg"]})
  // debugger;
  const table1DataList = {
      "amyloid": reportAmyloidtable1({ settingOfProduct:setting['amyloid'], typeSelect: productCoreItem.type1Selector, tableItems:coreItem['amyloid']['analysis'].bottom[1].cards[0], selectedFile: selectedFile, defaultAtlas:defaultAtlas, RF:RF }),
      "dat": reportDATtable1({     settingOfProduct:setting['dat'],     typeSelect: productCoreItem.type1Selector, tableItems:coreItem['dat']['analysis'].bottom[1].cards[0], selectedFile: selectedFile, defaultAtlas:defaultAtlas , RF:RF}),
      "fdg": reportAmyloidtable1({     settingOfProduct:setting['fdg'],     typeSelect: productCoreItem.type1Selector, tableItems:coreItem['fdg']['analysis'].bottom[1].cards[0], selectedFile: selectedFile, defaultAtlas:defaultAtlas , RF:RF}),
      "tau": reportAmyloidtable1({     settingOfProduct:setting['tau'],     typeSelect: productCoreItem.type1Selector, tableItems:coreItem['tau']['analysis'].bottom[1].cards[0], selectedFile: selectedFile, defaultAtlas:defaultAtlas , RF:RF}),
  }

  const table2DataList = {
    "amyloid":reportAmyloidDATtable2({ settingOfProduct:setting['amyloid'], typeSelect: productCoreItem.type2Selector, tableItems:coreItem['amyloid']['analysis'].bottom[1].cards[1], selectedFile: selectedFile, defaultAtlas:defaultAtlas, RF:RF }),
    "dat":reportAmyloidDATtable2({     settingOfProduct:setting['dat'],     typeSelect: productCoreItem.type2Selector, tableItems:coreItem['dat']['analysis'].bottom[1].cards[1], selectedFile: selectedFile, defaultAtlas:defaultAtlas, RF:RF }),
  }

  
  // debugger;

  const product_full_name = () => {
    if (productName === 'amyloid') return 'Amyloid'
    else if(productName === 'dat') return 'Dopamine'
    else if(productName === 'tau') return 'Tau'
    else if(productName === 'fdg') return 'FDG'
    else return 'Amyloid'
  } 

  const suvrOrSbr = productName ==='dat' ? ' SBR ' : ' SUVR '
  const imgCollection = []
  let temp
  // debugger;
  for (const [i,ref] of refs.entries()) {
    if (productName === 'dat') {
      const initialPercent = (i/refs[0].length)*100
      const plusPercent = 100/(refs[0].length*4)
      const img_scale = 2
      const brainTable = await domtoimage.toPng(document.getElementById(`brain-table-${i}`), {
        //improve image quality
        width: document.getElementById(`brain-table-${i}`).clientWidth * img_scale,
        height: document.getElementById(`brain-table-${i}`).clientHeight * img_scale,
        style:{
          transformOrign: 'center',
          transform: `scale(${img_scale}) translateX(+12%) translateY(+13%)`,  
        }
      })
      temp = initialPercent+plusPercent
      
      transferFunc({message:"Making PDF...", percent: temp.toFixed(0), target:"PDF"})

      const report2Graph = await html2canvas(document.getElementById(`report2-graph-${i}`),{scale:1.5}).then(canvas=>canvas.toDataURL('image/jpeg'));
      temp = temp+plusPercent
      transferFunc({message:"Making PDF...", percent: temp.toFixed(0), target:"PDF"})

      const report3Axial = await domtoimage.toPng(document.getElementById(`report3-axial-${i}`),)
      temp = temp+plusPercent
      transferFunc({message:"Making PDF...", percent: temp.toFixed(0), target:"PDF"})

      const report3Mip = await domtoimage.toPng(document.getElementById(`report3-mip-${i}`),)
      temp = temp+plusPercent
      transferFunc({message:"Making PDF...", percent: temp.toFixed(0), target:"PDF"})

      const imgResult = {
        'brainTable': brainTable,
        'report2Graph': report2Graph,
        'report3Axial': report3Axial,
        'report3Mip': report3Mip,
      };
      imgCollection.push(imgResult)
    } else {

      const initialPercent = (i/refs[0].length)*100
      const plusPercent = 100/(refs[0].length*4)
      // const sumPercent = initialPercent + plusPercent
      const img_scale = 3
      const brainTable = await domtoimage.toPng(document.getElementById(`brain-table-${i}`), {
      //imporve image quality
      width: document.getElementById(`brain-table-${i}`).clientWidth * img_scale,
      height: document.getElementById(`brain-table-${i}`).clientHeight * img_scale,
      style:{
        transformOrign: 'center',
        transform: `scale(${img_scale}) translateX(+11%) translateY(+12%)`,      
      }
      })
      temp = initialPercent+plusPercent
      transferFunc({message:"Making PDF...", percent: temp.toFixed(0), target:"PDF"})

      const report2Graph = await domtoimage.toPng(document.getElementById(`report2-graph-${i}`), {
      //imporve image quality
      width: document.getElementById(`report2-graph-${i}`).clientWidth * img_scale,
      height: document.getElementById(`report2-graph-${i}`).clientHeight * img_scale,
      style:{
        transformOrign: 'center',
        transform: `scale(${img_scale}) translateX(-22%) translateY(+12%)`,      
      }
      })
      temp = temp+plusPercent
      transferFunc({message:"Making PDF...", percent: temp.toFixed(0), target:"PDF"})

      const report3Axial = await domtoimage.toPng(document.getElementById(`report3-axial-slices-${i}`), {quality: 1})
      temp = temp+plusPercent
      transferFunc({message:"Making PDF...", percent: temp.toFixed(0), target:"PDF"})

      const report3Axial_colormap = await domtoimage.toPng(document.getElementById(`report3-axial-colorbar-${i}`),
      {
        width:( document.getElementById(`report3-axial-colorbar-${i}`).clientWidth)*img_scale ,
        height: (document.getElementById(`report3-axial-colorbar-${i}`).clientHeight)*img_scale ,
        style:{
          transformOrign: 'center',
          transform: `scale(${img_scale}) translateX(-30%) translateY(-22%)`,      
        }
      }
      )

      const report3Surface = await domtoimage.toPng(document.getElementById(`report3-surface-img-${i}`),)
      const report3Surface_colormap = await domtoimage.toPng(document.getElementById(`report3-surface-colormap-${i}`),{
        width:( document.getElementById(`report3-surface-colormap-${i}`).clientWidth)*img_scale ,
        height: (document.getElementById(`report3-surface-colormap-${i}`).clientHeight)*img_scale ,
        style:{
          transformOrign: 'center',
          transform: `scale(${img_scale}) translateX(-30%) translateY(-22%)`,      
        }
      })
      temp = temp+plusPercent
      transferFunc({message:"Making PDF...", percent: temp.toFixed(0), target:"PDF"})

      const imgResult = {
        'brainTable': brainTable,
        'report2Graph': report2Graph,
        'report3Axial': report3Axial,
        'report3Axial_colormap': report3Axial_colormap,
        'report3Surface': report3Surface,
        'report3Surface_colormap': report3Surface_colormap,
      };

      imgCollection.push(imgResult)
    }
  }
 


  const pdf = refs.map(_=>new jsPDF("p", "mm", "a4"))
  const setFont = (pdf) => {
    pdf.addFileToVFS("montserrat_italic.ttf", montserrat_medium_italic);
    pdf.addFont("montserrat_italic.ttf", "montserrat", "italic");
    pdf.addFileToVFS("montserrat_medium.ttf", montserrat_medium);
    pdf.addFont("montserrat_medium.ttf", "montserrat", "normal");
    pdf.addFileToVFS("montserrat_bold.ttf", montserrat_bold);
    pdf.addFont("montserrat_bold.ttf", "montserrat", "bold");
    pdf.addFileToVFS("montserrat_bold_semi.ttf", montserrat_bold_semi);
    pdf.addFont("montserrat_bold_semi.ttf", "montserrat", "bold_semi")
    pdf.addFileToVFS("montserrat_light.ttf", montserrat_light);
    pdf.addFont("montserrat_light.ttf", "montserrat", "light")    
  }

  const header = (pdf) => {
    const today = new Date();
    const year = today.getFullYear(); 
    const month = today.getMonth() + 1; 
    const date = today.getDate();

    pdf.setFont("montserrat", "italic")
    pdf.setFontSize(8);
    pdf.setTextColor(179,179, 179)
    pdf.text(20,10,'This product has not been approved by the MFDS and FDA')
    pdf.text(20,13,'and is not intended to diagnose, treat, cure, or prevent any disease.')
    pdf.text(180,10, 'Last Printed')
    pdf.text(184,13, date + '/' + month + '/' + year)    
  }

  const mainTitle = (pdf) => {
    pdf.setFillColor(90, 122, 181)
    pdf.rect(20,15,1,20, 'F')
    
    pdf.setFont("montserrat", "bold")
    pdf.setFontSize(20)
    pdf.setTextColor(0,0, 0)
    pdf.text(25,23, 'BTXBrain-' + product_full_name())

    pdf.setFont("montserrat", "light")
    pdf.setFontSize(13)
    productName === 'perfusion' ? pdf.text(25,31, 'AI-based Automatic '+ product_full_name() + ' SPECT Quantification Report') :   
    pdf.text(25,31, 'AI-based Automatic '+ product_full_name() + ' PET Quantification Report')      
  }
  
  const subTitle = (pdf,iconSrc,a,b, msg, x, y) => {
    pdf.addImage(iconSrc, 'PNG', a,b ,5.5,5)
    pdf.setFont("montserrat", "bold")
    pdf.setTextColor(90, 122, 181)
    pdf.text(x,y, msg)    
  }

  const tableTitle = (pdf,msg, x, y) => {
    pdf.setFont("montserrat", "normal")
    pdf.setFontSize(12)
    pdf.setTextColor(0, 0, 0)
    pdf.text(x, y, msg)
  }
  
  const footer = (pdf, pageNum, opacity, idx) => {
    pdf.setFont("montserrat", "normal")
    pdf.setFontSize(8);
    pdf.setTextColor(179,179, 179)
    pdf.text(20, 270, 'Quantification analysis details')
    pdf.setFont("montserrat", "light")

    pdf.text(20, 273, 'Atlas used: ' +  settingOfProduct.defaultAtlas +' / Reference region: '+ settingOfProduct.defaultRef[selectedFile.Tracer])
    pdf.addImage(MEDIA_LOGO, 'PNG', 20, 276, 60, 4)
    pdf.text(180,280, 'PatientID : ' + selectedFile.PatientID)
    pdf.text(185,283, 'page '+ pageNum +'/'+pageLength)
    pdf.setFont("montserrat", 'bold')
    pdf.setFontSize(50)
    pdf.saveGraphicsState();
    pdf.setGState(new pdf.GState({opacity: opacity}))
    // pdf.text(50,190, 'FOR RESEARCH', null, 30)
    // pdf.text(50,210, 'PURPOSES ONLY', null, 30)
    pdf.saveGraphicsState();
    pdf.setGState(new pdf.GState({opacity: 1}))
  }



  const pdfCollection = []
  for (const [idx,item] of imgCollection.entries()){

    const plusPercent = 100/(imgCollection.length*pageLength)

    const tracer_fullName = (tracer) => {
      if(tracer === '[18F]FBP') return '[18F]Florbetapir'
      else if(tracer === '[18F]FMM') return '[18F]Flutemetamol'
      else if(tracer === '[18F]FBB') return '[18F]Florbetaben'
      else if(tracer === '[18F]FPN') return '[18F]Florapronol'
      else if(tracer === '[18F]FPCIT') return '[18F]FP-CIT'
      else if(tracer === '[18F]FDG') return '[18F]FDG'
      else if(tracer === '[18F]TAU') return '[18F]TAU'
      else return '[18F]Florbetapir'
    }

    const patient_info = [
      ["Patient Name", selectedFile.PatientName],
      ["Patient ID", selectedFile.PatientID],
      ["DOB", selectedFile.Age],
      ["Sex", selectedFile.Sex],
      ["Study Date", selectedFile.AcquisitionDateTime],
      ["Study Description", tracer_fullName(selectedFile.Tracer).replace('18', '\u00B9\u2078').replace('99mtc', '\u2079\u2079\u1d50'+'Tc')]
    ]

    const patient_info_state = [
      settingOfProduct.defaultPInfoState[0].state,
      settingOfProduct.defaultPInfoState[1].state,
      settingOfProduct.defaultPInfoState[2].state,
      settingOfProduct.defaultPInfoState[3].state,
      true
    ]

    const headerName_region = productName === 'dat' ? 'Measure' : 'Region';
    const data_columns_1 = [
      {title: "Region", dataKey : "Region"},
      {title:"Total", dataKey : "Total"},
      {title: "Left", dataKey:"Left"},
      {title: "Right", dataKey:"Right"},
    ]
    const data_columns_2 = [
      {title: headerName_region, dataKey : headerName_region},
      {title:"Total", dataKey : "Total"},
      {title: "Left", dataKey:"Left"},
      {title: "Right", dataKey:"Right"},
    ]
    
    const data_rows = table1DataList[productName].items
    for (var i =0; i<data_rows.length; i++){
      data_rows[i].Total = data_rows[i].Total.toFixed(2)
      data_rows[i].Left = data_rows[i].Left.toFixed(2)
      data_rows[i].Right = data_rows[i].Right.toFixed(2)
    }

    const data_rows_2 = productName === 'dat' || productName==='amyloid'? table2DataList[productName].items :""
  
    
    for (var i=0; i<data_rows_2.length; i++){
      const semiquantification_percent_mark = ['Striatal asymmetry index', 'Caudate asymmetry index','Putamen asymmetry index' ]
      if(semiquantification_percent_mark.includes(data_rows_2[i].Measure)){
        data_rows_2[i].Measure = data_rows_2[i].Measure + ', %'
      }
    }
    
    if (productName === 'dat' || productName==='amyloid') {
      for (var i =0; i<data_rows_2.length; i++){
        data_rows_2[i].Total = data_rows_2[i].Total.toFixed(2)
        data_rows_2[i].Left = data_rows_2[i].Left.toFixed(2)
        data_rows_2[i].Right = data_rows_2[i].Right.toFixed(2)
      }
    }

    setFont(pdf[idx]);
    header(pdf[idx]);
    mainTitle(pdf[idx]);

    subTitle(pdf[idx], MEDIA_reportIcon1,20,42, 'Patient Information', 30, 46)
    const new_paitent_info = []
    for (var i=0; i<patient_info.length; i++){
      if (patient_info_state[i]){
        new_paitent_info.push(patient_info[i])
      }
    }
    console.log(new_paitent_info)
    
    pdf[idx].autoTable({
      body: new_paitent_info,
      // body: patient_info_state.map((item, idx) => item ? patient_info[idx]: ''),
      margin:{top:53, left:31},
      theme: 'plain',
      
      didParseCell : (body)=>{
        body.cell.styles.fontSize = 11
        const header = ["Patient Name", "Patient ID","DOB","Sex","Study Date", "Study Description"]
        if (header.includes(body.cell.text[0])){
          body.cell.styles.font = 'montserrat'
          body.cell.styles.fontStyle = "bold_semi"
          body.cell.styles.cellWidth =46
          // debugger;
        }else{
          body.cell.styles.font = 'montserrat'
          body.cell.styles.fontStyle = "light"
        }
      }
    })
    // debugger;
    //brain table
    subTitle(pdf[idx],MEDIA_reportIcon2,20,112, 'Quantification Results', 30, 116)
    if (productName === 'dat') {
      footer(pdf[idx], 1, 0.2, idx);
      pdf[idx].addImage(item.brainTable, 'PNG', 30, 120, Math.ceil(140*1.07), 130);
      transferFunc({message:"Please wait...", target:""})
    } else {
      footer(pdf[idx], 1, 0.2, idx);
      pdf[idx].addImage(item.brainTable, 'PNG', 30, 120, Math.ceil(140*1.07), 130);
      transferFunc({message:"Please wait...", target:""})
    } 

    // page 2
    pdf[idx].addPage();
    header(pdf[idx]);
    mainTitle(pdf[idx]);
    subTitle(pdf[idx],MEDIA_reportIcon2,20,42,'Quantification Results', 30, 46);
    
    // d3 graph
    if (productName === 'dat') {
      // tableTitle('Striatal SBR', 30, 58)
      pdf[idx].addImage(item.report2Graph, 'PNG', 20, 50, Math.ceil(3.4*55), 55);
    } else {
      pdf[idx].addImage(item.report2Graph, 'PNG', 3, 50, Math.ceil(3.4*60), 60);
      tableTitle(pdf[idx],'Regional SUVR', 30, 112)
    }    
    //*SUVR or SBR table
    const table1_startY = productName !== 'dat' ? 115: 105
    const table_fontSize = productName !== 'dat' ? 11 : 10
    pdf[idx].autoTable(data_columns_1, data_rows, {
      startY:table1_startY,
      margin:{left:30},
      tableWidth : 155,
      theme:'plain',
      styles : {
        halign :'center',
      },
      headStyles : {
        fillColor : [202,202,202],
      },          
      didParseCell : (body) => {
        body.cell.styles.fontSize = table_fontSize
        // body.cell.contentHeight = 0.2
        // body.cell.height = 0.2
        body.cell.styles.minCellHeight =0.2
        if (body.section === 'head'){
          body.cell.styles.font = 'montserrat'
          body.cell.styles.fontStyle = "bold_semi"
        }else {
          // body.table.styles.bodyStyles = {
          //   height : 5,
          
          // }
          // debugger;
          const Total = parseFloat(body.row.raw.Total.toString())
          const Left = parseFloat(body.row.raw.Left.toString());
          const Right = parseFloat(body.row.raw.Right.toString());
          body.cell.styles.font = 'montserrat'
          body.cell.styles.fontStyle = "light"
          
          body.row.raw.Total = Total.toFixed(2);
          body.row.raw.Left = Left.toFixed(2)
          body.row.raw.Right = Right.toFixed(2)
        }
      },
      createCell:(cell, opts) => {
        cell.styles.cellPadding = 50;
      }
    })
    if (pageLength === 4){
      footer(pdf[idx], 2, 0.202,idx)
      transferFunc({message:"Please wait...", target:""})
      pdf[idx].addPage();
      header(pdf[idx]);
      mainTitle(pdf[idx]);
      subTitle(pdf[idx], MEDIA_reportIcon2,20,42,'Quantification Results', 30, 46);
      if (productName === 'amyloid' || productName ==='dat'){
        const titleContent = productName === 'amyloid'? 'Regional Centiloid' : 'Semiquantification'
        const table2_startY = productName === 'amyloid' ? 60: 60
        tableTitle(pdf[idx],titleContent, 30, table2_startY-3)
        //Centiloid table
        pdf[idx].autoTable(data_columns_2, data_rows_2, {
          startY:table2_startY,
          margin:{left:30},
          tableWidth : 155,
          theme:'plain',
          styles : {
            halign :'center'
          },
          headStyles : {
            fillColor : [202,202,202],
          },
          didParseCell : (body) => {
            body.cell.styles.fontSize = table_fontSize
            if (body.section === 'head'){
              body.cell.styles.font = 'montserrat'
              body.cell.styles.fontStyle = "bold_semi"
            }else {
              const Total = parseFloat(body.row.raw.Total.toString());
              const Left = parseFloat(body.row.raw.Left.toString());
              const Right = parseFloat(body.row.raw.Right.toString());
              body.cell.styles.font = 'montserrat'
              body.cell.styles.fontStyle = "light"
              body.row.raw.Total = Total.toFixed(2)
              body.row.raw.Left = Left.toFixed(2)
              body.row.raw.Right = Right.toFixed(2)
            }
          }
        })
        footer(pdf[idx],3, 0.201, idx);
        transferFunc({message:"Please wait...", target:""})
      }
      
    }else {
      if (productName === 'amyloid' || productName ==='dat'){
        const titleContent = productName === 'amyloid'? 'Regional Centiloid' : 'Semiquantification'
        const table2_startY = productName === 'amyloid' ? 195: 212
        tableTitle(pdf[idx],titleContent, 30, table2_startY-3)
        //Centiloid table
        pdf[idx].autoTable(data_columns_2, data_rows_2, {
          startY:table2_startY,
          margin:{left:30},
          tableWidth : 155,
          theme:'plain',
          styles : {
            halign :'center'
          },
          headStyles : {
            fillColor : [202,202,202],
          },
          didParseCell : (body) => {
            body.cell.styles.fontSize = table_fontSize
            if (body.section === 'head'){
              body.cell.styles.font = 'montserrat'
              body.cell.styles.fontStyle = "bold_semi"
            }else {
              const Total = parseFloat(body.row.raw.Total.toString());
              const Left = parseFloat(body.row.raw.Left.toString());
              const Right = parseFloat(body.row.raw.Right.toString());
              body.cell.styles.font = 'montserrat'
              body.cell.styles.fontStyle = "light"
              body.row.raw.Total = Total.toFixed(2)
              body.row.raw.Left = Left.toFixed(2)
              body.row.raw.Right = Right.toFixed(2)
            }
          }
        })
      }
      footer(pdf[idx],2, 0.201, idx);
      transferFunc({message:"Please wait...", target:""})
    }

    //*second table 
      pdf[idx].addPage();
      header(pdf[idx]);
      mainTitle(pdf[idx]);
      subTitle(pdf[idx],MEDIA_reportIcon3,20,42, product_full_name() + " PET Image",30,46)

      tableTitle(pdf[idx],"Spatially normalized" + suvrOrSbr + "Image", 35, 58)

    
      const report3AxialSize = productName === 'dat' ? {'width': Math.ceil(2.3*80), 'height': 30}:{'width':Math.ceil(2*80), 'height':62}
      // const report3AxialSize = productName === 'dat' ? {'width': Math.ceil(2.3*80), 'height': 30}:{'width':Math.ceil(2.3*80), 'height':62}
      pdf[idx].addImage(item.report3Axial, 'PNG', 20, 65, report3AxialSize.width, report3AxialSize.height);
      productName !== 'dat' && pdf[idx].addImage(item.report3Axial_colormap, 'PNG', 180, 66, 30, 60)

      productName !== 'dat' && tableTitle(pdf[idx],"SUVR rendered on brain surface", 35, 148)
      productName === 'dat' ? pdf[idx].addImage(item.report3Mip, 'PNG', 25, 100, Math.ceil(2.3*80), 80) : pdf[idx].addImage(item.report3Surface, 'PNG', 20, 160, Math.ceil(2*80), 80); //surface image
      productName !== 'dat' && pdf[idx].addImage(item.report3Surface_colormap, 'PNG', 175, 166, 35, 80);
      footer(pdf[idx], pageLength ,0.206, idx);
      transferFunc({message:"Please wait...", target:""})

      pdfCollection.push(pdf)
      
  }


  const zip = new JSZip();

  // zip.generateAsync({ type: 'blob' }).then((content) => {
  //   saveAs(content, 'test.zip');
  // });
  pdf.forEach((p,i)=>{
    //*디버깅용
    // pdf.save('output.pdf')
    //*최종
    //* 파일명 : fileNum+"_"+PatientID +"_"+PatientName+"_"+tracerName+"_"+AcquisitionDateTime+"_"+refName+".pdf"
    const selectedFile_report = selectedFile
    zip.file(selectedFile_report.id+'_'+selectedFile_report.PatientID+'_'+selectedFile_report.PatientName+'_'+selectedFile_report.Tracer+'_'+selectedFile_report.AcquisitionDateTime+'_'+settingOfProduct.defaultRef[selectedFile_report.Tracer]+'.pdf', p.output('blob'))
    
  })
  
  // zip.generateAsync({type:'blob'}).then((content) => {
  //   saveAs(content, 'BRTNX(PDF).zip');
  //   spinnerController(false)
  //   let output_time_fast = new Date()
  //   // console.log("output time", output_time_fast)
  //   const time_variation_sec = (output_time_fast.getTime() - input_time_fast.getTime())/1000
  //   console.log("time_variation_sec",time_variation_sec)
  // });

  zip.generateAsync({type:'base64'}).then((content) => {
    // saveAs(content, 'BRTNX(PDF).zip');
    var token = sessionStorage.getItem('token')
    var frm = new FormData();
    frm.append("data", content);
    axios.post(
      process.env.REACT_APP_BASE_URL + "testing/report_upload/",
      frm,
      {
        headers: {
          'Authorization': 'jwt ' + token,
          'Content-Type': "multipart/form-data"
        }
      }).then(response => {
        console.log(response);
      })
      .catch(error => {
        console.error(error);
      })

    spinnerController(false)
    let output_time_fast = new Date()
    // console.log("output time", output_time_fast)
    const time_variation_sec = (output_time_fast.getTime() - input_time_fast.getTime())/1000
    console.log("time_variation_sec",time_variation_sec)
  });



  // promiseAll.then(() => {
  //   console.log("complete: ", promiseAll);
  //   setTimeout(() => {
  //     // func3("Downloading...")
  //     func3({ message: "Downloading...", percent: 100, target: "PDF" })
  //     let output_time_normal = new Date()
  //     // console.log("output time",output_time_normal)
  //     const time_variation_sec = (output_time_normal.getTime() - input_time_normal.getTime()) / 1000
  //     console.log("time_variation_sec", time_variation_sec)
  //   }, 0)
    // zip.generateAsync({ type: 'base64' }).then((content) => {
    //   var token = sessionStorage.getItem('token')
    //   var frm = new FormData();
    //   frm.append("data", content);
    //   axios.post(
    //     IPinUSE + "testing/report_upload/",
    //     frm,
    //     {
    //       headers: {
    //         'Authorization': 'jwt ' + token,
    //         'Content-Type': "multipart/form-data"
    //       }
    //     }).then(response => {
    //       console.log(response);
    //     })
    //     .catch(error => {
    //       console.error(error);
    //     })
      // saveAs(content, IPinUSE + `result/download/pdf.zip`);
      // func(false)
      // func2(false)
    // });
  // });
}
// export const generatePDF = async (elRefs, func, func2, func3) => {

//   func(true)
//   const pdfArr = elRefs.map(_=>new jsPDF('p', 'mm', 'A4'))
//   const width = pdfArr[0].internal.pageSize.getWidth();
//   const height = pdfArr[0].internal.pageSize.getHeight();

//   let zip = new JSZip();
//     const totalFiles = elRefs.length;
//     const eachPages = elRefs[0].length
//     const totalPages = totalFiles * eachPages;
//     const promiseAll = Promise.all(elRefs.map( async (elRef,fileNum)=>{
//       const PatientID = elRef[0].current.childNodes[0].childNodes[2].childNodes[0].childNodes[1].childNodes[0].childNodes[1].childNodes[1].childNodes[0].textContent
//       const PatientName = elRef[0].current.childNodes[0].childNodes[2].childNodes[0].childNodes[1].childNodes[0].childNodes[0].childNodes[1].childNodes[0].textContent
//       const tracerName = (elRef[0].current.childNodes[0].childNodes[2].childNodes[0].childNodes[1].childNodes[0].childNodes[5].childNodes[1].childNodes[0].textContent).replace('\u00B9\u2078', '18')
//       const AcquisitionDateTime = elRef[0].current.childNodes[0].childNodes[2].childNodes[0].childNodes[1].childNodes[0].childNodes[4].childNodes[1].childNodes[0].textContent
//       const refName = (elRef[0].current.childNodes[0].childNodes[3].childNodes[1].textContent).split(':').at(-1).trim()

//       const fname = fileNum+"_"+PatientID +"_"+PatientName+"_"+tracerName+"_"+AcquisitionDateTime+"_"+refName+".pdf"
//       console.log('pdf:', fname)

//       const canvasArray = await Promise.all(elRef.map( (ref,pageNum)=>{

//         const currentPage = fileNum * eachPages + (pageNum+1)
//         const currentPercent = (currentPage/totalPages*100).toFixed(0)
//         console.log(currentPercent, currentPage, totalPages)
//         setTimeout(()=>{
//           func3(currentPercent+"%")
//         },0)

//         return html2canvas(ref.current, {allowTaint:true, useCORS:true})
//       }));

//     return Promise.all(canvasArray.map( async (canvas,pageNum)=>{
//       const imgData = canvas.toDataURL('image/png',);
//       pdfArr[fileNum].addImage(imgData, 'JPEG', 0, 0, width, height);
//       if (pageNum < canvasArray.length-1) pdfArr[fileNum].addPage();
//       else zip.file(fname, pdfArr[fileNum].output('blob'));

//       const currentPage = fileNum * eachPages + (pageNum+1)
//       const currentPercent = (currentPage/totalPages*100).toFixed(0)
//       console.log(currentPercent, currentPage, totalPages)
//       setTimeout(()=>{
//         func3("Compressing... ("+currentPercent+"%)")
//       },0)
//       // func3("Compressing...");

//       return pageNum;
//     }));
//   }))
//   promiseAll.then(()=>{
//     console.log("complete: ", promiseAll);
//     setTimeout(()=>{
//       func3("Downloading...")
//     },0)
//     zip.generateAsync({type:'blob'}).then((content) => {
//       saveAs(content, 'brtnx(pdf).zip');
//       func(false)
//       func2(false)
//     });
//   });
// }


export const structAmyloidCSV = ({ ref, atlas, srcData, srcSUVR, srcCentiloid, godThanksSUVR, godThanksCentiloid, defaultCSVFormat } = {}) => {
  let godThanksFormating = godThanksSUVR.items.reduce((arr0, v0) => {
    arr0.region.push(v0.Region)
    arr0.subRegion.push(v0.Region)
    arr0["Total SUVR"].push(v0.Total)
    arr0["Left SUVR"].push(v0.Left)
    arr0["Right SUVR"].push(v0.Right)
    v0.subItem.map(v1 => {
      arr0.region.push(v0.Region)
      arr0.subRegion.push(v1.Region)
      arr0["Total SUVR"].push(v1.Total)
      arr0["Left SUVR"].push(v1.Left)
      arr0["Right SUVR"].push(v1.Right)
    })
    return arr0
  }, { region: [], subRegion: [], "Total SUVR": [], "Left SUVR": [], "Right SUVR": [] })



  let godThanksCentiloidValues = godThanksCentiloid.items.reduce((arr0, v0) => {
    arr0["Total Centiloid"].push(v0.Total)
    arr0["Left Centiloid"].push(v0.Left)
    arr0["Right Centiloid"].push(v0.Right)
    v0.subItem.map(v1 => {
      arr0["Total Centiloid"].push(v1.Total)
      arr0["Left Centiloid"].push(v1.Left)
      arr0["Right Centiloid"].push(v1.Right)
    })
    return arr0
  }, { "Total Centiloid": [], "Left Centiloid": [], "Right Centiloid": [] })


  const SUVR = defaultCSVFormat[1].state
  const Centil = defaultCSVFormat[2].state
  const Total = defaultCSVFormat[0].subOption[0].state;
  const LR = defaultCSVFormat[0].subOption[1].state;

  const statusTableObj = {
    "ST": { state: SUVR && Total, obj: godThanksFormating, targetArr: ['Total SUVR'] },
    "SLR": { state: SUVR && LR, obj: godThanksFormating, targetArr: ['Left SUVR', 'Right SUVR'] },
    "CT": { state: Centil && Total, obj: godThanksCentiloidValues, targetArr: ['Total Centiloid'] },
    "CLR": { state: Centil && LR, obj: godThanksCentiloidValues, targetArr: ['Left Centiloid', 'Right Centiloid'] },
  }

  Object.values(statusTableObj).forEach(val => {
    const state = val.state;
    const obj = val.obj; //str
    const targetArr = val.targetArr; // arr
    targetArr.forEach(target => {
      if (!state) delete obj[target]
    })
  })


  // console.log("after SUVR",godThanksFormating)
  // console.log("after Centiloid", godThanksCentiloidValues)




  // const columnsSUVR = srcSUVR.reduce((columnArr, item)=>{
  //   columnArr.push({'Patient ID':srcData.PatientID, Type: "Left SUVR", var: item.varname.Left, lobe:item.fullname, fullname: item.fullname})
  //   columnArr.push({'Patient ID':srcData.PatientID, Type: "Right SUVR", var: item.varname.Right, lobe:item.fullname, fullname: item.fullname})
  //   const subItem = item.subItem.reduce((columnArr2, subitem)=>{
  //     columnArr2.push({'Patient ID':srcData.PatientID, Type: "Left SUVR", var: subitem.varname.Left, lobe: item.fullname, fullname: subitem.fullname})
  //     columnArr2.push({'Patient ID':srcData.PatientID, Type: "Right SUVR", var: subitem.varname.Right, lobe: item.fullname, fullname: subitem.fullname})
  //     return columnArr2
  //   },[])
  //   columnArr=[...columnArr, ...subItem]
  //   return columnArr
  // },[])


  // const columnsCentiloid = srcCentiloid.reduce((columnArr, item)=>{
  //   columnArr.push({'Patient ID':srcData.PatientID, Type: "Left Centiloid", var: item.varname.Left, lobe:item.fullname, fullname: item.fullname})
  //   columnArr.push({'Patient ID':srcData.PatientID, Type: "Right Centiloid", var: item.varname.Right, lobe:item.fullname, fullname: item.fullname})
  //   const subItem = item.subItem.reduce((columnArr2, subitem)=>{
  //     columnArr2.push({'Patient ID':srcData.PatientID, Type: "Left Centiloid", var: subitem.varname.Left, lobe: item.fullname, fullname: subitem.fullname})
  //     columnArr2.push({'Patient ID':srcData.PatientID, Type: "Right Centiloid", var: subitem.varname.Right, lobe: item.fullname, fullname: subitem.fullname})
  //     return columnArr2
  //   },[])
  //   columnArr=[...columnArr, ...subItem]
  //   return columnArr
  // },[])

  // const columnIndex = columnsSUVR.filter(({Type})=>Type==='Left SUVR').reduce((columnArr, item)=>{
  //   columnArr[0].push(item.lobe)
  //   columnArr[1].push(item.fullname)
  //   return columnArr
  // },[[],[]])

  const columnAllIndex = [
    ["fileID", "Patient ID","Study date","Patient Name", "Atlas", "Reference", "Region", ...godThanksFormating.region],
    ["fileID", "Patient ID","Study date","Patient Name", "Atlas", "Reference", "Subregion", ...godThanksFormating.subRegion],
  ]



  // const columnAllIndex = [
  //   ["fileID", "Patient ID", "Patient Name", "Atlas", "Reference", "Region",   ...columnIndex[0]],
  //   ["fileID", "Patient ID", "Patient Name", "Atlas", "Reference", "Subregion",...columnIndex[1]],
  // ]
  // const columns = [...columnsSUVR, ...columnsCentiloid]

  const CSV_SUVRKeys = Object.keys(godThanksFormating)
  const rowIndexSUVR_source = ['Total SUVR', 'Left SUVR', 'Right SUVR']
  const rowIndexSUVR = CSV_SUVRKeys.filter(x => rowIndexSUVR_source.includes(x))



  const CSV_CentiloidKeys = Object.keys(godThanksCentiloidValues)
  const rowIndexCentiloid_source = ['Total Centiloid', 'Left Centiloid', 'Right Centiloid']
  const rowIndexCentiloid = CSV_CentiloidKeys.filter(x => rowIndexCentiloid_source.includes(x))

  // const rowIndex = ['Total SUVR', 'Left SUVR', 'Right SUVR', 'Total Centiloid', 'Left Centiloid','Right Centiloid']
  const rowsSUVR = rowIndexSUVR.map(item1 => {
    const rowSUVR = godThanksFormating[item1]
    return [srcData.fileID, srcData.PatientID,srcData.AcquisitionDateTime ,srcData.PatientName, atlas, ref.fullName, item1, ...rowSUVR]
  })
  const rowsCentiloid = rowIndexCentiloid.map(item1 => {
    const rowCentiloid = godThanksCentiloidValues[item1]
    return [srcData.fileID, srcData.PatientID,srcData.AcquisitionDateTime ,srcData.PatientName, atlas, ref.fullName, item1, ...rowCentiloid]
  })

  const finalRowsBody = [
    // ...[srcData.fileID, srcData.PatientID, srcData.PatientName, atlas, ref.fullName,...rowsSUVR], 
    // ...[srcData.fileID, srcData.PatientID, srcData.PatientName, atlas, ref.fullName,...rowsCentiloid]
    ...rowsSUVR,
    ...rowsCentiloid
  ]

  return {
    headRows: columnAllIndex,
    bodyRows: finalRowsBody,
  }
}

export const structDATCSV = ({ ref, atlas, srcData, srcSBR, srcSemiquant, godThanksSBR, godThanksSemiquatification, defaultCSVFormat } = {}) => {

  const godThanksFormating = godThanksSBR.items.reduce((arr0, v0) => {
    arr0.region.push(v0.Region)
    arr0.subRegion.push(v0.Region)
    arr0["Total"].push(v0.Total)
    arr0["Left"].push(v0.Left)
    arr0["Right"].push(v0.Right)
    v0.subItem.map(v1 => {
      arr0.region.push(v0.Region)
      arr0.subRegion.push(v1.Region)
      arr0["Total"].push(v1.Total)
      arr0["Left"].push(v1.Left)
      arr0["Right"].push(v1.Right)
    })
    return arr0
  }, { region: [], subRegion: [], "Total": [], "Left": [], "Right": [] })

  const godThanksSemiquatificationValues = godThanksSemiquatification.items.reduce((arr0, v0) => {
    arr0.region.push(v0.Measure)
    arr0.subRegion.push(v0.Region)
    arr0["Total"].push(v0.Total)
    arr0["Left"].push(v0.Left)
    arr0["Right"].push(v0.Right)
    v0.subItem.map(v1 => {
      arr0.region.push(v0.Region)
      arr0.subRegion.push(v1.Region)
      arr0["Total"].push(v1.Total)
      arr0["Left"].push(v1.Left)
      arr0["Right"].push(v1.Right)
    })
    return arr0
  }, { region: [], subRegion: [], "Total": [], "Left": [], "Right": [] })




  const SBR = defaultCSVFormat[1].state
  const Semiq = defaultCSVFormat[2].state
  const Total = defaultCSVFormat[0].subOption[0].state;
  const LR = defaultCSVFormat[0].subOption[1].state;

  const statusTableObj = {
    "ST": { state: SBR && Total, obj: godThanksFormating, targetArr: ["Total"] },
    "SLR": { state: SBR && LR, obj: godThanksFormating, targetArr: ["Left", 'Right'] },
    "CT": { state: Semiq && Total, obj: godThanksSemiquatificationValues, targetArr: ["Total"] },
    "CLR": { state: Semiq && LR, obj: godThanksSemiquatificationValues, targetArr: ["Left", "Right"] },
  }

  Object.values(statusTableObj).forEach(val => {
    const state = val.state;
    const obj = val.obj; //str
    const targetArr = val.targetArr; // arr
    targetArr.forEach(target => {
      if (!state) delete obj[target]
    })
  })


  // const srcSBR_Semiquant = [

  //   ...srcSBR,
  //   ...srcSemiquant
  // ]


  // const columnsSBR_Semiquant = srcSBR_Semiquant.reduce((columnArr, item)=>{
  //   columnArr.push({'Patient ID':srcData.PatientID, Type: "Left", var: item.varname.Left, lobe:item.fullname, fullname: item.fullname})
  //   columnArr.push({'Patient ID':srcData.PatientID, Type: "Right", var: item.varname.Right, lobe:item.fullname, fullname: item.fullname})
  //   const subItem = item.subItem.reduce((columnArr2, subitem)=>{
  //     columnArr2.push({'Patient ID':srcData.PatientID, Type: "Left", var: subitem.varname.Left, lobe: item.fullname, fullname: subitem.fullname})
  //     columnArr2.push({'Patient ID':srcData.PatientID, Type: "Right", var: subitem.varname.Right, lobe: item.fullname, fullname: subitem.fullname})
  //     return columnArr2
  //   },[])
  //   columnArr=[...columnArr, ...subItem]
  //   return columnArr
  // },[])

  // const columnIndex = columnsSBR_Semiquant.filter(({Type})=>Type==='Left').reduce((columnArr, item)=>{
  //   columnArr[0].push(item.lobe)
  //   columnArr[1].push(item.fullname)
  //   return columnArr
  // },[[],[]])

  const columnAllIndex = [
    ["fileID", "Patient ID", "Study date","Patient Name", "Atlas", "Reference", "Region", ...godThanksFormating.region, ...godThanksSemiquatificationValues.region],
    ["fileID", "Patient ID", "Study date","Patient Name", "Atlas", "Reference", "Subregion", ...godThanksFormating.subRegion],
  ]



  // const columns = [...columnsSBR_Semiquant]
  const CSV_Keys = Object.keys(godThanksFormating)
  const rowIndex_source = ['Total', 'Left', 'Right']
  const rowIndex = CSV_Keys.filter(x => rowIndex_source.includes(x))

  const rows = rowIndex.map(item1 => {
    // const row = columns.filter(({Type})=>Type===item1).map(item2=>{
    //   return srcData[item2.var]
    // })
    const rowSBR = godThanksFormating[item1]
    const rowSemiquatification = godThanksSemiquatificationValues[item1]
    return [srcData.fileID, srcData.PatientID, srcData.AcquisitionDateTime, srcData.PatientName, atlas, ref.fullName, item1, ...rowSBR, ...rowSemiquatification]
  })

  return {
    headRows: columnAllIndex,
    bodyRows: rows,
  }
}

export const structFDGTauCSV = ({ ref, atlas, srcData, srcSUVR, godThanksSUVR, defaultCSVFormat } = {}) => {
  const godThanksFormating = godThanksSUVR.items.reduce((arr0, v0) => {
    arr0.region.push(v0.Region)
    arr0.subRegion.push(v0.Region)
    arr0["Total SUVR"].push(v0.Total)
    arr0["Left SUVR"].push(v0.Left)
    arr0["Right SUVR"].push(v0.Right)
    v0.subItem.map(v1 => {
      arr0.region.push(v0.Region)
      arr0.subRegion.push(v1.Region)
      arr0["Total SUVR"].push(v1.Total)
      arr0["Left SUVR"].push(v1.Left)
      arr0["Right SUVR"].push(v1.Right)
    })
    return arr0
  }, { region: [], subRegion: [], "Total SUVR": [], "Left SUVR": [], "Right SUVR": [] })

  const SUVR = defaultCSVFormat[1].state
  const Total = defaultCSVFormat[0].subOption[0].state;
  const LR = defaultCSVFormat[0].subOption[1].state;

  const statusTableObj = {
    "ST": { state: SUVR && Total, obj: godThanksFormating, targetArr: ['Total SUVR'] },
    "SLR": { state: SUVR && LR, obj: godThanksFormating, targetArr: ['Left SUVR', 'Right SUVR'] },
  }

  Object.values(statusTableObj).forEach(val => {
    const state = val.state;
    const obj = val.obj; //str
    const targetArr = val.targetArr; // arr
    targetArr.forEach(target => {
      if (!state) delete obj[target]
    })
  })

  console.log(godThanksFormating)
  // const refFactor = (()=>{
  //   if (ref.shortName == "pons" || ref.shortName == "cerebralWM") return srcData["ratio_gry2pons"];
  //   else if (ref.shortName == "wcrbll") return srcData["ratio_gry2crbl"];
  //   else return 1;
  // })();

  // const columnsSUVR = srcSUVR.reduce((columnArr, item)=>{
  //   columnArr.push({'Patient ID':srcData.PatientID, Type: "Left SUVR", var: item.varname.Left, lobe:item.fullname, fullname: item.fullname})
  //   columnArr.push({'Patient ID':srcData.PatientID, Type: "Right SUVR", var: item.varname.Right, lobe:item.fullname, fullname: item.fullname})
  //   const subItem = item.subItem.reduce((columnArr2, subitem)=>{
  //     columnArr2.push({'Patient ID':srcData.PatientID, Type: "Left SUVR", var: subitem.varname.Left, lobe: item.fullname, fullname: subitem.fullname})
  //     columnArr2.push({'Patient ID':srcData.PatientID, Type: "Right SUVR", var: subitem.varname.Right, lobe: item.fullname, fullname: subitem.fullname})
  //     return columnArr2
  //   },[])
  //   columnArr=[...columnArr, ...subItem]
  //   return columnArr
  // },[])

  // const columnIndex = columnsSUVR.filter(({Type})=>Type==='Left SUVR').reduce((columnArr, item)=>{
  //   columnArr[0].push(item.lobe)
  //   columnArr[1].push(item.fullname)
  //   return columnArr
  // },[[],[]])


  const columnAllIndex = [
    ["fileID", "Patient ID", "Study date","Patient Name", "Atlas", "Reference", "Region", ...godThanksFormating.region],
    ["fileID", "Patient ID", "Study date","Patient Name", "Atlas", "Reference", "Subregion", ...godThanksFormating.subRegion],
  ]
  // const columnAllIndex = [
  //   ["fileID", "Patient ID", "Patient Name", "Atlas", "Reference", "Region",   ...columnIndex[0]],
  //   ["fileID", "Patient ID", "Patient Name", "Atlas", "Reference", "Subregion",...columnIndex[1]],
  // ]
  // const columns = [...columnsSUVR, ...columnsCentiloid]

  const CSV_Keys = Object.keys(godThanksFormating)
  const rowIndex_source = ['Total SUVR', 'Left SUVR', 'Right SUVR']
  const rowIndex = CSV_Keys.filter(x => rowIndex_source.includes(x))

  // const rowIndexSUVR = ['Total SUVR', 'Left SUVR', 'Right SUVR']
  // const rowIndex = ['Total SUVR', 'Left SUVR', 'Right SUVR', 'Total Centiloid', 'Left Centiloid','Right Centiloid']
  const rowsSUVR = rowIndex.map(item1 => {
    const rowSUVR = godThanksFormating[item1]
    return [srcData.fileID, srcData.PatientID, srcData.AcquisitionDateTime, srcData.PatientName, atlas, ref.fullName, item1, ...rowSUVR]
  })
  //  debugger;
  const finalRowsBody = [
    // ...[srcData.fileID, srcData.PatientID, srcData.PatientName, atlas, ref.fullName,...rowsSUVR], 
    // ...[srcData.fileID, srcData.PatientID, srcData.PatientName, atlas, ref.fullName,...rowsCentiloid]
    ...rowsSUVR,
  ]

  return {
    headRows: columnAllIndex,
    bodyRows: finalRowsBody,
  }
}

export const generateCSV = async ({ data, transpose = false, fname } = {}) => {
  const transData = transpose ? data[0].map((_, colIndex) => data.map(row => row[colIndex])) : data;
  // debugger;
  // const csvContent = transData.map(e => {return e.join(";")})
  // const textEncoder = new TextEncoder('windows-1252');
  // const csvContentEncoded = textEncoder.encode(csvContent)
  // var blob = new Blob([csvContentEncoded], {type: 'text/csv;charset=windows-1252;'});
  // saveAs(blob, 'some-data.csv');

  // debugger;
  const csvContent = "data:text/csv;charset=utf-8," + transData.map(e => {
    return e.join(",")
  })
    .join("\r\n");
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", fname);
  // link.setAttribute("download", "brtnx_quantified.csv");
  document.body.appendChild(link);
  link.click();

}

// analysisAmyloidtable1, analysisDATtable1 merge
export const analysisTable1 = ({
    settingOfProduct,
    typeSelect,
    tableItems,
    selectedFile,
    defaultAtlas,
    RF,
    isSelectMultiAtlas,
  } = {}) => {
    const calculateWeightedAverage = (varNames, weights) =>
      varNames.reduce(
        (acc, el, idx) => acc + selectedFile[el] * weights[idx],
        0,
      );

    const selectedAtlas = (atlas, multiAtlas) => {
      return isSelectMultiAtlas
        ? multiAtlas === defaultAtlas
        : atlas === defaultAtlas;
    };

    const filteredList = settingOfProduct.list.filter(
      ({ level, type, atlas, isFolded, multiAtlas }) =>
        selectedAtlas(atlas, multiAtlas) &&
        level === 0 &&
        type === typeSelect &&
        !isFolded,
    );

    const result = filteredList.map((item, idx) => {
      const tableItem = tableItems.tableHead.reduce((obj, head) => {
        if (typeof item.varname.Left === 'string') {
          // 서버에서 직접 받는 값들
          switch (head) {
            case 'Region':
              obj[head] = item.fullname;
              break;
            case 'Total':
              const oldValue =
                (selectedFile[item.varname.Left] +
                  selectedFile[item.varname.Right]) /
                2;
              obj[head] = oldValue * RF;
              break;
            case 'Left':
              obj[head] = selectedFile[item.varname.Left] * RF;
              break;
            case 'Right':
              obj[head] = selectedFile[item.varname.Right] * RF;
              break;
            default:
              break;
          }
          return obj;
        } else {
          // 프론트에서 조합하는 값들
          const RightWeightAcc = item.weight.Right.reduce(
            (acc, val) => acc + val,
            0,
          );
          const LeftWeightAcc = item.weight.Left.reduce(
            (acc, val) => acc + val,
            0,
          );
          const LeftAvg = calculateWeightedAverage(
            item.varname.Left,
            item.weight.Left,
          );

          const RightAvg = calculateWeightedAverage(
            item.varname.Right,
            item.weight.Right,
          );

          switch (head) {
            case 'Region':
              obj[head] = item.fullname;
              break;
            case 'Total':
              const weightedMean2 =
                (RightAvg + LeftAvg) / (RightWeightAcc + LeftWeightAcc);
              obj[head] = weightedMean2 * RF;
              break;
            case 'Left':
              obj[head] = (LeftAvg / LeftWeightAcc) * RF;
              break;
            case 'Right':
              obj[head] = (RightAvg / RightWeightAcc) * RF;
              break;
            default:
              break;
          }

          return obj;
        }
      }, {});

      const subItemList = settingOfProduct.list.filter(
        ({ id, belongToForeignKey, level }) =>
          level === 1 &&
          id !== item.primaryKey &&
          belongToForeignKey === item.primaryKey,
      );

      if (subItemList.length !== 0) {
        tableItem['subItem'] = subItemList.map((subItem) => {
          return tableItems.tableHead.reduce((obj, head) => {
            switch (head) {
              case 'Region':
                obj[head] = subItem.fullname;
                break;
              case 'Total':
                const newTotal =
                  ((selectedFile[subItem.varname.Left] * subItem.weight.Left +
                    selectedFile[subItem.varname.Right] *
                      subItem.weight.Right) /
                    (subItem.weight.Left + subItem.weight.Right)) *
                  RF;
                obj[head] = newTotal;
                break;
              case 'Left':
                obj[head] = selectedFile[subItem.varname.Left] * RF;
                break;
              case 'Right':
                obj[head] = selectedFile[subItem.varname.Right] * RF;
                break;
              default:
                break;
            }
            return obj;
          }, {});
        });
      } else {
        tableItem['subItem'] = [];
      }
      tableItem['id'] = idx;
      return tableItem;
    });

    return { tableHead: tableItems.tableHead, items: result };
  };


export const analysisAmyloidDATcard2_2 = ({ settingOfProduct, typeSelect, tableItems, selectedFile, defaultAtlas, } = {}) => {  
  const result = settingOfProduct.list.filter(({ level, type, atlas, isFolded }) => atlas === defaultAtlas && level === 0 && type === typeSelect && !isFolded).map((item, idx) => {
    const tableItem = tableItems.tableHead.reduce((obj, head, idx) => {
      if (typeof (item.varname.Left) === 'string') {
        if (head === 'Region')
          obj[head] = item.fullname
        else if (head === 'Measure')
          obj[head] = item.fullname
        else if (head === 'Total')
          obj[head] = (selectedFile[item.varname.Left] + selectedFile[item.varname.Right]) / 2
        else if (head === 'Left')
          obj[head] = selectedFile[item.varname.Left]
        else if (head === 'Right')
          obj[head] = selectedFile[item.varname.Right]
        else if (head === 'Centiloid')
          obj[head] = (selectedFile[item.varname.Left] + selectedFile[item.varname.Right]) / 2
        return obj
      } else {
        const RightWeightAcc = item.weight.Right.reduce((sum, val) => {
          return sum + val
        }, 0)
        const LeftWeightAcc = item.weight.Left.reduce((sum, val) => {
          return sum + val
        }, 0)
        // debugger;
        if (head === 'Region')
          obj[head] = item.fullname
        else if (head === 'Total' || head === 'Centiloid')
          //TODO : 펼쳐진 코드 정리
          obj[head] = (() => {
            const RightAvg = item.varname.Right.reduce((acc, el, idx) => {
              const weightRight = item.weight.Right[idx]
              acc += selectedFile[el] * weightRight
              return acc
            }, 0)
            const LeftAvg = item.varname.Left.reduce((acc, el, idx) => {
              const weightLeft = item.weight.Left[idx]
              acc += selectedFile[el] * weightLeft
              return acc
            }, 0)
            const weightedMean2 = (RightAvg + LeftAvg) / (RightWeightAcc + LeftWeightAcc)
            return (weightedMean2)
          })()
        else if (head === 'Left')
          obj[head] = item.varname.Left.reduce((acc, el, idx) => {
            const weightLeft = item.weight.Left[idx]
            acc += selectedFile[el] * weightLeft
            return acc
          }, 0) / LeftWeightAcc
        else if (head === 'Right')
          obj[head] = item.varname.Right.reduce((acc, el, idx) => {
            const weightRight = item.weight.Right[idx]
            acc += selectedFile[el] * weightRight
            return acc
          }, 0) / RightWeightAcc
        return obj

      }
    }, {})

    const subItem = settingOfProduct.list.filter(({ id, belongToForeignKey, level }) => level === 1 && id !== item.primaryKey && belongToForeignKey === item.primaryKey)
    if (subItem.length !== 0) {
      tableItem['subItem'] = subItem.map(item2 => {
        return tableItems.tableHead.reduce((obj, head, idx) => {
          if (head === 'Region')
            obj[head] = item2.fullname
          else if (head === 'Measure')
            obj[head] = item.fullname
          else if (head === 'Total') {
            const oldTotal = (selectedFile[item2.varname.Left] + selectedFile[item2.varname.Right]) / 2
            let newTotal
            try {
              newTotal = (selectedFile[item2.varname.Left] * item2.weight.Left + selectedFile[item2.varname.Right] * item2.weight.Right) / (item2.weight.Left + item2.weight.Right)
            } catch (e) {
              console.log(e)
            }
            // if (Math.abs(oldTotal - newTotal) > 1.0) console.error('please check weighted sum process')
            obj[head] = newTotal
          } else if (head === 'Left')
            obj[head] = selectedFile[item2.varname.Left]
          else if (head === 'Right')
            obj[head] = selectedFile[item2.varname.Right]
          else if (head === 'Centiloid') {
            let newTotal
            try {
              newTotal = (selectedFile[item2.varname.Left] * item2.weight.Left + selectedFile[item2.varname.Right] * item2.weight.Right) / (item2.weight.Left + item2.weight.Right)
            } catch (e) {
              console.log(e)
            }
            obj[head] = newTotal
          }
          return obj
        }, {})
      })
    } else {
      tableItem['subItem'] = []
    }

    tableItem['id'] = idx
    return tableItem
  })
  return { tableHead: tableItems.tableHead, items: result };
}
export const analysisDATcard2_2 = ({ settingOfProduct, typeSelect, tableItems, selectedFile, defaultAtlas, } = {}) => {
  // if (settingOfProduct.list.filter(el=>el.isFolded).length !== 0){

  //   // TODO: setting.list 에 isFolded가 centiloid에 대해서는 반영되지 않음
  // }
  const result = settingOfProduct.list.filter(({ level, type, atlas, isFolded, multiAtlas }) => multiAtlas === defaultAtlas && level === 0 && type === typeSelect && !isFolded).map((item, idx) => {
    // debugger;
    const tableItem = tableItems.tableHead.reduce((obj, head, idx) => {
      if (typeof (item.varname.Left) === 'string') {
        if (head === 'Region')
          obj[head] = item.fullname
        else if (head === 'Measure')
          obj[head] = item.fullname
        else if (head === 'Total')
          obj[head] = (selectedFile[item.varname.Left] + selectedFile[item.varname.Right]) / 2
        else if (head === 'Left')
          obj[head] = selectedFile[item.varname.Left]
        else if (head === 'Right')
          obj[head] = selectedFile[item.varname.Right]
        else if (head === 'Centiloid')
          obj[head] = (selectedFile[item.varname.Left] + selectedFile[item.varname.Right]) / 2
        return obj
      } else {
        const RightWeightAcc = item.weight.Right.reduce((sum, val) => {
          return sum + val
        }, 0)
        const LeftWeightAcc = item.weight.Left.reduce((sum, val) => {
          return sum + val
        }, 0)
        // debugger;
        if (head === 'Region')
          obj[head] = item.fullname
        else if (head === 'Total' || head === 'Centiloid')
          //TODO : 펼쳐진 코드 정리
          obj[head] = (() => {
            const RightAvg = item.varname.Right.reduce((acc, el, idx) => {
              const weightRight = item.weight.Right[idx]
              acc += selectedFile[el] * weightRight
              return acc
            }, 0)
            const LeftAvg = item.varname.Left.reduce((acc, el, idx) => {
              const weightLeft = item.weight.Left[idx]
              acc += selectedFile[el] * weightLeft
              return acc
            }, 0)
            const weightedMean2 = (RightAvg + LeftAvg) / (RightWeightAcc + LeftWeightAcc)
            return (weightedMean2)
          })()
        else if (head === 'Left')
          obj[head] = item.varname.Left.reduce((acc, el, idx) => {
            const weightLeft = item.weight.Left[idx]
            acc += selectedFile[el] * weightLeft
            return acc
          }, 0) / LeftWeightAcc
        else if (head === 'Right')
          obj[head] = item.varname.Right.reduce((acc, el, idx) => {
            const weightRight = item.weight.Right[idx]
            acc += selectedFile[el] * weightRight
            return acc
          }, 0) / RightWeightAcc
        return obj

      }
    }, {})

    const subItem = settingOfProduct.list.filter(({ id, belongToForeignKey, level }) => level === 1 && id !== item.primaryKey && belongToForeignKey === item.primaryKey)
    if (subItem.length !== 0) {
      tableItem['subItem'] = subItem.map(item2 => {
        return tableItems.tableHead.reduce((obj, head, idx) => {
          if (head === 'Region')
            obj[head] = item2.fullname
          else if (head === 'Measure')
            obj[head] = item.fullname
          else if (head === 'Total') {
            const oldTotal = (selectedFile[item2.varname.Left] + selectedFile[item2.varname.Right]) / 2
            let newTotal
            try {
              newTotal = (selectedFile[item2.varname.Left] * item2.weight.Left + selectedFile[item2.varname.Right] * item2.weight.Right) / (item2.weight.Left + item2.weight.Right)
            } catch (e) {
              console.log(e)
            }
            // if (Math.abs(oldTotal - newTotal) > 1.0) console.error('please check weighted sum process')
            obj[head] = newTotal
          } else if (head === 'Left')
            obj[head] = selectedFile[item2.varname.Left]
          else if (head === 'Right')
            obj[head] = selectedFile[item2.varname.Right]
          else if (head === 'Centiloid') {
            let newTotal
            try {
              newTotal = (selectedFile[item2.varname.Left] * item2.weight.Left + selectedFile[item2.varname.Right] * item2.weight.Right) / (item2.weight.Left + item2.weight.Right)
            } catch (e) {
              console.log(e)
            }
            obj[head] = newTotal
          }
          return obj
        }, {})
      })
    } else {
      tableItem['subItem'] = []
    }

    tableItem['id'] = idx
    return tableItem
  })
  return { tableHead: tableItems.tableHead, items: result };
}


export const reportAmyloidtable1 = ({ settingOfProduct, typeSelect, tableItems, selectedFile, defaultAtlas, RF } = {}) => {
  // debugger;
  const result = settingOfProduct.list.filter(({ level, type, atlas, report, isFolded }) => atlas === defaultAtlas && report === true && type === typeSelect && !isFolded).map((item, idx) => {
    const tableItem = tableItems.tableHead.reduce((obj, head, idx) => {
      if (typeof (item.varname.Left) === 'string') {
        if (head === 'Region')
          obj[head] = item.fullname
        else if (head === 'Total') {
          const oldValue = (selectedFile[item.varname.Left] + selectedFile[item.varname.Right]) / 2;
          const totalVar = item.varname.Left.slice(0, -2)
          // const newValue = selectedFile[totalVar]
          obj[head] = (oldValue) * RF
        } else if (head === 'Left')
          obj[head] = (selectedFile[item.varname.Left]) * RF
        else if (head === 'Right')
          obj[head] = (selectedFile[item.varname.Right]) * RF
        return obj
      } else {
        const RightWeightAcc = item.weight.Right.reduce((sum, val) => {
          return sum + val
        }, 0)
        const LeftWeightAcc = item.weight.Left.reduce((sum, val) => {
          return sum + val
        }, 0)

        if (head === 'Region')
          obj[head] = item.fullname
        else if (head === 'Total')
          //TODO : 펼쳐진 코드 정리
          obj[head] = (() => {
            const RightAvg = item.varname.Right.reduce((acc, el, idx) => {
              const weightRight = item.weight.Right[idx]
              acc += selectedFile[el] * weightRight
              return acc
            }, 0)
            const LeftAvg = item.varname.Left.reduce((acc, el, idx) => {
              const weightLeft = item.weight.Left[idx]
              acc += selectedFile[el] * weightLeft
              return acc
            }, 0)
            const weightedMean2 = (RightAvg + LeftAvg) / (RightWeightAcc + LeftWeightAcc)
            return weightedMean2 * RF
          })()
        else if (head === 'Left')
          obj[head] = item.varname.Left.reduce((acc, el, idx) => {
            const weightLeft = item.weight.Left[idx]
            acc += selectedFile[el] * weightLeft
            return acc
          }, 0) / LeftWeightAcc * RF
        else if (head === 'Right')
          obj[head] = item.varname.Right.reduce((acc, el, idx) => {
            const weightRight = item.weight.Right[idx]
            acc += selectedFile[el] * weightRight
            return acc
          }, 0) / RightWeightAcc * RF
        return obj
      }
    }, {})
    tableItem['subItem'] = []
    tableItem['id'] = idx
    return tableItem
  })
  // debugger;
  return { tableHead: tableItems.tableHead, items: result };
}

export const reportDATtable1 = ({ settingOfProduct, typeSelect, tableItems, selectedFile, defaultAtlas, RF } = {}) => {
  const result = settingOfProduct.list.filter(({ level, type, atlas, report, multiAtlas }) => multiAtlas === defaultAtlas && report === true && type === typeSelect).map((item, idx) => {
    // debugger;
    const tableItem = tableItems.tableHead.reduce((obj, head, idx) => {
      if (typeof (item.varname.Left) === 'string') {
        if (head === 'Region')
          obj[head] = item.fullname
        else if (head === 'Total') {
          const oldValue = (selectedFile[item.varname.Left] + selectedFile[item.varname.Right]) / 2;
          const totalVar = item.varname.Left.slice(0, -2)
          // const newValue = selectedFile[totalVar]
          obj[head] = (oldValue) * RF
        }
        else if (head === 'Left')
          obj[head] = (selectedFile[item.varname.Left]) * RF
        else if (head === 'Right')
          obj[head] = (selectedFile[item.varname.Right]) * RF
        return obj
      } else {
        const RightWeightAcc = item.weight.Right.reduce((sum, val) => {
          return sum + val
        }, 0)
        const LeftWeightAcc = item.weight.Left.reduce((sum, val) => {
          return sum + val
        }, 0)
        // debugger;
        if (head === 'Region')
          obj[head] = item.fullname
        else if (head === 'Total')
          //TODO : 펼쳐진 코드 정리
          obj[head] = (() => {
            const RightAvg = item.varname.Right.reduce((acc, el, idx) => {
              const weightRight = item.weight.Right[idx]
              console.log("weightRight1:", weightRight)
              acc += selectedFile[el] * weightRight
              return acc
            }, 0)
            const LeftAvg = item.varname.Left.reduce((acc, el, idx) => {
              const weightLeft = item.weight.Left[idx]
              console.log("weightLeft1:", weightLeft)
              acc += selectedFile[el] * weightLeft
              return acc
            }, 0)
            const weightedMean2 = (RightAvg + LeftAvg) / (RightWeightAcc + LeftWeightAcc)
            return weightedMean2 * RF
          })()
        else if (head === 'Left')
          obj[head] = item.varname.Left.reduce((acc, el, idx) => {
            const weightLeft = item.weight.Left[idx]
            acc += selectedFile[el] * weightLeft
            return acc
          }, 0) / LeftWeightAcc * RF
        else if (head === 'Right')
          obj[head] = item.varname.Right.reduce((acc, el, idx) => {
            const weightRight = item.weight.Right[idx]
            acc += selectedFile[el] * weightRight
            return acc
          }, 0) / RightWeightAcc * RF
        return obj

      }
    }, {})
    tableItem['subItem'] = []
    tableItem['id'] = idx
    return tableItem
  })
  return { tableHead: tableItems.tableHead, items: result };
}

export const reportAmyloidDATtable2 = ({ settingOfProduct, typeSelect, tableItems, selectedFile, defaultAtlas, RF } = {}) => {
  // if (defaultAtlas.includes("Mal")){
    // debugger;
  //   console.log(subRegions)
  // }
  const newTableHead = tableItems.tableHead.filter(el => el !== 'Centiloid');
  const result = settingOfProduct.list.filter(({ level, type, atlas, report, isFolded }) => atlas === defaultAtlas && report && level === 0 && type === typeSelect && !isFolded).map((item, idx) => {
    const tableItem = newTableHead.reduce((obj, head, idx) => {
      if (typeof (item.varname.Left) === 'string') { // 서버에서 받은 값을 그대로 출력시키는 부분
        if (head === 'Region')
          obj[head] = item.fullname
        else if (head === 'Measure')
          obj[head] = item.fullname
        else if (head === 'Total')
          obj[head] = (selectedFile[item.varname.Left] + selectedFile[item.varname.Right]) / 2
        else if (head === 'Left')
          obj[head] = selectedFile[item.varname.Left]
        else if (head === 'Right')
          obj[head] = selectedFile[item.varname.Right]
        // else if (head==='Centiloid')
        //   obj[head]=(selectedFile[item.varname.Left] + selectedFile[item.varname.Right])/2
        return obj
      } else { // custom ROI 에 대해서만 동작하는 부분
        // if (head==='Region')
        //   obj[head]=item.fullname
        // else if (head==='Measure')
        //   obj[head]=item.fullname
        // else if (head==='Total')
        //   obj[head]=25
        // else if (head==='Left')
        //   obj[head]=26
        // else if (head==='Right')
        //   obj[head]=27
        // // else if (head==='Centiloid')
        // //   obj[head]=(selectedFile[item.varname.Left] + selectedFile[item.varname.Right])/2
        // return obj
        // debugger;
        const RightWeightAcc = item.weight.Right.reduce((sum, val) => {
          return sum + val
        }, 0)
        const LeftWeightAcc = item.weight.Left.reduce((sum, val) => {
          return sum + val
        }, 0)
        if (head === 'Region') {
          obj[head] = item.fullname
        } else if (head === 'Measure') {
          obj[head] = item.fullname
        }
        else if (head === 'Total') {
          //TODO : 펼쳐진 코드 정리
          obj[head] = (() => {
            const RightAvg = item.varname.Right.reduce((acc, el, idx) => {
              const weightRight = item.weight.Right[idx]
              acc += selectedFile[el] * weightRight
              return acc
            }, 0)
            const LeftAvg = item.varname.Left.reduce((acc, el, idx) => {
              const weightLeft = item.weight.Left[idx]
              acc += selectedFile[el] * weightLeft
              return acc
            }, 0)
            const weightedMean2 = (RightAvg + LeftAvg) / (RightWeightAcc + LeftWeightAcc)
            return weightedMean2
          })()
        } else if (head === 'Left')
          obj[head] = item.varname.Left.reduce((acc, el, idx) => {
            const weightLeft = item.weight.Left[idx]
            acc += selectedFile[el] * weightLeft
            return acc
          }, 0) / LeftWeightAcc
        else if (head === 'Right')
          obj[head] = item.varname.Right.reduce((acc, el, idx) => {
            const weightRight = item.weight.Right[idx]
            acc += selectedFile[el] * weightRight
            return acc
          }, 0) / RightWeightAcc
        return obj

      }
    }, {})

    const subItem = settingOfProduct.list.filter(({ id, belongToForeignKey, level }) => level === 1 && id !== item.primaryKey && belongToForeignKey === item.primaryKey)
    if (subItem.length !== 0) {
      tableItem['subItem'] = subItem.map(item2 => {
        return newTableHead.reduce((obj, head, idx) => {
          if (head === 'Region')
            obj[head] = item2.fullname
          else if (head === 'Measure')
            obj[head] = item.fullname
          else if (head === 'Total') {
            const oldTotal = (selectedFile[item2.varname.Left] + selectedFile[item2.varname.Right]) / 2
            let newTotal
            try {
              newTotal = (selectedFile[item2.varname.Left] * item2.weight.Left + selectedFile[item2.varname.Right] * item2.weight.Right) / (item2.weight.Left + item2.weight.Right)
            } catch (e) {
              console.log(e)
            }
            // if (Math.abs(oldTotal - newTotal) > 1.0) console.error('please check weighted sum process')
            obj[head] = newTotal
          } else if (head === 'Left')
            obj[head] = selectedFile[item2.varname.Left]
          else if (head === 'Right')
            obj[head] = selectedFile[item2.varname.Right]
          // else if (head==='Centiloid')
          //   obj[head]=(selectedFile[item2.varname.Left] + selectedFile[item2.varname.Right])/2
          return obj
        }, {})
      })
    } else {
      tableItem['subItem'] = []
    }

    tableItem['id'] = idx
    return tableItem
  })
  return { tableHead: newTableHead, items: result };
}

export const viewEnable = (controlOfProduct, selectedFileID) => {
  // if (selectedFileID === null) {
  //   return false;
  // } else {
  //   const sliceReady = controlOfProduct.openedFiles.find(el => el.fileID === selectedFileID).Slices.length;
  //   return sliceReady === 0 ? false : true;
  // }

  return selectedFileID !== null;
}


export const applyReferenceFactor = (temp, selectedFile, settingOfProduct) => {
  // debugger;
  const tracerName = selectedFile['Tracer'];
  const refName = settingOfProduct.defaultRef[tracerName]
  const RF = RefFactor({ refName, ponsRF: selectedFile["ratio_gry2pons"], crblRF: selectedFile["ratio_gry2crbl"] })
  // const RF = (() => {
  //   if (refName === "Pons") return selectedFile["ratio_gry2pons"];
  //   else if (refName === "Whole cerebellum") return selectedFile["ratio_gry2crbl"];
  //   else if (refName === "Cerebellar gray matter") return 1;
  //   else if (refName === "Cerebral white matter") return selectedFile["ratio_gry2pons"];
  //   else return 1;
  // })()

  const apply_ref_temp = temp.items.map(v => {
    return {
      ...v,
      Total: v.Total * RF,
      Left: v.Left * RF,
      Right: v.Right * RF,
      subItem: v.subItem.map(vv => {
        return {
          ...vv,
          Total: vv.Total * RF,
          Left: vv.Left * RF,
          Right: vv.Right * RF,
        }
      })
    }
  })

  return { ...temp, items: apply_ref_temp }
}
export const getVersionInfo = async () => {
  try {
    const versionInfo = await axios.get(process.env.REACT_APP_BASE_URL + `result/download/version_info.txt`).then(res => res).catch(e => null)
    return versionInfo
  } catch (err) {
    // sessionStorage.removeItem('username')
    // sessionStorage.removeItem('token')
    console.log(err);
    return null
  }
}

export const getLicenseInfo = async (productShortNames) => {
  const getAllProductLicenseInfo = async () => {
    try {
      const results = await Promise.all(
        productShortNames.map(( productShortName ) => {
          return axios
            .get(
              process.env.REACT_APP_BASE_URL + `result/download/licenseinfo-${productShortName}.txt`,
            )
            .then((res) => {
              return { productShortName, res };
            })
            .catch((e) => null);
        }),
      );
      return results;
    } catch (err) {
      console.log(err);
    }
  };

  try {
    const results = await getAllProductLicenseInfo();
    let licenseInfo = {
      // amyloid: { valid: false, until: false, count: 0 },
      // dat: { valid: false, until: false, count: 0 },
      // fdg: { valid: false, until: false, count: 0 },
      // tau: { valid: false, until: false, count: 0 },
      // perfusion: { valid: false, until: false, count: 0 },
    };

    const today = new Date();
    productShortNames.forEach((productShortName) => {
      const findLicense = results.find(
        (license) => license.productShortName === productShortName,
      );
      if (findLicense) {
        const { data } = findLicense.res;
        const [endDate, remainCount] = data.split(/\s+/);
        const licenseEndDate =
          endDate === 'inf' ? 'inf' : new Date(endDate);
        const count = remainCount === 'inf' ? 'inf' : Number(remainCount);

        licenseInfo[productShortName] = {
          valid: licenseEndDate === 'inf' || today < licenseEndDate,
          until:
            typeof licenseEndDate === 'string'
              ? licenseEndDate
              : licenseEndDate.toISOString().split('T')[0],
          count,
        };
      }
    });

    return licenseInfo;
  } catch (err) {
    console.log(err);
  }
};


export function CubicInterp2D(orig2D) {
  // dims = arr.shape
  const origDim = [orig2D.length, orig2D[0].length];
  let pad2D = new Array(origDim[0] + 3).fill(0).map(() => new Array(origDim[1] + 3).fill(0));

  for (var i = 0; i < origDim[0]; i++) {
    for (var j = 0; j < origDim[1]; j++) {
      pad2D[i + 1][j + 1] = orig2D[i][j]
    }
  }
  // 2D matrix slicing
  // new_arr = np.zeros([2*dims[0]-1, 2*dims[1]-1])
  const targDim = [origDim[0] * 2 - 1, origDim[1] * 2 - 1];
  let targ2D = new Array(targDim[0]).fill(0).map(() => new Array(targDim[1]).fill(0));

  for (let i = 0; i < origDim[0] - 1; i++) {
    for (let j = 0; j < origDim[1] - 1; j++) {
      // tmp4x4 = pad2D[i:i+4][ j:j+4]
      // if (i == 25 && j == 25){
      //   debugger
      // }
      let tmp4x4 = pad2D.slice(i, i + 4).map(i => i.slice(j, j + 4))
      targ2D[2 * i][2 * j] = pad2D[i + 1][j + 1]
      targ2D[2 * i + 1][2 * j] = Math.max(_cubic(tmp4x4, 0.5, 0), 0)
      targ2D[2 * i][2 * j + 1] = Math.max(_cubic(tmp4x4, 0, 0.5), 0)
      targ2D[2 * i + 1][2 * j + 1] = Math.max(_cubic(tmp4x4, 0.5, 0.5), 0)

      // # Boundary condition
      if (i == origDim[0] - 2 || j == origDim[1] - 2) {
        targ2D[2 * i + 2][2 * j] = pad2D[i + 2][j + 1]
        targ2D[2 * i][2 * j + 2] = pad2D[i + 1][j + 2]
        targ2D[2 * i + 2][2 * j + 1] = Math.max(_cubic(tmp4x4, 0.5, 0), 0)
        targ2D[2 * i + 1][2 * j + 2] = Math.max(_cubic(tmp4x4, 0, 0.5), 0)
        targ2D[2 * i + 2][2 * j + 2] = pad2D[i + 2][j + 2]
      } else {
        continue;
      }

    }
  }
  return targ2D
}
export function _cubic(p, x, y) {
  let a00 = p[1][1]
  let a01 = -.5 * p[1][0] + .5 * p[1][2]
  let a02 = p[1][0] - 2.5 * p[1][1] + 2 * p[1][2] - .5 * p[1][3]
  let a03 = -.5 * p[1][0] + 1.5 * p[1][1] - 1.5 * p[1][2] + .5 * p[1][3]
  let a10 = -.5 * p[0][1] + .5 * p[2][1]
  let a11 = .25 * p[0][0] - .25 * p[0][2] - .25 * p[2][0] + .25 * p[2][2]
  let a12 = -.5 * p[0][0] + 1.25 * p[0][1] - p[0][2] + .25 * p[0][3] + .5 * p[2][0] - 1.25 * p[2][1] + p[2][2] - .25 * p[2][3]
  let a13 = .25 * p[0][0] - .75 * p[0][1] + .75 * p[0][2] - .25 * p[0][3] - .25 * p[2][0] + .75 * p[2][1] - .75 * p[2][2] + .25 * p[2][3]
  let a20 = p[0][1] - 2.5 * p[1][1] + 2 * p[2][1] - .5 * p[3][1]
  let a21 = -.5 * p[0][0] + .5 * p[0][2] + 1.25 * p[1][0] - 1.25 * p[1][2] - p[2][0] + p[2][2] + .25 * p[3][0] - .25 * p[3][2]
  let a22 = p[0][0] - 2.5 * p[0][1] + 2 * p[0][2] - .5 * p[0][3] - 2.5 * p[1][0] + 6.25 * p[1][1] - 5 * p[1][2] + 1.25 * p[1][3] + 2 * p[2][0] - 5 * p[2][1] + 4 * p[2][2] - p[2][3] - .5 * p[3][0] + 1.25 * p[3][1] - p[3][2] + .25 * p[3][3]
  let a23 = -.5 * p[0][0] + 1.5 * p[0][1] - 1.5 * p[0][2] + .5 * p[0][3] + 1.25 * p[1][0] - 3.75 * p[1][1] + 3.75 * p[1][2] - 1.25 * p[1][3] - p[2][0] + 3 * p[2][1] - 3 * p[2][2] + p[2][3] + .25 * p[3][0] - .75 * p[3][1] + .75 * p[3][2] - .25 * p[3][3]
  let a30 = -.5 * p[0][1] + 1.5 * p[1][1] - 1.5 * p[2][1] + .5 * p[3][1]
  let a31 = .25 * p[0][0] - .25 * p[0][2] - .75 * p[1][0] + .75 * p[1][2] + .75 * p[2][0] - .75 * p[2][2] - .25 * p[3][0] + .25 * p[3][2]
  let a32 = -.5 * p[0][0] + 1.25 * p[0][1] - p[0][2] + .25 * p[0][3] + 1.5 * p[1][0] - 3.75 * p[1][1] + 3 * p[1][2] - .75 * p[1][3] - 1.5 * p[2][0] + 3.75 * p[2][1] - 3 * p[2][2] + .75 * p[2][3] + .5 * p[3][0] - 1.25 * p[3][1] + p[3][2] - .25 * p[3][3]
  let a33 = .25 * p[0][0] - .75 * p[0][1] + .75 * p[0][2] - .25 * p[0][3] - .75 * p[1][0] + 2.25 * p[1][1] - 2.25 * p[1][2] + .75 * p[1][3] + .75 * p[2][0] - 2.25 * p[2][1] + 2.25 * p[2][2] - .75 * p[2][3] - .25 * p[3][0] + .75 * p[3][1] - .75 * p[3][2] + .25 * p[3][3]
  let x2 = x * x
  let x3 = x2 * x
  return a00 + (a01 + (a02 + a03 * y) * y) * y + (a10 + (a11 + (a12 + a13 * y) * y) * y) * x + (a20 + (a21 + (a22 + a23 * y) * y) * y) * x2 + (a30 + (a31 + (a32 + a33 * y) * y) * y) * x3
}

export function isThisAFile(maybeFile) {
  return new Promise((resolve, reject) => {
    if (maybeFile.type !== '') return resolve(maybeFile)

    const reader = new FileReader()
    reader.onloadend = () => {
      if (reader.error && (reader.error.name === 'NotFoundError' || reader.error.name === 'NotReadableError')) return reject(reader.error.name)
      resolve(maybeFile)
    }
    reader.readAsBinaryString(maybeFile)
  })
}
import axios from 'axios';

export function check_file(data) {
  return axios.post('testing/check_file/', {
    check_list: data.check_list,
  });
}

export function fetchFiles(data) {
  return axios.get('testing/');
}

export function update_worklist(data) {
  return axios.put('testing/update_filelist/', {
    obj: data.obj,
    addToWorklist: data.addToWorklist,
  });
}

export async function deleteSelection(data) {
  const payload = {
    data: null,
  };
  if (process.env.REACT_APP_IS_CLOUD === 'true') {
    payload.data = {
      files: data.obj,
      product: data.product,
    };
  }
  const results = await axios.delete('testing/', payload);
  if (data.setReloadChecker) {
    const time = new Date().getTime();
    data.setReloadChecker(time);
  }
  return results;
}

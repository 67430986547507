import React from 'react';
import styles from './InputText.module.scss';

export default function InputText({
  title,
  name,
  placeholder,
  value,
  setValue,
  type,
  inputClassName,
}) {
  return (
    <div className={`${styles[`${inputClassName}`]}`}>
      <div className={`${styles['input-title']}`}>{title}</div>
      <div className={`${styles['input-content']}`}>
        <label>
          <input
            type={type}
            name={name}
            value={value?.[name]}
            autoComplete="off"
            onChange={(e) => {
              setValue({ ...value, [e.target.name]: e.target.value });
            }}
            placeholder={placeholder}
          />
        </label>
      </div>
    </div>
  );
}

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { coreItem } from '0_variables/coreItem';
import {
  getTargetPathOnWorklist,
  openSingleFileWithoutSelect,
} from '0_variables/utils';
import {
  selectSingleFile,
  shiftSelectFiles,
  selectAllFiles,
} from '1_reduxs/reducers/filesReducer';
import styles from './FileTable.module.scss';
import FileTableHead from './FileTableHead';
import FileTableRow from './FileTableRow';

export default function FileTable({
  identifier,
  productName,
  tableHead,
  FileList,
  sortClick,
  setSortClick,
  // currPage,
  // setCurrPage,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const productCoreItem = coreItem[productName];
  const CTN_Mode = productCoreItem.CTN_Mode;
  const TracerColorMap = productCoreItem.tracers.reduce((map, tracer) => {
    map[tracer.shortname] = tracer.color;
    return map;
  }, {});
  const TracerNames = productCoreItem.tracers.map((obj) => obj.shortname);
  const [slidingTargetID, setSlidingTargetID] = useState('');
  const [lastClickFileID, setLastClickFileID] = useState();

  const isTFP =
    productName === 'tau' ||
    productName === 'fdg' ||
    productName === 'perfusion';
  const selectedStatus = FileList.some((el) => el.Select === true);
  const filteredTableHead = tableHead.filter((v) =>
    CTN_Mode ? v.title !== 'Centiloid' : true,
  );

  // const [currPage, setCurrPage] = useState(1)
  // const [slicePageNum, setSlicePageNum] = useState(10)
  // const [pageVolume, setPageVolume] = useState(10)
  // const pagenationNum = [...Array(Math.ceil(FileList?.length/slicePageNum)).keys()].map(v=>v+1)
  // const selectAllFileIndex = [...Array(slicePageNum).keys()].map(v=>v+((currPage-1)*slicePageNum))
  // const fileArray = FileList.filter((el,idx)=>selectAllFileIndex.includes(idx))
  // const fileIDArray = fileArray.map(el=>el.id)

  const onClickTableHead = ({ title }) => {
    if (!Object.keys(sortClick).includes(title)) {
      if (title !== 'Select') {
        setSortClick({ [title]: 'ascend' });
      }
    } else {
      if (title !== 'Uploaded date') {
        sortClick[title] === 'ascend'
          ? setSortClick({ [title]: 'descend' })
          : setSortClick({ 'Uploaded date': 'descend' });
      } else {
        sortClick[title] === 'descend'
          ? setSortClick({ [title]: 'ascend' })
          : setSortClick({ 'Uploaded date': 'descend' });
      }
    }
  };
  const handleSelectAll = () => {
    const groupSelector = identifier === 'worklist' ? 1 : 0;
    dispatch(
      selectAllFiles({ groupSelector, productName, status: !selectedStatus }),
    );
  };

  const shiftClickHandelr = (fileID) => {
    const index1 = sortedFileList.findIndex((v) => v.fileID === fileID);
    const index2 = sortedFileList.findIndex(
      (v) => v.fileID === lastClickFileID,
    );
    const idxs = [index1, index2];
    const minID = Math.min(...idxs);
    const maxID = Math.max(...idxs);

    const newFileList = sortedFileList.map((v, index) => ({
      ...v,
      Select: index >= minID && index <= maxID,
    }));

    dispatch(shiftSelectFiles({ items: newFileList }));
  };

  const fileClickHandler = (fileData) => {
    dispatch(selectSingleFile({ fileID: fileData.fileID }));
  };

  const fileDoubleClickHandler = (singleFile) => {
    const targetPage = getTargetPathOnWorklist(
      location.pathname.split('/')[2].toLowerCase(),
    );
    openSingleFileWithoutSelect({
      targetPage,
      history,
      dispatch,
      singleFile,
      fileList: FileList,
      tracerList: TracerNames,
      productName,
    });
  };

  const sortedFileList = FileList.map((fileData) => {
    const { Update, Tracer, PatientID } = fileData;

    // Composite_C - centiloid [amyloid]
    const Composite_C = productName === 'amyloid' ? fileData['Composite_C'] : 0;
    // DAT_Dorsal_striatum - SBR
    const DAT_Dorsal_striatum =
      productName === 'dat' ? fileData['DAT_Dorsal_striatum'] : 0;
    const tracerName = Tracer.replaceAll('18', '\u00B9\u2078').replaceAll(
      '99mtc',
      '\u2079\u2079\u1d50Tc',
    );

    return {
      id: fileData.id,
      fileID: fileData.fileID,
      Opened: fileData.Opened,
      Complete: fileData.Complete,
      Select: fileData.Select,
      Update: Update.substring(0, 16),
      TracerColor: TracerColorMap[Tracer],
      Tracer: tracerName,
      PatientName: fileData.PatientName,
      OriginalFileName: fileData.OriginalFileName,
      PatientID: PatientID.toString(),
      Age: fileData.Age, // DOB
      Sex: fileData.Sex,
      AcquisitionDateTime: fileData.AcquisitionDateTime, //scan date
      Composite_C, // amyloid
      DAT_Dorsal_striatum, // DAT
    };
  }).sort((a, b) => {
    if (Object.keys(sortClick).length !== 0) {
      const targetKey = tableHead.find(
        (v) => v.title === Object.keys(sortClick)[0],
      ).fieldName;
      if (Object.values(sortClick)[0] === 'ascend')
        return a[targetKey] < b[targetKey] ? -1 : 1;
      else if (Object.values(sortClick)[0] === 'descend') {
        return b[targetKey] < a[targetKey] ? -1 : 1;
      } else return b.id - a.id;
    } else {
      return b.id - a.id;
    }
  });

  return (
    <>
      <table className={styles.table}>
        <thead>
          <tr>
            {filteredTableHead.map((head, idx) => (
              <th
                key={idx}
                className={`${styles[head.title.replaceAll(' ', '_')]}`}
                onClick={() => onClickTableHead(head)}
              >
                <FileTableHead
                  columnTitle={head.title}
                  selectedStatus={selectedStatus}
                  sortClick={sortClick}
                  handleSelectAll={handleSelectAll}
                />
              </th>
            ))}
          </tr>
        </thead>
        <tbody id="fileTable_body">
          {sortedFileList.map((fileData, fileIdx) => (
            <tr
              key={fileData.fileID}
              style={{
                background: fileData.Opened ? styles['opened-color'] : '',
                color: fileData.Complete
                  ? 'rgba(255,255,255,1)'
                  : 'rgba(255,255,255,0.3)',
              }}
              className={styles.trMain}
              onClick={(e) => {
                if (e.shiftKey) {
                  shiftClickHandelr(fileData.fileID);
                } else {
                  setLastClickFileID(fileData.fileID);
                  fileClickHandler(fileData);
                }
              }}
              onDoubleClick={() => {
                fileDoubleClickHandler(fileData);
              }}
            >
              <FileTableRow
                key={fileData.fileID}
                productName={productName}
                identifier={identifier}
                isTFP={isTFP}
                fileData={fileData}
                slidingTargetID={slidingTargetID}
                setSlidingTargetID={setSlidingTargetID}
              />
            </tr>
          ))}
        </tbody>
      </table>

      <ReactTooltip anchorSelect=".tooltip-file-table" opacity="1" />
    </>
  );
}

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { subRegions } from '0_variables/subRegions';
import { type1Selector, type2Selector } from '0_variables/utils';
import { openCustomROIModal } from '1_reduxs/reducers/modalReducer';
import { changeSettings } from '1_reduxs/reducers/settingReducer';
import { justCheck as MEDIA_justCheck } from '6_media';
import styles from './CustomROIModal.module.scss';

export default function CustomROIModal(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const productName = params.product.toLowerCase();
  const settingByProduct = useSelector((state) => state.setting[productName]);
  const selectAtlas = settingByProduct.defaultAtlas;
  const subRegionList = settingByProduct.list;
  const [userDefinedROI, setUserDefinedROI] = useState('');
  const [selectedRegions, setSelectedRegions] = useState([]);

  const updateSettingToStore = (updatedSettingValue) => {
    const updatedSettings = {
      ...settingByProduct,
      ...updatedSettingValue,
    };
    dispatch(changeSettings({ productName, updatedSettings }));
  };

  const setSubRegionList = (subRegions) => {
    updateSettingToStore({ list: subRegions });
  };

  const exceptList = {
    amyloid: ['Global', 'Brainstem'],
    dat: ['Dorsal striatum', 'Ventral striatum'],
    fdg: ['Global', 'Brainstem'],
    tau: ['Global', 'Brainstem'],
    perfusion: ['Global', 'Brainstem'],
  };
  const subRegionDefault = subRegions[productName].filter(
    ({ type }) => type === type1Selector[productName],
  );

  const closeHandler = () => {
    setUserDefinedROI('');
    setSelectedRegions([]);
    dispatch(openCustomROIModal({ isOn: false }));
  };

  const saveHandler = (title, list) => {
    var newPrimaryKey =
      Math.max(...subRegionList.map((el) => el.primaryKey)) + 1;
    var newIdentifier =
      Math.max(...subRegionList.map((el) => el.identifier)) + 1;
    const titleObj_card1 = {
      static: false,
      primaryKey: newPrimaryKey,
      belongToForeignKey: newPrimaryKey,
      level: 0,
      type: type1Selector[productName],
      multiAtlas: 'Melbourne Subcortical',
      atlas: selectAtlas,
      belongTo: title,
      fullname: title,

      identifier: newIdentifier,
      report: true,
      // TODO: 나중에 값 계산이 필요함
      varname: {
        Left: list.map(
          (fullname) =>
            subRegionList.find(
              (el) =>
                el.fullname === fullname &&
                el.type === type1Selector[productName],
            ).varname.Left,
        ),
        Right: list.map(
          (fullname) =>
            subRegionList.find(
              (el) =>
                el.fullname === fullname &&
                el.type === type1Selector[productName],
            ).varname.Right,
        ),
      },

      weight: {
        Left: list.map(
          (fullname) =>
            subRegionList.find(
              (el) => el.fullname === fullname && el.level === 1,
            ).weight.Left,
        ),
        Right: list.map(
          (fullname) =>
            subRegionList.find(
              (el) => el.fullname === fullname && el.level === 1,
            ).weight.Right,
        ),
      },
    };

    const titleObj_card2 = {
      static: false,
      primaryKey: newPrimaryKey + 1,
      belongToForeignKey: newPrimaryKey + 1,
      level: 0,
      type: type2Selector[productName],
      atlas: selectAtlas,
      belongTo: title,
      fullname: title,

      identifier: newIdentifier,
      report: true,
      // TODO: 나중에 값 계산이 필요함
      varname: {
        Left: list.map(
          (fullname) =>
            subRegionList.find(
              (el) =>
                el.fullname === fullname &&
                el.type === type2Selector[productName],
            )?.varname.Left,
        ),
        Right: list.map(
          (fullname) =>
            subRegionList.find(
              (el) =>
                el.fullname === fullname &&
                el.type === type2Selector[productName],
            )?.varname.Right,
        ),
      },
      weight: {
        Left: list.map(
          (fullname) =>
            subRegionList.find(
              (el) => el.fullname === fullname && el.level === 1,
            ).weight.Left,
        ),
        Right: list.map(
          (fullname) =>
            subRegionList.find(
              (el) => el.fullname === fullname && el.level === 1,
            ).weight.Right,
        ),
      },
    };

    const listObjArr1 = list.map((el, idx) => ({
      ...subRegions[productName].find(
        ({ fullname, atlas, type }) =>
          fullname === el &&
          atlas === selectAtlas &&
          type === type1Selector[productName],
      ),
      static: false,
      primaryKey: newPrimaryKey + (2 + idx),
      belongToForeignKey: newPrimaryKey,
      report: false,
      belongTo: title,
    }));

    const listObjArr2 = list.map((el, idx) => ({
      ...subRegions[productName].find(
        ({ fullname, atlas, type }) =>
          fullname === el &&
          atlas === selectAtlas &&
          type === type2Selector[productName],
      ),
      static: false,
      primaryKey: newPrimaryKey + (2 + idx + listObjArr1.length),
      belongToForeignKey: newPrimaryKey + 1,
      report: false,
      belongTo: title,
    }));
    // debugger;
    if (productName === 'amyloid') {
      setSubRegionList([
        ...subRegionList,
        titleObj_card1,
        titleObj_card2,
        ...listObjArr1,
        ...listObjArr2,
      ]);
    } else {
      setSubRegionList([
        ...subRegionList,
        titleObj_card1,
        ...listObjArr1,
        ...listObjArr2,
      ]);
    }
    closeHandler();
  };

  return (
    <div
      className={`${styles['container']}`}
      onClick={() => {
        if (window.confirm('Are you sure to close user-defined ROI?')) {
          closeHandler();
        }
      }}
    >
      <div
        className={styles.boxContainer}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className={styles.boxTitle}>User-Defined ROI</div>
        <div className={styles.boxContent}>
          <div className={styles.brain_regions_analyze_subContent}>
            <div>
              <div className={styles.brain_regions_analyze_subContent_title}>
                <div>
                  ROI name :
                  <input
                    name="userDefinedRoi"
                    type="text"
                    placeholder="My ROI name"
                    value={userDefinedROI}
                    onChange={(e) => {
                      if (isNaN(e.target.value)) {
                        setUserDefinedROI(e.target.value);
                      } else if (e.target.value === '') {
                        setUserDefinedROI(e.target.value);
                      } else {
                        alert('A ROI name must start with a letter.');
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            {subRegionDefault
              .filter(
                ({ type, atlas, level, fullname }) =>
                  !exceptList[productName].includes(fullname) &&
                  level === 0 &&
                  atlas === selectAtlas &&
                  type === type1Selector[productName],
              )
              .map((item) => (
                <div key={`sub_regions_${item.identifier}`}>
                  <div
                    className={styles.brain_regions_analyze_subContent_title}
                  >
                    <div>{item.fullname}</div>
                  </div>
                  <div>
                    {subRegionDefault
                      .filter(
                        ({ belongToForeignKey, level }) =>
                          level === 1 && belongToForeignKey === item.primaryKey,
                      )
                      .map((subItem) => (
                        <div
                          key={`sub_regions_child_${subItem.identifier}`}
                          className={
                            styles[
                              `${
                                subItem.fullname.length > 19
                                  ? 'brain_regions_analyze_subContent_subregion_overflow'
                                  : 'brain_regions_analyze_subContent_subregion'
                              }`
                            ]
                          }
                          onClick={() => {
                            const check = selectedRegions.includes(
                              subItem.fullname,
                            );
                            if (check) {
                              const filtered = selectedRegions.filter(
                                (el) => el !== subItem.fullname,
                              );
                              setSelectedRegions([...filtered]);
                            } else {
                              setSelectedRegions([
                                ...selectedRegions,
                                subItem.fullname,
                              ]);
                            }
                          }}
                        >
                          <div>{subItem.fullname}</div>
                          <div>
                            {selectedRegions.includes(subItem.fullname) ? (
                              <img src={MEDIA_justCheck} alt="" />
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div
          className={styles.saveButton}
          onClick={() => {
            if (userDefinedROI === '') {
              alert('Please Enter ROI name.');
            } else {
              if (selectedRegions[0] !== undefined) {
                const title = userDefinedROI;
                const list = selectedRegions;
                saveHandler(title, list);
                closeHandler();
              } else {
                alert('Please select subregion(s).');
              }
            }
          }}
        >
          Add
        </div>
      </div>
    </div>
  );
}

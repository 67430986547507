import React, { useRef, useState, useEffect } from 'react';
import styles from './ReportGraph.module.scss';
import * as d3 from 'd3';
import { clamp } from '0_variables/utils';

export default function ReportGraph(props) {
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  // const containerRef = useRef(null);
  const ref = useRef(null);
  const svg0Ref = useRef(null);
  const svg1Ref = useRef(null);
  const svg2Ref = useRef(null);

  useEffect(() => {
    if (
      props.productName === 'amyloid' ||
      props.productName === 'tau' ||
      props.productName === 'fdg' ||
      props.productName === 'perfusion'
    ) {
      setData1([
        {
          region: 'Global / Composite',
          value: props.selectedFile.Global * props.RF,
        },
        {
          region: 'Frontal',
          value:
            ((props.selectedFile.Frontal_L + props.selectedFile.Frontal_R) /
              2) *
            props.RF,
        },
        {
          region: 'PCC-Precuneus',
          value:
            ((props.selectedFile.PCC_L + props.selectedFile.PCC_R) / 2) *
            props.RF,
        },
        {
          region: 'Lateral temporal',
          value:
            ((props.selectedFile.Lateral_temporal_L +
              props.selectedFile.Lateral_temporal_R) /
              2) *
            props.RF,
        },
        {
          region: 'Lateral parietal',
          value:
            ((props.selectedFile.Lateral_parietal_L +
              props.selectedFile.Lateral_parietal_R) /
              2) *
            props.RF,
        },
        {
          region: 'Medial temporal',
          value:
            ((props.selectedFile.Medial_temporal_L +
              props.selectedFile.Medial_temporal_R) /
              2) *
            props.RF,
        },
        {
          region: 'Occipital',
          value:
            ((props.selectedFile.Occipital_L + props.selectedFile.Occipital_R) /
              2) *
            props.RF,
        },
        {
          region: 'Basal ganglia',
          value:
            ((props.selectedFile.Basal_ganglia_L +
              props.selectedFile.Basal_ganglia_R) /
              2) *
            props.RF,
        },
      ]);
      setData2([
        {
          region: 'Global / Composite',
          value: props.selectedFile.Composite_C * props.RF,
        },
        {
          region: 'Frontal',
          value:
            ((props.selectedFile.Frontal_L_C + props.selectedFile.Frontal_R_C) /
              2) *
            props.RF,
        },
        {
          region: 'PCC-Precuneus',
          value:
            ((props.selectedFile.PCC_L_C + props.selectedFile.PCC_R_C) / 2) *
            props.RF,
        },
        {
          region: 'Lateral temporal',
          value:
            ((props.selectedFile.Lateral_temporal_L_C +
              props.selectedFile.Lateral_temporal_R_C) /
              2) *
            props.RF,
        },
        {
          region: 'Lateral parietal',
          value:
            ((props.selectedFile.Lateral_parietal_L_C +
              props.selectedFile.Lateral_parietal_R_C) /
              2) *
            props.RF,
        },
        {
          region: 'Medial temporal',
          value:
            ((props.selectedFile.Medial_temporal_L_C +
              props.selectedFile.Medial_temporal_R_C) /
              2) *
            props.RF,
        },
        {
          region: 'Occipital',
          value:
            ((props.selectedFile.Occipital_L_C +
              props.selectedFile.Occipital_R_C) /
              2) *
            props.RF,
        },
        {
          region: 'Basal ganglia',
          value:
            ((props.selectedFile.Basal_ganglia_L_C +
              props.selectedFile.Basal_ganglia_R_C) /
              2) *
            props.RF,
        },
      ]);
    } else if (props.productName === 'dat') {
      // debugger;
      setData1([
        {
          // "fileID":props.fileID,
          Category: 'Dorsal striatum',
          Left: Math.max(0.01, props.selectedFile.DAT_Dorsal_striatum_L),
          Right: Math.max(0.01, props.selectedFile.DAT_Dorsal_striatum_R),
        },
        {
          // "fileID":props.fileID,
          Category: 'Caudate',
          Left: Math.max(0.01, props.selectedFile.DAT_Caudate_nucleus_L),
          Right: Math.max(0.01, props.selectedFile.DAT_Caudate_nucleus_R),
        },
        {
          // "fileID":props.fileID,
          Category: 'Putamen A',
          Left: Math.max(0.01, props.selectedFile.DAT_Ant_Putamen_L),
          Right: Math.max(0.01, props.selectedFile.DAT_Ant_Putamen_R),
        },
        {
          // "fileID":props.fileID,
          Category: 'Putamen P',
          Left: Math.max(0.01, props.selectedFile.DAT_Post_Putamen_L),
          Right: Math.max(0.01, props.selectedFile.DAT_Post_Putamen_R),
        },
      ]);
    }
  }, [props.RF, props.productName, props.selectedFile]);

  useEffect(() => {
    if (
      props.productName === 'amyloid' ||
      props.productName === 'fdg' ||
      props.productName === 'tau' ||
      (props.productName === 'perfusion' && data1.length !== 0)
    ) {
      // debugger;
      const val1Max = 2.2;
      const val2Max = 100;
      const svgLabelWidth = ref.current.offsetWidth * 0.2;
      const svgWidth = ref.current.offsetWidth * 0.3;
      const svgHeight = ref.current.offsetHeight * 0.9;
      const gWidth = svgWidth * 0.9;
      const gHeight = svgHeight * 0.8;
      const gSize = {
        width: gWidth,
        height: gHeight,
        leftMargin: svgWidth * 0.05,
        topMargin: svgHeight * 0.1,
        bottomMargin: svgHeight * 0.01,
      };
      const svg0Size = { width: svgLabelWidth, height: svgHeight };
      const svg1Size = { width: svgWidth, height: svgHeight };
      const svg2Size = { width: svgWidth, height: svgHeight };

      // 데이터 스케일 변환
      const x1 = d3.scaleLinear().domain([0, val1Max]).range([0, gSize.width]);

      const x2 = d3.scaleLinear().domain([0, val2Max]).range([0, gSize.width]);

      const y = d3
        .scaleBand()
        .domain(
          data1.map((el) => {
            return el.region;
          }),
        )
        .range([0, gSize.height])
        .paddingInner(0.6);

      // Y축 라벨
      const yAxis = d3.axisLeft(y);
      const svg0 = d3.select(svg0Ref.current);
      svg0.selectAll('*').remove();
      svg0
        .attr('width', svg0Size.width)
        .attr('height', svg0Size.height)
        // .attr("style", "border:3px red solid;")
        .append('g')
        .attr(
          'transform',
          'translate(' + svg0Size.width + ', ' + gSize.topMargin + ')',
        )
        .call(yAxis)
        .selectAll('text')
        .attr('style', 'font-size: 14px');
      svg0.selectAll('path').attr('style', 'stroke-width: 0px');

      // 타이틀
      const svg1Title = d3.select(svg1Ref.current);
      svg1Title.selectAll('*').remove();
      svg1Title
        .append('text')
        .attr(
          'transform',
          'translate(' +
            gSize.width * 0.45 +
            ', ' +
            gSize.topMargin * 0.7 +
            ')',
        )
        .attr('style', 'font-size:16px; font-weight:600')
        .text('SUVR');

      const svg2Title = d3.select(svg2Ref.current);
      svg2Title.selectAll('*').remove();
      svg2Title
        .append('text')
        .attr(
          'transform',
          'translate(' + gSize.width * 0.4 + ', ' + gSize.topMargin * 0.7 + ')',
        )
        .attr('style', 'font-size:16px; font-weight:600')
        .text('Centiloid');

      // X축 라벨
      const x1Axis = d3
        .axisBottom(x1)
        .tickFormat((v) => `${v.toFixed(1)}`)
        .ticks(5);
      const svg1XAxis = d3.select(svg1Ref.current);
      svg1XAxis
        .append('g')
        .attr(
          'transform',
          'translate(' +
            gSize.leftMargin +
            ', ' +
            (gSize.topMargin + gSize.height + gSize.bottomMargin) +
            ')',
        )
        .call(x1Axis)
        .selectAll('text')
        .attr('style', 'font-size: 14px');

      const x2Axis = d3
        .axisBottom(x2)
        .tickFormat((v) => `${v.toFixed(0)}`)
        .ticks(5);
      const svg2XAxis = d3.select(svg2Ref.current);
      svg2XAxis
        .append('g')
        .attr(
          'transform',
          'translate(' +
            gSize.leftMargin +
            ', ' +
            (gSize.topMargin + gSize.height + gSize.bottomMargin) +
            ')',
        )
        .call(x2Axis)
        .selectAll('text')
        .attr('style', 'font-size: 14px');

      // SUVR 그래프
      const svg1Bg = d3.select(svg1Ref.current);
      svg1Bg
        .attr('width', svg1Size.width)
        .attr('height', svg1Size.height)
        // .attr("style", "border:3px black solid")
        .append('g')
        .attr(
          'transform',
          'translate(' + gSize.leftMargin + ', ' + gSize.topMargin + ')',
        )
        .selectAll('rect')
        .data(data1)
        .join('rect')
        .attr('x', 0)
        .attr('y', (d) => {
          return y(d.region);
        })
        .attr('style', 'fill:lightgray; rx:5; ry:5')
        .attr('width', (d) => {
          return x1(val1Max);
        })
        .attr('height', y.bandwidth());
      const svg1 = d3.select(svg1Ref.current);
      svg1
        .attr('width', svg1Size.width)
        .attr('height', svg1Size.height)
        // .attr("style", "border:3px black solid")
        .append('g')
        .attr(
          'transform',
          'translate(' + gSize.leftMargin + ', ' + gSize.topMargin + ')',
        )
        .selectAll('rect')
        .data(data1)
        .join('rect')
        .attr('x', 0)
        .attr('y', (d) => {
          return y(d.region);
        })
        .attr('style', 'fill:red; rx:5; ry:5')
        .attr('height', y.bandwidth())
        .transition()
        .duration(500)
        .attr('width', (d) => {
          return x1(clamp(d.value, 0, val1Max));
        });

      // Centiloid 그래프
      const svg2Bg = d3.select(svg2Ref.current);
      svg2Bg
        .attr('width', svg2Size.width)
        .attr('height', svg2Size.height)
        // .attr("style", "border:3px black solid")
        .append('g')
        .attr(
          'transform',
          'translate(' + gSize.leftMargin + ', ' + gSize.topMargin + ')',
        )
        .selectAll('rect')
        .data(data2)
        .join('rect')
        .attr('x', 0)
        .attr('y', (d) => {
          return y(d.region);
        })
        .attr('style', 'fill:lightgray; rx:5; ry:5')
        .attr('width', (d) => {
          return x2(val2Max);
        })
        .attr('height', y.bandwidth());

      const svg2 = d3.select(svg2Ref.current);
      svg2
        .attr('width', svg2Size.width)
        .attr('height', svg2Size.height)
        // .attr("style", "border:3px black solid")
        .append('g')
        .attr(
          'transform',
          'translate(' + gSize.leftMargin + ', ' + gSize.topMargin + ')',
        )
        .selectAll('rect')
        .data(data2)
        .join('rect')
        .attr('x', 0)
        .attr('y', (d) => {
          return y(d.region);
        })
        .attr('style', 'fill:red; rx:5; ry:5')
        .attr('height', y.bandwidth())
        .transition()
        .duration(500)
        .attr('width', (d) => {
          return x2(clamp(d.value, 0, val2Max));
        });
    } else if (props.productName === 'dat' && data1.length !== 0) {
      var econ2 = data1;

      const svgWidth = ref.current.offsetWidth;
      const svgHeight = ref.current.offsetHeight;
      //chart setup
      var svg = d3.select(svg0Ref.current);
      svg.selectAll('g').remove();
      svg.selectAll('defs').remove();

      var defs = svg.append('defs');
      var margin = { top: 50, right: 120, bottom: 40, left: 120 },
        width = svgWidth - margin.left - margin.right,
        height = svgHeight - margin.top - margin.bottom,
        g = svg
          .append('g')
          .attr(
            'transform',
            'translate(' + margin.left + ',' + margin.top + ')',
          );

      // debugger;
      //y position calculation function
      // const tempMax = Math.max(
      //   ...econ2.map((el) => Math.max(el.Left, el.Right)),
      // );
      // const tempMin = Math.min(
      //   ...econ2.map((el) => Math.min(el.Left, el.Right)),
      // );
      const yscaleMax = 5;
      // debugger;
      // const dataMin = Math.max(0.01, tempMin)
      var y = d3
        .scaleLinear()
        // .domain([0, 5])
        .domain([0, yscaleMax])
        .range([height, 0]);

      var x0 = d3
        .scaleBand() // domain defined below
        .rangeRound([0, width])
        .paddingInner(0.3)
        .paddingOuter(0.5);

      var x1 = d3
        .scaleBand() // domain and range defined below
        .paddingInner(0.3)
        .paddingOuter(0.5);

      //blue, tan, red colors
      var z = d3.scaleOrdinal().range(['#597AB5', '#64C1D6']);
      //reference to the y axis
      //axisLeft put labels on left side
      //ticks(n) refers to # of increment marks on the axis
      const yAxis = d3.axisLeft(y).ticks(3);

      //examine first object, retrieve the keys, and discard the first key
      //return resulting array of keys
      // [ "2017 Q1", "2017 Q2", "2017 Q3 First Estimate"]
      var subCategories = Object.keys(econ2[0]).slice(1);

      //use new array from just the Category values for the bottom x-axis
      x0.domain(econ2.map((d) => d.Category));

      //array of quarterly value names, fitted in the available bottom categories (x0.bandwidth())
      x1.domain(subCategories).rangeRound([0, x0.bandwidth()]);

      // Add bar chart
      var selection = g
        .selectAll('g')
        .data(econ2)
        .enter()
        .append('g')
        .attr('transform', (d) => 'translate(' + x0(d.Category) + ',0)');
      selection
        .selectAll('rect')
        //Use map function with the subCategories array and the Econ2 array
        .data(function (d, idx) {
          const Obj = subCategories.map(function (key, i) {
            return { fileID: props.fileID, id: idx, key: key, value: d[key] };
          });
          return Obj;
        })
        .enter()
        .append('rect')
        .attr('x', (d) => x1(d.key))
        //If the value is negative, put the top left corner of the rect bar on the zero line
        // .attr("y", d => (d.value<0 ? y(0) : y(d.value)) )
        .attr('y', (d) => y(yscaleMax * 0))

        .attr('width', x1.bandwidth())
        // .attr("fill", d => {
        //   return z(d.key)
        // })
        .style('fill', function (d, i) {
          var p = d.value > 4 ? 20 : 0;

          var grad = defs
            .append('linearGradient')
            .attr('x1', '0%')
            .attr('x2', '0%')
            .attr('y1', '0%')
            .attr('y2', '100%')
            .attr('id', 'grad_' + d.fileID + '_' + d.id + '_' + i);

          grad.append('stop').attr('offset', '0%').attr('stop-color', z(d.key));

          if (d.value > 4) {
            grad
              .append('stop')
              .attr('offset', '8%')
              .attr('stop-color', z(d.key));

            grad
              .append('stop')
              .attr('offset', '8%')
              .attr('stop-color', 'white');
            grad
              .append('stop')
              .attr('offset', '10%')
              .attr('stop-color', 'white');

            grad
              .append('stop')
              .attr('offset', '10%')
              .attr('stop-color', z(d.key));
            grad
              .append('stop')
              .attr('offset', '13%')
              .attr('stop-color', z(d.key));

            grad
              .append('stop')
              .attr('offset', '13%')
              .attr('stop-color', 'white');
            grad
              .append('stop')
              .attr('offset', '15%')
              .attr('stop-color', 'white');

            grad
              .append('stop')
              .attr('offset', '15%')
              .attr('stop-color', z(d.key));
            grad
              .append('stop')
              .attr('offset', '18%')
              .attr('stop-color', z(d.key));

            grad
              .append('stop')
              .attr('offset', '18%')
              .attr('stop-color', 'white');
            grad
              .append('stop')
              .attr('offset', p + '%')
              .attr('stop-color', 'white');

            grad
              .append('stop')
              .attr('offset', p + '%')
              .attr('stop-color', z(d.key));
          }

          grad
            .append('stop')
            .attr('offset', '100%')
            .attr('stop-color', z(d.key));

          return 'url(#grad_' + d.fileID + '_' + d.id + '_' + i + ')';
        })
        .attr('rx', '5px')
        // .transition()
        // .duration(500)
        // .attr("height", d => Math.abs(y(d.value) - y(0)) )
        // .attr("height", d => y(yscaleMax*0.5))
        .attr('height', (d) => y(yscaleMax * 1))
        .transition()
        .duration(500)
        // .attr("y", (d,i)=>y(Math.min(4,d.value)))
        .attr('y', (d, i) => y(d.value <= 4 ? d.value : 5))
        // .attr("height", (d,i)=>y(5-Math.min(4, d.value)))
        // .attr("height", (d,i)=>y(5-d.value))
        .attr('height', (d, i) =>
          y(d.value <= 4 ? yscaleMax - d.value : yscaleMax - 5),
        );

      // selection
      //   .transition()
      //   .duration(500)
      //   .attr("y", (d,i)=>y(d.value))
      //   .attr("height", (d, i) => d.value);

      //can not nest the text element inside the rect element !
      selection
        .selectAll('text')
        .data(function (d) {
          return subCategories.map(function (key) {
            return { key: key, value: d[key] };
          });
        })
        .enter()
        .append('text')
        .attr('x', (d) => x1(d.key))
        //offset the position of the y value (positive / negative) to have the text over/under the rect bar
        // .attr("y", d => d.value<=0 ? y(0) - (y(4) - (Math.abs(y(d.value) - y(0)) + 20)) : y(d.value) - 10)
        .attr('y', (d) =>
          d.value <= 0
            ? y(0) - (y(4) - (Math.abs(y(d.value) - y(0)) + 20))
            : d.value <= 4
            ? y(d.value) - 10
            : y(5) - 10,
        )
        .style('fill', (d) => z(d.key))
        .style('font-size', '1em')
        //make sure one just decimal place is displayed
        .text((d) => Number.parseFloat(d.value).toFixed(1));

      //add the x-axis
      g.append('g')
        // .attr("class", "axis")
        .attr('transform', 'translate(0,' + height + ')')
        .call(d3.axisBottom(x0))
        .selectAll('.tick text')
        .attr('style', 'font-size:15px');
      //use wrap function to wrap long lines in labels
      // .call(wrap, x0.bandwidth());

      //add the y-axis - notice it does not have css class 'axis'
      g.append('g')
        // .attr("style","border:1px red solid")
        .call(yAxis);

      //idenitfy zero line on the y axis.
      g.append('line')
        .attr('y1', y(0))
        .attr('y2', y(0))
        .attr('x1', 0)
        .attr('x2', width)
        .attr('stroke', 'gray');

      var legend = g
        .append('g')
        .attr('font-family', 'sans-serif')
        .attr('font-size', '13px')
        .attr('text-anchor', 'start')
        .selectAll('g')
        .data(subCategories)
        .enter()
        .append('g')
        .attr('transform', function (d, i) {
          return 'translate(100,' + i * 24 + ')';
        });
      legend
        .append('rect')
        .attr('x', width - 100)
        .attr('width', 8)
        .attr('height', 8)
        .attr('fill', z);
      legend
        .append('text')
        .attr('x', (d) => (d.length > 7 ? width + 5 : width - 80))
        .attr('y', 5.5)
        .attr('dy', '0.22em')
        .text((d) => d);
    }
  }, [data1, data2, props.fileID, props.productName]);

  return (
    <div
      ref={ref}
      id={`report2-graph-${props.order}`}
      className={styles.d3Graph}
      style={{
        display: props.pageNumber.split('/').at(0) === '3' ? 'none' : '',
      }}
    >
      {(() => {
        if (
          props.productName === 'amyloid' ||
          props.productName === 'tau' ||
          props.productName === 'fdg' ||
          props.productName === 'perfusion'
        ) {
          return (
            <>
              <svg ref={svg0Ref}></svg>
              <svg ref={svg1Ref}></svg>
              {(() => {
                if (props.productName === 'amyloid') {
                  if (props.selectedFile['Tracer'] === '[18F]FPN') {
                    return;
                  } else {
                    return <svg ref={svg2Ref}></svg>;
                  }
                }
              })()}
              {/* {(props.productName==="amyloid") &&
                      <svg ref={svg2Ref}>
                      </svg>
                    } */}
            </>
          );
        } else if (props.productName === 'dat') {
          return (
            <>
              <svg
                ref={svg0Ref}
                className="chart"
                width="800"
                height="250"
                aria-labelledby="graph-title"
                aria-describedby="graph-desc"
              >
                <text
                  style={{ fontSize: '17px', fontWeight: '600' }}
                  transform="translate(100, 20)"
                  className="chartDisplayTitle"
                >
                  Striatal SBR
                </text>
              </svg>
            </>
          );
        }
      })()}
    </div>
  );
}

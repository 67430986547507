import axios from 'axios';

export function Signin(data) {
  return axios.post('testing/one_user_login/', data);
}

export function AuthCheck(data) {
  return axios.get('testing/auth_test/');
}

export function Registration(data) {
  return axios.post('rest-auth/registration/', data);
}

// not used
// export function TokenVerification(data) {
//   return axios.post('api/token/verify/', data);
// }

export function getAuthJWT(data) {
  // return axios.post(IPinUSE+'api/token/', data);
  return axios.post('testing/one_user_login/', data);
}

export function ResetPassword(Auth, data) {
  return axios.post('testing/forgot/', data, { headers: Auth });
}

export function ActivateUser(Auth, data) {
  return axios.post('testing/activate_user/', data, {
    headers: Auth,
  });
}

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai';
import { BiLoaderAlt } from 'react-icons/bi';
import { useToggle } from 'react-use';
import { coreItem, SPECT_status, initProductInfo } from '0_variables/coreItem';
import { viewEnable, closeSingleFile, getLicenseInfo } from '0_variables/utils';
import { updateLicenseAll } from '1_reduxs/reducers/licenseReducer';
import { setSelectedFileID } from '1_reduxs/reducers/controllerReducer';
import { DropDownDiv } from '5_components';
import { info as MEDIA_INFO } from '6_media';
import styles from './Header.module.scss';

export default function Header(props) {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const control = useSelector((state) => state.control);
  const setting = useSelector((state) => state.setting);
  const licenseState = useSelector((state) => state.license);
  const productName = params.product.toLowerCase();
  const productCoreItem = coreItem[productName];
  // const currentPageName = location.pathname.split('/')[2].toLowerCase();
  const CTN_Mode = productCoreItem.CTN_Mode;
  const Cloud_Mode = productCoreItem.Cloud_Mode;
  const tracerObjs = productCoreItem.tracers;
  const tracerList = tracerObjs.map((v) => v.shortname);
  const [hoverState, setHoverState] = useState(null);
  const [productSelected, setProductSelected] = useState(
    coreItem[productName].productName,
  );
  const [licenseRequired, toggleLicenseRequirement] = useToggle(true);
  const tracerColor = tracerObjs.reduce((obj, item) => {
    obj[item.shortname] = item.color;
    return obj;
  }, {});

  const productDropdown = {
    options: Object.keys(licenseState)
      .filter((productName) => {
        const licenseInfoByProduct = licenseState[productName];
        const isPerfusion = productName === 'perfusion';
        const isVisiable = SPECT_status || !isPerfusion;

        return licenseInfoByProduct.valid && isVisiable;
      })
      .map((productName) => coreItem[productName].productName), // 제품이름 정의된 case로 보여주기
    selectItem: productSelected,
    setItem: (val) => {
      if (setting.changed) {
        alert('Please save the changes before leaving the page.');
      } else {
        const productName = val.toLowerCase();
        productTransition(productName);
        setProductSelected(val);
      }
    },
  };

  const productTransition = (productName) => {
    // if (
    //   currentPageName === 'dashboard' ||
    //   currentPageName === 'upload' ||
    //   currentPageName === 'setting'
    // ) {
    //   const targetUrl = `/${productName}/${currentPageName}`;
    //   history.push(targetUrl);
    // } else {
    //   const targetUrl = `/${productName}/upload`;
    //   history.push(targetUrl);
    // }

    history.push(`/${productName}/upload`);
  };

  useEffect(() => {
    async function updateLicenseInfoALL() {
      const productShortNames = initProductInfo.map((v) => v.productShortName);
      const licenseResult = await getLicenseInfo(productShortNames);
      dispatch(updateLicenseAll(licenseResult));
    }

    if (licenseRequired && licenseState[productName].isInitailized === false) {
      toggleLicenseRequirement();
      updateLicenseInfoALL();
    }
  }, [
    licenseRequired,
    licenseState,
    productName,
    dispatch,
    toggleLicenseRequirement,
  ]);

  useEffect(() => {
    // 주소창으로 접근했는데 selectedFileID가 null인 경우, upload 페이지로 리디렉션
    if (control[productName].selectedFileID === null) {
      const currentPage = location.pathname.split('/')[2].toLowerCase();
      switch (currentPage) {
        case 'view':
          history.push(`/${productName}/upload`);
          break;
        case 'analysis':
          history.push(`/${productName}/upload`);
          break;
        case 'report':
          history.push(`/${productName}/upload`);
          break;
        default:
          break;
      }
    }
  }, [control, history, location.pathname, productName]);

  const tabSwitch = (currentPage, targetFileID) => {
    switch (currentPage) {
      case 'view':
        history.push(`/${productName}/${currentPage}/${targetFileID}`);
        break;
      case 'analysis':
        history.push(`/${productName}/${currentPage}/${targetFileID}`);
        break;
      case 'report':
        history.push(`/${productName}/${currentPage}/${targetFileID}`);
        break;
      default:
        break;
    }
  };

  const headerEnable = (currentPage) => {
    if (
      currentPage === 'dashboard' ||
      currentPage === 'upload' ||
      currentPage === 'setting'
    ) {
      return false;
    } else {
      return true;
    }
  };

  const headerTabText = (item) => {
    return (
      <span
        className={styles.fileInfo}
        style={{
          opacity: viewEnable(control[productName], item.fileID) ? 1 : 0.3,
          borderColor: tracerColor[item.Tracer],
        }}
      >
        {item.PatientName}
      </span>
    );
  };

  const headerTabIcon = (item, viewEnabled) => {
    if (viewEnabled) {
      return (
        <span
          className={styles.closeIcon}
          onClick={(e) => {
            e.stopPropagation();
            closeSingleFile(
              dispatch,
              item,
              location.pathname.split('/')[2].toLowerCase(),
              productName,
              history,
            );
          }}
        >
          {/* {item.fileID} */}
          <AiOutlineClose />
        </span>
      );
    } else {
      return (
        <span className={styles.loadIcon}>
          <BiLoaderAlt />
        </span>
      );
    }
  };

  const headerTabContainer = (item) => {
    return (
      <div
        key={item.fileID}
        style={{
          opacity: headerEnable(location.pathname.split('/')[2].toLowerCase())
            ? 1
            : 0.4,
        }}
        className={
          control[productName].selectedFileID === item.fileID
            ? styles.activeTab
            : ''
        }
        onClick={() => {
          if (headerEnable(location.pathname.split('/')[2].toLowerCase())) {
            dispatch(setSelectedFileID({ productName, selectedFile: item }));
            const currentPage = location.pathname.split('/')[2].toLowerCase();
            const targetFileID = item.fileID;
            tabSwitch(currentPage, targetFileID);
          }
        }}
        onMouseEnter={() => {
          if (hoverState === null) setHoverState(item.fileID);
        }}
        onMouseOver={() => {
          if (hoverState === null) setHoverState(item.fileID);
        }}
        onMouseLeave={() => {
          setHoverState(null);
        }}
      >
        {headerTabText(item)}
        {headerTabIcon(item, viewEnable(control[productName], item.fileID))}
      </div>
    );
  };

  return (
    <div className={`${styles['container']}`}>
      <div className={`${styles['tabgroup']}`}>
        {control[productName].openedFiles
          .filter(({ Tracer }) => tracerList.includes(Tracer))
          .map((item) => headerTabContainer(item))}
      </div>
      <div className={`${styles['infogroup']}`}>
        {!CTN_Mode && (
          <div className={`${styles['product']}`}>
            {Cloud_Mode && (
              <div>
                <img
                  src={MEDIA_INFO}
                  data-tip
                  data-for="tooltip"
                  alt="infomation"
                />
              </div>
            )}
            <DropDownDiv
              style={{ background: '#202223' }}
              item={productDropdown}
              styleName={'headerStyle'}
            />
          </div>
        )}
      </div>
    </div>
  );
}

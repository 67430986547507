import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { changeSettings } from '1_reduxs/reducers/settingReducer';
import {
  Check as MEDIA_Check,
  unCheck as MEDIA_unCheck,
  justCheck as MEDIA_justCheck,
} from '6_media';
import styles from './ReportFormats.module.scss';

export default function ReportFormats() {
  const dispatch = useDispatch();
  const params = useParams();
  const productName = params.product.toLowerCase();
  const settingByProduct = useSelector((state) => state.setting[productName]);
  const pInfoState = settingByProduct.defaultPInfoState;
  const [pInfoCheckBox, setPInfoCheckBox] = useState(
    pInfoState.map((el) => el.state).every((el) => el === true),
  );

  useEffect(() => {
    setPInfoCheckBox(
      pInfoState.map((el) => el.state).every((el) => el === true),
    );
  }, [pInfoState]);

  const updateSettingToStore = (updatedSettingValue) => {
    const updatedSettings = {
      ...settingByProduct,
      ...updatedSettingValue,
    };
    dispatch(changeSettings({ productName, updatedSettings }));
  };

  const pInfoSet = (state) => {
    setPInfoCheckBox(state);
    updateSettingToStore({
      defaultPInfoState: [
        { name: 'Patient Name', state: state },
        { name: 'Patient ID', state: state },
        { name: 'DOB', state: state },
        { name: 'Sex', state: state },
        { name: 'Type & Date of Examination', state: state },
      ],
    });
  };

  return (
    <div className={styles['container']}>
      <div className={styles['title']}>
        <div
          onClick={() => {
            pInfoSet(!pInfoCheckBox);
          }}
        >
          <img
            src={pInfoCheckBox ? MEDIA_Check : MEDIA_unCheck}
            alt="checkbox"
          />
        </div>
        <div>Patient Information</div>
      </div>
      <div className={styles['checkList']}>
        {pInfoState.map((item, idx) => {
          return (
            <div
              key={idx}
              onClick={() => {
                const updatedPInfoState = pInfoState.map((obj) =>
                  obj.name === item.name
                    ? { ...obj, state: !item.state }
                    : { ...obj },
                );
                const allCheckPinfo = updatedPInfoState
                  .map((el) => el.state)
                  .some((el) => el === true);
                allCheckPinfo
                  ? setPInfoCheckBox(true)
                  : setPInfoCheckBox(false);
                updateSettingToStore({
                  defaultPInfoState: updatedPInfoState,
                });
              }}
            >
              <div>{item.name}</div>
              <div style={{ visibility: `${item.state ? '' : 'hidden'}` }}>
                <img src={MEDIA_justCheck} alt="checkbox" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { coreItem } from '0_variables/coreItem';
import { PageLayout, CardLayout, Information } from '5_components';
import { AnalysisCard1, AnalysisCard2, AnalysisCard3 } from './components';

export default function Analysis(props) {
  const location = useLocation();
  const params = useParams();
  const fileList = useSelector((state) => state.fileList);
  const pageName = location.pathname.split('/')[2];
  const productName = params.product.toLowerCase();
  const productCoreItem = coreItem[productName];

  const [expandedComponent, setExpandedComponent] = useState('');

  const analysisPageTop = () => {
    const burgerProps = {
      Enable: true,
      isViewContainer: false,
      isWorklist: false,
    };

    return <Information burgerProps={burgerProps} />;
  };

  const analysisPageBottom = () => {
    const makeCardComponents = (tracerName) => {
      const noCentiloidOption = ['fdg', 'tau', 'perfusion'];
      const isHideAnalysisCard2_2 =
        tracerName === '[18F]FPN' || noCentiloidOption.includes(productName);

      return {
        Analysis_card1: {
          content: <AnalysisCard1 />,
          cardHeight: '100%',
          isExpandable: false,
          isExpanded: false,
        },
        Analysis_card2_1: {
          content: <AnalysisCard2 dataType="card2_1" />,
          cardHeight: expandedComponent === 'Analysis_card2_2' ? '10%' : '100%',
          isExpandable: !isHideAnalysisCard2_2,
          isExpanded: expandedComponent === 'Analysis_card2_1',
        },
        Analysis_card2_2: !isHideAnalysisCard2_2 && {
          content: <AnalysisCard2 dataType="card2_2" />,
          cardHeight: expandedComponent === 'Analysis_card2_1' ? '10%' : '100%',
          isExpandable: !isHideAnalysisCard2_2,
          isExpanded: expandedComponent === 'Analysis_card2_2',
        },
        Analysis_card3: productName !== 'dat' && {
          content: <AnalysisCard3 />,
          cardHeight: '100%',
          isExpandable: false,
          isExpanded: false,
        },
      };
    };

    const makeCardItem = () => {
      let cardItem = [];
      const selectedFile = fileList.find(
        ({ fileID }) => fileID === params.fileID,
      );

      if (selectedFile) {
        const tracerName = selectedFile['Tracer'];
        const cardComponents = makeCardComponents(tracerName);

        cardItem = productCoreItem[pageName].bottom.map((bottomBlock) =>
          bottomBlock.cards
            .filter(
              (card) =>
                tracerName !== '[18F]FPN' ||
                card.title !== 'Regional Centiloid', // 'Regional Centiloid' card remove
            )
            .map((card) => {
              const { content, cardHeight, isExpandable, isExpanded } =
                cardComponents[card.componentName];

              return {
                ...card,
                content,
                cardHeight,
                isExpandable,
                isExpanded,
              };
            }),
        );
      }

      return cardItem;
    };

    const cardItemList = makeCardItem();

    return (
      <>
        {cardItemList.map((cardItem, i) => (
          <CardLayout
            key={i}
            cards={cardItem}
            setExpanded={(componentName) =>
              setExpandedComponent((prevState) =>
                prevState === componentName ? '' : componentName,
              )
            }
          />
        ))}
      </>
    );
  };

  return (
    <PageLayout
      topContent={analysisPageTop}
      bottomContent={analysisPageBottom}
    />
  );
}

import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import InputRange from 'react-input-range';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { gsap } from 'gsap';
import { MdPlayArrow, MdPause, MdOutlineRefresh } from 'react-icons/md';
import { coreItem } from '0_variables/coreItem';
import { updateViewOption } from '1_reduxs/reducers/viewOptionReducer';
import { Information, DropDownDiv, Burger } from '5_components';
import {
  viewOverlayOff as MEDIA_viewOverlayOff,
  viewOverlayOn as MEDIA_viewOverlayOn,
  viewCrosshairOff as MEDIA_viewCrosshairOff,
  viewCrosshairOn as MEDIA_viewCrosshairOn,
  viewSnOff as MEDIA_viewSnOff,
  viewSnOn as MEDIA_viewSnOn,
  viewInvertOff as MEDIA_viewInvertOff,
  viewInvertOn as MEDIA_viewInvertOn,
} from '6_media';
import styles from './Container.module.scss';
import '5_components/14_InputRange/InputRange.scss';

export const ViewTopMenu = () => {
  const dispatch = useDispatch();
  const {
    clipConfig,
    opacity,
    colorMap,
    showCrosshair,
    showNormalized,
    showInverted,
    window_max_out,
    window_min_out,
    window_max_in,
    window_min_in,
  } = useSelector((state) => state.viewOption);
  const params = useParams();
  const productName = params.product.toLowerCase();
  const productCoreItem = coreItem[productName];
  const CTN_Mode = productCoreItem.CTN_Mode;

  const defaultOpacity = CTN_Mode ? 1 : productName === 'dat' ? 1 : 0.6;
  const [opacityBackup, setOpacityBackup] = useState(opacity);

  const defaultWindowMax = 32767;
  const defaultWindowMin = 0;

  const changeViewOption = (optionName, updateValue) => {
    dispatch(
      updateViewOption({
        optionName,
        updateValue,
      }),
    );
  };

  const burgerProps = {
    Enable: true,
    isViewContainer: true,
    isWorklist: false,
  };
  const cmapDropdown = {
    selectItem: colorMap,
    options: ['invertGray', 'hot', 'jet', 'pet'],
    setItem: (val) => {
      changeViewOption('colorMap', val);
    },
  };

  const iconScaleUp = ({ currentTarget }) => {
    gsap.to(currentTarget, { scale: 1.2 });
  };

  const iconScaleReset = ({ currentTarget }) => {
    gsap.to(currentTarget, { scale: 1 });
  };

  const refreshIconRotate = ({ currentTarget }) => {
    gsap.to(currentTarget, { rotation: '+=360' });
  };

  return (
    <>
      <div className={styles.viewController}>
        <div className={styles.mip}>
          <span>MIP </span>
          <span
            className={styles.reactIcon}
            onClick={() =>
              changeViewOption('clipConfig', {
                ...clipConfig,
                status: !clipConfig.status,
              })
            }
            role="button"
            aria-label="mip play/pause button"
          >
            {clipConfig.status ? (
              <MdPause
                className="reactIcon"
                onMouseEnter={iconScaleUp}
                onMouseLeave={iconScaleReset}
                size={'1.7vw'}
              />
            ) : (
              <MdPlayArrow
                className="reactIcon"
                onMouseEnter={iconScaleUp}
                onMouseLeave={iconScaleReset}
                size={'1.7vw'}
              />
            )}
          </span>
          <span className={styles.inputRange}>
            <div className="singleSidedInput">
              <InputRange
                step={1}
                maxValue={50}
                minValue={0}
                value={clipConfig.speed}
                onChange={(value) => {
                  changeViewOption('clipConfig', {
                    ...clipConfig,
                    speed: value,
                  });
                }}
              />
            </div>
          </span>
        </div>
        {!CTN_Mode && (
          <div className={styles.overlay}>
            <span>Template</span>
            <span
              className={styles.reactIcon}
              onClick={() => {
                if (opacity === 1) {
                  changeViewOption('opacity', opacityBackup);
                } else {
                  changeViewOption('opacity', 1);
                  setOpacityBackup(opacity);
                }
              }}
              style={{ border: '0px solid red' }}
              role="button"
              aria-label="opacity on/off button"
            >
              <img
                className={styles.overlayOnIcon}
                src={opacity < 1 ? MEDIA_viewOverlayOff : MEDIA_viewOverlayOn}
                onMouseEnter={iconScaleUp}
                onMouseLeave={iconScaleReset}
                alt="overlay"
              />
            </span>
            <span
              className={styles.reactIcon}
              onClick={() => {
                changeViewOption('opacity', defaultOpacity);
              }}
              role="button"
              aria-label="opacity set default value button"
            >
              <MdOutlineRefresh
                className="reactIcon"
                color={'white'}
                size={'1.7vw'}
                onMouseEnter={iconScaleUp}
                onMouseLeave={iconScaleReset}
                onClick={refreshIconRotate}
              />
            </span>
            <span className={styles.inputRange}>
              <div className="singleSidedInput">
                {/* step을 0.05로 하면 맥스 1로 올라가질 않음.
                ex : 0.9500000000000001 */}
                <InputRange
                  step={5}
                  maxValue={100}
                  minValue={0}
                  value={opacity * 100}
                  onChange={(updatedValue) => {
                    changeViewOption('opacity', updatedValue / 100);
                  }}
                />
              </div>
            </span>
          </div>
        )}
        <div className={styles.intensity}>
          <span>
            {CTN_Mode ? 'Intensity' : productName === 'dat' ? 'SBR' : 'SUVR'}
          </span>
          <span
            className={styles.reactIcon}
            onClick={() => {
              if (showNormalized) {
                changeViewOption('window_max_out', defaultWindowMax);
                changeViewOption('window_min_out', defaultWindowMin);
              } else {
                changeViewOption('window_max_in', defaultWindowMax);
                changeViewOption('window_min_in', defaultWindowMin);
              }
            }}
          >
            <MdOutlineRefresh
              color={'white'}
              size={'1.7vw'}
              className="reactIcon"
              onMouseEnter={iconScaleUp}
              onMouseLeave={iconScaleReset}
              onClick={refreshIconRotate}
            />
          </span>
          <span className={styles.inputRange}>
            <div className="doubleSidedInput">
              <InputRange
                draggableTrack
                step={10}
                maxValue={32767}
                minValue={0}
                value={(() => {
                  if (showNormalized) {
                    return { min: window_min_out, max: window_max_out };
                  } else {
                    return { min: window_min_in, max: window_max_in };
                  }
                })()}
                onChange={(value) => {
                  const min = Math.max(defaultWindowMin, value.min);
                  const max = Math.min(defaultWindowMax, value.max);
                  if (showNormalized) {
                    changeViewOption('window_max_out', max);
                    changeViewOption('window_min_out', min);
                  } else {
                    changeViewOption('window_max_in', max);
                    changeViewOption('window_min_in', min);
                  }
                }}
              />
            </div>
          </span>
        </div>
        <div className={styles.btnGroup}>
          <span
            onClick={() => changeViewOption('showCrosshair', !showCrosshair)}
            className={`${styles.reactIcon} tooltip-target`}
            style={{ width: '1.7vw', height: '1.7vw' }}
            data-tooltip-content={
              showCrosshair ? 'Show Crosshair' : 'Hide Crosshair'
            }
            role="button"
            aria-label="crosshair on/off button"
          >
            <img
              src={
                showCrosshair ? MEDIA_viewCrosshairOn : MEDIA_viewCrosshairOff
              }
              style={{ width: '80%', height: '80%' }}
              alt=""
              className="reactIcon"
              onMouseEnter={iconScaleUp}
              onMouseLeave={iconScaleReset}
            />
          </span>
          {!CTN_Mode && (
            <span
              onClick={() =>
                changeViewOption('showNormalized', !showNormalized)
              }
              className={`${styles.reactIcon} tooltip-target`}
              style={{ width: '1.7vw', height: '1.7vw' }}
              data-tooltip-content={showNormalized ? 'Normalized' : 'Original'}
              role="button"
              aria-label="normalize on/off button"
            >
              <img
                src={showNormalized ? MEDIA_viewSnOn : MEDIA_viewSnOff}
                style={{ width: '80%', height: '80%' }}
                alt=""
                className="reactIcon"
                onMouseEnter={iconScaleUp}
                onMouseLeave={iconScaleReset}
              />
            </span>
          )}
          <span
            onClick={() => changeViewOption('showInverted', !showInverted)}
            className={`${styles.reactIcon} tooltip-target`}
            style={{ width: '1.7vw', height: '1.7vw' }}
            data-tooltip-content={showInverted ? 'Invert' : 'Original'}
            role="button"
            aria-label="invert on/off button"
          >
            <img
              src={showInverted ? MEDIA_viewInvertOn : MEDIA_viewInvertOff}
              style={{ width: '80%', height: '80%' }}
              alt=""
              className="reactIcon"
              onMouseEnter={iconScaleUp}
              onMouseLeave={iconScaleReset}
            />
          </span>
        </div>
        <div className={styles.colormap}>
          <span>
            <DropDownDiv
              style={{
                width: '13.3vw',
                height: '100%',
                background: '#34383C',
              }}
              item={cmapDropdown}
              styleName="view_drop_container"
            />
          </span>
        </div>
        {!CTN_Mode && (
          <div className={styles.burger}>
            <Burger {...burgerProps} />
          </div>
        )}
      </div>

      <ReactTooltip anchorSelect=".tooltip-target" place="bottom" opacity="1" />
      <Information burgerProps={null} />
    </>
  );
};

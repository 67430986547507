import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { coreItem } from '0_variables/coreItem';
import {
  RefFactor,
  analysisTable1,
  analysisAmyloidDATcard2_2,
} from '0_variables/utils';

import { QuantTable } from '5_components';
import { spinnerV2 as MEDIA_spinnerV2 } from '6_media';

import styles from './Analysis_card2.module.scss';

export default function AnalysisCard2(props) {
  const dataType = props.dataType; // card2_1, card2_2 중 하나
  const [trClick, setTrClick] = useState([]);
  const [sortClick, setSortClick] = useState({ id: 'ascend' });

  const PAGE_NAME = 'analysis'; // analysis에서만 사용됨
  const params = useParams();
  const productName = params.product.toLowerCase();

  const settingOfProduct = useSelector((state) => state.setting[productName]);
  const defaultAtlas = settingOfProduct.defaultAtlas;
  const selectedFile = useSelector((state) =>
    state.fileList.find(({ fileID }) => fileID === params.fileID),
  );

  const [items, setItems] = useState([]);
  const [tableHead, setTableHead] = useState([]);

  useEffect(() => {
    const tracerName = selectedFile['Tracer'];
    const refName = settingOfProduct.defaultRef[tracerName];
    const defaultAtlas = settingOfProduct.defaultAtlas;

    function getData() {
      const tableItems =
        coreItem[productName][PAGE_NAME].bottom[1].cards[
          dataType === 'card2_1' ? 0 : 1
        ];
      const RF = RefFactor({
        productName,
        refName,
        ponsRF: selectedFile['ratio_gry2pons'],
        crblRF: selectedFile['ratio_gry2crbl'],
        wmRF: selectedFile['ratio_gry2wm'],
        cgRF: selectedFile['ratio_gry2cg'],
        global: 1 / selectedFile['Global'],
      });

      const dataForCard =
        dataType === 'card2_1'
          ? analysisTable1({
              settingOfProduct,
              typeSelect: coreItem[productName].type1Selector,
              tableItems: tableItems,
              selectedFile: selectedFile,
              defaultAtlas,
              RF,
              isSelectMultiAtlas: productName === 'dat',
            })
          : analysisAmyloidDATcard2_2({
              settingOfProduct,
              typeSelect: coreItem[productName].type2Selector,
              tableItems,
              selectedFile: selectedFile,
              defaultAtlas,
            });

      setTableHead(dataForCard.tableHead);
      setItems(dataForCard.items);
    }

    getData();
  }, [dataType, productName, selectedFile, settingOfProduct]);

  return (
    <div className={styles.container}>
      {!items.length && (
        <div className={styles.loading}>
          <>
            <span className={styles.loadIcon}>
              <img
                src={MEDIA_spinnerV2}
                style={{ width: '50%' }}
                alt="spinnerV2"
              />
            </span>
            <span className={styles.loadMsg}>{'Loading...'}</span>
          </>
        </div>
      )}

      {items.length && (
        <QuantTable
          tableHead={tableHead}
          items={items}
          trClick={trClick}
          setTrClick={setTrClick}
          sortClick={sortClick}
          setSortClick={setSortClick}
          defaultAtlas={defaultAtlas}
          reportTooltip={true}
        />
      )}
    </div>
  );
}

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  spinner: { status: false, percent: 0, message: '' },
  customROI: { status: false },
  pacsModal: { status: false, setReloadChecker: null },
  sendedDicomModal: { status: false },
  uploadModal: { status: false, setReloadChecker: null },
  expiredModal: { status: false },
  versionModal: { status: false },
  reportPacsModal: { status: false },
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openSpinner: (state, action) => {
      // payload  = {status, length?, percent?, message?, target?}
      state.spinner = { ...state.spinner, ...action.payload };
    },
    openPacsModal: (state, action) => {
      state.pacsModal.status = action.payload.isOn;
      state.pacsModal.setReloadChecker = action.payload.setReloadChecker;
    },
    openSendedDicomModal: (state, action) => {
      state.sendedDicomModal.status = action.payload.isOn;
    },
    openUploadModal: (state, action) => {
      state.uploadModal.status = action.payload.isOn;
      state.uploadModal.setReloadChecker = action.payload.setReloadChecker;
    },
    openExpiredModal: (state, action) => {
      state.expiredModal.status = action.payload.isOn;
    },
    openVersionModal: (state, action) => {
      state.versionModal.status = action.payload.isOn;
    },
    openCustomROIModal: (state, action) => {
      state.customROI.status = action.payload.isOn;
    },
    openReportPacsModal: (state, action) => {
      state.reportPacsModal.status = action.payload.isOn;
    },
  },
});

export const {
  openSpinner,
  openPacsModal,
  openSendedDicomModal,
  openUploadModal,
  openExpiredModal,
  openVersionModal,
  openCustomROIModal,
  openReportPacsModal,
} = modalSlice.actions;

// thunk
export function openSpinnerThunk(values) {
  return async (dispatch, getState) => {
    dispatch(openSpinner(values));

    const spinnerState = getState().modal.spinner;
    return spinnerState;
  };
}

export default modalSlice.reducer;
